import React, { useEffect, useRef, useState } from 'react';
import ReporteTabla from '../Modulos/ReporteTabla'
import Barra from '../Modulos/Barra';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { FaSearch } from "@react-icons/all-files/fa/FaSearch";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { FaExpand } from '@react-icons/all-files/fa/FaExpand';
import { FaCompress } from '@react-icons/all-files/fa/FaCompress';
import './Css/reportes.css'
import axios from '../utils/axiosConfig';
import Swal from 'sweetalert2';
import ReporteTabla3 from '../Modulos/ReporteTabla3';

var miStorage = window.localStorage;
export default function Reportes() {

  const [groupedData, setGroupedData] = useState([]);
  const [data, setData] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandedRowId, setExpandedRowId] = useState(null);
  const [idPowerBiSelected, setIdPowerBiSelected] = useState();
  const [bitOpen, setBitOpen] = useState(false);

  const HandlePageByRole = async () => {
    let data = {
      pagina: "10",
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );

      if (res.data.success === false) {
        window.location.href = "/";
      } else {
        console.log("BIt permisos: ", res.data.data);
        if (res.data.data.length > 0) {
          setBitOpen(res.data.data[0].bitOpen);
          setBitInsert(res.data.data[0].bitInsert);
          setBitEdit(res.data.data[0].bitEdit);
          setBitDelete(res.data.data[0].bitDelete);

          if (res.data.data[0].bitOpen) {
            console.warn("Tienes todos los permisos");
          } else {
            Swal.fire({
              title: 'Información',
              text: 'No tiene los permisos necesarios para acceder a esta página',
              icon: 'info',
              showConfirmButton: false,
              timer: 3000,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              },
            }).then(() => {
              window.location.href = "/principal"; // Redirect to another page
            });
          }
        }
      }
    } catch (error) {
      console.error("Error al listar bit permisos:", error);
    }
  };

  const columns = [
    {
      name: "Categorías",
      selector: "categoryName",
      sortable: false, // Desactiva el ordenamiento
      cell: (row) => {
        if (!row.isReport) {
          // Para categorías
          return (
            <div
              onClick={() => handleRowClicked(row)}
              style={{ cursor: "pointer", fontWeight: "bold" }}
            >
              {row.categoryName}
            </div>
          );
        } else {
          // Para reportes
          return (
            <div
              onClick={(e) => {
                e.stopPropagation(); // Evitar que el clic afecte al contenedor padre
                handleRowClicked(row);
              }}
              style={{
                paddingLeft: "20px",
                color: "#566A7F",
                cursor: "pointer",
              }}
            >
              {row.categoryName}
            </div>
          );
        }
      },
    },
  ];


  const tableData = {
    columns,
    data: groupedData.flatMap((item) => {
      const isExpanded = expandedRows.includes(item.categoryName); // Verifica si la categoría está expandida
      return [
        item,
        ...(isExpanded
          ? item.reports.map((report) => ({
            ...report,
            categoryName: report.NombreReporte,
            isReport: true, // Marca como reporte
          }))
          : []),
      ];
    }),
  };

  // Función para manejar clics en una fila
  const handleRowClicked = (row) => {
    if (!row.isReport) {
      // Si es una categoría, alternar su expansión/colapso
      setExpandedRows((prevExpandedRows) => {
        if (prevExpandedRows.includes(row.categoryName)) {
          // Si ya está expandida, colapsarla
          return prevExpandedRows.filter((name) => name !== row.categoryName);
        } else {
          // Si no está expandida, agregarla
          return [...prevExpandedRows, row.categoryName];
        }
      });
    } else {
      // Si es un reporte, manejar el clic y enviar su ID
      console.log("Reporte seleccionado:", row.Id_PowerBI);
      setIdPowerBiSelected(row.Id_PowerBI);

      // Aquí puedes manejar lógica adicional, como cargar datos o redirigir
    }
  };



  useEffect(() => {
    HandlePageByRole();

    axios
      .get(process.env.REACT_APP_SERVER + "/operaciones/getreportesbi", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
      })
      .then((res) => {
        if (res.data.success === false) {
          window.location.href = "/";
        } else {
          const fetchedData = res.data.data;
          setData(fetchedData);

          // Ordenar reportes alfabéticamente dentro de cada categoría
          const grouped = Object.values(
            fetchedData.reduce((acc, item) => {
              if (!acc[item.ReportCategoria]) {
                acc[item.ReportCategoria] = {
                  categoryName: item.ReportCategoria,
                  reports: [],
                };
              }
              acc[item.ReportCategoria].reports.push(item);
              return acc;
            }, {})
          );

          // Ordenar las categorías alfabéticamente
          grouped.sort((a, b) => a.categoryName.localeCompare(b.categoryName));

          // Ordenar los reportes dentro de cada categoría
          grouped.forEach((group) => {
            group.reports.sort((a, b) => a.NombreReporte.localeCompare(b.NombreReporte));
          });

          setGroupedData(grouped);
        }
      })
      .catch((error) => console.error(error));
  }, []);


  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">


          <div className="layout-page">
            <Barra />
            {bitOpen && (
              <div className="content-wrapper">

                <div className="container-fluid flex-grow-1 container-p-y">
                  <MDBRow>
                    <MDBCol md='8'>
                      <h4 className="fw-bold mb-4">Business Intelligence</h4>
                    </MDBCol>
                  </MDBRow>

                  <div class="row">
                    <div class="col-sm-3">
                      <div class="row">
                        <div class="col-sm-12 mb-3">
                          <div class="card">
                            <div class="card-body">
                              {/* <h5 class="card-title"><i className="bx bx-file"></i> Eventos</h5>
                              <p class="card-text">Reporte de eventos del proyecto</p> */}
                              <DataTableExtensions
                                {...tableData} export={false} print={false} defaultSortAsc={true}
                              >
                                <DataTable
                                  defaultSortField="id"
                                  defaultSortAsc={true}
                                  highlightOnHover
                                  export={false}
                                  onRowClicked={handleRowClicked}
                                  conditionalRowStyles={[
                                    {
                                      when: (row) => row.isReport, // Estilo para reportes
                                      style: {
                                        paddingLeft: "20px",
                                        color: "#566A7F",
                                        cursor: "pointer", // Asegura que sea clickeable
                                        textDecoration: "none",
                                      },
                                    },
                                    {
                                      when: (row) => !row.isReport, // Estilo para categorías
                                      style: {
                                        fontWeight: "bold",
                                        color: "#566A7F",
                                        cursor: "pointer", // También clickeable para expandir/colapsar
                                      },
                                    },
                                  ]}
                                />


                              </DataTableExtensions>
                              {/* <button onClick={actualizarTabla} class="btn btn-secondary"><i class='bx bx-loader bx-spin-hover'></i> Cargar reportes</button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-9" >
                      {/* <div class="card" > */}
                      {/* <div class="card-body" > */}
                      {/* Generar boton para maximixar el grafico powerBI */}
                      {/* <ReporteTabla onActualizarTabla={contador} idPowerBi={idPowerBiSelected} /> */}
                      {idPowerBiSelected ? (
                        <ReporteTabla3 reportId={idPowerBiSelected} data={data} />
                      ) : (
                        <div className="text-center">
                          <h4 className="fw-bold mb-4">Seleccione un reporte para visualizar</h4>
                          <i className='bx bxs-report bx-lg'></i>
                        </div>
                      )}
                      {/* </div> */}
                      {/* </div> */}

                    </div>
                  </div>
                </div>

                <footer className="content-footer footer bg-footer-theme">
                  <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                    <div className="mb-2 mb-md-0">
                      ©, Producto realizado por <a href="https://logiseguridad.com" target="_blank" className="footer-link fw-bolder"> Logiseguridad Ltda</a>
                    </div>

                  </div>
                </footer>

                <div className="content-backdrop fade"></div>
              </div>
            )}
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </>
  );
}
