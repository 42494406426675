// App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Trafico from './Pages/Trafico';
import Trayecto from './Pages/Trayecto';
import Login from './Pages/Login';
import Historico from './Pages/Historico';
import Reportes from './Pages/Reportes';
import Roles from './Pages/Roles';
import Usuarios from './Pages/Usuarios';
import ControlDevices from './Pages/ControlDevices';
import ControlDevice from './Pages/ControlDevice';
import AzureUpload from './Pages/AzureUpload';
import AuthenticatedApp from "./AuthenticatedApp";
import TokenServiceRefresh from "./tokenServiceRefresh";
import Solicitudes from "./Pages/Solicitudes";
import Principal from "./Pages/Principal";
import MyProfile from "./Pages/MyProfile";
import Valitronics from "./Pages/Valitronics";
import CrearContrato2 from "./Pages/CrearContrato2";
import ReporteTabla2 from "./Modulos/ReporteTabla2";
import ReporteTabla3 from "./Modulos/ReporteTabla3";
import ReporteTabla from "./Modulos/ReporteTabla";
import ControlContracts from "./Pages/ControlContracts";

function App() {
    // Función para bloquear la navegación de flechas en los selects
    const handleKeyDown = (e) => {
        if (e.target.tagName === 'SELECT' && ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(e.key)) {
            e.preventDefault(); // Evitar la navegación con las flechas en los <select>
        }
    };

    // useEffect que registra el evento global para todos los select
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup: Remover el event listener cuando se desmonte el componente App
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <Router>
            <AuthenticatedApp>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/principal" element={<Principal />} />
                    <Route path="/trafico" element={<Trafico />} />
                    <Route path="/trayecto" element={<Trayecto />} />
                    <Route path="/historico" element={<Historico />} />
                    <Route path="/reportes" element={<Reportes />} />
                    <Route path="/roles" element={<Roles />} />
                    <Route path="/usuarios" element={<Usuarios />} />
                    <Route path="/controldevices" element={<ControlDevices />} />
                    <Route path="/controldevice" element={<ControlDevice />} />
                    <Route path="/azureupload" element={<AzureUpload />} />
                    <Route path="/solicitudes" element={<Solicitudes />} />
                    <Route path="/myprofile" element={<MyProfile />} />
                    <Route path="/valitronics" element={<Valitronics />} />
                    <Route path="/crearContrato2" element={<CrearContrato2 />} />
                    <Route path="/controlContracts" element={<ControlContracts />} />
                    {/* <Route path="/reporteTabla2" element={<ReporteTabla2 />} />*/}
                    <Route path="/reporteTabla3" element={<ReporteTabla3 />} />
                </Routes>
            </AuthenticatedApp>
        </Router>
    );
}

export default App;
