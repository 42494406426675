import React, { useState, useEffect, useCallback } from 'react';

const StaticMapRecorrido = ({ recorrido, ultRecorrido }) => {
  const [mapRecorrido, setMapRecorrido] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState(null);
  const [mapKey, setMapKey] = useState(0);

  // Actualiza el estado del recorrido cuando cambia la prop
  useEffect(() => {
    setLoading(true); // Se activa el loader ANTES de actualizar el recorrido
    setMapRecorrido(recorrido);
    console.log("Recorrido actualizado:", recorrido);
  }, [recorrido]);

  // Optimizar la función updateImage con useCallback
  const updateImage = useCallback((path) => {
    if (!path.length) return;

    setLoading(true); // Se asegura de que el loader se active antes de la carga

    try {
      const filteredPath = path.filter(punto => punto.latitude !== 0 && punto.longitude !== 0);
      let tolerance = filteredPath.length > 2000 ? 0.2 : filteredPath.length > 1000 ? 0.1 : 0.05;
      const simplifiedPath = simplifyPath(filteredPath, tolerance);
      const polyline = simplifiedPath.map(point => `${point.latitude},${point.longitude}`).join('|');

      const newImageUrl = `https://maps.googleapis.com/maps/api/staticmap?size=330x330&path=${polyline}&style=feature:all|element:labels|visibility:on&style=feature:all|element:geometry|saturation:0&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

      setTimeout(() => {  // Simular carga real y evitar flickering
        setImageUrl(newImageUrl);
        setMapKey(prevKey => prevKey + 1);
        setLoading(false);
      }, 1000); // Tiempo de espera antes de actualizar el mapa
    } catch (err) {
      console.error("Error al actualizar la imagen del mapa:", err);
      setLoading(false);
    }
  }, []);

  // Llama a updateImage solo cuando mapRecorrido cambia
  useEffect(() => {
    if (mapRecorrido.length > 0) {
      updateImage(mapRecorrido);
    }
  }, [mapRecorrido, updateImage]);

  // Función para simplificar el path y reducir la cantidad de puntos
  const simplifyPath = (path, tolerance) => {
    const simplifiedPath = [path[0]];

    for (let i = 1; i < path.length - 1; i++) {
      const prevPoint = simplifiedPath[simplifiedPath.length - 1];
      const currentPoint = path[i];
      const distance = calculateDistance(prevPoint, currentPoint);

      if (distance > tolerance) {
        simplifiedPath.push(currentPoint);
      }
    }

    simplifiedPath.push(path[path.length - 1]);
    return simplifiedPath;
  };

  // Función para calcular distancia entre puntos
  const calculateDistance = (point1, point2) => {
    return Math.sqrt(
      Math.pow(point2.latitude - point1.latitude, 2) +
      Math.pow(point2.longitude - point1.longitude, 2)
    );
  };

  return (
    <div style={{ height: '330px', width: '100%' }} className='imgUltimoRecorrido'>
      {loading ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        imageUrl && (
          <img
            key={mapKey} // Forzar re-render cuando cambia la ruta
            onLoad={() => setLoading(false)}
            onError={() => setLoading(false)}
            src={imageUrl}
            style={{ display: loading ? 'none' : 'block' }} // No mostrar la imagen mientras carga
            alt="Mapa del recorrido"
          />
        )
      )}
    </div>
  );
};

export default StaticMapRecorrido;
