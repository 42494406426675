import React, { useEffect } from 'react';
import Gallery from '../../../Modulos/Gallery';

const ModalGaleria = ({
    isOpen,
    contratoseleccionado,
    infocontratoContainer,
    eliminar,
    OpenTracking,
    OpenMonitoreo2,
    OpenConsolidado
}) => {
    useEffect(() => {
        console.log("Valor de visibilidad del modal:", isOpen);
        console.log("Props de ModalGaleria:", { isOpen, contratoseleccionado, infocontratoContainer, eliminar, OpenTracking, OpenMonitoreo2 });
    }, [isOpen, contratoseleccionado, infocontratoContainer, eliminar, OpenTracking, OpenMonitoreo2]);

    return (
        <div className="modal-dialog modal-xl">
            <form className="modal-content" id="ModalGaleriaW">
                <div className="modal-header">
                    <h5 className="modal-title" id="backDropModalTitle">
                        FOTOS CONTRATO
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={eliminar}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <Gallery
                            contrato={contratoseleccionado}
                            infoContrato={infocontratoContainer}
                            tracking={OpenTracking}
                            monitoreo={OpenMonitoreo2}
                            consolidado={OpenConsolidado}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ModalGaleria;
