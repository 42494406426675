import React, { useState, useRef, useEffect } from "react";
import Swal from "sweetalert2";

const ModalFoto = (props) => {
    const [latitud, setLatitud] = useState("0.00");
    const [longitud, setLongitud] = useState("0.00");
    const [fechaFoto, setFechaFoto] = useState("");
    const [eventoSeleccionado, setEventoSeleccionado] = useState(props.eventoSeleccionado || props.listEventos[0]?.ID_Evento);
    const fileInputRef = useRef(null); // Para acceder a los archivos seleccionados
    const username = localStorage.getItem("username");

    const UploadPhoto = () => {
        console.log("Subiendo Foto...");
        console.log("Contrato Seleccionado:", props.contratoseleccionado);

        const pattern = /\d+\.\d{2,}$/;
        if (!pattern.test(latitud) || !pattern.test(longitud)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Latitud y Longitud deben ser números válidos con al menos dos decimales.",
                timer: 2000,
                showConfirmButton: false,
            });
            return;
        }

        try {
            const fecha = new Date(fechaFoto);
            // console.log(fecha);
            if (isNaN(fecha.getTime())) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Fecha inválida. Selecciona una fecha y hora válidas.",
                    timer: 2000,
                    showConfirmButton: false,
                });
                return;
            }

            const year = fecha.getFullYear();
            const mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
            const dia = ("0" + fecha.getDate()).slice(-2);
            const hora = ("0" + fecha.getHours()).slice(-2);
            const minuto = ("0" + fecha.getMinutes()).slice(-2);
            const segundos = "25";
            const fechacompleta = `${year}_${mes}_${dia}_${hora}_${minuto}_${segundos}`;

            const files = fileInputRef.current.files;
            const formData = new FormData();

            for (let i = 0; i < files.length; i++) {
                const newName = `${fechacompleta}_${i}_${props.device}_${props.placa}_${eventoSeleccionado}_${Math.floor(Math.random() * 1000)}_${username}_${latitud}_${longitud}_${props.selectedContrato}_${props.FKLokProyecto}_${props.FKICEmpresa}`;

                if (files[i].type.startsWith("image/")) {
                    formData.append("files", files[i], newName + ".jpg");
                } else if (files[i].type.startsWith("video/")) {
                    formData.append("files", files[i], newName + ".mp4");
                }
                console.log("Nombre de la foto ajustado:", newName);
            }

            // Imprimir el contenido del FormData
            console.log("FormData:");
            for (let [key, value] of formData.entries()) {
                console.log(key, value);
            }

            Swal.fire({
                title: "Subiendo Foto...",
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            Swal.close();
            props.onClose();
            Swal.fire({
                icon: "success",
                title: "Foto subida con éxito",
                timer: 1000,
                showConfirmButton: false,
            });


            fetch(`${process.env.REACT_APP_SERVER}/upload`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                    Swal.close();
                    if (data.hasOwnProperty("success")) {
                        Swal.fire({
                            icon: "success",
                            title: "Foto subida con éxito",
                            timer: 1000,
                            showConfirmButton: false,
                        });
                        props.onClose(); // Cierra el modal correctamente
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "No se pudo subir la foto.",
                        });
                    }
                })
                .catch((error) => {
                    Swal.close();
                    console.error("Error subiendo foto:", error);
                });
        } catch (err) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Hubo un problema al procesar la solicitud.",
            });
            console.log(err);
        }
    };

    useEffect(() => {
        console.log("Data", props);
        console.log("Data: datos contrato", props.data);
        console.log("fecha inicio", props.data.fechainicio);
    }, []);

    useEffect(() => {
        console.log("Evento Seleccionado:", eventoSeleccionado);
    }, [eventoSeleccionado]);

    useEffect(() => {
        if (props.data) {
            setFechaFoto(props.data.fechainicio);
        }
    }, [props])

    return (
        <>
            <div className="modal-body">
                <div className="row">
                    <div className="mb-3">
                        <label className="form-label" htmlFor="contrato">Contrato</label>
                        <div className="input-group input-group-merge">
                            <span className="input-group-text">
                                <i className="bx bx-file-blank"></i>
                            </span>
                            <input type="text" className="form-control" value={props.selectedContrato} readOnly />
                        </div>
                    </div>

                    <div className="mb-3">
                        <label className="form-label" htmlFor="latitud">Latitud</label>
                        <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-map-pin"></i></span>
                            <input type="text" className="form-control" value={latitud} onChange={(e) => setLatitud(e.target.value)} />
                        </div>
                    </div>

                    <div className="mb-3">
                        <label className="form-label" htmlFor="longitud">Longitud</label>
                        <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-map-pin"></i></span>
                            <input type="text" className="form-control" value={longitud} onChange={(e) => setLongitud(e.target.value)} />
                        </div>
                    </div>

                    <div className="mb-3">
                        <label className="form-label" htmlFor="fechafoto">Hora/Fecha</label>
                        <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-calendar"></i></span>
                            <input type="datetime-local" className="form-control" value={fechaFoto} onChange={(e) => setFechaFoto(e.target.value)} min={props.data.fechainicio} max={props.data.fechafin ? props.data.fechafin : undefined} />
                        </div>
                    </div>

                    <div className="mb-3">
                        <label className="form-label" htmlFor="eventos">Evento</label>
                        <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-file-blank"></i></span>
                            <select className="form-select" value={eventoSeleccionado} onChange={(e) => setEventoSeleccionado(e.target.value)}>
                                {props.listEventos?.map((loc) => (
                                    <option key={loc.ID_Evento} value={loc.ID_Evento}>{loc.Descripcion}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="mb-3">
                        <label className="form-label" htmlFor="fotos">Fotos</label>
                        <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-file"></i></span>
                            <input type="file" className="form-control" multiple ref={fileInputRef} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal-footer">
                <button type="button" className="btn btn-outline-secondary" onClick={props.onClose}>Cerrar</button>
                <button type="button" className="btn btn-primary" onClick={UploadPhoto}>Guardar</button>
            </div>
        </>
    );
};

export default ModalFoto;
