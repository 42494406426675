import React, { useEffect, useState } from 'react'
import "../../App.css";
import "../../Pages/Css/trafico.css";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import MenuContratos from '../menuContratos/menuContratos';


const TraficoResponsive = (props) => {
    const [moreInfo, setMoreInfo] = useState(null);
    const showMoreInfo = (index) => {
        if (moreInfo === index) {
            setMoreInfo(null); // Ocultar si ya está seleccionado
        } else {
            setMoreInfo(index); // Mostrar información adicional para el índice seleccionado
        }
    };

    const [filterContratos, setFilterContratos] = useState(props.filteredContratos || []);

    useEffect(() => {
        console.log("Responsive Table trafico ==> ", props.contratosList);
        console.log("Responsive Table trafico filtros search ==> ", props.filteredContratos);
    }, [props]);

    useEffect(() => {
        let arrayFilter = [...props.filteredContratos];
        const originalArray = [...props.filteredContratos];
        if (props.isMovingActive) {
            arrayFilter = arrayFilter.filter((contrato) => contrato.IconMoving.includes("moving"));
        } else if (props.isStopActive) {
            arrayFilter = arrayFilter.filter((contrato) => contrato.IconMoving.includes("stop"));
        } else if (props.isStopAlertActive) {
            arrayFilter = arrayFilter.filter((contrato) => contrato.IconMoving.includes("stopAlert"));
        } else if (props.isDesvioAlertActive) {
            arrayFilter = arrayFilter.filter((contrato) => contrato.IconDesvio.includes("desvioalerta"));
        } else if (props.isLowBatteryActive) {
            arrayFilter = arrayFilter.filter((contrato) => contrato.icon_bat.includes("batt-low"));
        } else if (props.isOpenActive) {
            arrayFilter = arrayFilter.filter((contrato) =>
                contrato.IconLocked.includes("candado") ||
                contrato.IconLocked.includes("openedpadlockalerta")
            );
        } else if (props.isTimeExchangeActive) {
            const thirtyMinutesInMs = 30 * 60 * 1000;
            arrayFilter = arrayFilter.filter((contrato) => {
                const loksysServerTime = new Date(contrato.LoksysServerTime);
                const currentTime = new Date();
                currentTime.setHours(currentTime.getHours() - 5);
                const difference = currentTime - loksysServerTime;
                return difference >= thirtyMinutesInMs;
            });
        } else if (props.isRefuerzoActive) {
            arrayFilter = arrayFilter.filter((data) => {

                const anexos = originalArray.filter((contratoAnexo) =>
                    // Validamos que PlacaTruck y ContainerNum sean válidos (ni null ni vacíos)
                    (contratoAnexo.PlacaTruck !== null && contratoAnexo.PlacaTruck !== '') &&
                    (data.PlacaTruck !== null && data.PlacaTruck !== '') &&
                    // (contratoAnexo.ContainerNum !== null && contratoAnexo.ContainerNum !== '') &&
                    // (data.ContainerNum !== null && data.ContainerNum !== '') &&
                    // Comparamos que ambos tengan los mismos valores y que sean de contratos distintos
                    contratoAnexo.PlacaTruck === data.PlacaTruck &&
                    contratoAnexo.ContainerNum === data.ContainerNum &&
                    contratoAnexo.ContractID !== data.ContractID
                );

                return anexos.length > 0;
            });
        } else {
            console.log("No hay botones activos");
        }
        setFilterContratos(arrayFilter);
    }, [props]);
    return (
        <div className='containerCardsTrafico'>
            {filterContratos !== null ? (
                filterContratos && filterContratos.map((contrato, index) => (
                    <div key={index} className='card ContainerContratoInitial' onClick={() => showMoreInfo(index)}>
                        <div className='infoEmpresaContrato'>
                            <div className='opcionesContrato'>
                                {props.showEmpresa && (
                                    <h5><strong>{contrato.NombreEmpresa}</strong></h5>
                                )}
                                <MenuContratos
                                    data={contrato}
                                    handleAllStatesDropdowns={props.handleAllStatesDropdowns}
                                    abrirModal2={props.abrirModal2}
                                    showModalOpenResporteServicio={props.showModalOpenResporteServicio}
                                    toggleDropdownReportes={props.toggleDropdownReportes}
                                    dropdownOpenReportes={props.dropdownOpenReportes}
                                    OpenReporteServicio={props.OpenReporteServicio}
                                    abrirModal={props.abrirModal}
                                    toggleDropdownMultimedia={props.toggleDropdownMultimedia}
                                    dropdownOpenMultimedia={props.dropdownOpenMultimedia}
                                    OpenGalleryPhoto={props.OpenGalleryPhoto}
                                    OpenGalleryVideo={props.OpenGalleryVideo}
                                    OpenUploadPhoto={props.OpenUploadPhoto}
                                    OpenUploadVideo={props.OpenUploadVideo}
                                    toggleDropdownValitronics={props.toggleDropdownValitronics}
                                    dropdownOpenValitronics={props.dropdownOpenValitronics}
                                    OpenComprobante={props.OpenComprobante}
                                    OpenListCheck={() => props.OpenListCheck(contrato)}
                                    toggleDropdownOpciones={props.toggleDropdownOpciones}
                                    dropdownOpenOpciones={props.dropdownOpenOpciones}
                                    showEditContract={() => props.EditContrato(contrato)}
                                    EditContrato={() => props.EditTrayecto(contrato)}
                                    ActualizarContratoDevice={props.ActualizarContratoDevice}
                                />
                            </div>
                            {props.showDispositivo && (
                                <p><span>Dispositivo: </span>{contrato.FKLokDeviceID}</p>
                            )}
                            {props.showPlaca && (
                                <p><span>Placa: </span>{contrato.PlacaTruck}</p>
                            )}
                            {props.showRuta && (
                                <p><span>Ruta: </span>{contrato.DescripcionRuta}</p>
                            )}
                            {props.showEstado && (
                                <p><span>Estado: </span>{contrato.TipoReporte}</p>
                            )}
                            {props.showUbicacionGps && (
                                <p><span>Ubicacion GPS: </span>{contrato.Ciudad}</p>
                            )}
                            {contrato.mostrargeocerca === 1 && (
                                <>
                                    <p><span>Geocerca: </span>{contrato.geocerca}{" "}{`(${contrato.LastReportgeocerca} min)`}</p>
                                </>
                            )}
                            {props.showUbicacionTrafico && (
                                <p><span>Ubicacion Trafico: </span>{contrato.LastReportUbica}</p>
                            )}
                            {props.showTiempo && (
                                <p><span>Tiempo:</span> {contrato.Tiempo}</p>
                            )}
                        </div>
                        <div className='iconsContrato'>
                            <div className='icons'>
                                {props.showBateria && (
                                    <span><img src={`${contrato.icon_bat}`} width="20" height="25" /></span>
                                )}
                                {props.showMoving && (
                                    <span><img src={`${contrato.IconMoving}`} width="25" height="25" /></span>
                                )}
                                {props.showLocked && (
                                    <span><img src={`${contrato.IconLocked}`} width="25" height="25" /></span>
                                )}
                                {props.showBack && (
                                    <span><img src={`${contrato.IconBack}`} width="25" height="25" /></span>
                                )}
                                {props.showDesvio && (
                                    <span><img src={`${contrato.IconDesvio}`} width="25" height="25" /></span>
                                )}
                                {props.showSeguro && (
                                    <span><img src={`${contrato.IconSeguro}`} width="25" height="25" /></span>
                                )}
                                {props.showValitronics && (
                                    <span><img src={`${contrato.IconValitronics}`} width="25" height="25" /></span>
                                )}
                                {contrato.DescripcionTrayecto === null && (
                                    <img src="/images/trayectonull.png" width={25} height={25} title="no tiene trayectos asigandos" style={{ alignSelf: 'center' }} />
                                )}
                            </div>


                        </div>
                        {(props.collapsableResponsive || moreInfo === index) && (
                            <div className='moreInfoStyles'>
                                <p><span>Contrato: </span>{contrato.ContractID}</p>
                                <p><span>Ref: </span>{contrato.Ref}</p>
                                <p><span>Container: </span>{contrato.ContainerNum}</p>
                                <p><span>Trayecto: </span>{contrato.DescripcionTrayecto}</p>
                                <p><span>Ultimo Contacto: </span>{contrato.LoksysServerTime}</p>
                                <p><span>Observaciones: </span>{contrato.LastReportNota}</p>
                            </div>
                        )}
                    </div>

                ))

            ) : (
                props.contratosList && props.contratosList.map((contrato, index) => (
                    <div key={index} className='card ContainerContratoInitial' onClick={() => showMoreInfo(index)}>
                        <div className='infoEmpresaContrato'>
                            <div className='opcionesContrato'>
                                {props.showEmpresa && (
                                    <h5><strong>{contrato.NombreEmpresa}</strong></h5>
                                )}
                                <MenuContratos
                                    data={contrato}
                                    handleAllStatesDropdowns={props.handleAllStatesDropdowns}
                                    abrirModal2={props.abrirModal2}
                                    showModalOpenResporteServicio={props.showModalOpenResporteServicio}
                                    toggleDropdownReportes={props.toggleDropdownReportes}
                                    dropdownOpenReportes={props.dropdownOpenReportes}
                                    OpenReporteServicio={props.OpenReporteServicio}
                                    abrirModal={props.abrirModal}
                                    toggleDropdownMultimedia={props.toggleDropdownMultimedia}
                                    dropdownOpenMultimedia={props.dropdownOpenMultimedia}
                                    OpenGalleryPhoto={props.OpenGalleryPhoto}
                                    OpenGalleryVideo={props.OpenGalleryVideo}
                                    OpenUploadPhoto={props.OpenUploadPhoto}
                                    OpenUploadVideo={props.OpenUploadVideo}
                                    toggleDropdownValitronics={props.toggleDropdownValitronics}
                                    dropdownOpenValitronics={props.dropdownOpenValitronics}
                                    OpenComprobante={props.OpenComprobante}
                                    OpenListCheck={() => props.OpenListCheck(contrato)}
                                    toggleDropdownOpciones={props.toggleDropdownOpciones}
                                    dropdownOpenOpciones={props.dropdownOpenOpciones}
                                    showEditContract={() => props.EditContrato(contrato)}
                                    EditContrato={() => props.EditTrayecto(contrato)}
                                    ActualizarContratoDevice={props.ActualizarContratoDevice}
                                />
                            </div>
                            {props.showDispositivo && (
                                <p><span>Dispositivo: </span>{contrato.FKLokDeviceID}</p>
                            )}
                            {props.showPlaca && (
                                <p><span>Placa: </span>{contrato.PlacaTruck}</p>
                            )}
                            {props.showRuta && (
                                <p><span>Ruta: </span>{contrato.DescripcionRuta}</p>
                            )}
                            {props.showEstado && (
                                <p><span>Estado: </span>{contrato.TipoReporte}</p>
                            )}
                            {props.showUbicacionGps && (
                                <p><span>Ubicacion GPS: </span>{contrato.Ciudad}</p>
                            )}
                            {props.showUbicacionTrafico && (
                                <p><span>Ubicacion Trafico: </span>{contrato.LastReportUbica}</p>
                            )}
                            {props.showTiempo && (
                                <p><span>Tiempo:</span> {contrato.Tiempo}</p>
                            )}
                        </div>
                        <div className='iconsContrato'>
                            <div className='icons'>
                                {props.showBateria && (
                                    <span><img src={`${contrato.icon_bat}`} width="20" height="25" /></span>
                                )}
                                {props.showMoving && (
                                    <span><img src={`${contrato.IconMoving}`} width="25" height="25" /></span>
                                )}
                                {props.showLocked && (
                                    <span><img src={`${contrato.IconLocked}`} width="25" height="25" /></span>
                                )}
                                {props.showBack && (
                                    <span><img src={`${contrato.IconBack}`} width="25" height="25" /></span>
                                )}
                                {props.showDesvio && (
                                    <span><img src={`${contrato.IconDesvio}`} width="25" height="25" /></span>
                                )}
                                {props.showSeguro && (
                                    <span><img src={`${contrato.IconSeguro}`} width="25" height="25" /></span>
                                )}
                                {props.showValitronics && (
                                    <span><img src={`${contrato.IconValitronics}`} width="25" height="25" /></span>
                                )}

                            </div>


                        </div>
                        {(props.collapsableResponsive || moreInfo === index) && (
                            <div className='moreInfoStyles'>
                                <p><span>Contrato: </span>{contrato.ContractID}</p>
                                <p><span>Ref: </span>{contrato.Ref}</p>
                                <p><span>Container: </span>{contrato.ContainerNum}</p>
                                <p><span>Trayecto: </span>{contrato.DescripcionTrayecto}</p>
                                <p><span>Ultimo Contacto: </span>{contrato.LoksysServerTime}</p>
                                <p><span>Observaciones: </span>{contrato.LastReportNota}</p>
                            </div>
                        )}
                    </div>

                ))

            )}
        </div>
    )
}

export default TraficoResponsive
