import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Barra from '../Modulos/Barra';
import './Css/themes/base/all.css';
import '../App.css';
import Swal from 'sweetalert2';
import axios from '../utils/axiosConfig';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import ModalBaseLg from '../components/modals/modal-base-lg';
import ReporteContratoH from "../Modulos/ReporteContratoH";
import ReporteContrato from "../Modulos/ReporteContrato";
import { Button, Form } from 'reactstrap';
import FormTrakalok from '../components/formsSolicitudes/formTrakalok';
import { formattedGlobalDate } from './../utils/formattedDates';
import ReporteServicio from '../Modulos/Reporteservicio';
import ReporteDian from "../Modulos/ReporteDian";
import ModalReporteServicio from '../components/modals/modalReporteServicio';
import MenuContratos from './../components/menuContratos/menuContratos';
import { set } from 'date-fns';
import BaseModalXXL from '../components/modals/baseModalXXL';
import ModalGaleria from '../components/modals/menuContratos/modalGaleria';
import VideoGallery from '../componentes/videoGallery';
import RSeguimientoServicio from '../components/modals/modalRSeguimientoServicio';
import FormReporteServicio from '../components/formsSolicitudes/formReporteServicio';
import ModalComprobanteValitronics from '../components/modals/menuContratos/modalComprobanteValitronics';
import BaseModalAuto from '../components/modals/baseModalAuto';
import PrintComponent from '../components/printComponent/PrintComponent';
import PreloaderHistorico from '../components/preloaders/preloader-historico';
import ModalReporteServicio2 from '../components/modals/menuContratos/modalReporteServicio';

const ControlContracts = () => {
    // Obtén el parámetro "contract" desde la query string
    const [searchParams] = useSearchParams();
    const contract = searchParams.get("contract");
    const [contractId, setContractId] = useState('');
    const [contractInfo, setContractInfo] = useState({});
    const [ultPunto, setUltPunto] = useState(-1);
    const [contractR, setContractR] = useState(-1);
    const [rutaseleccionada, setRutaseleccionada] = useState(-1);
    const [modalTrakalok, setModalTrakalok] = useState(false);
    const [modalReporte, setModalReporte] = useState(false);
    const project = localStorage.getItem('project');

    const [dropdownOpenMultimedia, setDropdownOpenMultimedia] = useState(false);
    const [dropdownOpenReportes, setDropdownOpenReportes] = useState(false);
    const [dropdownOpenOpciones, setDropdownOpenOpciones] = useState(false);
    const [dropdownOpenValitronics, setDropdownOpenValitronics] = useState(false);

    const [dataGlobal, setDataGlobal] = useState([]);
    const [modalAbierto2, setModalAbierto2] = useState(false);
    const [modalAbierto, setModalAbierto] = useState(false);
    const [contratoDian, setContratoDian] = useState(-1);
    const [modalIsLoading, setModalIsLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [isDialogOpenModalGaleria, setIsDialogOpenModalGaleria] = useState(false);
    const [isDialogOpenRSeguimientoServicio, setIsDialogOpenRSeguimientoServicio] = useState(false);
    const [isDialogOpenModalComprobanteValitronics, setIsDialogOpenModalComprobanteValitronics] = useState(false);
    const [isDialogOpenModalReporteServicio, setIsDialogOpenModalReporteServicio] = useState(false);
    const [isDialogOpenModalListaCheck, setIsDialogOpenModalListaCheck] = useState(false);
    const [isDialogOpenEditSolicitud, setIsDialogOpenEditSolicitud] = useState(false);
    const [isDialogOpenEditContratoProject, setIsDialogOpenEditContratoProject] = useState(false);
    const [isDialogOpenAsignacion, setIsDialogOpenAsignacion] = useState(false);


    const [bitOpenTracking, setBitOpenTracking] = useState(false);
    const [bitInsertTracking, setBitInsertTracking] = useState(false);
    const [bitEditTracking, setBitEditTracking] = useState(false);
    const [bitDeleteTracking, setBitDeleteTracking] = useState(false);
    const [bitOpenMonitoreo, setBitOpenMonitoreo] = useState(false);
    const [bitInsertMonitoreo, setBitInsertMonitoreo] = useState(false);
    const [bitEditMonitoreo, setBitEditMonitoreo] = useState(false);
    const [bitDeleteMonitoreo, setBitDeleteMonitoreo] = useState(false);
    const [bitOpenUploadPhoto, setBitOpenUploadPhoto] = useState(false);
    const [bitInsertUploadPhoto, setBitInsertUploadPhoto] = useState(false);
    const [bitEditUploadPhoto, setBitEditUploadPhoto] = useState(false);
    const [bitDeleteUploadPhoto, setBitDeleteUploadPhoto] = useState(false);
    const [bitOpenUploadVideo, setBitOpenUploadVideo] = useState(false);
    const [bitInsertUploadVideo, setBitInsertUploadVideo] = useState(false);
    const [bitEditUploadVideo, setBitEditUploadVideo] = useState(false);
    const [bitDeleteUploadVideo, setBitDeleteUploadVideo] = useState(false);
    const [bitOpenActualizarContrato, setBitOpenActualizarContrato] = useState(false);
    const [bitInsertUActualizarContrato, setBitInsertUActualizarContrato] = useState(false);
    const [bitEditUActualizarContrato, setBitEditUActualizarContrato] = useState(false);
    const [bitDeleteUActualizarContrato, setBitDeleteUActualizarContrato] = useState(false);
    const [bitOpenUpdateContract, setBitOpenUpdateContract] = useState(false);
    const [bitInsertUpdateContract, setBitInsertUpdateContract] = useState(false);
    const [bitEditUpdateContract, setBitEditUpdateContract] = useState(false);
    const [bitDeleteUpdateContract, setBitDeleteUpdateContract] = useState(false);
    const [bitOpenUpdateContrato, setBitOpenUpdateContrato] = useState(false);
    const [bitInsertUpdateContrato, setBitInsertUpdateContrato] = useState(false);
    const [bitEditUpdateContrato, setBitEditUpdateContrato] = useState(false);
    const [bitDeleteUpdateContrato, setBitDeleteUpdateContrato] = useState(false);

    const [infoContrato, setInfoContrato] = useState({ ContractID: "ND" });
    const [infoContrato2, setInfoContrato2] = useState({ ContractID: "ND" });
    const [contratoseleccionado, setContratoSeleccionado] = useState(-1);
    const [infocontratoContainer, setInfoContratoContainer] = useState("");
    const [infoUpdateContrato, setInfoUpdateContrato] = useState([]);
    const [infoContratoAjustado, setInfoContratoAjustado] = useState({ ContractID: "ND" });
    const [username, setUsername] = useState("");
    const [placa, setPlaca] = useState("");
    const [device, setDevice] = useState("");
    const [FKLokProyecto, setFKLokProyecto] = useState("");
    const [FKICEmpresa, setFKICEmpresa] = useState("");
    const [isDialogOpenModalFoto, setIsDialogOpenModalFoto] = useState(false);
    const [isDialogOpenModalVideo, setIsDialogOpenModalVideo] = useState(false);

    const [onHide, setOnHide] = useState(false);
    const [showPreloader, setShowPreloader] = useState(false);

    // Puedes hacer uso de este valor para, por ejemplo, mostrarlo o para filtrar la data
    console.log("Contract recibido:", contract);

    const idDevice = localStorage.getItem('deviceContract');
    const [infoDevice, setInfoDevice] = useState([]);
    const [listComprobantes, setListComprobantes] = useState([]);


    // Control opne Modales
    const openModalTrakalok = (id) => {
        console.log("Contract ID", id);
        setContractId(id);
        setModalTrakalok(true);
    }

    const OpenModalReporteServicio = (data) => {
        console.log("DAta desde el reporteModal", data);
        setInfoContratoAjustado(data);
        setIsDialogOpenModalReporteServicio(true);
    }

    const obtenerYEstablecerDatos = async (contrato) => {
        try {
            const resContrato = await axios.post(
                process.env.REACT_APP_SERVER + "/contratos/getinfocontrato",
                JSON.stringify({ contrato }),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                    },
                    withCredentials: true,
                }
            );

            if (resContrato.success === false) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `${resContrato.mensaje}`,
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();
                        confirmButton.style.backgroundColor = '#ff0000';
                        confirmButton.style.color = '#ffffff';
                        confirmButton.style.border = 'none';
                        confirmButton.style.padding = '10px 20px';
                        confirmButton.style.fontSize = '16px';
                        confirmButton.style.borderRadius = '5px';
                        confirmButton.style.cursor = 'pointer';
                    }
                })
            } else {
                console.log("Respuesta a evaluar del contrato antes de actualizar ", resContrato);
                if (project == 1) {
                    console.log("Proyecto status", project);
                    setInfoUpdateContrato(resContrato.data.data);
                    setIsDialogOpenEditSolicitud(true);
                    setContratoSeleccionado(contrato);

                } else {
                    console.log("Proyecto status", project)
                    setInfoUpdateContrato(resContrato.data.data);
                    setIsDialogOpenEditContratoProject(true);
                    setContratoSeleccionado(contrato);
                }
            }
        } catch (err) {
            console.log(err);

        }
    };

    const obtenerYEstablecerDatosProyecto = async (contrato) => {
        try {
            const resContrato = await axios.post(
                process.env.REACT_APP_SERVER + "/contratos/getinfocontratoproyecto",
                JSON.stringify({ contrato }),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                    },
                    withCredentials: true,
                }
            );

            if (resContrato.success === false) {
                window.location = "/";
            } else {
                console.log("Respuesta a evaluar del contrato antes de actualizar ", resContrato);
                if (project == 1) {
                    console.log("Proyecto status", project);
                    setInfoUpdateContrato(resContrato.data.data);
                    setIsDialogOpenEditSolicitud(true);
                    setContratoSeleccionado(contrato);
                } else {
                    console.log("Proyecto status", project);
                    setInfoUpdateContrato(resContrato.data.data);
                    setIsDialogOpenEditContratoProject(true);
                    setContratoSeleccionado(contrato);
                }
            }
        } catch (err) {
            console.log(err);

        }
    };

    const OpenUploadPhoto = async (data) => {
        setDataGlobal(data);
        console.log("DATA", data);
        let dataPermisos = {
            pagina: "15",
        };

        try {
            const res = await axios.post(
                process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
                JSON.stringify(dataPermisos),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                    },
                    withCredentials: true,
                }
            );

            // Handle the response here after the request completes
            if (res.data.success === false) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `${res.data.mensaje}`,
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();
                        confirmButton.style.backgroundColor = '#ff0000';
                        confirmButton.style.color = '#ffffff';
                        confirmButton.style.border = 'none';
                        confirmButton.style.padding = '10px 20px';
                        confirmButton.style.fontSize = '16px';
                        confirmButton.style.borderRadius = '5px';
                        confirmButton.style.cursor = 'pointer';
                    }
                });
            } else {
                console.log("BIt permisos tracking: ", res.data.data);
                if (res.data.data.length > 0) {
                    setBitOpenUploadPhoto(res.data.data[0].bitOpen);
                    setBitInsertUploadPhoto(res.data.data[0].bitInsert);
                    setBitEditUploadPhoto(res.data.data[0].bitEdit);
                    setBitDeleteUploadPhoto(res.data.data[0].bitDelete);
                    if (res.data.data[0].bitOpen) {
                        console.warn("Tienes todos los permisos");
                        console.warn("DATA uplodad foto", data);
                        $("#fotos").val("");
                        setContratoSeleccionado(data.ContractID);
                        setUsername(data.username);
                        setPlaca(data.PlacaTruck);
                        setDevice(data.FKLokDeviceID);
                        setFKLokProyecto(data.FKLokProyecto);
                        setFKICEmpresa(data.FKICEmpresa);
                        setIsDialogOpenModalFoto(true);
                        $("#latitudF").val("0.00");
                        $("#longitudF").val("0.00");
                        $("#fechafoto").val(data.fecha);
                    } else {
                        Swal.fire({
                            title: "Información",
                            text: "No tiene los permisos necesarios para acceder a esta opción",
                            icon: "info",
                            timer: 3000,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading();
                            },
                        }).then(() => {

                            console.error("No tienes permisos");
                        });
                    }
                }
            }
        } catch (error) {
            console.error("Error al listar bit permisos:", error);
        }

    }

    const OpenUploadVideo = async (data) => {
        setDataGlobal(data);
        let dataPermisos = {
            pagina: "16",
        };

        try {
            const res = await axios.post(
                process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
                JSON.stringify(dataPermisos),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                    },
                    withCredentials: true,
                }
            );

            // Handle the response here after the request completes
            if (res.data.success === false) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `${res.data.mensaje}`,
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();
                        confirmButton.style.backgroundColor = '#ff0000';
                        confirmButton.style.color = '#ffffff';
                        confirmButton.style.border = 'none';
                        confirmButton.style.padding = '10px 20px';
                        confirmButton.style.fontSize = '16px';
                        confirmButton.style.borderRadius = '5px';
                        confirmButton.style.cursor = 'pointer';
                    }
                });
            } else {
                console.log("BIt permisos tracking: ", res.data.data);
                if (res.data.data.length > 0) {
                    setBitOpenUploadVideo(res.data.data[0].bitOpen);
                    setBitInsertUploadVideo(res.data.data[0].bitInsert);
                    setBitEditUploadVideo(res.data.data[0].bitEdit);
                    setBitDeleteUploadVideo(res.data.data[0].bitDelete);
                    if (res.data.data[0].bitOpen) {
                        console.warn("Tienes todos los permisos");
                        console.log("$$", data);
                        console.log(data.ContractID);
                        setContratoSeleccionado(data.ContractID);
                        setUsername(data.username);
                        setPlaca(data.PlacaTruck);
                        setDevice(data.FKLokDeviceID);
                        setFKLokProyecto(data.FKLokProyecto);
                        setFKICEmpresa(data.FKICEmpresa);
                        setIsDialogOpenModalVideo(true);
                        $("#latitudV").val("0.00");
                        $("#longitudV").val("0.00");
                        $("#fechaVideo").val(data.fecha);
                    } else {
                        Swal.fire({
                            title: "Información",
                            text: "No tiene los permisos necesarios para acceder a esta opción",
                            icon: "info",
                            timer: 3000,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading();
                            },
                        }).then(() => {

                            console.error("No tienes permisos");
                        });
                    }
                }
            }
        } catch (error) {
            console.error("Error al listar bit permisos:", error);
        }

    }

    const showEditContract = async (data) => {
        let dataPermisos = {
            pagina: "19",
        };

        try {
            const res = await axios.post(
                process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
                JSON.stringify(dataPermisos),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                    },
                    withCredentials: true,
                }
            );

            // Handle the response here after the request completes
            if (res.data.success === false) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `${res.data.mensaje}`,
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();
                        confirmButton.style.backgroundColor = '#ff0000';
                        confirmButton.style.color = '#ffffff';
                        confirmButton.style.border = 'none';
                        confirmButton.style.padding = '10px 20px';
                        confirmButton.style.fontSize = '16px';
                        confirmButton.style.borderRadius = '5px';
                        confirmButton.style.cursor = 'pointer';
                    }
                })
            } else {
                console.log("BIt permisos tracking: ", res.data.data);
                if (res.data.data.length > 0) {
                    setBitOpenActualizarContrato(res.data.data[0].bitOpen);
                    setBitInsertUActualizarContrato(res.data.data[0].bitInsert);
                    setBitEditUActualizarContrato(res.data.data[0].bitEdit);
                    setBitDeleteUActualizarContrato(res.data.data[0].bitDelete);
                    if (bitOpenActualizarContrato) {
                        console.warn("Tienes todos los permisos");
                        if (project == 1) {
                            obtenerYEstablecerDatos(data.ContractID);
                        } else {
                            obtenerYEstablecerDatosProyecto(data.ContractID);
                        }
                    } else {
                        Swal.fire({
                            title: "Información",
                            text: "No tiene los permisos necesarios para acceder a esta opción",
                            icon: "info",
                            timer: 3000,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading();
                            },
                        }).then(() => {

                            console.error("No tienes permisos");
                        });
                    }
                }
            }
        } catch (error) {
            console.error("Error al listar bit permisos:", error);
        }

    }

    const EditContrato = async (data) => {
        setDataGlobal(data);
        let dataPermisos = {
            pagina: "18",
        };

        try {
            const res = await axios.post(
                process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
                JSON.stringify(dataPermisos),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                    },
                    withCredentials: true,
                }
            );

            // Handle the response here after the request completes
            if (res.data.success === false) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `${res.data.mensaje}`,
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();
                        confirmButton.style.backgroundColor = '#ff0000';
                        confirmButton.style.color = '#ffffff';
                        confirmButton.style.border = 'none';
                        confirmButton.style.padding = '10px 20px';
                        confirmButton.style.fontSize = '16px';
                        confirmButton.style.borderRadius = '5px';
                        confirmButton.style.cursor = 'pointer';
                    }
                });
            } else {
                console.log("BIt permisos tracking: ", res.data.data);
                if (res.data.data.length > 0) {
                    setBitOpenUpdateContract(res.data.data[0].bitOpen);
                    setBitInsertUpdateContract(res.data.data[0].bitInsert);
                    setBitEditUpdateContract(res.data.data[0].bitEdit);
                    setBitDeleteUpdateContract(res.data.data[0].bitDelete);
                    if (res.data.data[0].bitOpen) {
                        console.warn("Tienes todos los permisos");
                        setContratoSeleccionado(data.ContractID);
                        if (data.trayecto != 0) {
                            setRutaseleccionada(data.trayecto);
                            setIsDialogOpenAsignacion(true);
                            const modalElement = document.getElementById("ModalAsignacion");
                            if (modalElement) {
                                const modalInstance3 = new bootstrap.Modal(modalElement, {
                                    backdrop: 'static',
                                    keyboard: false,
                                });
                                modalInstance3.show();
                            } else {
                                console.error("El modal 'ModalAsignacion' no se encontró en el DOM.");
                            }

                        } else {
                            Swal.fire({
                                title: "Información",
                                text: "No tiene los permisos necesarios para acceder a esta opción",
                                icon: "info",
                                timer: 3000,
                                showConfirmButton: false,
                                allowOutsideClick: false,
                                didOpen: () => {
                                    Swal.showLoading();
                                },
                            }).then(() => {

                                console.error("No tienes permisos");
                            });
                        }
                    }
                }
            }
        } catch (error) {
            console.error("Error al listar bit permisos:", error);
        }        //$( "#dialog" ).dialog( "open" );
    }

    const ActualizarContratoDevice = async (data) => {
        let dataPermisos = {
            pagina: "17",
        };

        try {
            const res = await axios.post(
                process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
                JSON.stringify(dataPermisos),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                    },
                    withCredentials: true,
                }
            );

            // Handle the response here after the request completes
            if (res.data.success === false) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `${res.data.mensaje}`,
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();
                        confirmButton.style.backgroundColor = '#ff0000';
                        confirmButton.style.color = '#ffffff';
                        confirmButton.style.border = 'none';
                        confirmButton.style.padding = '10px 20px';
                        confirmButton.style.fontSize = '16px';
                        confirmButton.style.borderRadius = '5px';
                        confirmButton.style.cursor = 'pointer';
                    }
                })
            } else {
                console.log("BIt permisos actualizar contrato: ", res.data.data);
                if (res.data.data.length > 0) {
                    setBitOpenUpdateContrato(res.data.data[0].bitOpen);
                    setBitInsertUpdateContrato(res.data.data[0].bitInsert);
                    setBitEditUpdateContrato(res.data.data[0].bitEdit);
                    setBitDeleteUpdateContrato(res.data.data[0].bitDelete);
                    if (res.data.data[0].bitOpen) {
                        console.warn("Tienes todos los permisos");
                        let send = {
                            Device: data.FKLokDeviceID,
                            Contrato: data.ContractID,
                        };
                        const json = JSON.stringify(send);
                        axios
                            .post(
                                process.env.REACT_APP_SERVER + "/operaciones/setlastcontractdevice",
                                json,
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                                    },
                                    withCredentials: true,
                                }
                            )
                            .then((res) => {
                                if (res.data.success) {
                                    $("#success-alert")
                                        .fadeTo(2000, 500)
                                        .slideUp(500, function () {
                                            $("#success-alert").slideUp(2000);
                                        });
                                } else {
                                    $("#danger-alert")
                                        .fadeTo(2000, 500)
                                        .slideUp(500, function () {
                                            $("#danger-alert").slideUp(2000);
                                        });
                                }
                            });
                    } else {
                        Swal.fire({
                            title: "Información",
                            text: "No tiene los permisos necesarios para acceder a esta opción",
                            icon: "info",
                            timer: 3000,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading();
                            },
                        }).then(() => {

                            console.error("No tienes permisos");
                        });
                    }
                }
            }
        } catch (error) {
            console.error("Error al listar bit permisos:", error);
        }

    }

    const ListarComprobantes = (contrato) => {
        let send = {
            contrato: contrato,
        };
        console.log(send);
        const json = JSON.stringify(send);
        axios
            .post(process.env.REACT_APP_SERVER + "/operaciones/getcomprobantevalitronics", json, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: `${res.data.mensaje}`,
                        didOpen: () => {
                            const confirmButton = Swal.getConfirmButton();
                            confirmButton.style.backgroundColor = '#ff0000';
                            confirmButton.style.color = '#ffffff';
                            confirmButton.style.border = 'none';
                            confirmButton.style.padding = '10px 20px';
                            confirmButton.style.fontSize = '16px';
                            confirmButton.style.borderRadius = '5px';
                            confirmButton.style.cursor = 'pointer';
                        }
                    });
                } else {
                    console.log(res.data.data);
                    setListComprobantes(res.data.data);
                }
            });
    }

    const OpenComprobante = (data) => {
        setContratoSeleccionado(data.ContractID);
        setIsDialogOpenModalComprobanteValitronics(true);
        ListarComprobantes(data.ContractID);
    }

    const OpenListCheck = (data) => {
        setInfoContrato(data);
        setIsDialogOpenModalListaCheck(true);
    }

    const abrirModal = (data) => {
        console.log("$$", data);
        console.log(data.ContractID);
        setModalAbierto(true);
        setContratoDian(data.ContractID);
    }


    const OpenReporteServicio = (data) => {
        console.log("Data para el modal Reporte :", data);
        setDataGlobal(data);
        setInfoContrato(data);
        openReporteConsolidado2(data);
    }

    const OpenGalleryPhoto = (data) => {
        setDataGlobal(data);
        setContratoSeleccionado(data.ContractID);
        setInfoContratoContainer(data.ContainerNum);
        setIsDialogOpenModalGaleria(true);
    }

    const OpenImages = async () => {
        // this.cerrarModal2();
        OpenGalleryPhoto(dataGlobal);
        $("#ModalGaleria").modal("show");
    }

    const OpenTracking = async () => {

        console.log("Entre");
        console.log("Data global", dataGlobal);
        const modalElement = document.getElementById("ModalGaleria");
        if (modalElement) {
            const modalInstance = bootstrap.Modal.getInstance(modalElement); // Get the Bootstrap modal instance
            if (modalInstance) {
                modalInstance.hide(); // Close the Bootstrap modal
            }
        }
        abrirModal2(dataGlobal);
        // $("#ModalReporteSegimiento").modal("show");
    }


    const OpenMonitoreo2 = async () => {
        const modalElement = document.getElementById("ModalGaleria");
        if (modalElement) {
            const modalInstance = bootstrap.Modal.getInstance(modalElement); // Get the Bootstrap modal instance
            if (modalInstance) {
                modalInstance.hide(); // Close the Bootstrap modal
            }
        }
        showModalOpenResporteServicio(dataGlobal);
    }

    const OpenReporteConsolidado2 = async () => {
        OpenModalReporteServicio(dataGlobal);
    }

    const OpenVideos = async () => {
        // this.cerrarModal2();
        OpenGalleryVideo(dataGlobal);
        $("#ModalVideoGaleria").modal("show");
    }

    const OpenGalleryVideo = (data) => {
        setContratoSeleccionado(data.ContractID);
    }

    const OpenValitronics = async () => {
        // this.cerrarModal2();
        OpenComprobante(dataGlobal);
    }

    const OpenListCheckModal = async () => {
        // this.cerrarModal2();
        OpenListCheck(dataGlobal);
    }

    const OpenMonitoreo = async () => {
        // this.cerrarModal2();
        showModalOpenResporteServicio(dataGlobal);
    }

    const OpenReporteConsolidado = async () => {
        // this.cerrarModal2();
        OpenModalReporteServicio(dataGlobal);
    }

    const openReporteConsolidado2 = (data)=>{
        OpenModalReporteServicio(data);
    }

    const OpenImages2 = async () => {
        setIsDialogOpenRSeguimientoServicio(false);
        OpenGalleryPhoto(dataGlobal);
        $("#ModalGaleria").modal("show");
    }

    const OpenTracking2 = async () => {
        setIsDialogOpenRSeguimientoServicio(false);
        abrirModal2(dataGlobal);
        $("#ModalReporteSegimiento").modal("show");
    }




    const handleAllStatesDropdowns = () => {
        setDropdownOpenMultimedia(false);
        setDropdownOpenReportes(false);
        setDropdownOpenOpciones(false);
        setDropdownOpenValitronics(false);
    }

    //Conrtrol de cierre modales
    const cerrarModal2 = () => {
        const modalElement = document.getElementById("ModalReporteSegimiento");
        if (modalElement) {
            const modalInstance = bootstrap.Modal.getInstance(modalElement); // Get the Bootstrap modal instance
            if (modalInstance) {
                modalInstance.hide(); // Close the Bootstrap modal
            }
        }
        setModalAbierto2(false); // Also update your React state
    }

    const abrirModal2 = async (data) => {
        console.log(data);
        setModalIsLoading(true);
        setDataGlobal(data);

        let dataPermisos = {
            pagina: "13",
        };

        try {
            const res = await axios.post(
                process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
                JSON.stringify(dataPermisos),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                    },
                    withCredentials: true,
                }
            );

            // Handle the response here after the request completes
            if (res.data.success === false) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `${res.data.mensaje}`,
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();
                        confirmButton.style.backgroundColor = '#ff0000';
                        confirmButton.style.color = '#ffffff';
                        confirmButton.style.border = 'none';
                        confirmButton.style.padding = '10px 20px';
                        confirmButton.style.fontSize = '16px';
                        confirmButton.style.borderRadius = '5px';
                        confirmButton.style.cursor = 'pointer';
                    }
                })
            } else {
                console.log("BIt permisos tracking: ", res.data.data);
                if (res.data.data.length > 0) {
                    setBitOpenTracking(res.data.data[0].bitOpen);
                    setBitInsertTracking(res.data.data[0].bitInsert);
                    setBitEditTracking(res.data.data[0].bitEdit);
                    setBitDeleteTracking(res.data.data[0].bitDelete);

                    if (res.data.data[0].bitOpen) {
                        console.warn("Tienes todos los permisos");
                        console.log("$$", data);
                        console.log(data.ContractID);
                        setModalAbierto2(true);
                        setInfoContrato2(data);
                        setModalIsLoading(false);
                    } else {
                        cerrarModal2();
                        Swal.fire({
                            title: "Información",
                            text: "No tiene los permisos necesarios para acceder a esta página",
                            icon: "info",
                            timer: 3000,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading();
                            },
                        }).then(() => {

                            console.error("No tienes permisos");
                        }).catch((error) => { console.log(error) });
                    }

                }
            }
        } catch (error) {
            console.error("Error al listar bit permisos:", error);
        }
    };

    const showModalOpenResporteServicio = async (data) => {
        let dataPermisos = {
            pagina: "14",
        };

        try {
            const res = await axios.post(
                process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
                JSON.stringify(dataPermisos),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                    },
                    withCredentials: true,
                }
            );

            // Handle the response here after the request completes
            if (res.data.success === false) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `${res.data.mensaje}`,
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();
                        confirmButton.style.backgroundColor = '#ff0000';
                        confirmButton.style.color = '#ffffff';
                        confirmButton.style.border = 'none';
                        confirmButton.style.padding = '10px 20px';
                        confirmButton.style.fontSize = '16px';
                        confirmButton.style.borderRadius = '5px';
                        confirmButton.style.cursor = 'pointer';
                    }
                });
            } else {
                console.log("BIt permisos tracking: ", res.data.data);
                if (res.data?.data?.length > 0) {
                    setBitOpenMonitoreo(res.data.data[0].bitOpen);
                    setBitInsertMonitoreo(res.data.data[0].bitInsert);
                    setBitEditMonitoreo(res.data.data[0].bitEdit);
                    setBitDeleteMonitoreo(res.data.data[0].bitDelete);
                    if (res.data.data[0].bitOpen) {
                        console.warn("Tienes todos los permisos");
                        console.log("$$", data);
                        console.log(data.ContractID);
                        setDataGlobal(data);
                        setContratoSeleccionado(data.ContractID);
                        setIsDialogOpenRSeguimientoServicio(true);
                    } else {
                        onCloseReporteServicio();
                        Swal.fire({
                            title: "Información",
                            text: "No tiene los permisos necesarios para acceder a esta página",
                            icon: "info",
                            timer: 3000,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading();
                            },
                        }).then(() => {

                            console.error("No tienes permisos");
                        });
                    }
                }
            }
        } catch (error) {
            console.error("Error al listar bit permisos:", error);
        }


    }

    const openModalReporte = (contrato) => {
        try {
            axios.post(process.env.REACT_APP_SERVER + "/operaciones/getcontratounico", JSON.stringify({ contrato: contrato.ContractID }), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            }).then((res) => {
                if (res.data.success === false) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: `${res.data.mensaje}`,
                        didOpen: () => {
                            const confirmButton = Swal.getConfirmButton();
                            confirmButton.style.backgroundColor = '#ff0000';
                            confirmButton.style.color = '#ffffff';
                            confirmButton.style.border = 'none';
                            confirmButton.style.padding = '10px 20px';
                            confirmButton.style.fontSize = '16px';
                            confirmButton.style.borderRadius = '5px';
                            confirmButton.style.cursor = 'pointer';
                        }
                    });
                } else {
                    console.log("Informacion de el contrato", res.data.data[0]);
                    setContractInfo(res.data.data[0]);

                }
            }).catch((error) => {
                console.error(error);
            });
        } catch (error) {
            console.error(error);
        } finally {
            setModalReporte(true);
        }
    }

    const cerrarModal = () => {
        setModalAbierto(false);
    }


    const handleModalClose = () => {
        // Aquí puedes pausar los videos
        console.log('Modal cerrado, pausar los videos aquí');
        setOnHide(true);

    };

    const onCloseModalReporte = () => {
        setModalReporte(false);
    }

    const onCloseModalTrack = () => {
        setModalTrakalok(false);
    }
    const onCloseReporteServicio = () => {
        setIsDialogOpenRSeguimientoServicio(false);
    }

    const onCloseModalComprobanteValitronics = () => {
        setIsDialogOpenModalComprobanteValitronics(false);
    }

    const onCloseModalListaCheck = () => {
        setIsDialogOpenModalListaCheck(false);
    }
    const onCloseModalReporteServicio = () => {
        setIsDialogOpenModalReporteServicio(false);
        setUltPunto(-1);
        setContractR(-1);
    }

    const toggleDropdownReportes = () => {
        setDropdownOpenReportes(!dropdownOpenReportes);
    }

    const toggleDropdownOpciones = () => {
        setDropdownOpenOpciones(!dropdownOpenOpciones);
    }

    const toggleDropdownValitronics = () => {
        setDropdownOpenValitronics(!dropdownOpenValitronics);
    }

    const toggleDropdownMultimedia = () => {
        dropdownOpenMultimedia(!dropdownOpenMultimedia);
    };

    const CargarInfoByIdDevice = (idDevice) => {
        let dataDevice = {
            device: idDevice,
        };
        setShowPreloader(true);
        console.log("Data Device", dataDevice);
        axios
            .post(
                process.env.REACT_APP_SERVER + "/operaciones/getcontratoscontroldevice",
                JSON.stringify(dataDevice),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                if (res.data.success === false) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: `${res.data.mensaje}`,
                        didOpen: () => {
                            const confirmButton = Swal.getConfirmButton();
                            confirmButton.style.backgroundColor = '#ff0000';
                            confirmButton.style.color = '#ffffff';
                            confirmButton.style.border = 'none';
                            confirmButton.style.padding = '10px 20px';
                            confirmButton.style.fontSize = '16px';
                            confirmButton.style.borderRadius = '5px';
                            confirmButton.style.cursor = 'pointer';
                        }
                    });
                } else {
                    console.log("Informacion de la solicitud de control contracts", res.data.data);
                    setInfoDevice(res.data.data);
                    setShowPreloader(false);
                }
            })
            .catch((err) => console.log(err));
    };

    const eliminar = () => {
        var elementos = document.getElementsByClassName("object-box");
        var elementosArray = Array.from(elementos);
        elementosArray.forEach(function (elemento) {
            elemento.parentNode.removeChild(elemento);
        });
    };

    const loadFillData = (infoDevice) => {
        if (showPreloader) {
            return (
                <>
                    <tr>
                        <td colSpan="8" className="text-center">
                            <PreloaderHistorico />
                        </td>
                    </tr>
                </>
            )
        }

        if (infoDevice.length === 0) {
            return (
                <tr>
                    <td colSpan="8" className="text-center">
                        <i className='bx bxs-spreadsheet bx-lg bx-tada-hover'></i>
                        <p>No hay datos disponibles</p>
                    </td>
                </tr>
            )
        }

        let rows = [];
        infoDevice.map((element) => {
            rows.push(
                <tr key={element.ContractID}>
                    <td>{element.ContractID}</td>
                    <td>{element.NombreEmpresa}</td>
                    <td>{element.DescripcionRuta}</td>
                    <td>{formattedGlobalDate(element.InicioServicio)}</td>
                    <td>{formattedGlobalDate(element.FechaHoraFin)}</td>
                    <td>{element.Position}</td>
                    <td>
                        <i className="bx bxs-cog" style={{ fontSize: '24px', color: "#006699", cursor: 'pointer' }} onClick={() => openModalTrakalok(element.ContractID)}></i>
                    </td>
                    <td>
                        {/* <i className="bx bxs-report" style={{ fontSize: '24px', color: "#006699", cursor: 'pointer' }} onClick={() => openModalReporte(element)}></i> */}
                        <MenuContratos
                            data={element}
                            handleAllStatesDropdowns={handleAllStatesDropdowns}
                            abrirModal2={abrirModal2}
                            showModalOpenResporteServicio={showModalOpenResporteServicio}
                            toggleDropdownReportes={toggleDropdownReportes}
                            dropdownOpenReportes={dropdownOpenReportes}
                            OpenReporteServicio={OpenReporteServicio}
                            abrirModal={abrirModal}
                            toggleDropdownMultimedia={toggleDropdownMultimedia}
                            dropdownOpenMultimedia={dropdownOpenMultimedia}
                            OpenGalleryPhoto={OpenImages}
                            OpenGalleryVideo={OpenVideos}
                            OpenUploadPhoto={OpenUploadPhoto}
                            OpenUploadVideo={OpenUploadVideo}
                            toggleDropdownValitronics={toggleDropdownValitronics}
                            dropdownOpenValitronics={dropdownOpenValitronics}
                            OpenComprobante={OpenComprobante}
                            OpenListCheck={OpenListCheck}
                            toggleDropdownOpciones={toggleDropdownOpciones}
                            dropdownOpenOpciones={dropdownOpenOpciones}
                            showEditContract={showEditContract}
                            EditContrato={EditContrato}
                            ActualizarContratoDevice={ActualizarContratoDevice}
                        />
                    </td>
                </tr>
            );
        });
        return rows;
    }

    useEffect(() => {
        CargarInfoByIdDevice(contract);
    }, []);

    return (
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <div className="layout-page">
                        <Barra />
                        <div className="content-wrapper">
                            <div className="container-fluid flex-grow-1 container-p-y">
                                <MDBRow>
                                    <MDBCol md="7">
                                        <label
                                            className="fw-bold mb-4 label_trafico"
                                            style={{ fontSize: "22px", marginRight: "10px" }}
                                        >
                                            Control Device
                                        </label>
                                    </MDBCol>
                                </MDBRow>
                                <div className="card">
                                    <div
                                        className="table-responsive"
                                        style={{
                                            height: `calc(100vh - 200px)`,
                                            width: "100%",
                                            overflowY: "auto",
                                            overflowX: "auto",
                                        }}
                                    >
                                        <table
                                            className="table table-hover"
                                            id="tabla"
                                            style={{
                                                width: "100%",
                                                tableLayout: "fixed",
                                                minWidth: "1200px",
                                            }}
                                        >
                                            <thead className="FondoHeaderTabla2" style={{ textAlign: "center" }}>
                                                <tr>
                                                    <th style={{ color: "#ffffff" }}>CONTRACT ID</th>
                                                    <th style={{ color: "#ffffff", width: '400px' }}>EMPRESA</th>
                                                    <th style={{ color: "#ffffff" }}>RUTA</th>
                                                    <th style={{ color: "#ffffff" }}>INICIO</th>
                                                    <th style={{ color: "#ffffff" }}>FIN</th>
                                                    <th style={{ color: "#ffffff" }}>ULT. POSICION</th>
                                                    <th style={{ color: "#ffffff", width: '60px', alignItems: 'center' }}></th>
                                                    <th style={{ color: "#ffffff", width: '60px', alignItems: 'center' }}></th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0" id="tabladata">
                                                {/* Aquí se debe renderizar la información (por ejemplo, usando map sobre infoDevice) */}
                                                {loadFillData(infoDevice)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <ModalReporteServicio
                id="ModalReporteServicioHistorico"
                isOpen={isDialogOpenModalReporteServicio}
                onClose={onCloseModalReporteServicio}
                childComponent={<ReporteServicio
                    contrato={infoContratoAjustado}
                    ultPunto={ultPunto}
                    contractR={contractR}
                />}
            />
            <BaseModalAuto
                size={'xl'}
                isOpen={isDialogOpenModalListaCheck}
                title={''}
                onClose={onCloseModalListaCheck}
                childComponent={<PrintComponent data={infoContrato} />}
            />

            <ModalBaseLg
                isOpen={isDialogOpenModalComprobanteValitronics}
                title={''}
                onClose={onCloseModalComprobanteValitronics}
                childComponent={<ModalComprobanteValitronics
                    listComprobantes={listComprobantes} />}
            />

            <RSeguimientoServicio
                isOpen={isDialogOpenRSeguimientoServicio}
                title={'Monitoreo de tráfico'}
                onClose={onCloseReporteServicio}
                childComponent={
                    <FormReporteServicio
                        contrato={contratoseleccionado}
                        images={OpenImages2}
                        tracking={OpenTracking2}
                        consolidado={OpenReporteConsolidado} />}
            />

            <ModalBaseLg
                isOpen={modalTrakalok}
                title={'Informacion de contrato'}
                onClose={onCloseModalTrack}
                childComponent={<FormTrakalok contractId={contractId} toggle={onCloseModalTrack} listAgain={() => CargarInfoByIdDevice(contract)} />}
            />

            <ModalReporteServicio
                isOpen={modalReporte}
                title={''}
                onClose={onCloseModalReporte}
                childComponent={<ReporteServicio contrato={contractInfo} ultPunto={ultPunto} contractR={contractR} />}
            />

            {/* Modal de contrato de seguimineto */}
            <BaseModalXXL
                isOpen={modalAbierto2}
                title={'Contrato Seguimiento'}
                onClose={cerrarModal2}
                loading={modalLoading}
            >
                <ReporteContratoH
                    isOpen={modalAbierto2}
                    onClose={cerrarModal2}
                    contratoVar={infoContrato2}
                    aleatorio={Math.floor(Math.random() * 1000) + 1}
                    images={OpenImages}
                    videos={OpenVideos}
                    valitronics={OpenValitronics}
                    checkList={OpenListCheckModal}
                    monitoreo={OpenMonitoreo}
                    consolidado={OpenReporteConsolidado}
                />
            </BaseModalXXL >

            {/* Moal Galeria */}
            <div
                className="modal fade"
                id="ModalGaleria"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
            >

                <ModalGaleria
                    isOpen={isDialogOpenModalGaleria}
                    contratoseleccionado={contratoseleccionado}
                    infocontratoContainer={infocontratoContainer}
                    eliminar={eliminar}
                    OpenTracking={OpenTracking}
                    OpenMonitoreo2={OpenMonitoreo2}
                    OpenConsolidado={OpenReporteConsolidado2}
                />
            </div>

            <div
                className="modal fade"
                id="ModalVideoGaleria"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
            >
                <div className="modal-dialog modal-lg">
                    <form className="modal-content" id="ModalGaleriaW">
                        <div className="modal-header">
                            <h5 className="modal-title" id="backDropModalTitle">
                                VIDEOS
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => { handleModalClose() }}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="container text-center">
                                <div className="row">
                                    <VideoGallery contrato={contratoseleccionado} />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div
                className="modal fade"
                id="ModalReporteDian"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
            >
                <div className="modal-dialog modal-lg">
                    <form className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="backDropModalTitle">
                                Contrato Dian
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <ReporteDian
                                    isOpen={modalAbierto}
                                    onClose={cerrarModal}
                                    contratoVar={contratoDian}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ControlContracts;
