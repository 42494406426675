import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'
import '../../Pages/Css/solicitudes.css';
import TooltipComponent from '../../componentes/radix/Tooltip';
import Swal from 'sweetalert2';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import axios from '../../utils/axiosConfig';
import SwitchButton from '../../componentes/SwitchButton';
import { formattedGlobalDate } from '../../utils/formattedDates';

const FormReporteServicio = ({ contrato, images, tracking, consolidado }) => {
    const [fHora, setFHora] = useState(new Date());
    const [fHoraFormatted, setFHoraFormated] = useState(null);
    const [notas, setNotas] = useState(null);
    const [ubicacion, setUbicacion] = useState(null);
    const [listReportes, setListReportes] = useState([]);
    const [listTiposEstados, setListTiposEstados] = useState([]);
    const [tipoReportes, setTipoReportes] = useState([]);
    const [idReport, setIdReport] = useState(null);
    const [selectedTipyStatus, setSelectedTipyStatus] = useState(null);
    const [selectedTipyAction, setSelectedTipyAction] = useState(null);
    const [asegurado, setAsegurado] = useState("");
    const [individual, setIndividual] = useState(false);
    const userSolicitud = localStorage.getItem('username');

    const idSolicitud = contrato;
    //METHOD SORT
    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');



    const handleCheckboxChangeIndividual = (newValue) => {
        setIndividual(newValue);
        console.log(newValue);
    };

    //FORMATEO FECHA Y HORA
    const handleDateTimeChangeHoraCita = (e) => {
        // Captura el valor del input
        const value = e.target.value;

        // Extrae la fecha y hora
        const [date, time] = value.split('T');

        // Agrega los segundos a la hora
        const timeWithSeconds = time.length === 5 ? `${time}:00` : time;

        // Combina la fecha y la hora formateada
        const formattedDateTime = `${date} ${timeWithSeconds}`;

        // Actualiza los estados
        // console.log('Datos de fecha antes de formatear: -->>', value);
        // console.log('Datos fecha formateada: -->>', formattedDateTime);
        setFHoraFormated(formattedDateTime);
        setFHora(value);
    };

    const formattedSetTime = (time) => {
        // Divide la cadena en fecha y hora
        const [date, timePart] = time.split('T');

        // Asegúrate de que la parte de la hora no contenga la parte '.000Z'
        const cleanTimePart = timePart.split('.')[0];

        // Combina la fecha y la hora formateada
        const formattedDateTime = `${date} ${cleanTimePart}`;

        // console.log("Datos fecha formateado para actualizar ***", formattedDateTime);

        // Aquí puedes actualizar tu estado o realizar alguna acción con formattedDateTime
        setFHora(formattedDateTime);
        setFHoraFormated(formattedDateTime);
    };

    const handleSelectChangeTipoAccion = (event) => {
        const selectState = parseInt(event.target.value);
        setSelectedTipyAction(selectState);
        console.log("Tipo accion selescted: ", selectState);
    }
    const handleSelectChangeTipoStatus = (event) => {
        const selectState = parseInt(event.target.value);
        setSelectedTipyStatus(selectState);
        console.log("Tipo estado selescted: ", selectState);
    }

    //LISTAR SOLISICITUDES REPORTES
    const listarReporteServicios = () => {
        let dataContrato = {
            contrato: idSolicitud
        }
        axios
            .post(process.env.REACT_APP_SERVER + "/contratos/getreportestrafico", JSON.stringify(dataContrato), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log("Informacion REPORTE SEGU8IMINETO SERVICIO: ", res.data.data);
                    setListReportes(res.data.data);
                }
            }).catch((err) => console.log(err));
    }

    //LISTAR EESTADOS
    const listTipoAccionesReportes = () => {

        axios
            .get(process.env.REACT_APP_SERVER + "/contratos/gettipoacciones", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log("Informacion REPORTE SEGU8IMINETO accion realizada: ", res.data.data);
                    setTipoReportes(res.data.data);
                }
            }).catch((err) => console.log(err));
    }
    const listTipoEstadoReporte = () => {

        axios
            .get(process.env.REACT_APP_SERVER + "/contratos/gettiporeportes", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log("Informacion REPORTE SEGU8IMINETO estados servicio: ", res.data.data);
                    setListTiposEstados(res.data.data);
                }
            }).catch((err) => console.log(err));
    }

    //CARGAR INFO
    const loadFillData = (array) => {
        // console.log("este es el array de la funcion loadFillData", array);
        return array.map((data) => {
            console.log("Data XTIME: ", data.XTime);
            // Función para formatear la fecha y hora
            const formatDate = (dateTimeString) => {
                // Parsea el string ISO 8601 a objeto Date
                const parsedDate = parseISO(dateTimeString);

                // Formatea la fecha y hora según el formato deseado
                const formattedDate = format(parsedDate, "d MMMM yyyy h:mma", { locale: es });

                return formattedDate;
            };

            const formattedSetTime = (time) => {
                // Divide la cadena en fecha y hora
                const [date, timePart] = time.split('T');

                // Asegúrate de que la parte de la hora no contenga la parte '.000Z'
                const cleanTimePart = timePart.split('.')[0];

                // Combina la fecha y la hora formateada
                const formattedDateTime = `${date} ${cleanTimePart}`;

                console.log("Datos fecha formateado para actualizar ***", formattedDateTime);

                // Aquí puedes actualizar tu estado o realizar alguna acción con formattedDateTime
                return formattedDateTime;
            };

            const formateo = formattedGlobalDate(data.XTime);


            // const formatedTimeZone = formattedSetTime(data.XTime);

            // Ejemplo de uso con la cadena formateada previamente
            // const formattedDate = formatDate(formatedTimeZone); // Usar aquí tu cadena formateada anteriormente

            return (
                <React.Fragment >
                    {/* <tr onClick={() => this.toggleRow(data.ContractID)}> */}

                    <tr>

                        <td style={{ color: "#000", height: '10px' }}>{formateo}</td>


                        <td style={{ color: "#000" }}>{data.TipoAccion}</td>


                        <td style={{ color: "#000" }}>{data.TipoReporte}</td>


                        <td style={{ color: "#000" }}>{data.Ubicacion}</td>


                        <td style={{ color: "#000" }}>{data.Nota}</td>


                        <td style={{ color: "#000" }}>{data.XUser}</td>


                        <td >
                            <a href='#' onClick={(e) => { e.preventDefault(); loadDataUpdate(data.IdReport) }}><i class='bx bxs-pencil bx-tada' style={{ color: '#006699' }}></i></a>
                        </td>

                        <td >
                            <a href='#' onClick={(e) => { e.preventDefault(); deleteReporteSeguimiento(data.IdReport) }}><i class='bx bx-trash' style={{ color: '#006699' }}></i></a>
                        </td>


                    </tr>

                </React.Fragment >
            )
        })
    }

    //CARGAR TIOPO DE ASEGURADO BOOLEAN
    const handleChangeAsegurado = () => {
        let dataReporte = {
            FKICTipoReporte: selectedTipyStatus
        }
        axios
            .post(process.env.REACT_APP_SERVER + "/contratos/getaseguradotiporeporte", JSON.stringify(dataReporte), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            })
            .then((res) => {
                if (res.data.success == false) {
                    window.location = "/";
                } else {
                    console.log("Informacion REPORTE SEGU8IMINETO SERVICIO asegurado: ", res.data.data);
                    setAsegurado(res.data.data[0].Asegurar);
                }
            }).catch((err) => console.log(err));
    }

    //CARGAR INFO PARA ACTUALIZAR
    const loadDataUpdate = (idReport) => {
        console.log("ID Reporte: ", idReport);
        const filteredByIdReport = listReportes.filter((report) => report.IdReport === idReport);
        console.log(filteredByIdReport);
        const filteredByIdReportState = listTiposEstados.filter((reportstate) => reportstate.TipoReporte === filteredByIdReport[0].TipoReporte);
        const filteredByIdReportAction = tipoReportes.filter((actiontstate) => actiontstate.TipoAccion === filteredByIdReport[0].TipoAccion);
        console.log("este es el array de la funcion loadDataUpdate cambio de estado : ", filteredByIdReportState);
        formattedSetTime(filteredByIdReport[0].XTime);
        setNotas(filteredByIdReport[0].Nota);
        setSelectedTipyStatus(filteredByIdReportState[0].IdTipoReporte);
        setSelectedTipyAction(filteredByIdReportAction[0].IdTipoAccion);
        setUbicacion(filteredByIdReport[0].Ubicacion);
        setIdReport(filteredByIdReport[0].IdReport);
        setIndividual();
    }

    //INSERTAR REPORTE DE SEGUIMIENTO
    const insertReporteSeguimiento = () => {
        let dataReport = {
            FKICTipoReporte: selectedTipyStatus,
            FKLokTipoAccion: selectedTipyAction,
            Nota: notas,
            Ubicacion: ubicacion,
            XTime: fHoraFormatted,
            Individual: individual,
            FKLokContractID: idSolicitud
        }
        console.log("Data sreporte solcicitud antes de subirlo : ", dataReport);
        if (!fHora || !notas || !ubicacion || !selectedTipyStatus || !selectedTipyAction || selectedTipyAction === tipoReportes[0].IdTipoAccion || selectedTipyStatus === listTiposEstados[0].IdTipoReporte) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Debe llenar los campos obligatorios",
                didOpen: () => {
                    const confirmButton = Swal.getConfirmButton();
                    confirmButton.style.backgroundColor = '#ff0000';
                    confirmButton.style.color = '#ffffff';
                    confirmButton.style.border = 'none';
                    confirmButton.style.padding = '10px 20px';
                    confirmButton.style.fontSize = '16px';
                    confirmButton.style.borderRadius = '5px';
                    confirmButton.style.cursor = 'pointer';
                }
            });
        } else {
            axios
                .post(process.env.REACT_APP_SERVER + "/contratos/insertreportetrafico", JSON.stringify(dataReport), {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                    },
                    withCredentials: true,
                })
                .then((res) => {
                    if (res.data.success == false) {
                        window.location = "/";
                    } else {
                        console.log("Informacion REPORTE SEGU8IMINETO INSERTARRR *****: ", res.data.data);
                        listarReporteServicios();
                        clearFields();
                    }
                }).catch((err) => console.log(err));
        }
    }

    //ACTUALIZAR REPORTE DE SEGUIMIENTO
    const updateReporteSeguimiento = () => {
        let dataReportUPT = {
            FKICTipoReporte: selectedTipyStatus,
            FKLokTipoAccion: selectedTipyAction,
            Nota: notas,
            Ubicacion: ubicacion,
            IdReport: idReport,
        }
        if (!fHora || !notas || !ubicacion || !selectedTipyStatus || !selectedTipyAction || selectedTipyAction === tipoReportes[0].IdTipoAccion || selectedTipyStatus === listTiposEstados[0].IdTipoReporte) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Debe llenar todos los campos.",
                didOpen: () => {
                    const confirmButton = Swal.getConfirmButton();
                    confirmButton.style.backgroundColor = '#ff0000';
                    confirmButton.style.color = '#ffffff';
                    confirmButton.style.border = 'none';
                    confirmButton.style.padding = '10px 20px';
                    confirmButton.style.fontSize = '16px';
                    confirmButton.style.borderRadius = '5px';
                    confirmButton.style.cursor = 'pointer';
                }
            });
        } else {
            axios
                .post(process.env.REACT_APP_SERVER + "/contratos/updatereportetrafico", JSON.stringify(dataReportUPT), {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                    },
                    withCredentials: true,
                })
                .then((res) => {
                    if (res.data.success == false) {
                        window.location = "/";
                    } else {
                        console.log("Informacion REPORTE SEGU8IMINETO ACTUALIZAR *****: ", res.data.data);
                        listarReporteServicios();
                        clearFields();
                    }
                }).catch((err) => console.log(err));
        }
    }

    //ELIMINAR REPORTE DE SEGUIMIENTO
    const deleteReporteSeguimiento = (IdReport) => {
        console.log("Id reporte: ", IdReport);
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'custom-confirm-button', // or 'btn btn-info' if you use Bootstrap
                cancelButton: 'custom-cancel-button' // or 'btn btn-danger' if you use Bootstrap
            },
            buttonsStyling: false
        });
        swalWithBootstrapButtons.fire({
            title: "Está seguro?",
            text: "Esto no es reversible!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, anular",
            cancelButtonText: "No, cancelar!",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                let dataReporteAnular = {
                    IdReport: IdReport
                }
                axios
                    .post(process.env.REACT_APP_SERVER + "/contratos/deletereportetrafico", JSON.stringify(dataReporteAnular), {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                        },
                        withCredentials: true,
                    })
                    .then((res) => {
                        if (res.data.success == false) {
                            window.location = "/";
                        } else {
                            listarReporteServicios();
                            swalWithBootstrapButtons.fire({
                                title: "Eliminado!",
                                text: "Su reporte de seguimiento ha sido anulada con éxito.",
                                icon: "success"
                            });

                        }
                    }).catch((err) => console.log(err));


            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                console.log("no se anuilo la solicitud");
            }
        });


    }



    //LIMPIAR CAMPOS
    const clearFields = () => {
        cargarFechaHoraActual();
        setNotas("");
        setIndividual(false);
        setSelectedTipyAction(tipoReportes[0].IdTipoAccion);
        setSelectedTipyStatus(listTiposEstados[0].IdTipoReporte);
        setIdReport(null);
        setAsegurado("");
        setUbicacion("");
    }

    //CARGAR FECHA HORA ACTUAL APENAS SE CARGA EL COMPONENTE
    const cargarFechaHoraActual = () => {
        const ahora = new Date(); // Obtiene la fecha y hora actual en la zona horaria local
        const year = ahora.getFullYear();
        const month = ahora.getMonth() + 1;
        const day = ahora.getDate();
        const hours = ahora.getHours();
        const minutes = ahora.getMinutes();

        // Formatear la fecha y hora local
        const formattedDateTime = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        console.log("Hora actual local:", formattedDateTime);
        formattedSetTime(formattedDateTime);
        // Actualiza el estado con la fecha y hora local
    };

    const imagesModal = (e) => {
        e.preventDefault();
        console.log("Abrir modal de imagenes");
        images();

    }

    const trackingModal = (e) => {
        e.preventDefault();
        console.log("Abrir modal de tracking");
        tracking();
    }

    const consolidadoModal = (e) => {
        e.preventDefault();
        console.log("Abrir modal de consolidado");
        consolidado();
    }


    const renderSortIcon = (column) => {

        if (sortBy === column) {
            if (sortDirection === "asc") {
                return <span>&uarr;</span>; // Icono de flecha hacia arriba
            } else {
                return <span>&darr;</span>; // Icono de flecha hacia abajo
            }
        }

        return null; // No mostrar ningún ícono si no está ordenado por esta columna
    }

    // const tiposEstados = [
    //     { id: 1, nombre: 'Pendiente' },
    //     { id: 2, nombre: 'En proceso' },
    //     { id: 3, nombre: 'Finalizado' },

    // ];

    useEffect(() => {
        listarReporteServicios();
        listTipoAccionesReportes();
        listTipoEstadoReporte();
        cargarFechaHoraActual();
    }, []);

    useEffect(() => {
        if (selectedTipyStatus) {
            handleChangeAsegurado();
        }
    }, [selectedTipyStatus]);



    // <i class='bx bxs-pencil bx-tada' style={{ color: '#006699' }}></i>
    return (
        <div style={{ height: '75vh' }} className='containerMonitoreoTrafico'>
            <MDBRow style={{ marginTop: '-15px', marginBottom: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <a href="#" onClick={(e) => { imagesModal(e) }}>Imagenes IA</a>&nbsp;/&nbsp;<a href="#" onClick={(e) => { trackingModal(e) }}>Tracking</a>&nbsp;/&nbsp;<a href="#" onClick={(e) => { consolidadoModal(e) }}>Consolidado</a>
                </div>
            </MDBRow>
            <MDBRow style={{ display: 'flex', alignItems: 'start', justifyContent: 'start', flexDirection: 'column' }}>
                {/* Número de solicitud */}
                <MDBCol md={6} style={{ display: 'flex', flexDirection: 'row' }} className='columnsStyles'>
                    <MDBCol md={5}>
                        <div className="InputTooltipWrapper reporteSeolicitud">
                            <label className="Label" htmlFor="solicitud">Contrato</label>
                            {/* <TooltipComponent message={'Acá iría el número que identifica la solicitud en curso '} /> */}
                        </div>
                    </MDBCol>
                    <MDBCol md={8} className='reporteSeolicitudInputt'>
                        <input className="Input" id="name" defaultValue={idSolicitud} disabled />
                    </MDBCol>
                </MDBCol>

                {/* Fecha y hora */}
                <MDBCol md={6} style={{ display: 'flex', flexDirection: 'row' }} className='columnsStyles'>
                    <MDBCol md={5}>
                        <div className='InputTooltipWrapper reporteSeolicitud'>
                            <label className="Label" htmlFor="fechaHoraCita">Fecha/Hora</label>
                            {/* <TooltipComponent message={'Escoge una fecha y hora para tu solicitud'} /> */}
                        </div>
                    </MDBCol>
                    <MDBCol md={8} className='reporteSeolicitudInputt'>
                        <input className="Input" id="fechaHoraCita" type='datetime-local' value={fHora} onChange={handleDateTimeChangeHoraCita} />
                    </MDBCol>
                </MDBCol>

                {/* Ubicacion */}
                <MDBCol md={6} style={{ display: 'flex', flexDirection: 'row' }} className='columnsStyles'>
                    <MDBCol md={5}>
                        <div className='InputTooltipWrapper reporteSeolicitud'>
                            <label className="Label" htmlFor="fechaHoraCita">Ubicacion</label>
                            <span style={{ color: 'red', fontSize: 15 }}>*</span>
                            {/* <TooltipComponent message={'Escoge una fecha y hora para tu solicitud'} /> */}
                        </div>
                    </MDBCol>
                    <MDBCol md={8} className='reporteSeolicitudInputt'>
                        <input className="Input" id="fechaHoraCita" type='text' placeholder='Ubicación del vehículo' value={ubicacion} onChange={(e) => setUbicacion(e.target.value)} />
                    </MDBCol>
                </MDBCol>

                {/* Accion Realizada */}
                <MDBCol md={6} style={{ display: 'flex', flexDirection: 'row' }} className='columnsStyles'>
                    <MDBCol md={5}>
                        <div className="InputTooltipWrapper reporteSeolicitud">
                            <label className="Label" htmlFor="tipoContrato">Acción Realizada</label>
                            <span style={{ color: 'red', fontSize: 15 }}>*</span>
                            {/* <span style={{ color: 'red', fontSize: 15 }}>*</span> */}
                            {/* <TooltipComponent message={'Selecciona el estado de la solicitud'} /> */}
                        </div>
                    </MDBCol>
                    <MDBCol md={12} className='reporteSeolicitudInputt'>
                        <select className="InputSelectReportSeguimihnetoSol" onChange={handleSelectChangeTipoAccion} value={selectedTipyAction}>
                            {tipoReportes.map((tipo) => (
                                <option key={tipo.IdTipoAccion} value={tipo.IdTipoAccion}>
                                    {tipo.TipoAccion}
                                </option>
                            ))}
                        </select>
                    </MDBCol>
                </MDBCol>

                {/* Estado de solicitud */}
                <MDBCol md={6} style={{ display: 'flex', flexDirection: 'row' }} className='columnsStyles'>
                    <MDBCol md={5}>
                        <div className="InputTooltipWrapper reporteSeolicitud">
                            <label className="Label" htmlFor="tipoContrato">Estado de servicio</label>
                            <span style={{ color: 'red', fontSize: 15 }}>*</span>
                            {/* <span style={{ color: 'red', fontSize: 15 }}>*</span> */}
                            {/* <TooltipComponent message={'Selecciona el estado de la solicitud'} /> */}
                        </div>
                    </MDBCol>
                    <MDBCol md={12} className='reporteSeolicitudInputt'>
                        <select className="InputSelectReportSeguimihnetoSol" onChange={handleSelectChangeTipoStatus} value={selectedTipyStatus}>
                            {listTiposEstados.map((state) => (
                                <option key={state.IdTipoReporte} value={state.IdTipoReporte}>
                                    {state.TipoReporte}
                                </option>
                            ))}
                        </select>
                    </MDBCol>
                </MDBCol>


                {/* Notas */}
                <MDBCol md={10} style={{ display: 'flex', flexDirection: 'row' }} className='columnsStyles'>
                    <MDBCol md={3}>
                        <div className='contToltip reporteSeolicitud'>
                            <label className="Label" htmlFor="observacionesServicio">Notas</label>
                            <span style={{ color: 'red', fontSize: 15 }}>*</span>
                            {/* <TooltipComponent message={'Cliente para consultas'} /> */}
                        </div>
                    </MDBCol>
                    <MDBCol md={10} className='reporteMonitoreoInputt'>
                        <textarea className="TextAreaReportSe" value={notas} onChange={(e) => setNotas(e.target.value)} />
                    </MDBCol>
                </MDBCol>
                <br />

                {/* SwitchButtons */}
                <MDBCol md={6} style={{ display: 'flex', flexDirection: 'row' }} className='columnsStyles'>
                    <MDBCol md={5}>
                        <div className='contToltip reporteSeolicitud'>
                            <label className="Label" htmlFor="observacionesServicio">Asegurado</label>
                            {/* <TooltipComponent message={'Cliente para consultas'} /> */}
                        </div>
                    </MDBCol>
                    <MDBCol md={12} className='reporteSeolicitudInputt'>
                        {!asegurado ? ' desactivado' : ' activado'}

                    </MDBCol>
                </MDBCol>
                <MDBCol md={6} style={{ display: 'flex', flexDirection: 'row' }} className='columnsStyles'>
                    <MDBCol md={5}>
                        <div className='contToltip reporteSeolicitud'>
                            <label className="Label" htmlFor="observacionesServicio">Individual</label>
                            {/* <TooltipComponent message={'Cliente para consultas'} /> */}
                        </div>
                    </MDBCol>
                    <MDBCol md={12} className='reporteSeolicitudInputt'>
                        <SwitchButton checked={individual} onChange={handleCheckboxChangeIndividual} type={'\u00A0'} />
                    </MDBCol>
                    <div className="ButtonWrapper" style={{ display: 'flex', justifyContent: 'flex-start', transform: "translate(-70px,-25px)" }}>


                        {idReport ? (
                            <button
                                className="Button blue"
                                onClick={(e) => { e.preventDefault(); updateReporteSeguimiento(); }}
                            >Actualizar</button>

                        ) : (
                            <button
                                className="Button blue"
                                onClick={(e) => { e.preventDefault(); insertReporteSeguimiento(); }}
                            // data-bs-dismiss='modal'
                            >Grabar</button>

                        )}




                    </div>
                </MDBCol>
            </MDBRow >



            <MDBRow>
                <MDBCol md={12}>
                    <div className="card ContainerTableReportesSolicitudes" >
                        <div
                            className="table-responsive text-nowrap"
                            style={{
                                height: `calc(70vh - 300px)`,
                                overflowY: "auto",
                                overflowX: "auto",
                                display: "flex",

                                // flexDirection: "column",
                            }}
                        >
                            <table className="table table-hover" id="tabla" style={{ overflowX: 'auto', width: "1290px" }}>
                                <thead className="FondoHeaderTabla2">
                                    <tr>

                                        <th
                                            style={{ color: "#ffffff", width: '200px' }}
                                        // onClick={() => sortData("IDSolicitudes")}
                                        >
                                            Fecha/Hora {renderSortIcon("IDSolicitudes")}
                                        </th>

                                        <th
                                            style={{ color: "#ffffff", width: '250px', textAlign: 'center' }}
                                        // onClick={() => sortData("NombreEmpresa")}
                                        >
                                            Acción{" "}
                                            {renderSortIcon("NombreEmpresa")}
                                        </th>
                                        <th
                                            style={{ color: "#ffffff", width: '250px', textAlign: 'center' }}
                                        // onClick={() => sortData("NombreEmpresa")}
                                        >
                                            Estado{" "}
                                            {renderSortIcon("NombreEmpresa")}
                                        </th>
                                        <th
                                            style={{ color: "#ffffff", width: '150px', textAlign: 'center' }}
                                        // onClick={() => sortData("NombreEmpresa")}
                                        >
                                            Ubicación{" "}
                                            {renderSortIcon("NombreEmpresa")}
                                        </th>

                                        <th
                                            style={{ color: "#ffffff", width: "250px", textAlign: 'center' }}
                                        // onClick={() => sortData("Ruta")}
                                        >
                                            Observaciones {renderSortIcon("Ruta")}
                                        </th>

                                        <th
                                            style={{ color: "#ffffff", width: "150px", textAlign: 'center' }}
                                        // onClick={() => sortData("PlacaTruck")}
                                        >
                                            Usuario {renderSortIcon("PlacaTruck")}
                                        </th>
                                        <th
                                            style={{ color: "#ffffff", width: "20px" }}
                                        // onClick={() => sortData("IconSeguro")}
                                        >
                                            {renderSortIcon("IconSeguro")}
                                        </th>
                                        <th
                                            style={{ color: "#ffffff", width: "20px" }}
                                        // onClick={() => sortData("IconSeguro")}
                                        >
                                            {renderSortIcon("IconSeguro")}
                                        </th>


                                    </tr>
                                </thead>
                                <tbody className="table-border-bottom-0" id="tabladata">
                                    {loadFillData(listReportes)}
                                    {/* el body */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </MDBCol>
            </MDBRow>
        </div>
    )
}

export default FormReporteServicio
