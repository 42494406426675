import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const BaseModalXXL = ({ isOpen, onClose, title, children, loading }) => {
    const backdropStyle = 'static';
    // Define un estilo inline para el modal
    const customModalStyle = { maxWidth: '90%' };

    return (
        <Modal
            isOpen={isOpen}
            toggle={onClose}
            backdrop={backdropStyle}
            keyboard={false}
            zIndex={9999}
            centered
            style={customModalStyle}  // Aplica el estilo inline aquí
        >
            <ModalHeader toggle={onClose}>{title}</ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
        </Modal>
    );
};

export default BaseModalXXL;
