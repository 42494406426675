import React, { useEffect, useRef, useState } from 'react';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import $, { get } from 'jquery';
import 'jquery-datetimepicker';
import axios from '../../utils/axiosConfig';
import { formattedGlobalDate } from './../../utils/formattedDates';
import Swal from 'sweetalert2';


const FormTrakalok = ({ isOpen, toggle, contractId, listAgain }) => {
    const [infoContrato, setInfoContrato] = useState({});
    const refs = {
        fechaHoraInstalacion: useRef(null),
        fechaHoraInicio: useRef(null),
        fechaHoraFin: useRef(null),
    };

    const [formData, setFormData] = useState({
        // contractId: 'SERV-00423434',
        // deviceId: '7500409508',
        // installationDate: '2025-01-02 08:38',
        // startDate: '2025-01-02 08:38',
        // endDate: '',
    });

    const getInfoTrakalok = () => {
        let dataContract = {
            contrato: contractId,
        };
        console.log("Data Contract", dataContract);
        axios
            .post(
                process.env.REACT_APP_SERVER + "/operaciones/getinfofechascontrato",
                JSON.stringify(dataContract),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                if (res.data.success === false) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: `${res.data.mensaje}`,
                        didOpen: () => {
                            const confirmButton = Swal.getConfirmButton();
                            confirmButton.style.backgroundColor = '#ff0000';
                            confirmButton.style.color = '#ffffff';
                            confirmButton.style.border = 'none';
                            confirmButton.style.padding = '10px 20px';
                            confirmButton.style.fontSize = '16px';
                            confirmButton.style.borderRadius = '5px';
                            confirmButton.style.cursor = 'pointer';
                        }
                    });
                } else {
                    console.log("Informacion del contrato", res.data.data);
                    setInfoContrato(res.data.data);
                }
            })
            .catch((err) => console.log(err));
    };


    const handleSubmit = () => {
        console.log('Formulario a enviar:', formData);
        let data = {
            contrato: formData.contractId,
            instalacion: formData.installationDate,
            inicio: formData.startDate,
            fin: formData.endDate,
        }
        onSubmit(data);
    };

    const onSubmit = (data) => {
        console.log("Data Contract", data);
        axios
            .post(
                process.env.REACT_APP_SERVER + "/operaciones/setfechascontrato",
                JSON.stringify(data),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                if (res.data.success === false) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: `${res.data.mensaje}`,
                        didOpen: () => {
                            const confirmButton = Swal.getConfirmButton();
                            confirmButton.style.backgroundColor = '#ff0000';
                            confirmButton.style.color = '#ffffff';
                            confirmButton.style.border = 'none';
                            confirmButton.style.padding = '10px 20px';
                            confirmButton.style.fontSize = '16px';
                            confirmButton.style.borderRadius = '5px';
                            confirmButton.style.cursor = 'pointer';
                        }
                    });
                } else {
                    console.log("Contrato actualizado", res.data.data);
                    toggle();
                    listAgain();
                    Swal.fire({
                        icon: "success",
                        title: "¡Éxito!",
                        text: `Contrato actualizado correctamente`,
                        didOpen: () => {
                            const confirmButton = Swal.getConfirmButton();
                            confirmButton.style.backgroundColor = '#007bff';
                            confirmButton.style.color = '#ffffff';
                            confirmButton.style.border = 'none';
                            confirmButton.style.padding = '10px 20px';
                            confirmButton.style.fontSize = '16px';
                            confirmButton.style.borderRadius = '5px';
                            confirmButton.style.cursor = 'pointer';
                        }
                    });
                }
            })
            .catch((err) => console.log(err));
    }


    useEffect(() => {
        const now = new Date();
        const currentDateTime = now.toISOString().slice(0, 16).replace('T', ' ');

        // Configurar datetimepicker en cada input
        const initDateTimePicker = (ref, key) => {
            if (ref.current) {
                $(ref.current).datetimepicker({
                    format: 'Y-m-d H:i',
                    step: 30,
                    value: formData[key] || currentDateTime,
                    onChangeDateTime: (dp, $input) => {
                        setFormData((prev) => ({
                            ...prev,
                            [key]: $input.val(),
                        }));
                    },
                });
            }
        };

        initDateTimePicker(refs.fechaHoraInstalacion, 'installationDate');
        initDateTimePicker(refs.fechaHoraInicio, 'startDate');
        initDateTimePicker(refs.fechaHoraFin, 'endDate');

        return () => {
            Object.values(refs).forEach(ref => {
                if (ref.current) $(ref.current).datetimepicker('destroy');
            });
        };
    }, [formData.installationDate, formData.startDate, formData.endDate]);

    const handleDateTimeInputChange = (e) => {
        let value = e.target.value;
        value = value.replace(/[^0-9- :]/g, ""); // Solo permite números, guiones y dos puntos

        if (value.length > 4 && value[4] !== '-') value = value.slice(0, 4) + '-' + value.slice(4);
        if (value.length > 7 && value[7] !== '-') value = value.slice(0, 7) + '-' + value.slice(7);
        if (value.length > 10 && value[10] !== ' ') value = value.slice(0, 10) + ' ' + value.slice(10);
        if (value.length > 13 && value[13] !== ':') value = value.slice(0, 13) + ':' + value.slice(13);

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: value,
        }));
    };


    useEffect(() => {
        getInfoTrakalok();
        console.log("contractId", contractId)
    }, [contractId]);

    useEffect(() => {
        console.log(infoContrato[0]);
        if (infoContrato) {
            setFormData((prevState) => ({
                ...prevState,
                contractId: infoContrato[0]?.ContractID,
                deviceId: infoContrato[0]?.FKLokDeviceID,
                installationDate: formattedGlobalDate(infoContrato[0]?.InicioServicio),
                startDate: formattedGlobalDate(infoContrato[0]?.FechaHoraInicio),
                endDate: formattedGlobalDate(infoContrato[0]?.FechaHoraFin),
            }));
        }
    }, [infoContrato]);

    return (
        <div style={{ height: 'auto ', padding: '20px', borderRadius: '8px' }}>
            <MDBRow style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>

                {/* ContractID y DeviceID */}
                <MDBCol md={6} style={{ display: 'flex', flexDirection: 'row' }}>
                    <MDBCol md={6}><label className="Label"><strong>ContractID:</strong></label></MDBCol>
                    <MDBCol md={6}><span>{formData.contractId}</span></MDBCol>
                </MDBCol>

                <MDBCol md={6} style={{ display: 'flex', flexDirection: 'row' }}>
                    <MDBCol md={6}><label className="Label"><strong>DeviceID:</strong></label></MDBCol>
                    <MDBCol md={6}><span>{formData.deviceId}</span></MDBCol>
                </MDBCol>

                {/* Fecha/Hora Instalación */}
                <MDBCol md={6} style={{ display: 'flex', flexDirection: 'row' }}>
                    <MDBCol md={6}><label className="Label">Fecha/Hora Instalación:</label></MDBCol>
                    <MDBCol md={6}>
                        <input
                            ref={refs.fechaHoraInstalacion}
                            className="Input"
                            type="text"
                            name="installationDate"
                            value={formData.installationDate}
                            onChange={handleDateTimeInputChange}
                            placeholder="YYYY-MM-DD HH:MM"
                            maxLength={16}
                        />
                    </MDBCol>
                </MDBCol>

                {/* Fecha/Hora Inicio */}
                <MDBCol md={6} style={{ display: 'flex', flexDirection: 'row' }}>
                    <MDBCol md={6}><label className="Label">Fecha/Hora Inicio:</label></MDBCol>
                    <MDBCol md={6}>
                        <input
                            ref={refs.fechaHoraInicio}
                            className="Input"
                            type="text"
                            name="startDate"
                            value={formData.startDate}
                            onChange={handleDateTimeInputChange}
                            placeholder="YYYY-MM-DD HH:MM"
                            maxLength={16}
                        />
                    </MDBCol>
                </MDBCol>

                {/* Fecha/Hora Fin */}
                <MDBCol md={6} style={{ display: 'flex', flexDirection: 'row' }}>
                    <MDBCol md={6}><label className="Label">Fecha/Hora Fin:</label></MDBCol>
                    <MDBCol md={6}>
                        <input
                            ref={refs.fechaHoraFin}
                            className="Input"
                            type="text"
                            name="endDate"
                            value={formData.endDate}
                            onChange={handleDateTimeInputChange}
                            placeholder="YYYY-MM-DD HH:MM"
                            maxLength={16}
                        />
                    </MDBCol>
                </MDBCol>

                {/* Botón de grabar */}
                <MDBCol md={6} style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <button className="Button blue" onClick={handleSubmit}>Grabar</button>
                </MDBCol>

            </MDBRow>
        </div>
    );
};

export default FormTrakalok;
