import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const MenuContratos = (props) => {
    const { data } = props;
    const location = useLocation();
    const { pathname } = location;

    // Si la ruta incluye '/historico', no mostramos las opciones extra.
    const mostrarOpcionesExtra = !(pathname.includes('/historico') || pathname.includes('/controlContracts'));


    // Ejemplo: cambiar label según la ruta.
    const serviceTrackingLabel = pathname.includes('/historico') || pathname.includes('/controlContracts')
        ? 'Trazabilidad de servicio'
        : 'Tracking Tiempo Real';

    const isModalEnabled = pathname.includes('/historico') || pathname.includes('/trafico');


    // Estados para cada dropdown
    const [dropdownOpenReportes, setDropdownOpenReportes] = useState(false);
    const [dropdownOpenMultimedia, setDropdownOpenMultimedia] = useState(false);
    const [dropdownOpenValitronics, setDropdownOpenValitronics] = useState(false);
    const [dropdownOpenOpciones, setDropdownOpenOpciones] = useState(false);

    // Funciones para alternar cada dropdown
    const toggleDropdownReportes = (e) => {
        e.stopPropagation();
        setDropdownOpenReportes(!dropdownOpenReportes);
    };

    const toggleDropdownMultimedia = (e) => {
        e.stopPropagation();
        setDropdownOpenMultimedia(!dropdownOpenMultimedia);
    };

    const toggleDropdownValitronics = (e) => {
        e.stopPropagation();
        setDropdownOpenValitronics(!dropdownOpenValitronics);
    };

    const toggleDropdownOpciones = (e) => {
        e.stopPropagation();
        setDropdownOpenOpciones(!dropdownOpenOpciones);
    };

    return (
        <div className="dropdown position-static">
            <button
                type="button"
                className="btn p-0 dropdown-toggle hide-arrow"
                data-bs-toggle="dropdown"
                onClick={props.handleAllStatesDropdowns}
            >
                <i className="bx bx-dots-vertical position-static"></i>
            </button>
            <div className="dropdown-menu">
                {data.FKLokTipoEquipo !== 8 && (
                    <span
                        onClick={() => props.abrirModal2(data)}
                        className="dropdown-item"
                        style={{ width: '100%' }}
                    >
                        <i className="bx bxs-map me-1"></i> {serviceTrackingLabel}
                    </span>
                )}
                <span
                    onClick={() => props.showModalOpenResporteServicio(data)}
                    className="dropdown-item"
                    style={{ width: '100%' }}
                >
                    <i className="bx bx-file me-1"></i> Monitoreo de tráfico
                </span>
                <span className="dropdown">
                    <button onClick={toggleDropdownReportes} className="dropdown-item">
                        <i className="bx bxs-report"></i> Reportes <i className="bx bx-caret-down"></i>
                    </button>
                    {dropdownOpenReportes && (
                        <div className="containerSpaceMenu">
                            <span
                                onClick={() => props.OpenReporteServicio(data)}
                                {...(isModalEnabled && {
                                    'data-bs-toggle': 'modal',
                                    'data-bs-target': mostrarOpcionesExtra ? "#ModalReporteServicio" : "#ModalReporteServicioHistorico"
                                })}
                                className="dropdown-item"
                                style={{ width: '100%' }}
                            >
                                <i className="bx bx-file me-1"></i> Reporte Consolidado
                            </span>

                            {data.FKLokTipoEquipo !== 8 && (
                                <span
                                    onClick={() => props.abrirModal(data)}
                                    data-bs-toggle="modal"
                                    data-bs-target="#ModalReporteDian"
                                    className="dropdown-item"
                                    style={{ width: '100%' }}
                                >
                                    <i className="bx bx-file me-1"></i> Reporte Dian
                                </span>
                            )}
                        </div>
                    )}
                </span>

                <span className="dropdown">
                    <button onClick={toggleDropdownMultimedia} className="dropbtn dropdown-item">
                        <i className="bx bx-film"></i> Multimedia <i className="bx bx-caret-down"></i>
                    </button>
                    {dropdownOpenMultimedia && (
                        <div className="containerSpaceMenu">
                            <span
                                onClick={() => props.OpenGalleryPhoto(data)}
                                data-bs-toggle="modal"
                                data-bs-target="#ModalGaleria"
                                className="dropdown-item"
                                style={{ width: '100%' }}
                            >
                                <i className="bx bx-camera me-1"></i> Imagenes / IA
                            </span>
                            <span
                                onClick={() => props.OpenGalleryVideo(data)}
                                data-bs-toggle="modal"
                                data-bs-target="#ModalVideoGaleria"
                                className="dropdown-item"
                                style={{ width: '100%' }}
                            >
                                <i className="bx bx-video me-1"></i> Videos / IA
                            </span>
                            <span onClick={() => props.OpenUploadPhoto(data)} className="dropdown-item" style={{ width: '100%' }}>
                                <i className="bx bx-upload me-1"></i> Subir Fotos
                            </span>
                            <span onClick={() => props.OpenUploadVideo(data)} className="dropdown-item" style={{ width: '100%' }}>
                                <i className="bx bx-upload me-1"></i> Subir Videos
                            </span>

                        </div>
                    )}
                </span>

                <span className="dropdown">
                    <button onClick={toggleDropdownValitronics} className="dropdown-item">
                        <i className="bx bx-check-circle"></i> Valitronics <i className="bx bx-caret-down"></i>
                    </button>
                    {dropdownOpenValitronics && (
                        <div className="containerSpaceMenu">
                            <span
                                onClick={() => props.OpenComprobante(data)}
                                // data-bs-toggle="modal"
                                // data-bs-target="#ModalComprobanteValitronics"
                                className="dropdown-item"
                                style={{ width: '100%' }}
                            >
                                <i className="bx bx-file me-1"></i> Historial de validaciones
                            </span>
                            <span
                                onClick={() => props.OpenListCheck(data)}
                                // data-bs-toggle="modal"
                                // data-bs-target="#ModalListaCheck"
                                className="dropdown-item"
                                style={{ width: '100%' }}
                            >
                                <i className="bx bx-file me-1"></i> Check List Contenedor
                            </span>
                        </div>
                    )}
                </span>

                <span className="dropdown">
                    <button onClick={toggleDropdownOpciones} className="dropdown-item">
                        <i className="bx bxs-cog"></i> Opciones <i className="bx bx-caret-down"></i>
                    </button>
                    {dropdownOpenOpciones && (
                        <div className="containerSpaceMenu">
                            <span
                                onClick={() => props.showEditContract(data)}
                                className="dropdown-item"
                                style={{ width: '100%' }}
                            >
                                <i className="bx bx-edit-alt me-1"></i> Editar Contrato
                            </span>
                            {mostrarOpcionesExtra && (
                                <>
                                    <span
                                        onClick={() => props.EditContrato(data)}
                                        className="dropdown-item"
                                        style={{ width: '100%' }}
                                    >
                                        <i className="bx bx-edit-alt me-1"></i> Editar Trayecto
                                    </span>
                                    {data.FKLokTipoEquipo !== 8 && (
                                        <span
                                            onClick={() => props.ActualizarContratoDevice(data)}
                                            className="dropdown-item"
                                            style={{ width: '100%' }}
                                        >
                                            <i className="bx bx-file me-1"></i> Actualizar contrato
                                        </span>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </span>
            </div>
        </div>
    );
};

export default MenuContratos;
