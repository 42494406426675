import React, { useEffect, useRef, useState } from 'react';
import TooltipComponent from '../../componentes/radix/Tooltip';
import ModalBaseSm from '../modals/modal-base-sm';
import FormSaveFilters from '../formsSolicitudes/formSaveFilters';
import LoadFillFilters from './loadFillFilters';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const FilterBodyOffcanvas = ({
    contratos,
    listContratos,
    onFiltersChange,
    savedFilter,
    newValueFilter,
    deleteNewFilter,
}) => {
    const [clientes, setClientes] = useState([]);
    const [rutas, setRutas] = useState([]);
    const [dispositivos, setDispositivos] = useState([]);
    const [estados, setEstados] = useState([]);
    const [tiempo, setTiempo] = useState('');
    const [tiempoDetencion, setTiempoDetencion] = useState('');
    const [empresasSeleccionadas, setEmpresasSeleccionadas] = useState([]);
    const [alertasSeleccionadas, setAlertasSeleccionadas] = useState([]);
    const [estadosSeleccionados, setEstadosSeleccionados] = useState([]);
    const [rutasSeleccionados, setRutasSeleccionados] = useState([]);
    const [dispositivosSeleccionados, setDispositivosSeleccionados] = useState([]);
    const [showModalSaveFilters, setShowModalSaveFilters] = useState(false);
    const [showModalLoadFilters, setShowModalLoadFilters] = useState(false);
    const [modal1Tiempo, setModal1Tiempo] = useState(false);
    const [modal2Tiempo, setModal2Tiempo] = useState(false);

    const [filterName, setFilterName] = useState('');

    const [butonAplyFilters, setButonAplyFilters] = useState(false);
    const [butonSaveFilters, setButonSaveFilters] = useState(false);

    const [errorTiempo1, setErrorTiempo1] = useState(false);
    const [errorTiempo2, setErrorTiempo2] = useState(false);

    const [orderByCriteria, setOrderByCriteria] = useState([]);

    const [arryaFilters, setArryaFilters] = useState([]);

    const dropdownAlerts = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownAlerts.current && !dropdownAlerts.current.contains(event.target)) {
                document.getElementById('estadoSelect').classList.remove('show');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const extractUniqueValues = (key) => {
        const uniqueValues = [...new Set(listContratos.map((contrato) => contrato[key]))];
        return uniqueValues.filter(Boolean);
    };

    const changeFilterName = (value) => {
        setFilterName(value);
    };

    useEffect(() => {
        if (listContratos && listContratos.length > 0) {
            const empresasUnicas = extractUniqueValues('NombreEmpresa');
            const rutasUnicas = extractUniqueValues('DescripcionRuta');
            const dispositivosUnicos = extractUniqueValues('FKLokDeviceID');
            const estadosUnicos = extractUniqueValues('TipoReporte');

            console.log('Empresas únicas: ', empresasUnicas);
            console.log('Rutas únicas: ', rutasUnicas);
            console.log('Dispositivos únicos: ', dispositivosUnicos);
            console.log('Estados unicos: ', estadosSeleccionados);

            setClientes(empresasUnicas);
            setRutas(rutasUnicas);
            setDispositivos(dispositivosUnicos);
            setEstados(estadosUnicos);
        }

        console.log('Contratos filtros: ', contratos);
    }, [listContratos]);

    const handleSelect = (setSelectedFunction, selectedItems, event) => {
        const eventValue = event.target.value;
        if (eventValue === 'EMPTY' || eventValue === 'TODOS') {
            console.warn('eventos no permitidos');
            return;
        }

        const selectedNames = Array.from(event.target.selectedOptions).map((option) => option.text);
        const nuevasSeleccionadas = selectedNames.filter((nombre) => !selectedItems.includes(nombre));

        setSelectedFunction((prev) => [...prev, ...nuevasSeleccionadas]);
        console.log('Seleccionados:', nuevasSeleccionadas);
    };

    const handleSelectEstados = (estado) => {
        setAlertasSeleccionadas((prev) => {
            if (prev.includes(estado)) {
                return prev.filter((est) => est !== estado);
            } else {
                if (estado === 'tiempoDetencion') {
                    setModal2Tiempo(true);
                } else if (estado === 'tiempoUltReporte') {
                    setModal1Tiempo(true);
                }
                return [...prev, estado];
            }
        });
    };

    const handleDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination || source.index === destination.index) return;
        const reordered = Array.from(orderByCriteria);
        const [moved] = reordered.splice(source.index, 1);
        reordered.splice(destination.index, 0, moved);
        setOrderByCriteria(reordered);
    };

    const addOrderByCriteria = (field, direction) => {
        localStorage.setItem('statusFilters', 'unsaved');
        setOrderByCriteria((prev) => {
            if (prev.length >= 5) return prev;
            const existing = prev.find((criteria) => criteria.field === field);
            if (existing) {
                return prev.map((criteria) =>
                    criteria.field === field ? { ...criteria, direction } : criteria
                );
            }
            return [...prev, { field, direction }];
        });
    };

    const removeOrderByCriteria = (field) => {
        localStorage.setItem('statusFilters', 'unsaved');
        setOrderByCriteria((prev) => prev.filter((criteria) => criteria.field !== field));
    };

    const handleSelectEmpresa = (event) => handleSelect(setEmpresasSeleccionadas, empresasSeleccionadas, event);
    const handleSelectRuta = (event) => handleSelect(setRutasSeleccionados, rutasSeleccionados, event);
    const handleSelectDispositivos = (event) =>
        handleSelect(setDispositivosSeleccionados, dispositivosSeleccionados, event);
    const handleSelectEstado = (event) => handleSelect(setEstadosSeleccionados, estadosSeleccionados, event);

    const handleSelectTiempo = (event) => {
        const value = parseInt(event.target.value);
        if (value === 0) {
            setErrorTiempo1(true);
        } else {
            setTiempo(value);
            setErrorTiempo1(false);
        }
    };

    const handleSelectTiempoDetencion = (event) => {
        const value = parseInt(event.target.value);
        if (value === 0) {
            setErrorTiempo2(true);
        } else {
            setTiempoDetencion(value);
            setErrorTiempo2(false);
        }
    };

    const handleFilterLoad = async (filter) => {
        console.log('Filtro seleccionado:', filter);
        try {
            setArryaFilters(filter);
            await aplyFilters2(filter);
        } catch (error) {
            console.error('Error al parsear el filtro:', error);
        }
    };

    // Función de eliminación simplificada: actualiza el estado y, en caso de alertas,
    // verifica si al eliminar la única alerta ya no quedan filtros activos.
    const eliminarSeleccionado = (setSelectedFunction, key, nombre) => {
        setSelectedFunction((prev) => {
            const updatedSelection = prev.filter((item) => item !== nombre);

            if (key === 'filterAlerta') {
                // Calculamos los nuevos valores que queremos tener para tiempo y tiempoDetencion
                // en caso de que se esté eliminando el filtro de tiempo.
                let newTiempo = tiempo;
                let newTiempoDetencion = tiempoDetencion;

                if (nombre === 'tiempoUltReporte') {
                    newTiempo = 0;
                    setModal1Tiempo(false);
                    setTiempo(0);
                }
                if (nombre === 'tiempoDetencion') {
                    newTiempoDetencion = 0;
                    setModal2Tiempo(false);
                    setTiempoDetencion(0);
                }

                // Si al eliminar la alerta quedan 0 alertas seleccionadas,
                // verificamos si existen otros filtros activos utilizando los nuevos valores.
                if (updatedSelection.length === 0) {
                    const hasOtherFilters =
                        empresasSeleccionadas.length > 0 ||
                        estadosSeleccionados.length > 0 ||
                        rutasSeleccionados.length > 0 ||
                        dispositivosSeleccionados.length > 0 ||
                        (updatedSelection.length > 0) ||
                        newTiempo > 0 ||
                        newTiempoDetencion > 0;

                    if (!hasOtherFilters) {
                        cleanFilters();
                    }
                }
            } else {
                // Para otros filtros se actualiza (si se desea) el localStorage.
                updateLocalStorageFilters(key, updatedSelection);
            }
            return updatedSelection;
        });
    };


    // Función auxiliar para actualizar el localStorage para otros filtros (si se desea)
    const updateLocalStorageFilters = (key, updatedSelection) => {
        const savedFilters = JSON.parse(localStorage.getItem('arrayFilters')) || { filters: [] };
        const filterIndex = savedFilters.filters.findIndex((filter) => Object.keys(filter)[0] === key);

        if (filterIndex !== -1) {
            if (updatedSelection.length === 0) {
                savedFilters.filters.splice(filterIndex, 1);
            } else {
                savedFilters.filters[filterIndex][key] = updatedSelection;
            }
        }

        if (savedFilters.filters.length > 0) {
            localStorage.setItem('arrayFilters', JSON.stringify(savedFilters));
        } else {
            localStorage.removeItem('arrayFilters');
            setArryaFilters(null);
            onFiltersChange(null);
        }

        const hasFilters = savedFilters.filters.length > 0;
        setButonAplyFilters(hasFilters);
    };

    const eliminarEmpresa = (nombre) => eliminarSeleccionado(setEmpresasSeleccionadas, 'filterEmpresa', nombre);
    const eliminarRuta = (nombre) => eliminarSeleccionado(setRutasSeleccionados, 'filterRuta', nombre);
    const eliminarEstado = (nombre) => eliminarSeleccionado(setEstadosSeleccionados, 'filterEstado', nombre);
    const eliminarDispositivo = (nombre) => eliminarSeleccionado(setDispositivosSeleccionados, 'filterDispositivo', nombre);
    const eliminarAlerta = (nombre) => eliminarSeleccionado(setAlertasSeleccionadas, 'filterAlerta', nombre);
    const eliminarTiempo = (nombre) => setTiempo(0);
    const eliminarTiempoDetencion = (nombre) => setTiempoDetencion(0);

    const aplyFilters = async () => {
        console.log('aplicando filtro');
        onFiltersChange(arryaFilters);
        setButonSaveFilters(true);
        setButonAplyFilters(false);
        savedFilter(true);
    };
    const aplyFilters2 = async (array) => {
        onFiltersChange(array);
        setButonSaveFilters(true);
        setButonAplyFilters(false);
        savedFilter(true);
    };
    const loadFilters = async () => setShowModalLoadFilters(true);
    const saveFilters = async () => setShowModalSaveFilters(true);

    // Función para limpiar todos los filtros y el localStorage
    const cleanFilters = async () => {
        setEmpresasSeleccionadas([]);
        setRutasSeleccionados([]);
        setDispositivosSeleccionados([]);
        setAlertasSeleccionadas([]);
        setEstadosSeleccionados([]);
        setOrderByCriteria([]);
        setTiempo('');
        setTiempoDetencion('');
        setFilterName('');
        setModal2Tiempo(false);
        setModal1Tiempo(false);

        localStorage.removeItem('arrayFilters');
        localStorage.removeItem('idFilterSelected');
        localStorage.removeItem('nameFilters');

        const selectElements = document.querySelectorAll('select.form-select');
        selectElements.forEach((select) => {
            select.value = 'EMPTY';
        });

        setTimeout(() => {
            onFiltersChange(null);
        }, 200);
    };

    const onCloseModalBaseSm = () => setShowModalSaveFilters(false);
    const onCloseModalBaseSmLoadFilters = () => setShowModalLoadFilters(false);

    // Si existe un objeto guardado en el localStorage, se inicializan los estados correspondientes
    useEffect(() => {
        const savedFilters = JSON.parse(localStorage.getItem('arrayFilters'));
        if (savedFilters && savedFilters.filters) {
            savedFilters.filters.forEach((filter) => {
                const key = Object.keys(filter)[0];
                const value = filter[key];

                switch (key) {
                    case 'filterEmpresa':
                        setEmpresasSeleccionadas(value || []);
                        break;
                    case 'filterEstado':
                        setEstadosSeleccionados(value || []);
                        break;
                    case 'filterRuta':
                        setRutasSeleccionados(value || []);
                        break;
                    case 'filterDispositivo':
                        setDispositivosSeleccionados(value || []);
                        break;
                    case 'filterAlerta':
                        setAlertasSeleccionadas(value.alertas || []);
                        setTiempo(value.tiempoUltReporte || 0);
                        setTiempoDetencion(value.tiempoDetencion || 0);
                        break;
                    case 'orderByCriteria':
                        setOrderByCriteria(value || []);
                        break;
                    default:
                        break;
                }
            });
        }
    }, []);

    // UseEffect para sincronizar el localStorage cada vez que cambien los filtros
    useEffect(() => {
        if (
            empresasSeleccionadas.length === 0 &&
            estadosSeleccionados.length === 0 &&
            alertasSeleccionadas.length === 0 &&
            rutasSeleccionados.length === 0 &&
            dispositivosSeleccionados.length === 0 &&
            tiempo === 0 &&
            tiempoDetencion === 0 &&
            orderByCriteria.length === 0
        ) {
            console.log('No hay filtros para guardar, saliendo del efecto.');
            localStorage.removeItem('arrayFilters');
            return;
        }
        const arrayFilters = { filters: [] };

        if (empresasSeleccionadas.length > 0) {
            arrayFilters.filters.push({ filterEmpresa: empresasSeleccionadas });
        }
        if (estadosSeleccionados.length > 0) {
            arrayFilters.filters.push({ filterEstado: estadosSeleccionados });
        }
        if (rutasSeleccionados.length > 0) {
            arrayFilters.filters.push({ filterRuta: rutasSeleccionados });
        }
        if (dispositivosSeleccionados.length > 0) {
            arrayFilters.filters.push({ filterDispositivo: dispositivosSeleccionados });
        }
        if (orderByCriteria.length > 0) {
            arrayFilters.filters.push({ orderByCriteria });
        }

        const filterAlerta = {
            ...(alertasSeleccionadas.length > 0 && { alertas: alertasSeleccionadas }),
            ...(tiempo > 0 && { tiempoUltReporte: tiempo }),
            ...(tiempoDetencion > 0 && { tiempoDetencion: tiempoDetencion }),
        };

        if (Object.keys(filterAlerta).length > 0) {
            arrayFilters.filters.push({ filterAlerta });
        }

        console.log('Filtros aplicados:', arrayFilters);

        if (arrayFilters.filters.length > 0) {
            localStorage.setItem('arrayFilters', JSON.stringify(arrayFilters));
            setArryaFilters(arrayFilters);
        } else {
            localStorage.removeItem('arrayFilters');
            setArryaFilters(null);
        }
    }, [
        empresasSeleccionadas,
        estadosSeleccionados,
        alertasSeleccionadas,
        rutasSeleccionados,
        dispositivosSeleccionados,
        tiempo,
        tiempoDetencion,
        orderByCriteria,
    ]);

    useEffect(() => {
        if (tiempo !== 0 && tiempo !== '') {
            setModal1Tiempo(true);
        }
        if (tiempoDetencion !== 0 && tiempoDetencion !== '') {
            setModal2Tiempo(true);
        }
    }, [tiempo, tiempoDetencion]);

    useEffect(() => {
        const hasFilters =
            empresasSeleccionadas.length > 0 ||
            estadosSeleccionados.length > 0 ||
            alertasSeleccionadas.length > 0 ||
            rutasSeleccionados.length > 0 ||
            dispositivosSeleccionados.length > 0 ||
            tiempo > 0 ||
            tiempoDetencion > 0 ||
            orderByCriteria.length > 0;

        setButonAplyFilters(hasFilters);
    }, [
        empresasSeleccionadas,
        estadosSeleccionados,
        alertasSeleccionadas,
        rutasSeleccionados,
        dispositivosSeleccionados,
        tiempo,
        tiempoDetencion,
        orderByCriteria,
    ]);

    useEffect(() => {
        console.log('Listar contratos', listContratos);
    }, [listContratos]);

    useEffect(() => {
        console.log(newValueFilter);
        if (newValueFilter !== '') {
            if (newValueFilter === 'moving') {
                handleSelectEstados('moving');
            } else if (newValueFilter === 'stop') {
                handleSelectEstados('stop');
            } else if (newValueFilter === 'stopAlert') {
                handleSelectEstados('stopAlert');
            } else if (newValueFilter === 'desvioalerta') {
                handleSelectEstados('desvioalerta');
            } else if (newValueFilter === 'candado') {
                handleSelectEstados('candado');
            } else if (newValueFilter === 'batt-low') {
                handleSelectEstados('batt-low');
            }
            aplyFilters();
        } else {
            console.log('no hay ningun button seleccionado en el filtro');
        }
    }, [newValueFilter]);

    useEffect(() => {
        console.log(deleteNewFilter);
        if (deleteNewFilter !== '') {
            if (deleteNewFilter === 'moving') {
                eliminarAlerta('moving');
            } else if (deleteNewFilter === 'stop') {
                eliminarAlerta('stop');
            } else if (deleteNewFilter === 'stopAlert') {
                eliminarAlerta('stopAlert');
            } else if (deleteNewFilter === 'desvioalerta') {
                eliminarAlerta('desvioalerta');
            } else if (deleteNewFilter === 'candado') {
                eliminarAlerta('candado');
            } else if (deleteNewFilter === 'batt-low') {
                eliminarAlerta('batt-low');
            }
        } else {
            console.log('no hay ningun button seleccionado en el filtro delete');
        }
    }, [deleteNewFilter]);

    return (
        <div className="row">
            <h5 className="offcanvas-title" id="offcanvasScrollingLabel" style={{ marginBottom: 12 }}>
                Empresas&nbsp;&nbsp;
                <TooltipComponent message={'Puede filtrar por clientes o nombres de empresas asignadas a su rol'} />
            </h5>
            {empresasSeleccionadas.length > 0 && (
                <div className="mb-3">
                    <div className="form-check form-switch custom-switch handleSelectFilter">
                        <label className="form-check-label" htmlFor="mySwitch">
                            Empresas seleccionadas
                        </label>
                        {empresasSeleccionadas.map((empr, index) => (
                            <li key={index} style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {empr}
                                <i
                                    className="bx bx-trash"
                                    onClick={() => eliminarEmpresa(empr)}
                                    style={{ marginLeft: '10px', cursor: 'pointer', color: 'red' }}
                                    title="Eliminar"
                                ></i>
                            </li>
                        ))}
                    </div>
                </div>
            )}
            <div className="mb-3">
                <div className="form-check form-switch custom-switch handleSelectFilter">
                    <select className="form-select" onChange={handleSelectEmpresa}>
                        <option value="EMPTY">Seleccione empresas...</option>
                        <option value="TODOS">Todas</option>
                        {clientes.map((nombre, index) => (
                            <option key={index} value={nombre}>
                                {nombre}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <h5 className="offcanvas-title" id="offcanvasScrollingLabel" style={{ marginBottom: 12 }}>
                Alertas&nbsp;&nbsp;
                <TooltipComponent message={'Construya los filtros por tipos de alertas, apertura, desvios, tiempo de retraso, bateria baja y demas opciones.'} />
            </h5>
            {alertasSeleccionadas.length > 0 && (
                <div className="mb-3">
                    <div className="form-check form-switch custom-switch handleSelectFilter">
                        <label>Alertas seleccionados</label>
                        <div>
                            {alertasSeleccionadas.map((alert, index) =>
                                alert === 'tiempoDetencion' || alert === 'tiempoUltReporte' ? (
                                    <li
                                        key={index}
                                        style={{ display: 'flex', alignItems: 'center', marginRight: '0px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                    >
                                        <img src={`/images/timer-regular-24.png`} alt={alert} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                                        {alert}
                                        <i
                                            className="bx bx-trash"
                                            onClick={() => eliminarAlerta(alert)}
                                            style={{ marginLeft: '10px', cursor: 'pointer', color: 'red' }}
                                            title="Eliminar"
                                        ></i>
                                    </li>
                                ) : (
                                    <li
                                        key={index}
                                        style={{ display: 'flex', alignItems: 'center', marginRight: '0px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                    >
                                        <img
                                            src={`/images/${alert}.png`}
                                            alt={alert}
                                            style={{ width: '20px', height: '20px', marginRight: '10px' }}
                                            onError={(e) => (e.target.src = `/images/${alert}.gif`)}
                                        />
                                        {alert}
                                        <i
                                            className="bx bx-trash"
                                            onClick={() => eliminarAlerta(alert)}
                                            style={{ marginLeft: '10px', cursor: 'pointer', color: 'red' }}
                                            title="Eliminar"
                                        ></i>
                                    </li>
                                )
                            )}
                        </div>
                    </div>
                </div>
            )}
            <div className="mb-3">
                <div
                    className="form-check form-switch custom-switch handleSelectFilter"
                    style={{ position: 'relative' }}
                >
                    <div
                        className="custom-select border border-gray-300 rounded-md px-2 py-2 cursor-pointer bg-white flex items-center justify-between"
                        onClick={() => document.getElementById('estadoSelect').classList.toggle('show')}
                        style={{ width: '100%' }}
                    >
                        {"Seleccione alertas..."}
                        <div ref={dropdownAlerts} className="options" id="estadoSelect" style={{ position: 'absolute', zIndex: 10001, width: '100%', marginTop: '8px' }}>
                            <div className="option" onClick={() => handleSelectEstados("secured")}>
                                <img src="/images/secured.png" alt="secured" />
                                Anclado de zona segura
                            </div>
                            <div className="option" onClick={() => handleSelectEstados("closedpadlock")}>
                                <img src="/images/closedpadlock.png" alt="Candado cerrado" />
                                Candado cerrado
                            </div>
                            <div className="option" onClick={() => handleSelectEstados("back")}>
                                <img src="/images/back.png" alt="Devolución" />
                                Devolución
                            </div>
                            <div className="option" onClick={() => handleSelectEstados("backalerta")}>
                                <img src="/images/backalerta.png" alt="Alerta devolución" />
                                Alerta devolución
                            </div>
                            <div className="option" onClick={() => handleSelectEstados("batt-charge")}>
                                <img src="/images/batt-charge.png" alt="Batería en carga" />
                                Batería en carga
                            </div>
                            <div className="option" onClick={() => handleSelectEstados("batt-low")}>
                                <img src="/images/batt-low.png" alt="Batería baja" />
                                Batería baja
                            </div>
                            <div className="option" onClick={() => handleSelectEstados("candado")}>
                                <img src="/images/candado.png" alt="Candado abierto" />
                                Candado abierto
                            </div>
                            <div className="option" onClick={() => handleSelectEstados("openedpadlockalerta")}>
                                <img src="/images/openedpadlockalerta.gif" alt="Candado abierto alerta" />
                                Candado abierto alerta
                            </div>
                            <div className="option" onClick={() => handleSelectEstados("unsecured")}>
                                <img src="/images/unsecured.gif" alt="Salida de zona segura" />
                                Salida de zona segura alerta
                            </div>
                            <div className="option" onClick={() => handleSelectEstados("desvio")}>
                                <img src="/images/desvio.gif" alt="Desvío" />
                                Desvío
                            </div>
                            <div className="option" onClick={() => handleSelectEstados("desvioalerta")}>
                                <img src="/images/desvioalerta.png" alt="Alerta desvío" />
                                Alerta desvío
                            </div>
                            <div className="option" onClick={() => handleSelectEstados("moving")}>
                                <img src="/images/moving.png" alt="En movimiento" />
                                En movimiento
                            </div>
                            <div className="option" onClick={() => handleSelectEstados("stop")}>
                                <img src="/images/stop.png" alt="Detención" />
                                Detención
                            </div>
                            <div className="option" onClick={() => handleSelectEstados("stopAlert")}>
                                <img src="/images/stopAlert.png" alt="Detención prolongada" />
                                Detención prolongada
                            </div>
                            <div className="option" onClick={() => handleSelectEstados("valitronics")}>
                                <img src="/images/valitronics.png" alt="Valitronics" />
                                Valitronics
                            </div>
                            <div className="option" onClick={() => handleSelectEstados("tiempoDetencion")}>
                                <img src="/images/timer-regular-24.png" alt="tiempoDetencion" />
                                Tiempo detención
                            </div>
                            <div className="option" onClick={() => handleSelectEstados("tiempoUltReporte")}>
                                <img src="/images/timer-regular-24.png" alt="tiempoUltReporte" />
                                Tiempo último reporte
                            </div>
                        </div>
                    </div>
                </div>
                {modal1Tiempo && (
                    <>
                        <h5 className="offcanvas-title" id="offcanvasScrollingLabel" style={{ marginBottom: 12 }}>
                            Tiempo último reporte&nbsp;&nbsp;
                            <TooltipComponent message={'Construya los filtros por tiempo de ultimo reporte en minutos.'} />
                        </h5>
                        <div className="mb-3">
                            <input
                                className="custom-select handleSelectFilterInput"
                                type="number"
                                value={tiempo}
                                onChange={handleSelectTiempo}
                                style={{
                                    border: '1px solid #ccc',
                                    padding: '10px',
                                    width: '100%',
                                    borderRadius: '5px',
                                    fontSize: '16px',
                                    boxSizing: 'border-box',
                                    transition: 'border-color 0.3s ease',
                                }}
                            />
                        </div>
                        {errorTiempo1 && <span style={{ color: 'red' }}>El tiempo de el último reporte debe ser mayor a 0</span>}
                    </>
                )}
                {modal2Tiempo && (
                    <>
                        <h5 className="offcanvas-title" id="offcanvasScrollingLabel" style={{ marginBottom: 12 }}>
                            Tiempo detención&nbsp;&nbsp;
                            <TooltipComponent message={'Construya los filtros por tiempo de detención en minutos.'} />
                        </h5>
                        <div className="mb-3">
                            <input
                                className="custom-input handleSelectFilterInput"
                                type="number"
                                value={tiempoDetencion}
                                onChange={handleSelectTiempoDetencion}
                                style={{
                                    border: '1px solid #ccc',
                                    padding: '10px',
                                    width: '100%',
                                    borderRadius: '5px',
                                    fontSize: '16px',
                                    boxSizing: 'border-box',
                                    transition: 'border-color 0.3s ease',
                                }}
                            />
                        </div>
                        {errorTiempo2 && <span style={{ color: 'red' }}>El tiempo de detención debe ser mayor a 0</span>}
                    </>
                )}
            </div>
            <h5 className="offcanvas-title" id="offcanvasScrollingLabel" style={{ marginBottom: 12 }}>
                Rutas&nbsp;&nbsp;
                <TooltipComponent message={'Construya los filtros por rutas, elija los trayectos por los que quiere mostrar los contratos.'} />
            </h5>
            {rutasSeleccionados.length > 0 && (
                <div className="mb-3">
                    <div className="form-check form-switch custom-switch handleSelectFilter">
                        <label className="form-check-label" htmlFor="mySwitch">
                            Rutas seleccionadas
                        </label>
                        {rutasSeleccionados.map((rta, index) => (
                            <li key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '0px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {rta}
                                <i
                                    className="bx bx-trash"
                                    onClick={() => eliminarRuta(rta)}
                                    style={{ marginLeft: '10px', cursor: 'pointer', color: 'red' }}
                                    title="Eliminar"
                                ></i>
                            </li>
                        ))}
                    </div>
                </div>
            )}
            <div className="mb-3">
                <div className="form-check form-switch custom-switch handleSelectFilter">
                    <select className="form-select" onChange={handleSelectRuta}>
                        <option value="EMPTY">Seleccione rutas...</option>
                        <option value="TODOS">Todas</option>
                        {rutas.map((nombre, index) => (
                            <option key={index} value={nombre}>
                                {nombre}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <h5 className="offcanvas-title" id="offcanvasScrollingLabel" style={{ marginBottom: 12 }}>
                Estados&nbsp;&nbsp;
                <TooltipComponent message={'Construya los filtros por estados, elija los estados que quiere seguir en la grilla de tráfico.'} />
            </h5>
            {estadosSeleccionados.length > 0 && (
                <div className="mb-3">
                    <div className="form-check form-switch custom-switch handleSelectFilter">
                        <label className="form-check-label" htmlFor="mySwitch">
                            Estados seleccionados
                        </label>
                        {estadosSeleccionados.map((est, index) => (
                            <li key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '0px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {est}
                                <i
                                    className="bx bx-trash"
                                    onClick={() => eliminarEstado(est)}
                                    style={{ marginLeft: '10px', cursor: 'pointer', color: 'red' }}
                                    title="Eliminar"
                                ></i>
                            </li>
                        ))}
                    </div>
                </div>
            )}
            <div className="mb-3">
                <div className="form-check form-switch custom-switch handleSelectFilter">
                    <select className="form-select" onChange={handleSelectEstado}>
                        <option value="EMPTY">Seleccione estados...</option>
                        <option value="TODOS">Todos</option>
                        {estados.map((nombre, index) => (
                            <option key={index} value={nombre}>
                                {nombre}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <h5 className="offcanvas-title" id="offcanvasScrollingLabel" style={{ marginBottom: 12 }}>
                Dispositivos&nbsp;&nbsp;
                <TooltipComponent message={'Construya los filtros por dispositivos, elija los dispositivos que quiere seguir en la grilla de tráfico.'} />
            </h5>
            {dispositivosSeleccionados.length > 0 && (
                <div className="mb-3">
                    <div className="form-check form-switch custom-switch handleSelectFilter">
                        <label className="form-check-label" htmlFor="mySwitch">
                            Dispositivos seleccionados
                        </label>
                        {dispositivosSeleccionados.map((disp, index) => (
                            <li key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '0px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {disp}
                                <i
                                    className="bx bx-trash"
                                    onClick={() => eliminarDispositivo(disp)}
                                    style={{ marginLeft: '10px', cursor: 'pointer', color: 'red' }}
                                    title="Eliminar"
                                ></i>
                            </li>
                        ))}
                    </div>
                </div>
            )}
            <div className="mb-3">
                <div className="form-check form-switch custom-switch handleSelectFilter">
                    <select className="form-select" onChange={handleSelectDispositivos}>
                        <option value="EMPTY">Seleccione dispositivos...</option>
                        <option value="TODOS">Todos</option>
                        {dispositivos.map((nombre, index) => (
                            <option key={index} value={nombre}>
                                {nombre}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {/* Ordenamientos */}
            <h5 className="offcanvas-title" style={{ marginBottom: 12 }}>Ordenar por:</h5>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="orderByList">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className="mb-3">
                            {orderByCriteria.map((criteria, index) => (
                                <Draggable key={criteria.field} draggableId={criteria.field} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                background: '#f9f9f9',
                                                padding: '10px',
                                                marginBottom: '8px',
                                                borderRadius: '5px',
                                                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                                                ...provided.draggableProps.style,
                                            }}
                                            onClick={() => toggleDirection(criteria.field)}
                                        >
                                            <span>
                                                {criteria.field === 'NombreEmpresa' && 'Empresa' ||
                                                    criteria.field === 'icon_bat' && 'Bateria' ||
                                                    criteria.field === 'PlacaTruck' && 'Placa' ||
                                                    criteria.field === 'DescripcionRuta' && 'Ruta' ||
                                                    criteria.field === 'FKLokDeviceID' && 'Dispositivo' ||
                                                    criteria.field === 'TipoReporte' && 'Estado' ||
                                                    criteria.field === 'IconLocked' && 'Candado' ||
                                                    criteria.field === 'IconMoving' && 'En movimiento' ||
                                                    criteria.field === 'IconBack' && 'En retorno' ||
                                                    criteria.field === 'IconSeguro' && 'Asegurado' ||
                                                    criteria.field === 'IconValitronics' && 'Valitronics' ||
                                                    criteria.field === 'tiempodetencion' && 'Tiempo detención' ||
                                                    criteria.field === 'LoksysServerTime' && 'Tiempo último reporte'}{' '}
                                                ({criteria.direction})
                                            </span>
                                            <i
                                                className="bx bx-trash"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    removeOrderByCriteria(criteria.field);
                                                }}
                                                style={{
                                                    marginLeft: 'auto',
                                                    cursor: 'pointer',
                                                    color: 'red',
                                                }}
                                                title="Eliminar"
                                            ></i>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div className="mb-3">
                <label>Columna:</label>
                <select
                    className="form-select"
                    onChange={(e) => addOrderByCriteria(e.target.value, 'asc')}
                >
                    <option value="EMPTY">Seleccione columnas...</option>
                    <option value="NombreEmpresa">Empresa</option>
                    <option value="icon_bat">Bateria</option>
                    <option value="PlacaTruck">Placa</option>
                    <option value="DescripcionRuta">Ruta</option>
                    <option value="FKLokDeviceID">Dispositivo</option>
                    <option value="TipoReporte">Estado</option>
                    <option value="IconLocked">Candado</option>
                    <option value="IconMoving">En movimiento</option>
                    <option value="IconBack">En retorno</option>
                    <option value="IconSeguro">Asegurado</option>
                    <option value="IconValitronics">Valitronics</option>
                    <option value="tiempodetencion">Tiempo detención</option>
                    <option value="LoksysServerTime">Tiempo último reporte</option>
                </select>
            </div>
            <div className="mb-3">
                <label>Dirección:</label>
                <select
                    className="form-select"
                    onChange={(e) =>
                        addOrderByCriteria(
                            orderByCriteria[orderByCriteria.length - 1]?.field || '',
                            e.target.value
                        )
                    }
                >
                    <option value="EMPTY">Seleccione dirección...</option>
                    <option value="asc">Ascendente</option>
                    <option value="desc">Descendente</option>
                </select>
            </div>
            <div className="filterCsss mb-3">
                {butonAplyFilters && <button className="Button blue" onClick={aplyFilters}>Aplicar</button>}
                {butonSaveFilters && <button className="Button blue" onClick={saveFilters}>Guardar</button>}
                {butonSaveFilters && <button className="Button blue" onClick={cleanFilters}>Limpiar</button>}
                <button className="Button blue" onClick={loadFilters}>Cargar</button>
            </div>
            <style jsx>{`
        .filterCsss {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          gap: 5px;
          flex-wrap: wrap;
        }
  
        .handleSelectFilterInput {
          padding-left: 5px !important;
          margin-left: 5px !important;
        }
  
        .handleSelectFilter {
          padding-left: 0px !important;
        }
  
        .custom-select {
          border: 1px solid #ccc;
          padding: 10px;
          cursor: pointer;
          position: relative;
        }
        .options {
          position: absolute;
          border: 1px solid #ccc;
          background-color: white;
          width: 100%;
          z-index: 10001;
          display: none;
          max-height: 200px;
          overflow-y: auto;
        }
        .options.show {
          display: block;
        }
        .option {
          padding: 10px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .option img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      `}</style>
            <ModalBaseSm
                isOpen={showModalSaveFilters}
                title={filterName ? `El filtro "${filterName}" ya existe.` : 'Crear un nuevo filtro'}
                onClose={onCloseModalBaseSm}
                childComponent={
                    <FormSaveFilters
                        onClose={() => setShowModalSaveFilters(false)}
                        changeStatus={() => saveFilters(false)}
                        filterNameChange={changeFilterName}
                    />
                }
            />
            <ModalBaseSm
                isOpen={showModalLoadFilters}
                title={'Cargue su colección de filtros guardados'}
                onClose={onCloseModalBaseSmLoadFilters}
                childComponent={
                    <LoadFillFilters onClose={() => setShowModalLoadFilters(false)} loadFilter={handleFilterLoad} cleanFilters={() => cleanFilters()} />
                }
            />
        </div>
    );
};

export default FilterBodyOffcanvas;
