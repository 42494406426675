import React, { useCallback, useEffect, useState } from 'react';
import axios from '../utils/axiosConfig';

const HERE_API_KEY = process.env.REACT_APP_HERE_MAPS_API_KEY;

const StaticMaps2 = ({ contrato, ultPoint }) => {
    const [infocontrato, setInfocontrato] = useState({});
    const [loading, setLoading] = useState(true);
    const [imageUrl, setImageUrl] = useState(null);
    const [LastMsgLat, setLastMsgLat] = useState(null);
    const [LastMsgLong, setLastMsgLong] = useState(null);
    const [mapKey, setMapKey] = useState(0); // Para forzar la actualización del mapa

    // Se ejecuta al montar el componente y cuando cambian `contrato` o `ultPoint`
    useEffect(() => {
        console.log("Cambioimpresion:", "Entró en useEffect (equivalente a componentDidMount)");

        setInfocontrato(contrato);
        setLoading(true); // Activa el loader antes de la actualización

        if (ultPoint !== -1 && ultPoint !== undefined) {
            console.log("Cambioimpresion:", "Entró en useEffect con ultPoint");
            getLatLngContrato(ultPoint);
        } else {
            console.log("Cambioimpresion:", "Entró en useEffect sin ultPoint");
            updateImage(contrato);
        }
    }, [contrato, ultPoint]); // Se ejecuta cuando cambia contrato o ultPoint

    // Obtener latitud y longitud del contrato
    const getLatLngContrato = useCallback(async (contratoId) => {
        try {
            let data = { "contrato": contratoId };
            console.log("Solicitando lat/long con datos:", data);

            const response = await axios.post(
                `${process.env.REACT_APP_SERVER}/operaciones/getlatlngcontrato`,
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('tkn')}`
                    },
                    withCredentials: true
                }
            );

            if (response.data.success) {
                console.log("Datos recibidos de latitud:", response.data.data);
                const { LastMsgLat, LastMsgLong } = response.data.data[0];

                setLastMsgLat(LastMsgLat);
                setLastMsgLong(LastMsgLong);
                setInfocontrato(contrato);
                setLoading(true); // Mostrar el loader antes de actualizar la imagen
                setMapKey(prevKey => prevKey + 1); // Forzar actualización

                updateImageAjustada(LastMsgLat, LastMsgLong);
            } else {
                console.error("Error en la respuesta:", response.data.message);
            }
        } catch (error) {
            console.error("Error al obtener la latitud y longitud:", error);
        }
    }, []);

    // Generar imagen con coordenadas ajustadas usando HERE con geoJSON
    const updateImageAjustada = useCallback((lat, lng) => {
        console.log("Generando imagen con geoJSON - Lat:", lat, "Lng:", lng);

        setLoading(true); // Asegurar que el loader se muestre antes de generar la imagen

        // Construir geoJSON para el marcador
        const geojsonPoint = encodeURIComponent(
            JSON.stringify({
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        properties: {
                            marker: "cp",
                            size: "large",
                        },
                        geometry: {
                            coordinates: [lng, lat], // HERE usa LONG, LAT
                            type: "Point",
                        },
                    },
                ],
            })
        );

        // Construcción de la URL con HERE Maps y geoJSON
        const size = '330x330';
        const newImageUrl = `https://image.maps.hereapi.com/mia/v3/base/mc/center:${lat},${lng};zoom=16/${size}/png8?apikey=${HERE_API_KEY}&style=explore.satellite.day&geojson=${geojsonPoint}`;

        setTimeout(() => {
            setImageUrl(newImageUrl);
            setLoading(false);
        }, 1000); // Simulación de carga
    }, []);

    // Generar imagen con los datos de `infocontrato` usando HERE con geoJSON
    const updateImage = useCallback((contrato) => {
        console.log("Generando imagen con contrato usando geoJSON");

        setLoading(true); // Asegurar que el loader se muestre antes de generar la imagen

        const size = '330x330';
        const lat = contrato.LastMsgLat || 0;
        const lng = contrato.LastMsgLong || 0;

        // Construir geoJSON para el marcador
        const geojsonPoint = encodeURIComponent(
            JSON.stringify({
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        properties: {
                            marker: "cp",
                            size: "large",
                        },
                        geometry: {
                            coordinates: [lng, lat], // HERE usa LONG, LAT
                            type: "Point",
                        },
                    },
                ],
            })
        );

        // Construcción de la URL con HERE Maps y geoJSON
        const newImageUrl = `https://image.maps.hereapi.com/mia/v3/base/mc/center:${lat},${lng};zoom=16/${size}/png8?apikey=${HERE_API_KEY}&style=explore.satellite.day&geojson=${geojsonPoint}`;

        setTimeout(() => {
            setImageUrl(newImageUrl);
            setLoading(false);
        }, 1000);
    }, []);

    return (
        <div style={{ height: '330px', width: '100%' }} className='imgUltimoPuntoControl'>
            {loading ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                imageUrl && (
                    <img
                        key={mapKey} // Forzar re-render cuando cambia la imagen
                        onLoad={() => setLoading(false)}
                        onError={() => setLoading(false)}
                        src={imageUrl}
                        alt="Mapa estático con marcador"
                        style={{ display: loading ? 'none' : 'block' }}
                    />
                )
            )}
        </div>
    );
};

export default StaticMaps2;
