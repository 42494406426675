import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Barra from '../Modulos/Barra';
import './Css/themes/base/all.css';
import '../App.css';
import Swal from 'sweetalert2';
import axios from '../utils/axiosConfig';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import ModalBaseLg from '../components/modals/modal-base-lg';
import { Button, Form } from 'reactstrap';
import FormTrakalok from '../components/formsSolicitudes/formTrakalok';
import { formattedGlobalDate } from './../utils/formattedDates';
import ReporteServicio from '../Modulos/Reporteservicio';
import ModalReporteServicio from '../components/modals/modalReporteServicio';

const ControlContracts = () => {
    // Obtén el parámetro "contract" desde la query string
    const [searchParams] = useSearchParams();
    const contract = searchParams.get("contract");
    const [contractId, setContractId] = useState('');
    const [contractInfo, setContractInfo] = useState({});
    const [ultPunto, setUltPunto] = useState(-1);
    const [contractR, setContractR] = useState(-1);
    const [modalTrakalok, setModalTrakalok] = useState(false);
    const [modalReporte, setModalReporte] = useState(false);

    // Puedes hacer uso de este valor para, por ejemplo, mostrarlo o para filtrar la data
    console.log("Contract recibido:", contract);

    const idDevice = localStorage.getItem('deviceContract');
    const [infoDevice, setInfoDevice] = useState([]);

    const openModalTrakalok = (id) => {
        console.log("Contract ID", id);
        setContractId(id);
        setModalTrakalok(true);
    }

    const openModalReporte = (contrato) => {
        try {
            axios.post(process.env.REACT_APP_SERVER + "/operaciones/getcontratounico", JSON.stringify({ contrato: contrato.ContractID }), {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                },
                withCredentials: true,
            }).then((res) => {
                if (res.data.success === false) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: `${res.data.mensaje}`,
                        didOpen: () => {
                            const confirmButton = Swal.getConfirmButton();
                            confirmButton.style.backgroundColor = '#ff0000';
                            confirmButton.style.color = '#ffffff';
                            confirmButton.style.border = 'none';
                            confirmButton.style.padding = '10px 20px';
                            confirmButton.style.fontSize = '16px';
                            confirmButton.style.borderRadius = '5px';
                            confirmButton.style.cursor = 'pointer';
                        }
                    });
                } else {
                    console.log("Informacion de el contrato", res.data.data[0]);
                    setContractInfo(res.data.data[0]);

                }
            }).catch((error) => {
                console.error(error);
            });
        } catch (error) {
            console.error(error);
        } finally {
            setModalReporte(true);
        }
    }

    const onCloseModalReporte = () => {
        setModalReporte(false);
    }

    const onCloseModalTrack = () => {
        setModalTrakalok(false);
    }

    const CargarInfoByIdDevice = (idDevice) => {
        let dataDevice = {
            device: idDevice,
        };
        console.log("Data Device", dataDevice);
        axios
            .post(
                process.env.REACT_APP_SERVER + "/operaciones/getcontratoscontroldevice",
                JSON.stringify(dataDevice),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                if (res.data.success === false) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: `${res.data.mensaje}`,
                        didOpen: () => {
                            const confirmButton = Swal.getConfirmButton();
                            confirmButton.style.backgroundColor = '#ff0000';
                            confirmButton.style.color = '#ffffff';
                            confirmButton.style.border = 'none';
                            confirmButton.style.padding = '10px 20px';
                            confirmButton.style.fontSize = '16px';
                            confirmButton.style.borderRadius = '5px';
                            confirmButton.style.cursor = 'pointer';
                        }
                    });
                } else {
                    console.log("Informacion de la solicitud", res.data.data);
                    setInfoDevice(res.data.data);
                }
            })
            .catch((err) => console.log(err));
    };

    const loadFillData = (infoDevice) => {
        let rows = [];
        infoDevice.map((element) => {
            rows.push(
                <tr key={element.ContractID}>
                    <td>{element.ContractID}</td>
                    <td>{element.NombreEmpresa}</td>
                    <td>{element.DescripcionRuta}</td>
                    <td>{formattedGlobalDate(element.InicioServicio)}</td>
                    <td>{formattedGlobalDate(element.FechaHoraFin)}</td>
                    <td>{element.Position}</td>
                    <td>
                        <i className="bx bxs-cog" style={{ fontSize: '24px', color: "#006699", cursor: 'pointer' }} onClick={() => openModalTrakalok(element.ContractID)}></i>
                    </td>
                    <td>
                        <i className="bx bxs-report" style={{ fontSize: '24px', color: "#006699", cursor: 'pointer' }} onClick={() => openModalReporte(element)}></i>
                    </td>
                </tr>
            );
        });
        return rows;
    }

    useEffect(() => {
        CargarInfoByIdDevice(contract);
    }, []);

    return (
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <div className="layout-page">
                        <Barra />
                        <div className="content-wrapper">
                            <div className="container-fluid flex-grow-1 container-p-y">
                                <MDBRow>
                                    <MDBCol md="7">
                                        <label
                                            className="fw-bold mb-4 label_trafico"
                                            style={{ fontSize: "22px", marginRight: "10px" }}
                                        >
                                            Control Device
                                        </label>
                                    </MDBCol>
                                </MDBRow>
                                <div className="card">
                                    <div
                                        className="table-responsive"
                                        style={{
                                            height: `calc(100vh - 200px)`,
                                            width: "100%",
                                            overflowY: "auto",
                                            overflowX: "auto",
                                        }}
                                    >
                                        <table
                                            className="table table-hover"
                                            id="tabla"
                                            style={{
                                                width: "100%",
                                                tableLayout: "fixed",
                                                minWidth: "1200px",
                                            }}
                                        >
                                            <thead className="FondoHeaderTabla2" style={{ textAlign: "center" }}>
                                                <tr>
                                                    <th style={{ color: "#ffffff" }}>CONTRACT ID</th>
                                                    <th style={{ color: "#ffffff", width: '400px' }}>EMPRESA</th>
                                                    <th style={{ color: "#ffffff" }}>RUTA</th>
                                                    <th style={{ color: "#ffffff" }}>INICIO</th>
                                                    <th style={{ color: "#ffffff" }}>FIN</th>
                                                    <th style={{ color: "#ffffff" }}>ULT. POSICION</th>
                                                    <th style={{ color: "#ffffff", width: '60px', alignItems: 'center' }}></th>
                                                    <th style={{ color: "#ffffff", width: '60px', alignItems: 'center' }}></th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0" id="tabladata">
                                                {/* Aquí se debe renderizar la información (por ejemplo, usando map sobre infoDevice) */}
                                                {loadFillData(infoDevice)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalBaseLg
                isOpen={modalTrakalok}
                title={'Informacion de contrato'}
                onClose={onCloseModalTrack}
                childComponent={<FormTrakalok contractId={contractId} toggle={onCloseModalTrack} listAgain={() => CargarInfoByIdDevice(contract)} />}
            />

            <ModalReporteServicio
                isOpen={modalReporte}
                title={''}
                onClose={onCloseModalReporte}
                childComponent={<ReporteServicio contrato={contractInfo} ultPunto={ultPunto} contractR={contractR} />}
            />
        </>
    );
};

export default ControlContracts;
