import React, { useState, useEffect, useCallback } from 'react';

const HERE_API_KEY = process.env.REACT_APP_HERE_MAPS_API_KEY;

const StaticsMapsRecorrido2 = ({ recorrido, ultRecorrido }) => {
  const [mapRecorrido, setMapRecorrido] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState(null);
  const [mapKey, setMapKey] = useState(0);

  useEffect(() => {
    setLoading(true);
    setMapRecorrido(recorrido);
    console.log("📌 Recorrido actualizado:", recorrido);
  }, [recorrido]);

  // Función para simplificar el path y reducir la cantidad de puntos
  const simplifyPath = (path, maxPoints = 50) => {
    if (path.length <= maxPoints) return path;
    const step = Math.ceil(path.length / maxPoints);
    return path.filter((_, index) => index % step === 0);
  };

  // Función para calcular el centro del recorrido
  const calculateCenter = (path) => {
    const latitudes = path.map(point => point.latitude);
    const longitudes = path.map(point => point.longitude);

    return {
      lat: (Math.max(...latitudes) + Math.min(...latitudes)) / 2,
      lng: (Math.max(...longitudes) + Math.min(...longitudes)) / 2,
    };
  };

  // Función para calcular el zoom dinámico en función de la distancia entre los puntos
  const calculateZoom = (path) => {
    const latitudes = path.map(point => point.latitude);
    const longitudes = path.map(point => point.longitude);

    const latDiff = Math.max(...latitudes) - Math.min(...latitudes);
    const lngDiff = Math.max(...longitudes) - Math.min(...longitudes);

    const maxDiff = Math.max(latDiff, lngDiff);
    console.log("🔍 Diferencia máxima entre puntos:", maxDiff);

    if (maxDiff > 10) return 4;
    if (maxDiff > 7) return 5;
    if (maxDiff > 4) return 6;
    if (maxDiff > 2) return 7;
    if (maxDiff > 0) return 12;
    return 10; // Zoom más cercano si los puntos están muy cerca
  };

  const updateImage = useCallback((path) => {
    if (!path.length) return;

    setLoading(true);

    try {
      const filteredPath = path.filter(punto => punto.latitude !== 0 && punto.longitude !== 0);
      if (filteredPath.length < 2) {
        console.error("⚠️ No hay suficientes puntos para trazar una línea.");
        setLoading(false);
        return;
      }

      console.log("HERE API KEY:", HERE_API_KEY);
      if (!HERE_API_KEY) {
        console.error("🚨 HERE API KEY no está definida. Verifica tu .env");
        return;
      }

      // Reducimos los puntos antes de enviarlos a HERE
      const simplifiedPath = simplifyPath(filteredPath, 50);

      // Calculamos el centro y el zoom dinámico
      const { lat, lng } = calculateCenter(simplifiedPath);
      const zoom = calculateZoom(simplifiedPath);
      console.log("📍 Centro del recorrido:", lat, lng);
      console.log("🔍 Zoom dinámico:", zoom);

      // Generamos `geojson=` para HERE
      const geojsonLine = encodeURIComponent(
        JSON.stringify({
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {
                color: "#0000FFFF",
                width: "6",
                "outline-color": "#000000FF",
                "outline-width": "0px",
              },
              geometry: {
                type: "LineString",
                coordinates: simplifiedPath.map(point => [point.longitude, point.latitude]), // HERE usa LONG, LAT
              },
            },
          ],
        })
      );

      console.log("📌 GeoJSON para HERE (puntos reducidos):", JSON.parse(decodeURIComponent(geojsonLine)));

      // Generar la URL de HERE Maps con `geojson=`
      const newImageUrl = `https://image.maps.hereapi.com/mia/v3/base/mc/center:${lat},${lng};zoom=${zoom}/330x330/png8?apikey=${HERE_API_KEY}&style=explore.day&geojson=${geojsonLine}`;

      console.log("Nueva URL de mapa:", newImageUrl);

      setTimeout(() => {
        setImageUrl(newImageUrl);
        setMapKey(prevKey => prevKey + 1);
        setLoading(false);
      }, 1000);
    } catch (err) {
      console.error("❌ Error al actualizar la imagen del mapa:", err);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (mapRecorrido.length > 0) {
      updateImage(mapRecorrido);
    }
  }, [mapRecorrido, updateImage]);

  return (
    <div style={{ height: '330px', width: '100%' }} className='imgUltimoRecorrido'>
      {loading ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        imageUrl && (
          <img
            key={mapKey}
            onLoad={() => setLoading(false)}
            onError={() => {
              setLoading(false);
              console.error("❌ Error cargando la imagen del mapa");
            }}
            src={imageUrl}
            style={{ display: loading ? 'none' : 'block'}}
            alt="Mapa del recorrido"
          />
        )
      )}
    </div>
  );
};

export default StaticsMapsRecorrido2;
