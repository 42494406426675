import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const BaseModalAuto = ({ isOpen, onClose, title, childComponent,size }) => {
    const backdropStyle = 'static';
    return (
        <Modal
            isOpen={isOpen}
            backdrop={backdropStyle}
            keyboard={false}
            zIndex={9999}
            size={size || ''}

        >
            <ModalHeader toggle={onClose} >{title}</ModalHeader>
            <ModalBody style={{ paddingTop: 10 }}>
                {childComponent}
            </ModalBody>
        </Modal>
    )
}

export default BaseModalAuto
