import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const BaseModalAuto2 = ({ isOpen, onClose, title, children, size }) => {
    const backdropStyle = 'static';
    const customModalStyle = { maxWidth: '90%' };

    return (
        <Modal
            isOpen={isOpen}
            backdrop={backdropStyle}
            keyboard={false}
            zIndex={9999}
            size={size || ''}
            centered

        >
            <ModalHeader toggle={onClose} >{title}</ModalHeader>
            <ModalBody style={{ paddingTop: 10 }}>
                {children}
            </ModalBody>
        </Modal>
    );
};

export default BaseModalAuto2;
