import React, { useEffect, useState, useRef } from "react";
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import $ from 'jquery';
import '../Pages/Css/trafico.css';
import { FaClock } from "@react-icons/all-files/fa/FaClock";
import { FaRoute } from "@react-icons/all-files/fa/FaRoute";
import { FaLock } from "@react-icons/all-files/fa/FaLock";
import { motion } from 'framer-motion';
import axios from "../utils/axiosConfig";
var miStorage = window.localStorage;

const Modal = ({ isOpen, onClose, contratoVar, aleatorio, children, images, monitoreo }) => {
  let map = null;
  let directionsService;
  var infowindow = null;
  const google = window.google;
  const [listaMensajes, setListaMensajes] = useState([]);
  const [uniquesGeocerca, setUniquesGeocerca] = useState([]);
  const [geocercaPolygons, setGeocercaPolygons] = useState([]);
  const [infoWindows, setInfoWindows] = useState([]);
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [contratoseleccionado, setContratoseleccionado] = useState(null);
  const [markers, setMarkers] = useState([]);
  const circleRef = useRef(null);
  const markersRef = useRef([]);
  const polylineRef = useRef([]);
  const infoWindowRef = useRef(null);
  const mapRef = useRef(null);
  const [timeTotal, setTimeTotal] = useState('');
  const [switchValueGeo, setSwitchValueGeo] = useState(false);

  useEffect(() => {
    if (isOpen) {
      console.log("entroooooo acontrato reporte********");
      $("#success-alert").hide();
      $("#danger-alert").hide();
      setListaMensajes([]);
      setInfoWindows([]);
      setSelectedMarkerIndex(null);
      setMarkers([]);
      console.log(contratoVar);
      document.getElementById("dispositivo_segH").innerHTML = contratoVar.FKLokDeviceID;
      document.getElementById("contrato_segH").innerHTML = contratoVar.ContractID;
      document.getElementById("cliente_segH").innerHTML = contratoVar.NombreEmpresa;
      document.getElementById("ruta_segH").innerHTML = contratoVar.DescripcionRuta;

      RenderMap();
      setContratoseleccionado(contratoVar);
      ListReports(contratoVar, 0, 1);
      if (contratoVar.trayecto != 0) {
        GetPolyline();
      }

    }
  }, [isOpen, contratoVar, aleatorio]);

  const RenderMap = () => {
    const coords = { lat: 10.992716649650994, lng: -74.80259436365039 };
    try {
      const el = document.getElementById('map');

      if (el) {
        mapRef.current = new google.maps.Map(el, {
          zoom: 16,
          center: {
            lat: coords.lat,
            lng: coords.lng,
          },
        });

        return mapRef.current;
      } else {
        return null;
      }
    } catch (error) {
      window.history.go(0);
    }

  }

  const GetPolyline = () => {
    try {
      let data = {
        "id": contratoVar.trayecto
      };
      const json = JSON.stringify(data);
      axios.post(process.env.REACT_APP_SERVER + '/operaciones/getpolylinetrayecto', json, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
        withCredentials: true
      })
        .then(response => {
          if (response.data.success == true) {
            let rutacompleta = response.data.data[0].Polyline.split("||||");
            for (var i = 0; i < rutacompleta.length; i++) {
              const decodedPath = google.maps.geometry.encoding.decodePath(rutacompleta[i]);

              const polyline = new google.maps.Polyline({
                path: decodedPath,
                geodesic: true,
                strokeColor: "#FF0000",
                strokeOpacity: 0.4,
                strokeWeight: 6,
              });
              polyline.setMap(mapRef.current);
            }

          }
        }).catch(error => {
          console.error(error);
        });

    } catch (error) {
      console.error(error);
    }
  }

  const ListReports = (contrato, all, op) => {

    var imagen = 'Cierre'
    if (contrato.Locked == 0) {
      imagen = 'Apertura';
      //document.getElementById("contenedorestadodiv").style.background='#D21F1F';
    } else {
      //document.getElementById("contenedorestadodiv").style.background='#2BA914';
    }
    //document.getElementById("contenedorestado").innerHTML=imagen;
    try {
      let data = {
        "fechainicio": contrato.fecha,
        "fechafin": contrato.fechafin,
        "device": contrato.FKLokDeviceID,
        "utcMinutos": 5,
        "allreport": all,
        "tipo": contrato.Active,
        "tipoequipo": contrato.FKLokTipoEquipo,
      };
      const json = JSON.stringify(data);
      axios.post(process.env.REACT_APP_SERVER + '/operaciones/getreportesdevice', json, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
        withCredentials: true
      })
        .then(response => {
          if (response.data.success == true) {
            const prevMarkersList = markersRef.current;
            setMarkers(prevMarkersList);
            console.log(response.data.data);
            setListaMensajes(response.data.data);
            rutaenMapa(response.data.data, op);
          }
        }).catch(error => {
          console.error(error);
        });

    } catch (error) {
      console.error(error);
    }
  }

  const rutaenMapa = (lista, op) => {
    const promises = lista
      .filter((objeto) => objeto.latitude !== 0 && objeto.longitude !== 0)
      .map((objeto) => {
        return new Promise((resolve) => {
          resolve({
            lat: objeto.latitude,
            lng: objeto.longitude,
            id: objeto.ID,
            html: objeto.HTML,
            ubicacion: objeto.Ciudad,
            icon: objeto.IconLocked,
          });
        });
      });

    Promise.all(promises)
      .then((flightPlanCoordinates) => {

        // Calcula y muestra las flechas de dirección entre todos los marcadores
        for (let i = 0; i < flightPlanCoordinates.length - 1; i++) {
          const directionArrow = new google.maps.Polyline({
            path: [flightPlanCoordinates[i + 1], flightPlanCoordinates[i]],
            geodesic: true,
            strokeColor: "#88A0CF",
            strokeOpacity: 1.0,
            strokeWeight: 2,
            icons: [
              {
                icon: {
                  path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                  scale: 1,
                  strokeColor: "#082357"
                },
                offset: "50%",
              },
            ],
            map: mapRef.current,
          });
        }
        const markersList = markersRef.current;

        // Eliminar todos los marcadores anteriores
        markersList.forEach(({ marker }) => marker.setMap(null));
        markersRef.current = [];

        // Crear nuevos marcadores
        const markerslist = flightPlanCoordinates.map((coordinate) => {
          const iconurl = coordinate.icon.includes("opened")
            ? "./truckopen.png"
            : "./truckclose.png";

          const marker = new google.maps.Marker({
            position: coordinate,
            map: mapRef.current,
            id: coordinate.id,
            ubicacion: coordinate.ubicacion,
            icon: {
              url: iconurl,
              scaledSize: new google.maps.Size(30, 30),
            },
          });

          const markerInfoWindow = new google.maps.InfoWindow({
            content: coordinate.html,
          });

          marker.addListener("click", () => {
            if (infoWindowRef.current) {
              infoWindowRef.current.close();
            }
            setSelectedMarker(marker);
            markerInfoWindow.open(mapRef.current, marker);
            infoWindowRef.current = markerInfoWindow;
          });

          return { marker, markerInfoWindow };
        });

        markersRef.current = markerslist;

        // Actualizar el círculo en el último marcador
        const updateCircleRadius = () => {
          if (markerslist.length > 0) {
            const lastMarker = markerslist[0].marker;
            const zoomLevel = mapRef.current.getZoom(); // Obtener nivel de zoom actual
            const baseRadius = 3; // Radio base en metros
            const maxZoom = 20; // Zoom máximo
            const scaleFactor = Math.pow(2, maxZoom - zoomLevel); // Escala inversa
            const newRadius = baseRadius * scaleFactor;

            // Eliminar el círculo existente si ya está definido
            if (circleRef.current) {
              circleRef.current.setMap(null);
            }

            // Crear un nuevo círculo
            circleRef.current = new google.maps.Circle({
              strokeColor: 'black',
              strokeOpacity: 1,
              strokeWeight: 2,
              fillColor: '#E0E302',
              fillOpacity: 0.8,
              map: mapRef.current,
              center: lastMarker.getPosition(),
              radius: newRadius,
            });
          }
        };

        // Vincular el evento de zoom al mapa
        google.maps.event.addListener(mapRef.current, 'zoom_changed', updateCircleRadius);

        // Llamar a la función inicialmente para establecer el radio correcto
        updateCircleRadius();

        if (op === 1) {
          const bounds = new google.maps.LatLngBounds();
          markerslist.forEach(({ marker }) =>
            bounds.extend(marker.getPosition())
          );
          mapRef.current.fitBounds(bounds);
        } else {
          const switchElement = document.getElementById("autocentrado");
          if (switchElement.checked && markerslist.length > 0) {
            const firstMarker = markerslist[0].marker;
            mapRef.current.setCenter(firstMarker.getPosition());
          }
        }

        setMarkers(markerslist);
        setInfoWindows(
          markerslist.map(({ markerInfoWindow }) => markerInfoWindow)
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };



  useEffect(() => {
    // Cerrar todos los InfoWindows cuando el componente se desmonte
    return () => {
      if (infoWindowRef.current) {
        infoWindowRef.current.close();
      }
    };
  }, []);


  const handleMarkerClick = (markerId) => {
    markers.forEach(({ marker, markerInfoWindow }) => {
      markerInfoWindow.close();
      if (marker.id === markerId) {
        markerInfoWindow.open(mapRef.current, marker);
        infoWindowRef.current = markerInfoWindow;
        setSelectedMarkerIndex(markerId);
        setSelectedMarker(marker);
        mapRef.current.panTo(marker.getPosition()); // Centrar el mapa en el marcador
        mapRef.current.setZoom(16);
      }
    });

  };

  const handleTableRowClick = (markerIndex) => {
    console.log("entro -> " + markerIndex + " total=" + listaMensajes.length);
    const objeto = listaMensajes[markerIndex];
    handleMarkerClick(objeto.ID);
  };

  const formatFecha = (fecha) => {
    const isoString = new Date(fecha).toISOString();
    const year = isoString.slice(0, 4);
    const month = isoString.slice(5, 7);
    const day = isoString.slice(8, 10);
    const hours = isoString.slice(11, 13);
    const minutes = isoString.slice(14, 16);
    const seconds = isoString.slice(17, 19);
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleVerUltPuntoClick = () => {
    try {
      let obj = listaMensajes[0];
      window.open('https://www.google.com/maps/?q=' + obj.latitude + ',' + obj.longitude + '&z=14', '_blank');
    } catch (error) { }
  };

  const handleSetUltPunto = () => {
    try {
      let data = {
        "lat": selectedMarker.position.lat(),
        "lng": selectedMarker.position.lng(),
        "id": selectedMarker.id,
        "contrato": contratoseleccionado.ContractID
      };
      const json = JSON.stringify(data);
      axios.post(process.env.REACT_APP_SERVER + '/operaciones/setultimopunto', json, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
        withCredentials: true
      })
        .then(response => {
          if (response.data.success.rowsAffected.length == 1) {
            $("#success-alert").fadeTo(2000, 500).slideUp(500, function () {
              $("#success-alert").slideUp(2000);
            });
          } else {
            $("#danger-alert").fadeTo(2000, 500).slideUp(500, function () {
              $("#danger-alert").slideUp(2000);
            });
          }
        }).catch(error => {
          $("#danger-alert").fadeTo(2000, 500).slideUp(500, function () {
            $("#danger-alert").slideUp(2000);
          });
        });

    } catch (error) {
      $("#danger-alert").fadeTo(2000, 500).slideUp(500, function () {
        $("#danger-alert").slideUp(2000);
      });
    }
  };

  const handleSetReporteAutomatico = () => {
    try {
      let data = {
        "ubicacion": selectedMarker.ubicacion,
        "contrato": contratoseleccionado.ContractID
      };
      const json = JSON.stringify(data);
      axios.post(process.env.REACT_APP_SERVER + '/operaciones/setreporteautomatico', json, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
        withCredentials: true
      })
        .then(response => {
          console.log(response);
          if (response.data.success == true) {
            $("#success-alert").fadeTo(2000, 500).slideUp(500, function () {
              $("#success-alert").slideUp(2000);
            });
          } else {
            $("#danger-alert").fadeTo(2000, 500).slideUp(500, function () {
              $("#danger-alert").slideUp(2000);
            });
          }
        }).catch(error => {
          $("#danger-alert").fadeTo(2000, 500).slideUp(500, function () {
            $("#danger-alert").slideUp(2000);
          });
        });

    } catch (error) {
      $("#danger-alert").fadeTo(2000, 500).slideUp(500, function () {
        $("#danger-alert").slideUp(2000);
      });
    }
  };

  const imagesModal = (e) => {
    e.preventDefault();
    images();
  }

  const monitoreoModal = (e) => {
    e.preventDefault();
    monitoreo();
  }

  const handleSwitchChangeGeo = (event) => {
    const newValue = event.target.checked;
    setSwitchValueGeo(newValue);
  };

  const handleTodoRecorridoClick = () => {
    try {
      ListReports(contratoseleccionado, 1, 1);
    } catch (error) { }
  };

  const getPolygonCenter = (polygon) => {
    const path = polygon.getPath();
    let latSum = 0;
    let lngSum = 0;
    const pathCount = path.getLength();

    // Sumar todas las latitudes y longitudes de los vértices
    path.forEach((latLng) => {
      latSum += latLng.lat();
      lngSum += latLng.lng();
    });

    // Calcular el centro
    return new google.maps.LatLng(latSum / pathCount, lngSum / pathCount);
  };

  const mostrarInformacionGeocerca = (geocerca, polygon) => {
    // Usar la función para obtener el centro del polígono
    const center = getPolygonCenter(polygon);

    // Filtrar los mensajes correspondientes a la geocerca seleccionada
    const mensajesDeGeocerca = listaMensajes.filter(
      (mensaje) => mensaje.NombreGeocerca === geocerca.geocerca
    );

    if (mensajesDeGeocerca.length === 0) {
      // Si no hay mensajes asociados a esta geocerca
      return;
    }

    // Encontrar la fecha más antigua y la más reciente
    const fechas = mensajesDeGeocerca.map((mensaje) => new Date(mensaje.PositionTime));
    const fechaInicial = new Date(Math.min(...fechas));
    const fechaFinal = new Date(Math.max(...fechas));
    const fechaFinalIndex = listaMensajes.findIndex(
      (mensaje) => new Date(mensaje.PositionTime).getTime() === fechaFinal.getTime()
    );
    console.log("Fecha final index:", fechaFinalIndex);


    let mensajeDiferencia = null;
    let fechaAnterior = null;
    if (fechaFinalIndex > 0) {
      fechaAnterior = new Date(listaMensajes[fechaFinalIndex - 1].PositionTime);
      console.log("Fecha anterior:", fechaAnterior);
    } else {
      console.log("No hay fecha anterior disponible.");
      mensajeDiferencia = "🚛 En geocerca";
    }

    // Calcular la diferencia entre la fecha de inicio y la fecha final
    const diferencia = fechaAnterior - fechaInicial;

    // Convertir la diferencia de milisegundos a días, horas, minutos y segundos
    const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
    const horas = Math.floor((diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutos = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
    const segundos = Math.floor((diferencia % (1000 * 60)) / 1000);

    // Formatear la diferencia de tiempo
    const tiempoDiferencia = `${horas}h ${minutos}m ${segundos}s`;

    // Crear el contenido del InfoWindow con la fecha inicial, final y la diferencia
    const contentString = `
        <div>
          <h5>${geocerca.geocerca}</h4>
          <p><strong>Ingreso:</strong> ${formatFecha(fechaInicial)}</p>
          <p><strong>Salida:</strong> ${mensajeDiferencia ? mensajeDiferencia : formatFecha(fechaAnterior)}</p>
          ${mensajeDiferencia ? '' : `<p><strong>Duración en geocerca:</strong> ${tiempoDiferencia}</p>`}
        </div>
      `;

    // Crear y abrir el InfoWindow
    const infoWindow = new google.maps.InfoWindow({
      content: contentString,
      position: center,
    });

    infoWindow.open(mapRef.current);
  };




  //Funcion para dibujar geocercas en el mapa
  const dibujarGeocercas = () => {
    // Limpiar los polígonos previamente dibujados si es necesario
    geocercaPolygons.forEach(polygon => polygon.setMap(null));

    // Limpiar el array de polígonos
    setGeocercaPolygons([]);

    // Dibujar nuevos polígonos
    const newPolygons = uniquesGeocerca.map((geocerca) => {
      const vertices = geocerca.vertices.split('/');
      const coords = vertices.map((coord) => {
        const [lat, lng] = coord.replace(/[()]/g, '').split(',').map(Number);
        return { lat, lng };
      });

      const polygon = new google.maps.Polygon({
        paths: coords,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      });

      polygon.setMap(mapRef.current);

      // Añadir un evento de clic al polígono
      google.maps.event.addListener(polygon, 'click', () => {
        // Mostrar información de la geocerca en un InfoWindow
        mostrarInformacionGeocerca(geocerca, polygon);
      });


      return polygon;
    });

    // Actualizar el estado de los polígonos
    setGeocercaPolygons(newPolygons);
  };


  useEffect(() => {
    if (switchValueGeo) {
      // Solo dibujar geocercas si el switch está activado
      dibujarGeocercas();
    } else {
      // Si el switch se desactiva, eliminamos los polígonos del mapa
      geocercaPolygons.forEach(polygon => polygon.setMap(null));
    }
  }, [switchValueGeo, uniquesGeocerca]);



  //(3.804092023012944,-76.6342724388831)/(3.801265846222622,-76.63058171927861)
  useEffect(() => {
    console.log("Lista de mensajes actualizada:", listaMensajes);

    // Filtramos los valores null y extraemos las geocercas únicas
    const uniques = [...new Set(listaMensajes.map((objeto) => objeto.NombreGeocerca).filter(item => item !== null))];

    // Creamos un array de objetos con la asociación geocerca -> vertices
    const geocercaWithVertices = uniques.map((geocerca) => {
      // Buscar los vertices para la geocerca actual
      const objeto = listaMensajes.find((item) => item.NombreGeocerca === geocerca && item.Vertices);
      return objeto ? { geocerca, vertices: objeto.Vertices } : null;
    }).filter(item => item !== null); // Eliminamos los objetos nulos si no se encuentra Vertices para una geocerca

    // Actualizamos el estado con el array de objetos
    console.log("Geocercas con Vertices:", geocercaWithVertices);
    setUniquesGeocerca(geocercaWithVertices);

    const b = listaMensajes[0]?.PositionTime;
    const a = listaMensajes[listaMensajes.length - 1]?.PositionTime;

    if (a && b) {
      const fechaInicio = new Date(a);
      const fechaFin = new Date(b);
      const diferencia = fechaFin - fechaInicio;
      const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
      const horas = Math.floor((diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutos = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
      const segundos = Math.floor((diferencia % (1000 * 60)) / 1000);
      setTimeTotal(`${dias}d ${horas}h ${minutos}m ${segundos}s`);
    }

  }, [listaMensajes]);




  if (!isOpen) return null;
  return (
    <>
      <MDBRow style={{ marginTop: '-15px', marginBottom: '10px' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <a href="#" onClick={(e) => { imagesModal(e) }}>Imagenes IA</a>&nbsp;/&nbsp;<a href="#" onClick={(e) => { monitoreoModal(e) }}>Monitoreo</a>
        </div>
      </MDBRow>
      <MDBRow style={{ marginBottom: '10px' }}>
        <MDBCol md='6'>
          <nav class="navbar navbar-expand-lg custom-navbar">
            <div class="container-fluid">
              <a class="navbar-brand" href="#" style={{ color: '#fff' }}>MENU</a>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarNavDropdown" style={{ display: 'flex' }}>
                <ul class="navbar-nav">
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: '#fff' }}>
                      Configuración
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                      <li onClick={handleVerUltPuntoClick}><a class="dropdown-item">Ver ultimo punto</a></li>
                      <li onClick={handleSetUltPunto}><a class="dropdown-item">Setear punto</a></li>
                    </ul>
                  </li>
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: '#fff' }}>
                      Reportes
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                      <li  ><a class="dropdown-item">Visualizar ultimos (50)</a></li>
                      <li  ><a class="dropdown-item">Visualizar ultimos (100)</a></li>
                      <li  ><a class="dropdown-item">Visualizar ultimos (200)</a></li>
                      <li  ><a class="dropdown-item">Visualizar ultimos (500)</a></li>
                      <li onClick={handleTodoRecorridoClick} ><a class="dropdown-item">Visualizar todos los reportes</a></li>
                    </ul>
                  </li>
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: '#fff' }}>
                      Trafico
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                      <li onClick={handleSetReporteAutomatico} ><a class="dropdown-item">Generar reporte automatico</a></li>
                    </ul>
                  </li>
                </ul>

              </div>
            </div>
          </nav>
        </MDBCol>

        <MDBCol md='6'>
          <MDBRow>
            <MDBCol md='4'>
              <div style={{ fontFamily: 'Courier New, sans-serif', fontSize: '12px', color: '#fff', marginLeft: 'auto', backgroundColor: '#000', padding: '3px', textAlign: 'center' }}>
                <FaClock size={18} className='icono rounded' /> Fecha/ hora inicio:<br />
                {listaMensajes?.length > 0 ? formatFecha(listaMensajes[listaMensajes.length - 1]?.PositionTime) : "Sin datos"}
              </div>
            </MDBCol>
            <MDBCol md='4'>
              <div style={{ fontFamily: 'Courier New, sans-serif', fontSize: '12px', color: '#fff', marginLeft: 'auto', backgroundColor: '#000', padding: '3px', textAlign: 'center' }}>
                <FaClock size={18} className='icono' /> fecha/ hora fin:<br />
                {listaMensajes?.length > 0 ? formatFecha(listaMensajes[0]?.PositionTime) : "Sin datos"}
              </div>
            </MDBCol>
            <MDBCol md='4'>
              <div id="contenedorestadodiv" style={{ fontFamily: 'Courier New, sans-serif', fontSize: '12px', color: '#fff', marginLeft: 'auto', backgroundColor: '#000', padding: '3px', textAlign: 'center' }}>
                <FaLock size={18} className='icono' /> Duración total:<br />
                {timeTotal ? timeTotal : "Sin datos"}
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCol>

      </MDBRow>
      <MDBRow>
        <MDBCol md='6'>
          <div id="map" style={{ border: '1px solid #c4c4c4' }} />

        </MDBCol>
        <MDBCol md='6'>
          <MDBRow>
            <MDBCol md='12'>
              <div style={{ overflow: 'hidden', width: '100%' }}>
                <motion.div
                  id="informacioneventos"
                  className="scrolling-text"
                  initial={{ x: '100%' }}
                  animate={{ x: '-100%' }}
                  transition={{
                    duration: 10,
                    repeat: Infinity,
                    ease: 'linear',
                  }}
                >
                </motion.div>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md='12' style={{ width: '100%', display: 'flex', justifyContent: 'start' }}>
              <div className="form-check form-switch custom-switch" style={{ marginLeft: '26px' }}>
                <input className="form-check-input" type="checkbox" role="switch" id="Geocerca" checked={switchValueGeo} onChange={handleSwitchChangeGeo} />
                <label className="form-check-label" for="autocentrado">Geocercas</label>
              </div>
            </MDBCol>
          </MDBRow>

          <div className="container" id="contenedor" style={{ fontSize: '10px' }}>

            <div className="row py-1">
              <div className="col">
                <b style={{ fontSize: '12px' }}>DISPOSITIVO:</b>
                <div style={{ fontSize: '12px' }} className="col" id="dispositivo_segH">

                </div>
              </div>
              <div className="col">
                <b style={{ fontSize: '12px' }}>CONTRATO:</b>
                <div style={{ fontSize: '12px' }} className="col" id="contrato_segH">
                </div>
              </div>
            </div>
            <div className="row py-1">
              <div className="col">
                <b style={{ fontSize: '12px' }}>CLIENTE:</b>
                <div style={{ fontSize: '12px' }} className="col" id="cliente_segH">
                </div>
              </div>
              <div className="col">
                <b style={{ fontSize: '12px' }}>RUTA:</b>
                <div style={{ fontSize: '12px' }} className="col" id="ruta_segH">
                </div>
              </div>
            </div>

            <div className="row py-1 containerReporteContratoSeguimientoHistorico" style={{ maxHeight: "400px", overflowY: "auto" }}>
              <table className="tableReporteContratoSeguimientoHistorico">
                <thead>
                  <tr>
                    <th className="sticky-header" style={{ width: "60px", textAlign: 'center' }}>Fecha</th>
                    <th className="sticky-header" style={{ width: "90px", textAlign: 'center' }}>Lugar</th>
                    <th className="sticky-header" style={{ width: "100px", textAlign: 'center' }}>Geocerca</th>
                    <th className="sticky-header" style={{ width: "32px", textAlign: 'center' }}>Bat</th>
                    <th className="sticky-header" style={{ width: "32px", textAlign: 'center' }}>Gps</th>
                    <th className="sticky-header" style={{ width: "50px", textAlign: 'center' }}>Vel</th>
                    <th className="sticky-header" style={{ width: "24px", textAlign: 'center' }}></th>
                    <th className="sticky-header" style={{ width: "24px", textAlign: 'center' }}></th>
                    <th className="sticky-header" style={{ width: "24px", textAlign: 'center' }}></th>
                    <th className="sticky-header" style={{ width: "24px", textAlign: 'center' }}></th>
                    <th className="sticky-header" style={{ width: "24px", textAlign: 'center' }}></th>
                    <th className="sticky-header" style={{ width: "24px", textAlign: 'center' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {listaMensajes.map((objeto, index) => (
                    <tr key={objeto.ID} className={objeto.latitude === 0 ? 'background-class' : ''} onClick={() => handleTableRowClick(index)}>
                      <td style={{ padding: '5px' }}>{formatFecha(objeto.PositionTime)}</td>
                      <td >{objeto.latitude === 0 ? <b>SIN GPS</b> : objeto.Ciudad}</td>
                      <td style={{ paddingLeft: '5px' }}>{objeto.NombreGeocerca}</td>
                      <td style={{ textAlign: 'center' }}>{parseFloat(objeto.Battery).toFixed(2).replace(/\.?0+$/, '')}</td>
                      <td style={{ textAlign: 'center' }}>{objeto.SatelliteCount}</td>
                      <td style={{ padding: '5px' }}>{objeto.Speed}</td>
                      <td><img src={`https://infocarga.pro/trafico${objeto.IconSatelite.substring(1)}`} width="20" height="20" /></td>
                      <td><img src={`https://infocarga.pro/trafico${objeto.IconMoving.substring(1)}`} width="20" height="20" /></td>
                      <td><img src={`https://infocarga.pro/trafico${objeto.IconLocked.substring(1)}`} width="20" height="20" /></td>
                      <td><img src={`https://infocarga.pro/trafico${objeto.IconDesvio.substring(1)}`} width="20" height="20" /></td>
                      <td><img src={`https://infocarga.pro/trafico${objeto.IconBack.substring(1)}`} width="20" height="20" /></td>
                      <td><img src={`https://infocarga.pro/trafico${objeto.IconAlejado.substring(1)}`} width="20" height="20" /></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

        </MDBCol>

      </MDBRow>
      <div className="alert alert-success" id="success-alert">
        <strong>Exitoso! </strong> Los cambios han sido realizados con exito.
      </div>

      <div className="alert alert-danger" id="danger-alert">
        <strong>Error! </strong> Se ha presentado un error, valide la información.
      </div>
    </>
  );
};

export default Modal;
