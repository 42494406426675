import React from 'react'
import { Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'

const FormStaticsMapsContrato = ({ handleContrato, contrato,onSubmit }) => {

    return (
        <>
            <Container className="d-flex justify-content-center px-0">
                <Row className="w-100"> {/* Ajusta el ancho de la fila */}
                    <Col className="d-flex flex-column align-items-center" style={{ paddingRight: '40px' }}> {/* Centra los elementos verticalmente */}
                        <FormGroup className="w-100"> {/* Asegura ancho completo dentro de la columna */}
                            <Label for="input1">Contrato inicial</Label>
                            <Input
                                type="text"
                                id="input1"
                                className="w-100"
                                placeholder="SERV-123456789"
                                style={{ textTransform: 'uppercase' }}
                                value={contrato}
                                onChange={(e) => handleContrato(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Container>
            <Container className="mt-4"> {/* Contenedor para el botón */}
                <Row>
                    <Col className="d-flex justify-content-center"> {/* Centra el botón horizontalmente */}
                        <Button onClick={onSubmit}> {/* Botón que dispara la función de submit */}
                            Cargar informe
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default FormStaticsMapsContrato
