import React from 'react';
import $, { error } from 'jquery';
import 'jquery-ui-dist/jquery-ui';
import './Css/themes/base/all.css';
import logo from '../logo.svg';
import '../App.css';
import './Css/trafico.css';
import Menu from '../Modulos/Menu';
import Barra from '../Modulos/Barra';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { FaSearch } from "@react-icons/all-files/fa/FaSearch";
import { FaFilter } from "@react-icons/all-files/fa/FaFilter";
import Gallery from '../Modulos/Gallery';
import ReporteDian from "../Modulos/ReporteDian";
import ReporteServicio from '../Modulos/Reporteservicio';
import ReporteContratoH from "../Modulos/ReporteContratoH";
import HistoricoResponsive from '../components/responsive/HistoricoResponsive';
import PrintComponent from '../components/printComponent/PrintComponent';
import BaseModalCreateSolcitud from '../components/modals/baseModal';
import FormUpdateContrato from '../components/formsSolicitudes/formUpdateContrato';
import axios from '../utils/axiosConfig';
import PreloaderHistorico from '../components/preloaders/preloader-historico';
import { formattedGlobalDate } from '../utils/formattedDates';
import RSeguimientoServicio from '../components/modals/modalRSeguimientoServicio';
import FormReporteServicio from '../components/formsSolicitudes/formReporteServicio';
import Swal from 'sweetalert2';
import FormUpdateContratoProyecto from '../components/formsSolicitudes/formUpdateContratoProyectos';
import MenuContratos from '../components/menuContratos/menuContratos';
import ModalFoto from '../components/modals/menuContratos/modalFoto';
import BaseModalAuto from '../components/modals/baseModalAuto';
import BaseModalXXL from '../components/modals/baseModalXXL';
import ModalReporteServicio from '../components/modals/modalReporteServicio';
import ModalReporteServicio2 from '../components/modals/menuContratos/modalReporteServicio';
import ModalComprobanteValitronics from '../components/modals/menuContratos/modalComprobanteValitronics';
import ModalBaseLg from '../components/modals/modal-base-lg';
import ModalGaleria from '../components/modals/menuContratos/modalGaleria';
import ModalVideo from '../components/modals/menuContratos/modalVideo';
import VideoGallery from '../componentes/videoGallery';
var table;
var miStorage = window.localStorage;

class Historico extends React.Component {
  constructor(props) {
    super(props);
    this.ListarContratos = this.ListarContratos.bind(this);
    this.ListarEventos = this.ListarEventos.bind(this);
    this.state = {
      listContratos: [],
      filteredContratos: [],
      listComprobantes: [],
      listRutas: [],
      listEmpresas: [],
      listEventos: [],
      contratoseleccionado: -1,
      rutaseleccionada: -1,
      eventoseleccionado: -1,
      desde: "",
      hasta: "",
      isShow: false,
      fechacontrato: "",
      username: "",
      placa: "",
      device: "",
      FKLokProyecto: "",
      FKICEmpresa: "",
      infocontratoContainer: '',
      infocontrato: { ContractID: 'ND' },
      infocontrato2: { ContractID: 'ND' },
      modalAbierto2: false,
      maxDateDesde: '',
      minDateHasta: '',
      maxDateHasta: '',
      dropdownOpenMultimedia: false,
      dropdownOpenReportes: false,
      dropdownOpenOpciones: false,
      dropdownOpenValitronics: false,
      isDialogOpenRSeguimientoServicio: false,
      modalAbierto: false,
      modalAbierto2: false,
      contratoDian: -1,
      isDialogOpenEditSolicitud: false,
      isDialogOpenEditContratoProject: false,
      infoUpdateContrato: [],
      showPreloader: false,
      showNoContratos: false,
      project: localStorage.getItem('project'),
      bitOpen: false,
      bitInsert: false,
      bitEdit: false,
      bitDelete: false,
      bitOpenTracking: false,
      bitInsertTracking: false,
      bitEditTracking: false,
      bitDeleteTracking: false,
      bitOpenMonitoreo: false,
      bitInsertMonitoreo: false,
      bitEditMonitoreo: false,
      bitDeleteMonitoreo: false,

      isDialogOpenEditSolicitud: false,
      isDialogOpenEditContratoProject: false,
      isDialogOpenRSeguimientoServicio: false,
      isDialogOpenRServicioAjustado: false,
      isDialogOpenModalReporteServicio: false,

      isDialogOpenModalFoto: false,
      isDialogOpenModalVideo: false,
      isDialogOpenModalComprobanteValitronics: false,
      isDialogOpenModalListaCheck: false,
      isDialogOpenModalGaleria: false,
      isDialogOpenAsignacion: false,

      //Modales 
      isDialogOpenModalFoto: false,

      dataGlobal: {},

      desde: "",
      hasta: "",
    };
    this.HandlePageByRole();
    this.ListarEventos();
    this.ListarEmpresas();

    this.abrirModal2 = this.abrirModal2.bind(this);
    this.cerrarModal2 = this.cerrarModal2.bind(this);
    this.OpenComprobante = this.OpenComprobante.bind(this);

    this.OpenGalleryPhoto = this.OpenGalleryPhoto.bind(this);
    this.OpenGalleryVideo = this.OpenGalleryVideo.bind(this);

    this.OpenUploadVideo = this.OpenUploadVideo.bind(this);


    // this.OpenGalleryVideo = this.OpenGalleryVideo.bind(this);
    this.OpenUploadPhoto = this.OpenUploadPhoto.bind(this);
    // this.OpenUploadVideo = this.OpenUploadVideo.bind(this);
    this.OpenReporteServicio = this.OpenReporteServicio.bind(this);
    this.onCloseEditContract = this.onCloseEditContract.bind(this);
    this.onCloseReporteServicio = this.onCloseReporteServicio.bind(this);
    this.OpenListCheck = this.OpenListCheck.bind(this);
    this.onCloseModalReporteServicio = this.onCloseModalReporteServicio.bind(this);
    // this.OpenComprobante = this.OpenComprobante.bind(this);
    // this.EditContrato = this.EditContrato.bind(this);
    // this.ActualizarContratoDevice = this.ActualizarContratoDevice.bind(this);
  }

  limpiarFechas = () => {
    $('#desde').val('');
    $('#hasta').val('');
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];

    // Reiniciar las propiedades del estado
    this.setState({
      desde: "",
      hasta: "",
      minDateHasta: "",
      maxDateDesde: formattedToday,
      maxDateHasta: formattedToday
    });
  }

  handleModalClose = () => {
    // Aquí puedes pausar los videos
    console.log('Modal cerrado, pausar los videos aquí');
    this.setState({ onHide: true });

  };

  onCloseModalFoto = () => {
    this.setState({ isDialogOpenModalFoto: false });
  }

  onCloseModalVideo = () => {
    this.setState({ isDialogOpenModalVideo: false });
  }

  abrirModal2 = async (data) => {
    this.setState({
      dataGlobal: data
    });
    let dataPermisos = {
      pagina: "13",
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
        JSON.stringify(dataPermisos),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );

      // Handle the response here after the request completes
      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("BIt permisos tracking: ", res.data.data);
        if (res.data.data.length > 0) {
          this.setState(
            {
              bitOpenTracking: res.data.data[0].bitOpen,
              bitInsertTracking: res.data.data[0].bitInsert,
              bitEditTracking: res.data.data[0].bitEdit,
              bitDeleteTracking: res.data.data[0].bitDelete,
            },
            () => {
              if (this.state.bitOpenTracking) {
                console.warn("Tienes todos los permisos");
                console.log("$$", data);
                console.log(data.ContractID);
                this.setState({
                  modalAbierto2: true,
                  infocontrato2: data,
                });
              } else {
                this.cerrarModal2();
                Swal.fire({
                  title: "Información",
                  text: "No tiene los permisos necesarios para acceder a esta página",
                  icon: "info",
                  timer: 3000,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                }).then(() => {

                  console.error("No tienes permisos");
                });
              }
            }
          );
        }
      }
    } catch (error) {
      console.error("Error al listar bit permisos:", error);
    }
  }

  cerrarModal2() {
    const modalElement = document.getElementById("ModalReporteSegimiento");
    if (modalElement) {
      const modalInstance = bootstrap.Modal.getInstance(modalElement); // Get the Bootstrap modal instance
      if (modalInstance) {
        modalInstance.hide(); // Close the Bootstrap modal
      }
    }
    this.setState({ modalAbierto2: false });
  }

  cerrarModal() {
    this.setState({ modalAbierto: false });
  }

  OpenVideos = async () => {
    // this.cerrarModal2();
    this.OpenGalleryVideo(this.state.dataGlobal);
    $("#ModalVideoGaleria").modal("show");
  }

  OpenValitronics = async () => {
    // this.cerrarModal2();
    this.OpenComprobante(this.state.dataGlobal);
  }

  OpenListCheckModal = async () => {
    // this.cerrarModal2();
    this.OpenListCheck(this.state.dataGlobal);
  }


  OpenListCheck(data) {
    this.setState({ infocontrato: data, isDialogOpenModalListaCheck: true });
  }

  showEditContract = async (data) => {
    console.warn("DATAhistoricoshowedit :", data);
    let dataPermisos = {
      pagina: "19",
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
        JSON.stringify(dataPermisos),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );

      // Handle the response here after the request completes
      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("BIt permisos tracking: ", res.data.data);
        if (res.data.data.length > 0) {
          this.setState(
            {
              bitOpenActualizarContrato: res.data.data[0].bitOpen,
              bitInsertUActualizarContrato: res.data.data[0].bitInsert,
              bitEditUActualizarContrato: res.data.data[0].bitEdit,
              bitDeleteUActualizarContrato: res.data.data[0].bitDelete,
            },
            () => {
              if (this.state.bitOpenActualizarContrato) {
                console.warn("Tienes todos los permisos");
                if (this.state.project == 1) {
                  this.obtenerYEstablecerDatos(data.ContractID);
                } else {
                  this.obtenerYEstablecerDatosProyecto(data.ContractID);
                }
              } else {

                Swal.fire({
                  title: "Información",
                  text: "No tiene los permisos necesarios para acceder a esta opción",
                  icon: "info",
                  timer: 3000,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                }).then(() => {

                  console.error("No tienes permisos");
                });
              }
            }
          );
        }
      }
    } catch (error) {
      console.error("Error al listar bit permisos:", error);
    }

  }

  handleAllStatesDropdowns = () => {
    this.setState({
      dropdownOpenMultimedia: false,
      dropdownOpenReportes: false,
      dropdownOpenOpciones: false,
      dropdownOpenValitronics: false
    })

  }
  filterContratos = (contratos, value) => {
    return contratos.filter(contrato => {
      const lowerValue = value.toLowerCase();
      const lowerContractID = contrato.ContractID ? contrato.ContractID.toLowerCase() : "";
      const lowerNombreEmpresa = contrato.NombreEmpresa ? contrato.NombreEmpresa.toLowerCase() : "";
      const lowerCiudad = contrato.Ciudad ? contrato.Ciudad.toLowerCase() : "";
      const lowerFKLokDeviceID = contrato.FKLokDeviceID ? contrato.FKLokDeviceID.toLowerCase() : "";
      const lowerPlacaTruck = contrato.PlacaTruck ? contrato.PlacaTruck.toLowerCase() : "";
      const lowerDescripcionRuta = contrato.DescripcionRuta ? contrato.DescripcionRuta.toLowerCase() : "";
      const lowerLastReportUbica = contrato.LastReportUbica ? contrato.LastReportUbica.toLowerCase() : "";
      const lowerContainerNum = contrato.ContainerNum ? contrato.ContainerNum.toLowerCase() : "";
      const lowerRef = contrato.Ref ? contrato.Ref.toLowerCase() : "";

      return (
        lowerContractID.includes(lowerValue) ||
        lowerNombreEmpresa.includes(lowerValue) ||
        lowerCiudad.includes(lowerValue) ||
        lowerFKLokDeviceID.includes(lowerValue) ||
        lowerPlacaTruck.includes(lowerValue) ||
        lowerDescripcionRuta.includes(lowerValue) ||
        lowerLastReportUbica.includes(lowerValue) ||
        lowerContainerNum.includes(lowerValue) ||
        lowerRef.includes(lowerValue)
      );
    });
  }

  handleSearchChange = (event) => {
    const { listContratos } = this.state;
    const value = event.target.value.toLowerCase();
    localStorage.setItem("searchHistorico", value);

    // If search value is empty, show all contracts
    if (value === "") {
      this.setState({ filteredContratos: listContratos });
    } else {
      // Filter contracts based on search input
      const filteredContratos = this.filterContratos(listContratos, value);
      console.log("Contratos filtrados", filteredContratos);
      this.setState({ filteredContratos }); // Update filteredContratos state
    }
  };

  HandlePageByRole = async () => {
    let data = {
      pagina: "4",
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true, // this should be inside the options object
        }
      );

      // Handle the response here after the request completes
      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("BIt permisos: ", res.data.data);
        if (res.data.data.length > 0) {
          this.setState({
            bitOpen: res.data.data[0].bitOpen,
            bitInsert: res.data.data[0].bitInsert,
            bitEdit: res.data.data[0].bitEdit,
            bitDelete: res.data.data[0].bitDelete,
          }, () => {
            if (this.state.bitOpen) {
              console.warn("Tienes todos los permisos");

              return
            } else {
              Swal.fire({
                title: 'Información',
                text: 'No tiene los permisos necesarios para acceder a esta página',
                icon: 'info',
                timer: 3000, // The time in milliseconds before the alert closes (3 seconds in this case)
                showConfirmButton: false,
                allowOutsideClick: false, // Prevents clicking outside the modal to close it
                didOpen: () => {
                  Swal.showLoading(); // Shows the loading spinner
                },
                timer: 3000 // Time before redirecting (3 seconds)
              }).then(() => {
                // This will execute after the modal finishes showing
                window.location.href = "/principal"; // Redirect to another page
              });
            }

          })
        }

      }
    } catch (error) {
      console.error("Error al listar bit permisos:", error);
    }
  };


  loadFillData2(array) {
    return this.state.listComprobantes.map((data) => {
      return (
        <tr>
          <td className="prueba" style={{ color: "#000" }}>
            <strong>{data.Comprobante}</strong>
          </td>
          <td className="prueba" style={{ color: "#000" }}>
            {data.Fecha}
          </td>
          <td className="prueba" style={{ color: "#000" }}>
            {data.Latitud}
          </td>
          <td className="prueba" style={{ color: "#000" }}>
            {data.Longitud}
          </td>
          <td className="prueba" style={{ color: "#000" }}>
            {data.Usuario}
          </td>
        </tr>
      )
    });
  }

  toggleDropdownMultimedia = () => {
    this.setState(prevState => ({
      dropdownOpenMultimedia: !prevState.dropdownOpenMultimedia
    }));
  };

  toggleDropdownReportes = () => {
    this.setState(prevState => ({
      dropdownOpenReportes: !prevState.dropdownOpenReportes
    }));
  }
  toggleDropdownOpciones = () => {
    this.setState(prevState => ({
      dropdownOpenOpciones: !prevState.dropdownOpenOpciones
    }));
  }
  toggleDropdownValitronics = () => {
    this.setState(prevState => ({
      dropdownOpenValitronics: !prevState.dropdownOpenValitronics
    }));
  }

  OpenGalleryPhoto(data) {
    console.log("DATAHISTORICO:", data);
    console.log("DATAHISTORICOCONTAINER:", data.ContainerNum);
    this.setState({
      dataGlobal: data,
      contratoseleccionado: data.ContractID,
      infocontratoContainer: data.ContainerNum
    });
  }

  OpenImages = async () => {
    // this.cerrarModal2();
    this.OpenGalleryPhoto(this.state.dataGlobal);
    $("#ModalGaleria").modal("show");
  }

  OpenImages2 = async () => {
    this.setState({
      isDialogOpenRSeguimientoServicio: false,
    });
    this.OpenGalleryPhoto(this.state.dataGlobal);
    $("#ModalGaleria").modal("show");
  }

  OpenTracking2 = async () => {
    this.setState({
      isDialogOpenRSeguimientoServicio: false,
    });
    this.abrirModal2(this.state.dataGlobal);
    $("#ModalReporteSegimiento").modal("show");
  }

  OpenTracking = async () => {

    console.log("Entre");
    console.log("Data global", this.state.dataGlobal);
    const modalElement = document.getElementById("ModalGaleria");
    if (modalElement) {
      const modalInstance = bootstrap.Modal.getInstance(modalElement); // Get the Bootstrap modal instance
      if (modalInstance) {
        modalInstance.hide(); // Close the Bootstrap modal
      }
    }
    this.abrirModal2(this.state.dataGlobal);
    $("#ModalReporteSegimiento").modal("show");
  }

  OpenMonitoreo = async () => {
    // this.cerrarModal2();
    this.showModalOpenResporteServicio(this.state.dataGlobal);
  }

  OpenReporteConsolidado = async () => {
    // this.cerrarModal2();
    console.log("Entrando al modal de reporte Consolidado");
    this.OpenModalReporteServicio(this.state.dataGlobal);
  }


  OpenModalReporteServicio(data) {
    console.log("DAta desde el reporteModal", data);
    this.setState(
      {
        infocontratoAjustado: data,
        isDialogOpenModalReporteServicio: true
      }
    );
  }

  OpenReporteConsolidado2 = async () => {
    // const modalElement = document.getElementById("ModalGaleria");
    // if (modalElement) {
    //   const modalInstance = bootstrap.Modal.getInstance(modalElement); // Get the Bootstrap modal instance
    //   if (modalInstance) {
    //     modalInstance.hide(); // Close the Bootstrap modal
    //   }
    // }
    this.OpenModalReporteServicio(this.state.dataGlobal);
  }

  OpenMonitoreo2 = async () => {
    const modalElement = document.getElementById("ModalGaleria");
    if (modalElement) {
      const modalInstance = bootstrap.Modal.getInstance(modalElement); // Get the Bootstrap modal instance
      if (modalInstance) {
        modalInstance.hide(); // Close the Bootstrap modal
      }
    }
    this.showModalOpenResporteServicio(this.state.dataGlobal);
  }

  OpenComprobante(data) {
    this.setState({ contratoseleccionado: data.ContractID, isDialogOpenModalComprobanteValitronics: true });
    this.ListarComprobantes(data.ContractID);
  }

  OpenGalleryVideo(data) {


    this.setState({ contratoseleccionado: data.ContractID });
  }

  obtenerYEstablecerDatos = async (contrato) => {
    try {
      const resContrato = await axios.post(
        process.env.REACT_APP_SERVER + "/contratos/getinfocontrato",
        JSON.stringify({ contrato }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );

      if (resContrato.success === false) {
        window.location = "/";
      } else {
        console.log("Respuesta a evaluar del contrato antes de actualizar ", resContrato);
        if (this.state.project == 1) {
          console.log("Proyecto status", this.state.project)
          this.setState({
            infoUpdateContrato: resContrato.data.data,
            isDialogOpenEditSolicitud: true,
            contratoseleccionado: contrato
          })
        } else {
          console.log("Proyecto status", this.state.project)
          this.setState({
            infoUpdateContrato: resContrato.data.data,
            isDialogOpenEditContratoProject: true,
            contratoseleccionado: contrato
          })
        }
      }
    } catch (err) {
      console.log(err);

    }
  };

  obtenerYEstablecerDatosProyecto = async (contrato) => {
    try {
      const resContrato = await axios.post(
        process.env.REACT_APP_SERVER + "/contratos/getinfocontratoproyecto",
        JSON.stringify({ contrato }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );

      if (resContrato.success === false) {
        window.location = "/";
      } else {
        console.log("Respuesta a evaluar del contrato antes de actualizar ", resContrato);
        if (this.state.project == 1) {
          console.log("Proyecto status", this.state.project)
          this.setState({
            infoUpdateContrato: resContrato.data.data,
            isDialogOpenEditSolicitud: true,
            contratoseleccionado: contrato
          })
        } else {
          console.log("Proyecto status", this.state.project)
          this.setState({
            infoUpdateContrato: resContrato.data.data,
            isDialogOpenEditContratoProject: true,
            contratoseleccionado: contrato
          })
        }
      }
    } catch (err) {
      console.log(err);

    }
  };

  onCloseEditContract() {
    if (this.state.project == 1) {
      this.setState({
        isDialogOpenEditSolicitud: false
      });
    } else {
      this.setState({
        isDialogOpenEditContratoProject: false
      });
    }
  }

  onCloseModalComprobanteValitronics = () => {
    this.setState({ isDialogOpenModalComprobanteValitronics: false });
  }

  onCloseModalListaCheck = () => {
    this.setState({ isDialogOpenModalListaCheck: false });
  }

  componentDidUpdate() {
    $('#search').keyup();

  }
  componentDidMount() {

    //MIN FECHA DE BUSQUEDA 
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];

    // Establecer los valores en el estado
    this.setState({
      maxDateDesde: formattedToday,
      maxDateHasta: formattedToday // Por defecto, "Hasta" también está limitado hasta hoy
    });

    localStorage.setItem('searchHistorico', '');

    //MIN FECHA DE BUSQUEDA 🎩

    var busquedaGuardada = localStorage.getItem('searchHistorico');
    $("#search").val(busquedaGuardada);
    $("#success-alert").hide();
    $("#danger-alert").hide();



    $("#search").on("keyup", function () {
      var value = $(this).val().toLowerCase();
      localStorage.setItem('searchHistorico', value);
      $("#tabladata tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
    });
  }



  //METODOS HANDLE PARA LAS FECHAS MAXIMO DE UN MES
  handleDesdeChange = (e) => {
    const selectedDate = e.target.value;

    // Verificar si se ha borrado el campo
    if (!selectedDate) {
      this.setState({
        desde: "",
        hasta: "",
        minDateHasta: "",
        maxDateHasta: ""
      });
      return;
    }

    const parsedDate = new Date(selectedDate);
    if (isNaN(parsedDate.getTime())) {
      console.error("Fecha inválida recibida");
      return;
    }

    // Calcular la fecha máxima permitida para "Hasta" (un mes después de "Desde")
    const maxHastaDate = new Date(parsedDate);
    maxHastaDate.setMonth(maxHastaDate.getMonth() + 1);
    const formattedMaxHastaDate = maxHastaDate.toISOString().split('T')[0];

    // Obtener la fecha actual para comparar
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];

    // Establecer el máximo de "Hasta" como la menor entre un mes desde "Desde" y la fecha actual
    const maxDateHasta = formattedMaxHastaDate > formattedToday ? formattedToday : formattedMaxHastaDate;

    this.setState({
      desde: selectedDate,
      hasta: selectedDate,
      minDateHasta: selectedDate,
      maxDateHasta: maxDateHasta
    });
  }


  handleHastaChange = (e) => {
    const selectedDate = e.target.value;
    console.log("Fecha seleccionada en 'Hasta':", selectedDate);

    // Asegurarse de que "Hasta" no pueda ser posterior a la fecha actual
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];

    // Ajustar el máximo de "Desde" para que no sea mayor a la fecha seleccionada en "Hasta" o la fecha actual
    if (selectedDate <= formattedToday) {
      this.setState({
        hasta: selectedDate,
        maxDateDesde: selectedDate
      });
    }
  }

  showModalOpenResporteServicio = async (data) => {
    let dataPermisos = {
      pagina: "14",
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
        JSON.stringify(dataPermisos),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );

      // Handle the response here after the request completes
      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("BIt permisos tracking: ", res.data.data);
        if (res.data.data.length > 0) {
          this.setState(
            {
              bitOpenMonitoreo: res.data.data[0].bitOpen,
              bitInsertMonitoreo: res.data.data[0].bitInsert,
              bitEditMonitoreo: res.data.data[0].bitEdit,
              bitDeleteMonitoreo: res.data.data[0].bitDelete,
            },
            () => {
              if (this.state.bitOpenMonitoreo) {
                console.warn("Tienes todos los permisos");
                console.log("$$", data);
                console.log(data.ContractID);
                this.setState({
                  globalData: data,
                  contratoseleccionado: data.ContractID,
                  isDialogOpenRSeguimientoServicio: true,
                });
              } else {
                this.onCloseReporteServicio();
                Swal.fire({
                  title: "Información",
                  text: "No tiene los permisos necesarios para acceder a esta página",
                  icon: "info",
                  timer: 3000,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                }).then(() => {

                  console.error("No tienes permisos");
                });
              }
            }
          );
        }
      }
    } catch (error) {
      console.error("Error al listar bit permisos:", error);
    }

  }

  onCloseReporteServicio() {
    this.setState({
      isDialogOpenRSeguimientoServicio: false,
    });
  }

  onCloseModalReporteServicio() {
    this.setState({
      isDialogOpenModalReporteServicio: false,
      ultPunto: -1,
      contractR: -1
    })
  }

  OpenUploadPhoto = async (data) => {

    let dataPermisos = {
      pagina: "15",
    };

    console.log("DATA UPLODAD FOTO", data);
    this.setState({
      dataGlobal: data,
    });

    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
        JSON.stringify(dataPermisos),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );

      // Handle the response here after the request completes
      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("BIt permisos tracking: ", res.data.data);
        if (res.data.data.length > 0) {
          this.setState(
            {
              bitOpenUploadPhoto: res.data.data[0].bitOpen,
              bitInsertUploadPhoto: res.data.data[0].bitInsert,
              bitEditUploadPhoto: res.data.data[0].bitEdit,
              bitDeleteUploadPhoto: res.data.data[0].bitDelete,
            },
            () => {
              if (this.state.bitOpenUploadPhoto) {
                console.warn("Tienes todos los permisos");
                console.warn("DATA uplodad foto", data);
                $("#fotos").val("");
                this.setState({
                  contratoseleccionado: data.ContractID,
                  username: data.username,
                  placa: data.PlacaTruck,
                  device: data.FKLokDeviceID,
                  FKLokProyecto: data.FKLokProyecto,
                  FKICEmpresa: data.FKICEmpresa,
                  isDialogOpenModalFoto: true,
                });
                $("#latitudF").val("0.00");
                $("#longitudF").val("0.00");
                $("#fechafoto").val(data.fecha);
                // const modalElement = document.getElementById("ModalFoto");
                // const modalInstance = new bootstrap.Modal(modalElement, {
                //   backdrop: 'static',
                //   keyboard: false,
                // });
                // modalInstance.show();
              } else {

                Swal.fire({
                  title: "Información",
                  text: "No tiene los permisos necesarios para acceder a esta opción",
                  icon: "info",
                  timer: 3000,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                }).then(() => {

                  console.error("No tienes permisos");
                });
              }
            }
          );
        }
      }
    } catch (error) {
      console.error("Error al listar bit permisos:", error);
    }
  }

  OpenUploadVideo = async (data) => {

    let dataPermisos = {
      pagina: "16",
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
        JSON.stringify(dataPermisos),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );

      // Handle the response here after the request completes
      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("BIt permisos tracking: ", res.data.data);
        if (res.data.data.length > 0) {
          this.setState(
            {
              bitOpenUploadVideo: res.data.data[0].bitOpen,
              bitInsertUploadVideo: res.data.data[0].bitInsert,
              bitEditUploadVideo: res.data.data[0].bitEdit,
              bitDeleteUploadVideo: res.data.data[0].bitDelete,
            },
            () => {
              if (this.state.bitOpenUploadVideo) {
                console.warn("Tienes todos los permisos");
                $("#videos").val("");
                this.setState({
                  contratoseleccionado: data.ContractID,
                  username: data.username,
                  placa: data.PlacaTruck,
                  device: data.FKLokDeviceID,
                  FKLokProyecto: data.FKLokProyecto,
                  FKICEmpresa: data.FKICEmpresa,
                  isDialogOpenModalVideo: true

                });
                $("#latitudV").val("0.00");
                $("#longitudV").val("0.00");
                $("#fechaVideo").val(data.fecha);
                // const modalElement = document.getElementById("ModalVideo");
                // const modalInstance2 = new bootstrap.Modal(modalElement, {
                //   backdrop: 'static',
                //   keyboard: false,
                // });
                // modalInstance2.show();
              } else {

                Swal.fire({
                  title: "Información",
                  text: "No tiene los permisos necesarios para acceder a esta opción",
                  icon: "info",
                  timer: 3000,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                }).then(() => {

                  console.error("No tienes permisos");
                });
              }
            }
          );
        }
      }
    } catch (error) {
      console.error("Error al listar bit permisos:", error);
    }
  }
  // METODO PARA LISTAR CONTRATOS HISTORICOS
  ListarContratos() {
    var value = $('#empresa').val();
    var empresa = $('#datalistOptions [value="' + value + '"]').data('value');
    if (empresa == undefined) {
      empresa = 0;
    }
    if ($("#desde").val() != "" && $("#hasta").val() != "") {
      let data =
      {
        "desde": $("#desde").val() + " 00:00:00",
        "hasta": $("#hasta").val() + " 23:59:59",
        "placa": $("#placa").val(),
        "empresa": empresa
      }
      this.setState({ showPreloader: true });
      console.log(data);
      const json = JSON.stringify(data);
      axios.post(process.env.REACT_APP_SERVER + '/operaciones/gethistoricos', json, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
        withCredentials: true
      })
        .then(res => {
          if (res.data.success == false) {
            this.setState({ showPreloader: false, filteredContratos: [] });
            Swal.fire({
              title: 'Error',
              text: 'No se pudo cargar la información, error de conexión',
              icon: 'error',
              showConfirmButton: true
            });
          } else {
            console.log("info Historico:", res.data.data);
            this.setState({ listContratos: res.data.data, filteredContratos: res.data.data, showPreloader: false, });
          }
        }).catch((error) => {
          console.log("Error al listar contratos historicos", error);
          this.setState({ showPreloader: false });
          Swal.fire({
            title: 'Error',
            text: 'No se pudo cargar la información, error de conexión',
            icon: 'error',
            timer: 3000,
            showConfirmButton: true
          });

        });
    } else {
      $("#danger-alert").fadeTo(2000, 500).slideUp(500, function () {
        $("#danger-alert").slideUp(2000);
      });
    }
  }
  // METODO PARA LISTAR EMPRESAS DEL PROYECTO
  ListarEmpresas() {
    axios.get(process.env.REACT_APP_SERVER + '/empresas/getempresas', {
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
      withCredentials: true
    })
      .then(res => {
        if (res.data.success == false) {
          window.location = "/";
        } else {
          this.setState({ listEmpresas: res.data.data });
        }
      }).catch((error) => { console.log(error); });
  }
  // ABRIR NODAL PARA SUBIR FOTOS
  // OpenUploadPhoto(data) {
  //   $("#fotos").val("");
  //   this.setState({ contratoseleccionado: data.ContractID, username: data.username, placa: data.PlacaTruck, device: data.FKLokDeviceID });
  //   $("#latitud").val("0.00");
  //   $("#longitud").val("0.00");
  //   $("#fechafoto").val(data.fecha);
  // }

  // METODO PARA SUBIR FOTOS
  UploadPhoto() {
    console.log("Subiendo Foto...");
    console.log(this.state.contratoseleccionado);

    var pattern = /\d+\.\d{2,}$/;
    if (
      !pattern.test($("#latitudF").val()) ||
      !pattern.test($("#longitudF").val())
    ) {
      $("#danger-alert")
        .fadeTo(2000, 500)
        .slideUp(500, function () {
          $("#danger-alert").slideUp(2000);
        });
    } else {
      try {
        const fecha = new Date($("#fechafoto").val());
        if (isNaN(fecha.getTime())) {
          $("#danger-alert")
            .fadeTo(2000, 500)
            .slideUp(500, function () {
              $("#danger-alert").slideUp(2000);
            });
        } else {
          const year = fecha.getFullYear();
          const mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
          const dia = ("0" + fecha.getDate()).slice(-2);
          const hora = ("0" + fecha.getHours()).slice(-2);
          const minuto = ("0" + fecha.getMinutes()).slice(-2);
          const segundos = "25";
          var fechacompleta =
            year +
            "_" +
            mes +
            "_" +
            dia +
            "_" +
            hora +
            "_" +
            minuto +
            "_" +
            segundos;

          const files = $("#fotos").prop("files");
          const formData = new FormData();

          for (let i = 0; i < files.length; i++) {
            const newName =
              fechacompleta +
              "_" +
              i +
              "_" +
              this.state.device +
              "_" +
              this.state.placa +
              "_" +
              this.state.eventoseleccionado +
              "_" +
              Math.floor(Math.random() * 1000) +
              "_" +
              this.state.username +
              "_" +
              $("#latitudF").val() +
              "_" +
              $("#longitudF").val() +
              "_" +
              this.state.contratoseleccionado +
              "_" +
              this.state.FKLokProyecto +
              "_" +
              this.state.FKICEmpresa;

            if (files[i].type.startsWith("image/")) {
              formData.append("files", files[i], newName + ".jpg");
            } else if (files[i].type.startsWith("video/")) {
              formData.append("files", files[i], newName + ".mp4");
            }
            console.log("Nombre de la foto ajustado", newName)
            alert("Nombre de la foto ajustado: " + newName);
          }
          let timerInterval;
          Swal.fire({
            title: "Subiendo Foto...",
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          });

          fetch(process.env.REACT_APP_SERVER + "/upload", {
            method: "POST",
            headers: {
              "Authorization": `Bearer ${localStorage.getItem("tkn")}`,
              // No se incluye 'Content-Type': 'multipart/form-data' porque fetch lo maneja automáticamente
            },
            body: formData
          })
            .then(response => response.json())
            .then(data => {
              Swal.close();
              if (data.hasOwnProperty("success")) {
                $("#success-alert")
                  .fadeTo(2000, 500)
                  .slideUp(500, function () {
                    $("#success-alert").slideUp(2000);
                  });
                $("#ModalFoto .close").click();
              } else {
                Swal.close();
                $("#danger-alert")
                  .fadeTo(2000, 500)
                  .slideUp(500, function () {
                    $("#danger-alert").slideUp(2000);
                  });
              }
            })
            .catch(error => {
              Swal.close();
              console.error("Error subiendo foto:", error);
            });
        }
      } catch (err) {
        Swal.close();
        $("#danger-alert")
          .fadeTo(2000, 500)
          .slideUp(500, function () {
            $("#danger-alert").slideUp(2000);
          });
      }
    }
  }

  // METODO PARA OBTENER LISTADO DE EVENTOS POR PROYECTO
  ListarEventos() {
    axios.get(process.env.REACT_APP_SERVER + '/operaciones/geteventos', {
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
      withCredentials: true
    })
      .then(res => {
        if (res.data.success === false) {
          window.location = "/";
        } else {
          this.setState({ listEventos: res.data.data });
          this.setState({ eventoseleccionado: res.data.data[0].ID_Evento });
        }
      }).catch((error) => { console.log(error); });
  }

  // METODO PARA ACTUALIZAR EVENTO SELECCIONADO
  EditarEvento(evento) {
    this.setState({ eventoseleccionado: evento });
  }

  // OpenGalleryPhoto(data) {
  //   this.setState({ contratoseleccionado: data.ContractID });
  // }

  OpenReporteServicio(data) {
    this.setState({ infocontrato: data });
  }

  eliminar() {
    var elementos = document.getElementsByClassName('object-box');
    var elementosArray = Array.from(elementos);
    elementosArray.forEach(function (elemento) {
      elemento.parentNode.removeChild(elemento);
    });
  }

  ListarComprobantes(contrato) {
    let send = {
      contrato: contrato,
    };
    console.log(send);
    const json = JSON.stringify(send);
    axios
      .post(process.env.REACT_APP_SERVER + "/operaciones/getcomprobantevalitronics", json, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.success == false) {
          window.location = "/";
        } else {
          console.log(res.data.data);
          this.setState({ listComprobantes: res.data.data });
        }
      }).catch((error) => { console.log(error); });
  }
  render() {
    return (
      <>
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">


            <div className="layout-page">
              <Barra />
              {this.state.bitOpen && (
                <div className="content-wrapper">

                  <div className="container-fluid flex-grow-1 container-p-y">
                    <MDBRow>
                      <MDBCol md='7'>
                        <h4 className="fw-bold mb-4 controlHistoricoH4">Control de tráfico histórico {this.state.filteredContratos.length > 0 ? `(${this.state.filteredContratos.length})` : ''}</h4>
                      </MDBCol>
                      <MDBCol md='4'>
                        <div className="input-group col-md-12">
                          <span className="input-group-text" id="b1"><FaSearch /></span>
                          <input id="search" type="text" className="form-control" placeholder="Filtrar" aria-describedby="b1" onKeyUp={this.handleSearchChange}
                          />
                        </div>
                      </MDBCol>
                      <MDBCol md='1'>
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasEnd"
                          aria-controls="offcanvasEnd"
                        >
                          <FaFilter />
                        </button>
                      </MDBCol>
                    </MDBRow>

                    <div className="card cardControlHistorico">
                      <div className="table-responsive text-nowrap"
                        style={{
                          height: `calc(100vh - 180px)`,
                          overflowY: "auto",
                          overflowX: "auto",
                          // display: "flex",
                          // flexDirection: "column",
                        }}>
                        <table className="table table-hover" id="tabla" style={{ width: '2000px', overflowX: 'auto' }}>
                          <thead className="FondoHeaderTabla2">
                            <tr>
                              <th style={{ color: '#ffffff' }}>EMPRESA</th>
                              <th style={{ color: '#ffffff' }}>REF</th>
                              <th style={{ color: '#ffffff' }}>PLACA</th>
                              <th style={{ color: '#ffffff' }}>CONTENEDOR</th>
                              <th style={{ color: '#ffffff' }}>DISPOSITIVO</th>
                              <th style={{ color: '#ffffff' }}>CONTRATO</th>
                              <th style={{ color: '#ffffff' }}>RUTA</th>
                              <th style={{ color: '#ffffff' }}>ULT.REPORTE</th>
                              <th style={{ color: '#ffffff', width: '250px' }}>ULT.OBSERVACION TRÁFICO</th>
                              <th style={{ color: '#ffffff' }}>ULT.UBICACIÓN</th>
                              <th style={{ color: '#ffffff' }}>TIEMPO SERVICIO</th>
                              <th style={{ color: '#ffffff', width: '150px' }}>TRAYECTO</th>
                              <th style={{ color: '#ffffff' }}>OPCIONES</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0" id="tabladata">
                            {this.loadFillData(this.state.filteredContratos)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className='card cardControlHistoricoResponsive'>
                      <HistoricoResponsive
                        listContratos={this.state.filteredContratos}
                        searchQuery={this.state.searchQuery}
                        OpenUploadPhoto={this.OpenUploadPhoto}
                        OpenGalleryPhoto={this.OpenGalleryPhoto}
                        OpenReporteServicio={this.OpenReporteServicio}
                        abrirModal2={this.abrirModal2}
                        handleAllStatesDropdowns={this.handleAllStatesDropdowns}
                        showModalOpenResporteServicio={this.showModalOpenResporteServicio}
                        abrirModal={this.abrirModal}
                        OpenUploadVideo={this.OpenUploadVideo}
                        OpenGalleryVideo={this.OpenGalleryVideo}
                        OpenComprobante={this.OpenComprobante}
                        OpenListCheck={this.OpenListCheck}
                        showEditContract={this.showEditContract}
                      />
                    </div>
                  </div>

                  {/* <footer className="content-footer footer bg-footer-theme">
                  <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                    <div className="mb-2 mb-md-0">
                      ©, Producto realizado por <a href="https://logiseguridad.com" target="_blank" className="footer-link fw-bolder"> Logiseguridad Ltda</a>
                    </div>

                  </div>
                </footer> */}

                  <div className="content-backdrop fade"></div>
                </div>
              )}
            </div>
          </div>

          <div className="layout-overlay layout-menu-toggle"></div>
        </div>

        {/* <div
          className="modal fade"
          id="ModalVideo"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog">
            <form className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">
                  Subir Videos
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="contrato">
                      Contrato
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-file-blank"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="contrato"
                        value={this.state.contratoseleccionado}
                        onChange={(e) => ""}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="latitud">
                      Latitud
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-map-pin"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="latitudV"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="longitud">
                      Longitud
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-map-pin"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="longitudV"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="fechaVideo">
                      Hora/Fecha
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-calendar"></i>
                      </span>
                      <input
                        type="datetime-local"
                        className="form-control"
                        id="fechaVideo"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="eventos">
                      Evento
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-file-blank"></i>
                      </span>
                      <select
                        className="form-select"
                        id="eventos"
                        name="location-input"
                        value={this.state.eventoseleccionado}
                        onChange={(e) => this.EditarEvento(e.target.value)}
                      >
                        {this.state.listEventos.map((loc) => (
                          <option key={loc.ID_Evento} value={loc.ID_Evento}>
                            {loc.Descripcion}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="videos">
                      Videos
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname"
                        className="input-group-text"
                      >
                        <i className="bx bx-file"></i>
                      </span>
                      <input
                        type="file"
                        className="form-control"
                        id="videos"
                        multiple
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary close"
                  data-bs-dismiss="modal"
                >
                  Cerrar
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.UploadVideo()}
                >
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div> */}


        <BaseModalAuto
          isOpen={this.state.isDialogOpenModalFoto}
          title={'Subir fotos'}
          onClose={this.onCloseModalFoto}
          childComponent={
            <ModalFoto
              data={this.state.dataGlobal}
              device={this.state.device}
              placa={this.state.placa}
              username={this.state.username}
              FKLokProyecto={this.state.FKLokProyecto}
              FKICEmpresa={this.state.FKICEmpresa}
              selectedContrato={this.state.contratoseleccionado}
              eventoSeleccionado={this.state.eventoseleccionado}
              listEventos={this.state.listEventos}
              editarEvento={this.EditarEvento}
              uploadPhoto={this.UploadPhoto}
              onClose={this.onCloseModalFoto}
            />
          }
        />

        <BaseModalAuto
          isOpen={this.state.isDialogOpenModalVideo}
          title={'Subir videos'}
          onClose={this.onCloseModalVideo}
          childComponent={
            <ModalVideo
              device={this.state.device}
              username={this.state.username}
              placa={this.state.placa}
              FKICEmpresa={this.state.FKICEmpresa}
              FKLokProyecto={this.state.FKLokProyecto}
              data={this.state.dataGlobal}
              selectedContrato={this.state.contratoseleccionado}
              eventoSeleccionado={this.state.eventoseleccionado}
              listEventos={this.state.listEventos}
              onClose={this.onCloseModalVideo}
            />}
        />


        <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasEnd" aria-labelledby="offcanvasScrollingLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasScrollingLabel">Filtros</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <div className="row">
              <div className="mb-3">
                <label className="form-label" htmlFor="desde">Desde:</label>
                <div className="input-group input-group-merge">
                  <span id="basic-icon-default-fullname2" className="input-group-text">
                    <i className="bx bx-file-blank"></i>
                  </span>
                  <input
                    type="date"
                    className="form-control"
                    id="desde"
                    value={this.state.desde}
                    // min={this.state.minDateDesde}
                    max={this.state.maxDateDesde}
                    onChange={this.handleDesdeChange}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="hasta">Hasta:</label>
                <div className="input-group input-group-merge">
                  <span id="basic-icon-default-fullname2" className="input-group-text">
                    <i className="bx bx-calendar"></i>
                  </span>
                  <input
                    type="date"
                    className="form-control"
                    id="hasta"
                    value={this.state.hasta}
                    min={this.state.minDateHasta}
                    max={this.state.maxDateHasta}
                    onChange={this.handleHastaChange}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="empresa" className="form-label">Empresa:</label>
                <div className="input-group input-group-merge">
                  <span id="basic-icon-default-fullname2" className="input-group-text"
                  ><i className="bx bx-buildings"></i></span>
                  <input
                    className="form-control"
                    list="datalistOptions"
                    id="empresa"
                    placeholder="Type to search..."
                  />
                </div>
                <datalist id="datalistOptions">
                  {this.state.listEmpresas.map((loc) => (
                    <option key={loc.IdEmpresa} data-value={loc.IdEmpresa} value={loc.NombreEmpresa}></option>
                  ))}
                </datalist>
              </div>
              <div className="mb-3">
                <label htmlFor="placa" className="form-label">Placa:</label>
                <div className="input-group input-group-merge">
                  <span id="basic-icon-default-fullname2" className="input-group-text"
                  ><i className="bx bx-car"></i></span>
                  <input
                    className="form-control"
                    id="placa"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="mb-3 d-flex flex-row g-4 align-items-end justify-content-end">
                <button type="button" className="btn btn-outline-secondary justify-content-end" onClick={this.ListarContratos} data-bs-dismiss="offcanvas">
                  <span className="tf-icons bx bx-search"></span>&nbsp; Consultar
                </button>
                <button type="button" className="btn btn-outline-secondary justify-content-end" onClick={this.limpiarFechas}>
                  Limpiar filtros
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="modal fade" id="ModalGaleria" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1">
          <div className="modal-dialog modal-xl">
            <form className="modal-content" id="ModalGaleriaW">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">FOTOS CONTRATO</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={this.eliminar}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <Gallery contrato={this.state.contratoseleccionado} infoContrato={this.state.infocontratoContainer} tracking={this.OpenTracking} monitoreo={this.OpenMonitoreo2} />
                </div>
              </div>

            </form>
          </div>
        </div> */}
        <div
          className="modal fade"
          id="ModalGaleria"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          {/* <BaseModalAuto2
          size={'xl'}
          isOpen={this.state.isDialogOpenModalGaleria}
          title="FOTOS CONTRATO"
          onClose={this.onCloseModalGaleria}
        >
          <Gallery
            contrato={this.state.contratoseleccionado}
            infoContrato={this.state.infocontratoContainer}
            tracking={this.OpenTracking}
            monitoreo={this.OpenMonitoreo2}
          />
        </BaseModalAuto2> */}


          <ModalGaleria
            isOpen={this.state.isDialogOpenModalGaleria}
            contratoseleccionado={this.state.contratoseleccionado}
            infocontratoContainer={this.state.infocontratoContainer}
            eliminar={this.eliminar}
            OpenTracking={this.OpenTracking}
            OpenMonitoreo2={this.OpenMonitoreo2}
            OpenConsolidado={this.OpenReporteConsolidado2}
          />
        </div>

        {/* <div className="modal fade" id="ModalReporteServicio" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1">
          <div className="modal-dialog modal-lg">
            <form className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <ReporteServicio contrato={this.state.infocontrato} />
                </div>
              </div>

            </form>
          </div>
        </div> */}

        <ModalReporteServicio
          isOpen={this.state.isDialogOpenModalReporteServicio}
          onClose={this.onCloseModalReporteServicio}
          childComponent={<ReporteServicio
            contrato={this.state.infocontratoAjustado}
            ultPunto={this.state.ultPunto}
            contractR={this.state.contractR}
          />}
        />
        <div
          className="modal fade"
          id="ModalReporteServicioHistorico"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          {/* <div className="modal-dialog modal-lg">
            <form className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <ReporteServicio contrato={this.state.infocontrato} ultPunto={this.state.ultPunto} contractR={this.state.contractR} />
                </div>
              </div>
            </form>
          </div> */}
          <ModalReporteServicio2
            infocontrato={this.state.infocontrato}
            ultPunto={this.state.ultPunto}
            contractR={this.state.contractR}
          />
        </div>


        <div
          className="modal fade"
          id="ModalReporteDian"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg">
            <form className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">
                  Contrato Dian
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <ReporteDian
                    isOpen={this.state.modalAbierto}
                    onClose={this.cerrarModal}
                    contratoVar={this.state.contratoDian}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* Modal edicion de contrato 🎩*/}
        <BaseModalCreateSolcitud
          isOpen={this.state.isDialogOpenEditSolicitud}
          title={'Actualizar contrato'}
          onClose={this.onCloseEditContract}
          childComponent={<FormUpdateContrato contrato={this.state.contratoseleccionado} info={this.state.infoUpdateContrato} />}
        />

        <BaseModalCreateSolcitud
          isOpen={this.state.isDialogOpenEditContratoProject}
          title={'Actualizar contrato'}
          onClose={this.onCloseEditContract}
          childComponent={<FormUpdateContratoProyecto contrato={this.state.contratoseleccionado} info={this.state.infoUpdateContrato} onClose={this.onCloseEditContract} />}
        />
        {/* Modal edicion de contrato 🎩*/}

        {/* <div
          className="modal fade"
          id="ModalComprobanteValitronics"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg">
            <form className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row" >
                  <table className="table table-hover">
                    <thead className="FondoHeaderTabla2">
                      <th style={{ color: "#ffffff" }}>Comprobante</th>
                      <th style={{ color: "#ffffff" }}>fecha</th>
                      <th style={{ color: "#ffffff" }}>Latitud</th>
                      <th style={{ color: "#ffffff" }}>Longitud</th>
                      <th style={{ color: "#ffffff" }}>Usuario</th>
                    </thead>
                    <tbody id="tablacomprobante">
                      {this.loadFillData2(this.state.listComprobantes)}
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
        </div> */}
        <ModalBaseLg
          isOpen={this.state.isDialogOpenModalComprobanteValitronics}
          title={''}
          onClose={this.onCloseModalComprobanteValitronics}
          childComponent={<ModalComprobanteValitronics
            listComprobantes={this.state.listComprobantes} />}
        />

        {/* Modal para lista de chequeo  🎩*/}
        {/* <div
          className="modal fade"
          id="ModalListaCheck"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg">
            <form className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <PrintComponent data={this.state.infocontrato} />
                </div>
              </div>
            </form>
          </div>
        </div> */}
        <BaseModalAuto
          size={'xl'}
          isOpen={this.state.isDialogOpenModalListaCheck}
          title={''}
          onClose={this.onCloseModalListaCheck}
          childComponent={<PrintComponent data={this.state.infocontrato} />}
        />

        {/* <div className="modal fade" id="ModalReporteSegimiento" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1">
          <div className="modal-dialog modal-xxl">
            <form className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">Contrato Seguimiento</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={this.cerrarModal2}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <ReporteContratoH isOpen={this.state.modalAbierto2} onClose={this.cerrarModal2} contratoVar={this.state.infocontrato2} aleatorio={Math.floor(Math.random() * 1000) + 1} images={this.OpenImages}
                    monitoreo={this.OpenMonitoreo} />
                </div>
              </div>

            </form>
          </div>
        </div> */}
        <BaseModalXXL
          isOpen={this.state.modalAbierto2}
          title={'Contrato Seguimiento'}
          onClose={this.cerrarModal2}
          loading={this.state.modalLoading}
        >
          <ReporteContratoH
            isOpen={this.state.modalAbierto2}
            onClose={this.cerrarModal2}
            contratoVar={this.state.infocontrato2}
            aleatorio={Math.floor(Math.random() * 1000) + 1}
            images={this.OpenImages}
            monitoreo={this.OpenMonitoreo}
            consolidado={this.OpenReporteConsolidado}
            videos={this.OpenVideos}
            valitronics={this.OpenValitronics}
            checkList={this.OpenListCheckModal}
          />
        </BaseModalXXL >

        <div className="alert alert-success" id="success-alert">
          <strong>Exitoso! </strong> Los cambios han sido realizados con exito.
        </div>

        <div className="alert alert-danger" id="danger-alert">
          <strong>Error! </strong> Se ha presentado un error, valide la información.
        </div>

        {/* Modal seguimiento contrato */}
        <RSeguimientoServicio
          isOpen={this.state.isDialogOpenRSeguimientoServicio}
          title={'Monitoreo de tráfico'}
          onClose={this.onCloseReporteServicio}
          childComponent={
            <FormReporteServicio
              contrato={this.state.contratoseleccionado}
              images={this.OpenImages2}
              tracking={this.OpenTracking2}
              consolidado={this.OpenReporteConsolidado} />}
        />
        {/* Modal seguimiento contrato */}

        <div
          className="modal fade"
          id="ModalVideoGaleria"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg">
            <form className="modal-content" id="ModalGaleriaW">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">
                  VIDEOS
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => { this.handleModalClose() }}
                ></button>
              </div>
              <div className="modal-body">
                <div className="container text-center">
                  <div className="row">
                    <VideoGallery contrato={this.state.contratoseleccionado} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>


      </>


    );
  }

  loadFillData(array) {

    if (this.state.showPreloader) {
      return (
        <>
          <tr>
            <td colSpan="10" className="text-center">
              <PreloaderHistorico />
            </td>
          </tr>
        </>
      )
    }

    if (this.state.filteredContratos.length === 0) {
      return (
        <tr>
          <td colSpan="10" className="text-center">
            <i className='bx bxs-spreadsheet bx-lg bx-tada-hover'></i>
            <p>No hay datos disponibles</p>
          </td>
        </tr>
      )
    }

    return this.state.filteredContratos.map((data) => {
      // Fechas en formato string
      const fechafin = data.fechafin;
      const fechainicio = data.fechainicio;

      // Convertir las fechas a objetos Date
      const fechaFinDate = new Date(fechafin);
      const fechaInicioDate = new Date(fechainicio);

      // Calcular la diferencia en milisegundos
      const diferenciaMilisegundos = fechaFinDate - fechaInicioDate;

      // Convertir la diferencia a días, horas, minutos y segundos
      const segundos = Math.floor(diferenciaMilisegundos / 1000);
      const minutos = Math.floor(segundos / 60);
      const horas = Math.floor(minutos / 60);
      const dias = Math.floor(horas / 24);

      // Ajustar los valores para obtener solo las horas, minutos y segundos restantes
      const horasRestantes = horas % 24;
      const minutosRestantes = minutos % 60;
      const segundosRestantes = segundos % 60;

      console.log(`Días: ${dias}, Horas: ${horasRestantes}, Minutos: ${minutosRestantes}, Segundos: ${segundosRestantes}`);

      return (
        <tr key={data.ContractID}>
          <td className="prueba"><strong>{data.NombreEmpresa}</strong></td>
          <td>{data.Ref}</td>
          <td>{data.PlacaTruck}</td>
          <td>{data.ContainerNum}</td>
          <td>{data.FKLokDeviceID}</td>
          <td>{data.ContractID}</td>
          <td>{data.DescripcionRuta}</td>
          <td>{formattedGlobalDate(data.LastReportTime)}</td>
          <td>{data.LastReportNota}</td>
          <td>{data.LastReportUbica}</td>
          <td>{data.TiempoServ}</td>
          <td className="textSmall"><span className="badge bg-label-warning text-wrap lh-1">{data.DescripcionTrayecto}</span></td>
          {/* <td className="oculto"> Oculto </td> */}
          <td>
            <MenuContratos
              data={data}
              handleAllStatesDropdowns={this.handleAllStatesDropdowns}
              abrirModal2={this.abrirModal2}
              showModalOpenResporteServicio={this.showModalOpenResporteServicio}
              abrirModal={this.abrirModal}
              OpenReporteServicio={this.OpenReporteServicio}
              OpenUploadPhoto={this.OpenUploadPhoto}
              OpenUploadVideo={this.OpenUploadVideo}
              OpenGalleryPhoto={this.OpenGalleryPhoto}
              OpenGalleryVideo={this.OpenGalleryVideo}
              OpenComprobante={this.OpenComprobante}
              OpenListCheck={this.OpenListCheck}
              showEditContract={this.showEditContract}
            />
          </td>

        </tr>
      )
    });
    $('.dropdown-toggle').dropdown();

  }
}

export default Historico;
