import React, { useEffect } from 'react';
import "../../App.css";
import "../../Pages/Css/trafico.css";
import MenuContratos from '../menuContratos/menuContratos';

const HistoricoResponsive = (props) => {
    const busquedaValue = localStorage.getItem('searchHistorico');
    useEffect(() => {
        console.log("Lista de contratos historico", props.listContratos);
    }, [props]);
    return (
        <div className='containerCardsHistoricos'>
            {busquedaValue ? (
                props.listContratos && props.listContratos
                    .filter(contrato =>
                        contrato.ContractID.toString().toLowerCase().includes(busquedaValue.toLowerCase()) ||
                        contrato.NombreEmpresa.toLowerCase().includes(busquedaValue.toLowerCase())
                    )
                    .map((contrato, index) => (
                        <div key={index} className='card containerContratoHistoricoInitial'>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <h5><strong>{contrato.NombreEmpresa}</strong></h5>
                                <MenuContratos
                                    data={contrato}
                                    handleAllStatesDropdowns={props.handleAllStatesDropdowns}
                                    abrirModal2={props.abrirModal2}
                                    showModalOpenResporteServicio={props.showModalOpenResporteServicio}
                                    abrirModal={props.abrirModal}
                                    OpenReporteServicio={props.OpenReporteServicio}
                                    OpenUploadPhoto={props.OpenUploadPhoto}
                                    OpenUploadVideo={props.OpenUploadVideo}
                                    OpenGalleryPhoto={props.OpenGalleryPhoto}
                                    OpenGalleryVideo={props.OpenGalleryVideo}
                                    OpenComprobante={props.OpenComprobante}
                                    OpenListCheck={props.OpenListCheck}
                                    showEditContract={props.showEditContract}
                                />
                            </div>
                            <p><span>Contrato: </span>{contrato.ContractID}</p>
                            <p><span>Dispositivo: </span> {contrato.FKLokDeviceID}</p>
                            <p><span>Ruta: </span> {contrato.DescripcionRuta}</p>
                            <p><span>Trayecto: </span></p>

                        </div>
                    ))
            ) : (
                props.listContratos && props.listContratos.map((contrato, index) => (
                    <div key={index} className='card containerContratoHistoricoInitial'>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <h5><strong>{contrato.NombreEmpresa}</strong></h5>
                            <MenuContratos
                                data={contrato}
                                handleAllStatesDropdowns={props.handleAllStatesDropdowns}
                                abrirModal2={props.abrirModal2}
                                showModalOpenResporteServicio={props.showModalOpenResporteServicio}
                                abrirModal={props.abrirModal}
                                OpenReporteServicio={props.OpenReporteServicio}
                                OpenUploadPhoto={props.OpenUploadPhoto}
                                OpenUploadVideo={props.OpenUploadVideo}
                                OpenGalleryPhoto={props.OpenGalleryPhoto}
                                OpenGalleryVideo={props.OpenGalleryVideo}
                                OpenComprobante={props.OpenComprobante}
                                OpenListCheck={props.OpenListCheck}
                                showEditContract={props.showEditContract}
                            />
                        </div>
                        <p><span>Contrato: </span>{contrato.ContractID}</p>
                        <p><span>Dispositivo: </span> {contrato.FKLokDeviceID}</p>
                        <p><span>Ruta: </span> {contrato.DescripcionRuta}</p>
                        <p><span>Trayecto: </span></p>

                    </div>
                ))
            )}
        </div>
    )
}

export default HistoricoResponsive
