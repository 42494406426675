import React, { useEffect, useState } from 'react';
import $ from 'jquery'; // Si sigues utilizando jQuery para las animaciones/alertas
import axios from '../../../utils/axiosConfig';
import { use } from 'react';

const ModalAsignacion = ({
    contratoseleccionado,
    listRutas,
    onClose,           // Función para cerrar el modal (por ejemplo, desde el padre)
    onUpdateContract,  // Función para actualizar la información luego de guardar
}) => {
    // Estados locales para manejar el contrato y el trayecto seleccionado
    const contrato = contratoseleccionado;
    const [rutaseleccionada, setRutaseleccionada] = useState(-1);

    // Maneja el cambio en el select
    const handleChange = (value) => {
        setRutaseleccionada(value);
    };

    // Función que llama a la función de editar (aquí se puede llamar a EditarRuta)
    const editarContrato = () => {
        EditarRuta();
    };

    // Función que realiza la actualización del trayecto para el contrato
    const EditarRuta = async () => {
        const data = {
            Trayecto: rutaseleccionada,
            Contrato: contrato,
        };
        const json = JSON.stringify(data);

        if (rutaseleccionada !== -1) {
            try {
                const res = await axios.post(
                    process.env.REACT_APP_SERVER + "/operaciones/updatecontratotrayecto",
                    json,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                        },
                        withCredentials: true,
                    }
                );
                if (res.data.hasOwnProperty("success")) {
                    // Muestra alerta de éxito (usando jQuery)
                    $("#success-alert")
                        .fadeTo(2000, 500)
                        .slideUp(500, function () {
                            $("#success-alert").slideUp(2000);
                        });
                    // Cierra el modal y actualiza la información (si se pasan estas funciones desde el padre)
                    $("#ModalAsignacion .close").click();
                    onUpdateContract(rutaseleccionada);
                    setRutaseleccionada(-1);
                } else {
                    $("#danger-alert")
                        .fadeTo(2000, 500)
                        .slideUp(500, function () {
                            $("#danger-alert").slideUp(2000);
                        });
                }
            } catch (error) {
                console.error("Error al actualizar el trayecto:", error);
            }
        } else {
            $("#danger-alert")
                .fadeTo(2000, 500)
                .slideUp(500, function () {
                    $("#danger-alert").slideUp(2000);
                });
        }
    };

    useEffect(() => {
        console.log("Contrato seleccionado:", contrato);
    }, [contrato]);

    return (
        <div className="modal-dialog">
            <form className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="backDropModalTitle">
                        Asignación de trayecto
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={onClose}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        {/* Campo de contrato (solo lectura) */}
                        <div className="mb-3">
                            <label className="form-label" htmlFor="contrato">
                                Contrato
                            </label>
                            <div className="input-group input-group-merge">
                                <span id="basic-icon-default-fullname2" className="input-group-text">
                                    <i className="bx bx-file-blank"></i>
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="contrato"
                                    value={contrato}
                                    readOnly
                                />
                            </div>
                        </div>
                        {/* Select para el trayecto */}
                        <div className="mb-3">
                            <label className="form-label" htmlFor="georuta">
                                Trayecto
                            </label>
                            <div className="input-group input-group-merge">
                                <span id="basic-icon-default-fullname" className="input-group-text">
                                    <i className="bx bx-trip"></i>
                                </span>
                                <select
                                    className="form-select"
                                    id="georuta"
                                    value={rutaseleccionada}
                                    onChange={(e) => handleChange(e.target.value)}
                                >
                                    <option key={-1} value={-1}>
                                        Selecciona un trayecto.
                                    </option>
                                    {listRutas &&
                                        listRutas.map((loc) => (
                                            <option key={loc.IDTrayecto} value={loc.IDTrayecto}>
                                                {loc.DescripcionTrayecto}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Botones del modal */}
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-outline-secondary close"
                        data-bs-dismiss="modal"
                        onClick={onClose}
                    >
                        Cerrar
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={editarContrato}
                    >
                        Guardar
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ModalAsignacion;
