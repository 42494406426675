import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../Pages/Css/carousel.css';
import ReactImageZoom from 'react-image-zoom';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Swal from 'sweetalert2';
import axios from '../utils/axiosConfig';
var miStorage = window.localStorage;
class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contrato: -1,
      fotos: [],
      nfotos: -1,
      currentIndex: 0,
      selectedItem: 0,
      isZoomed: false,
      mouseX: 0,
      mouseY: 0,
      predictionObject: [],
      comparision: {},
      fotosV: [],
      infoContainerNum: '',
      containerCode: '',
      containerOwner: '',
      visibleContain: false,

    };
    // this.handleChangePredictionColor = this.handleChangePredictionColor.bind(this);
    this.imageRef = React.createRef();

  }

  componentDidMount() {
    this.setState({ contrato: this.props.contrato });

    // this.getFotosContratoVisualLogistic(this.props.contrato);
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevState.currentIndex !== this.state.currentIndex) {
      this.loadAllImageAnalitycs(this.state.currentIndex);
    }
    if (this.props.contrato !== prevProps.contrato) {
      console.log("contrato propps:", this.props.contrato);
      console.log("Informacion contrato general props: ", this.props.infoContrato);
      const formattedContainerNum = this.props.infoContrato && this.props.infoContrato.replace(' ', '');
      console.log(formattedContainerNum);
      (async () => {
        this.setState({ contrato: this.props.contrato, fotos: [], fotosV: [], infoContainerNum: formattedContainerNum, });
        await this.getFotos(this.props.contrato);
        await this.getInfoAditionalContrato();
        await this.getFotosContratoVisualLogistic(this.props.contrato);
        // await this.loadAllImageAnalitycs(this.state.currentIndex);
      })();

    }

    if (prevState.fotosV !== this.state.fotosV) {
      console.log("Lo aplico aca ");
      this.loadAllImageAnalitycs(this.state.currentIndex);
    }
  }


  //TODO: Cambiar el endpoint para traer las fotos de acuerdo al contrato
  async getInfoAditionalContrato() {
    let token = localStorage.getItem('tkn');
    var contrato = {
      "contrato": this.props.contrato
    };
    console.log("Contrato a utilizar: ", contrato);
    axios.post(process.env.REACT_APP_SERVER + '/operaciones/getcontractvisuallogistic', JSON.stringify(contrato), {
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      withCredentials: true

    }).then((response) => {
      const comparisonResult = response.data.info.assets[0]?.comparison[0]?.result || {}; // Using optional chaining
      console.log(comparisonResult);
      this.setState({ comparision: comparisonResult });
    }).catch((err) => {
      console.log(err);
    });
  }

  ocultar() {
    const objectBoxes = document.querySelectorAll(".object-box");
    objectBoxes.forEach(objectBox => {
      objectBox.style.display = "none";
    });
  }

  mostrar() {
    const objectBoxes = document.querySelectorAll(".object-box");
    objectBoxes.forEach(objectBox => {
      objectBox.style.display = "block";
    });
  }

  eliminar() {
    var elementos = document.getElementsByClassName('object-box');
    var elementosArray = Array.from(elementos);
    elementosArray.forEach(function (elemento) {
      elemento.parentNode.removeChild(elemento);
    });

    elementos = document.getElementsByClassName('object-box2');
    elementosArray = Array.from(elementos);
    elementosArray.forEach(function (elemento) {
      elemento.parentNode.removeChild(elemento);
    });

    elementos = document.getElementsByClassName('object-box3');
    elementosArray = Array.from(elementos);
    elementosArray.forEach(function (elemento) {
      elemento.parentNode.removeChild(elemento);
    });
  }

  mostrarInfo(f) {
    console.log("Info FOto: ", f);
    document.getElementById("infofoto").innerHTML = f.Evento + "<br/>" + this.state.infoContainerNum + " - " + f.Descripcion + "<br/>" + f.Latitud + " , " + f.Longitud + "<br/>" + f.Nombreempresa + "<br/>" + f.Hora;
  }

  handleMouseEnter = (e) => {
    this.setState({ isZoomed: true });

    this.handleMouseMove(e);
  };

  handleMouseLeave = () => {
    this.setState({ isZoomed: false });
  };

  handleMouseMove = (e) => {
    const { isZoomed } = this.state;

    if (isZoomed) {
      const rect = e.target.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;

      this.setState({ mouseX, mouseY });
    }
  };

  handleChange = (currentIndex, event) => {
    this.setState({ currentIndex: currentIndex, selectedItem: currentIndex });
    this.eliminar();
    const infojson = document.getElementById('infojson');
    infojson.innerHTML = "";
    var fotos = this.state.fotos;
    var f = fotos[currentIndex];
    // console.log("Posicion actual: ", currentIndex);
    // console.log("Foto seleccionada: ", this.state.selectedItem);
    this.mostrarInfo(f);

  };

  /* 
  Funcion adapatada para traer toda la informacion y predicciones
  desde un contrato
  */
  getFotosContratoVisualLogistic = async (contrato) => {
    try {
      console.log("El contrato: ", contrato);
      console.log("Server URL: ", process.env.REACT_APP_SERVER);
      let servicioContrato = {
        contrato: contrato,
      }
      let token = localStorage.getItem('tkn');
      if (!token) {
        throw new Error("Token no encontrado en localStorage");
      }

      // Usamos await para esperar la resolución de la promesa
      const response = await axios.post(process.env.REACT_APP_SERVER + '/operaciones/getfotoscontractvisuallogistic',
        JSON.stringify(servicioContrato),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      console.log("Respuesta nuevo endpoint: ", response.data);
      console.log("Respuesta nuevo endpoint predictions: ", response.data.info.contract_predictions);
      // console.log("IAtem currentIndex e info: " + this.state.currentIndex + " selectedItmen info: " + this.state.selectedItem);
      this.setState({ fotosV: response.data.info.contract_predictions })

    } catch (error) {
      console.error("Error al consumir el endpoint: ", error);
    }
  }


  /* Funcion para cargar las predicciones de acuerdo a la visualLogisitc 
  en el caso que lo tenga
  */
  loadAllImageAnalitycs = async (currentIndex) => {
    this.eliminar();
    // console.log("initial state fotosV: ", this.state.fotosV);
    let nameFotoActual = this.state.fotos && this.state.fotos[currentIndex]?.photo;
    // console.log("el name de la foto actual es: ", nameFotoActual);
    let infoByFotoActual = this.state.fotosV.find(photo => photo.name === nameFotoActual);
    let fotos = this.state.fotos;
    let f = fotos[this.state.currentIndex];
    let photo = document.getElementById(f?.IDPhoto);

    // console.log("IDPHOTO INFO =>", photo);
    console.log("IMAGE ANALITYCS => Informacion de carga asociada a la foto actual **", infoByFotoActual);
    const infojson = document.getElementById('infojson');
    const infoAddJson = document.getElementById('infofoto2')
    infojson.innerHTML = "";
    infoAddJson.innerHTML = `<p>No hay información adicional asociada</p>`;
    const img = new Image();
    // Función para sanitizar id
    function sanitizeId(id) {
      return id.replace(/[^a-zA-Z0-9-_]/g, '_');
    }
    img.src = f?.Source;
    // img.className = 'image-container'
    img.onload = () => {
      this.setState({
        containerCode: '',
        containerOwner: '',
        visibleContain: false,
      });
      console.log("IMAGE ANALITYCS => Ancho:", img.width);
      try {
        if (!infoByFotoActual) {
          throw new Error(`No se encontró información asociada para la imagen: ${nameFotoActual}`);
        }
        //Lista para manejar la vista de la lista bruta en acordeon
        var predictions = infoByFotoActual?.predictions;
        console.log("Predicciones desde el global: ==>", predictions);
        //TODO: cambiar en produccion

        console.log("IMAGE ANALITYCS => predictions", predictions);
        predictions.forEach((prediction, index) => {
          const sanitizedTagName = sanitizeId(prediction.tag_name);
          var cadena = `
          <div class="accordion-item">
            <div class="accordion-header">
              <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#collapse-${index}" aria-expanded="true">
                ${prediction.tag_name}
              </button>
            </div>
            <div id="collapse-${index}" class="accordion-collapse collapse show">
              <div class="accordion-body">
                <ul>
        `;

          const tagname = prediction.tag_name;
          console.log("pruebatag", tagname);
          const boundingBox = prediction.bounding_box;
          var width = boundingBox.right - boundingBox.left;
          var height = boundingBox.bottom - boundingBox.top;
          console.log(width + "   -   " + height);
          const modalPrueba = document.getElementById('prueba');
          const modalContainer = document.getElementById('ModalGaleriaW');
          const panelinfo = document.getElementById('panelinfo');
          const panelinfo2 = document.getElementById('panelinfo2');


          //const modalRect = modalContainer.getBoundingClientRect();
          const imageRect = photo.getBoundingClientRect();
          const offsetX = imageRect.left; // Offset en el eje X
          const offsetY = imageRect.top; // Offset en el eje Y
          const offsetW = imageRect.width;
          const offsetH = imageRect.height;
          var diferenciaoriginalW = imageRect.width / img.width;
          var diferenciaoriginalH = imageRect.height / img.height;


          const objectBox = document.createElement("div");
          objectBox.className = "object-box";
          objectBox.title = prediction.tag_name;
          objectBox.id = `box-${index}`; // Asignar un ID único
          objectBox.style.border = "2px solid blue";
          // Establece las dimensiones del cuadro después de la rotación
          objectBox.style.width = width * diferenciaoriginalW + "px";
          objectBox.style.height = height * diferenciaoriginalH + "px";

          var body = document.body;
          console.log("w. imagen: " + offsetW + " | " + "w. body: " + body.clientWidth + " | " + "w. modal: " + modalContainer.clientWidth + "| w. panel: " + panelinfo.clientWidth);
          console.log("h. imagen: " + offsetH + " | " + "h. body: " + body.clientHeight + " | " + "h. modal: " + modalContainer.clientHeight);
          // Verificar el diseño responsive
          var isResponsive = window.matchMedia("(max-width: 768px)").matches;

          var restanteW, restanteH;
          if (isResponsive) {
            // Ajuste para diseño en columna (responsive)
            restanteW = ((modalContainer - offsetW) / 2);
            restanteH = (((modalContainer) - offsetH - 3) / 2);
            console.log("IMAGE ANALITYCS => is resposnive ", restanteW, " ", restanteH);
          } else {
            // Ajuste para diseño en fila (no responsive)
            restanteW = ((modalContainer.clientWidth - offsetW) / 2) + panelinfo.clientWidth - 250;
            restanteH = (((modalContainer.clientHeight) - offsetH - 3) / 2);
          }



          console.log(restanteW + "   restante+   " + offsetX + "   -    " + boundingBox[0]);
          console.log(restanteH + "   restante+   " + offsetY + "   -    " + boundingBox[1]);
          //const left = (boundingBox[0]*diferenciaoriginalW) + offsetX;
          let left, top;

          if (isResponsive) {
            left = (boundingBox.left * diferenciaoriginalW) + 35;
            top = (boundingBox.top * diferenciaoriginalH);
          } else {
            left = (boundingBox.left * diferenciaoriginalW) + restanteW - ((modalContainer.clientWidth - modalPrueba.clientWidth));
            top = (boundingBox.top * diferenciaoriginalH) + restanteH - ((modalContainer.clientHeight - modalPrueba.clientHeight));
          }

          console.log("IMAGE ANALITYCS: => top boundingbox", top);
          //(boundingBox[1]*diferenciaoriginalH)

          console.log(left + "   resultado+   " + top);
          console.log(boundingBox[0] + " {left} " + offsetX);
          console.log(boundingBox[1] + " {top} " + offsetY);
          objectBox.style.position = "absolute";
          objectBox.style.left = left + "px";
          objectBox.style.top = top + "px";
          // Agrega el elemento .object-box al contenedor
          photo.parentNode.appendChild(objectBox);

          console.log("*?* ", prediction);
          const attributes = prediction.attributes;
          let hasAditionalInfo = false;
          console.log("IMAGE ANALITYCS => attributes", attributes);
          console.log("IMAGE ANALITYCS => tipo de datos attributes", typeof attributes);
          let containerCode = '';
          let containerOwner = '';
          attributes.forEach((attribute, attrIndex) => {
            const name = attribute.name;
            console.log("pruebatag ", name);
            console.log("*?* length ", attributes.length);
            const value = attribute.value;
            // if (name.includes('Container_Code')) {
            //   this.setState({
            //     containerCode: value,
            //   })
            // }
            // if (name.includes('Container_Owner')) {
            //   this.setState({
            //     containerOwner: value,
            //   })
            // }

            // if (this.state.containerCode !== '' && this.state.containerOwner !== '') {
            //   const formattedFusion = this.state.containerOwner.replace(' ', '') + this.state.containerCode.replace(' ', '');
            //   console.log("formatted fussion :", formattedFusion);
            //   if (formattedFusion === this.state.infoContainerNum) {
            //     this.setState({ visibleContain: true });
            //   }
            // }


            // cadena += "<div class='attribute_class'>" + name + "<ul>";

            const content = value; // Cambio aquí: asigno value a content
            console.log("IMAGE ANALITYCS => content tipo de variable: ", typeof content);
            console.log("IMAGE ANALITYCS => content", content);
            let countString = 0;
            if (Array.isArray(content)) {
              content.forEach((contenido, contentIndex) => {
                cadena += "<div class='attribute_class'>" + name + "<ul>";
                cadena += `<li class='content_content' data-box-id='box-${sanitizeId(contenido.content)}'>${contenido.content}</li>`;
                const boundingBox2 = contenido.boundingBox;
                console.log(boundingBox);
                const width2 = Math.sqrt((boundingBox2[2] - boundingBox2[0]) ** 2 + (boundingBox2[3] - boundingBox2[1]) ** 2);
                const height2 = Math.sqrt((boundingBox2[6] - boundingBox2[0]) ** 2 + (boundingBox2[7] - boundingBox2[1]) ** 2);
                const angle2 = Math.atan2(boundingBox2[3] - boundingBox2[1], boundingBox2[2] - boundingBox2[0]);

                if (name.includes('Container_Code')) {
                  console.log(name);
                  containerCode = contenido.content.replace(' ', '');
                }

                if (name.includes('Container_Owner')) {
                  console.log(name);
                  containerOwner = contenido.content.replace(' ', '');
                }

                if (containerCode !== '' && containerOwner !== '') {
                  const formattedFusion = containerOwner + containerCode.slice(0, 6);
                  console.log("formatted fussion :", formattedFusion);
                  console.log(this.state.infoContainerNum.slice(4, 10));
                  const formattedContainerNum = this.state.infoContainerNum.slice(0, 10);
                  if (formattedFusion === formattedContainerNum) {
                    this.setState({
                      containerCode: containerCode,
                      containerOwner: containerOwner,
                      visibleContain: true,
                    });
                  } else {
                    this.setState({
                      containerCode: containerCode,
                      containerOwner: containerOwner,
                      visibleContain: false,
                    });
                  }
                }

                const modalPrueba2 = document.getElementById('prueba');
                const modalContainer2 = document.getElementById('ModalGaleriaW');
                const panelinfo2 = document.getElementById('panelinfo');
                //const modalRect = modalContainer.getBoundingClientRect();
                const imageRect2 = photo.getBoundingClientRect();
                const offsetX2 = imageRect.left; // Offset en el eje X
                const offsetY2 = imageRect.top; // Offset en el eje Y
                const offsetW2 = imageRect.width;
                const offsetH2 = imageRect.height;
                var diferenciaoriginalW2 = imageRect2.width / img.width;
                var diferenciaoriginalH2 = imageRect2.height / img.height;

                const objectBox = document.createElement("div");
                objectBox.className = "object-box2";
                objectBox.title = contenido.content;
                objectBox.id = `box-${sanitizeId(contenido.content)}`; // Asignar un ID único
                objectBox.style.transformOrigin = "top left";
                objectBox.style.border = "2px solid #6ABFF4";
                objectBox.style.transform = `rotate(${angle2}rad)`;

                // Establece las dimensiones del cuadro después de la rotación
                objectBox.style.width = width2 * diferenciaoriginalW2 + "px";
                objectBox.style.height = height2 * diferenciaoriginalH2 + "px";

                var body = document.body;

                // Verificar el diseño responsive
                var isResponsive = window.matchMedia("(max-width: 768px)").matches;

                var restanteW2, restanteH2;
                if (isResponsive) {
                  console.log("IMAGE ANALITYCS => is resposnive");
                  // Ajuste para diseño en columna (responsive)
                  restanteW2 = ((modalContainer2 - offsetW) / 2);
                  restanteH2 = (((modalContainer2) - offsetH - 3) / 2);
                } else {
                  // Ajuste para diseño en fila (no responsive)
                  restanteW2 = ((modalContainer2.clientWidth - offsetW) / 2) + panelinfo.clientWidth - 250;
                  restanteH2 = (((modalContainer2.clientHeight) - offsetH - 3) / 2);
                }

                // const left2 = (boundingBox2[0] * diferenciaoriginalW2) + restanteW2 - ((modalContainer2.clientWidth - modalPrueba2.clientWidth));
                // const top2 = (boundingBox2[1] * diferenciaoriginalH2) + restanteH2 - ((modalContainer2.clientHeight - modalPrueba2.clientHeight));;
                console.log("IMAGE ANALITYCS: => top2 boundingbox", top2);
                var left2, top2;

                if (isResponsive) {
                  left2 = (boundingBox2[0] * diferenciaoriginalW2) + 35;
                  top2 = (boundingBox2[1] * diferenciaoriginalH2);
                } else {
                  left2 = (boundingBox2[0] * diferenciaoriginalW2) + restanteW2 - ((modalContainer2.clientWidth - modalPrueba2.clientWidth));
                  top2 = (boundingBox2[1] * diferenciaoriginalH2) + restanteH2 - ((modalContainer2.clientHeight - modalPrueba2.clientHeight));
                }

                objectBox.style.position = "absolute";
                objectBox.style.left = left2 + "px";
                objectBox.style.top = top2 + "px";
                // Agrega el elemento .object-box al contenedor
                photo.parentNode.appendChild(objectBox);
                //document.querySelector("body").parentNode.appendChild(objectBox);

                this.mostrar();
                attribute.value.forEach(contenido => {
                  if (infoAddJson.innerHTML.includes("No hay información adicional asociada")) {
                    // infoAddJson.innerHTML = "";
                  }
                  hasAditionalInfo = true;
                  attributeAddHtml += `<li class='attribute_content' >${contenido.content}</li>`;
                });

                // infoAddJson.innerHTML = `<p>No hay información adicional asociada</p>`;
                console.log("pruebatag ", attributeAddHtml);
              });
            } else if (typeof content === 'string') {
              countString++;
              // Si es una cadena, crea un nuevo elemento HTML para mostrar su valor
              console.log("pruebatag cadena string entre en el metodo");
              let iconHTML = '';
              if (name === 'ID') {
                iconHTML = `<i class='bx bx-id-card bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (name === 'color') {
                iconHTML = `<i class='bx bxs-color-fill bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (name === 'status') {
                iconHTML = `<i class='bx bx-stats bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (name === 'condition') {
                iconHTML = `<i class='bx bx-box bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }

              if (name === 'door_status') {
                iconHTML = `<i class='bx bx-door-open bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              var attributeValueHtml = `<div class='attribute_class'><span class='container_span_iconName'>${iconHTML} <span class='color_name_attribute'>${name.toUpperCase()}</span></span>${content}</div>`;
              if (infoAddJson.innerHTML.includes("No hay información adicional asociada")) {
                infoAddJson.innerHTML = "";
              }
              // Agrega el nuevo elemento al contenedor infoAddJson
              hasAditionalInfo = true;
              infoAddJson.innerHTML += attributeValueHtml;

              console.log("pruebatag ", attributeValueHtml);
            } else {
              console.error("attribute.value no es un array:", value); // Cambio aquí: uso value en lugar de attribute.value

              cadena += "</ul></div>";
              // Añadir al infoAddJson
              let iconHTML = '';
              if (attribute.name === 'ID') {
                iconHTML = `<i class='bx bx-id-card bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (attribute.name === 'color') {
                iconHTML = `<i class='bx bxs-color-fill bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (attribute.name === 'status') {
                iconHTML = `<i class='bx bx-stats bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (attribute.name === 'condition') {
                iconHTML = `<i class='bx bx-box bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }

              if (attribute.name === 'door_status') {
                iconHTML = `<i class='bx bx-door-open bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              var attributeAddHtml = `
                  <div class='attribute_class'>
                      <span>${iconHTML} <span>${(attribute.name).toUpperCase()}:</span> ${attribute.value}</span>
                    <ul>
                `;
              // // attribute.value.forEach(contenido => {
              // //   attributeAddHtml += `<li class='attribute_content' >${contenido.content}</li>`;
              // // });
              attributeAddHtml += `</ul></div>`;
              console.log("pruebatag ", attributeAddHtml);
            }

            console.log("Contador de cadenas: ", countString);
          });



          const words = prediction.lines;
          console.log(words);
          words.forEach((word, wordIndex) => {
            cadena += `<li class='word_content' data-box-id='box-${sanitizeId(word.content)}-${wordIndex}'>${word.content}</li>`;
            const boundingBox2 = word.boundingBox;
            console.log(boundingBox);
            const width2 = Math.sqrt((boundingBox2[2] - boundingBox2[0]) ** 2 + (boundingBox2[3] - boundingBox2[1]) ** 2);
            const height2 = Math.sqrt((boundingBox2[6] - boundingBox2[0]) ** 2 + (boundingBox2[7] - boundingBox2[1]) ** 2);
            const angle2 = Math.atan2(boundingBox2[3] - boundingBox2[1], boundingBox2[2] - boundingBox2[0]);

            const modalPrueba2 = document.getElementById('prueba');
            const modalContainer2 = document.getElementById('ModalGaleriaW');
            const panelinfo2 = document.getElementById('panelinfo');
            //const modalRect = modalContainer.getBoundingClientRect();
            const imageRect2 = photo.getBoundingClientRect();
            const offsetX2 = imageRect.left; // Offset en el eje X
            const offsetY2 = imageRect.top; // Offset en el eje Y
            const offsetW2 = imageRect.width;
            const offsetH2 = imageRect.height;
            var diferenciaoriginalW2 = imageRect2.width / img.width;
            var diferenciaoriginalH2 = imageRect2.height / img.height;

            const objectBox = document.createElement("div");
            objectBox.className = "object-box2";
            objectBox.title = word.content;
            objectBox.id = `box-${sanitizeId(word.content)}-${wordIndex}`; // Asignar un ID único
            objectBox.style.transformOrigin = "top left";
            objectBox.style.transform = `rotate(${angle2}rad) scale(1)`;
            objectBox.style.border = "2px solid red";


            // Establece las dimensiones del cuadro después de la rotación
            objectBox.style.width = width2 * diferenciaoriginalW2 + "px";
            objectBox.style.height = height2 * diferenciaoriginalH2 + "px";

            var body = document.body;

            // Verificar el diseño responsive
            var isResponsive = window.matchMedia("(max-width: 768px)").matches;

            var restanteW2, restanteH2;


            if (isResponsive) {
              console.log("IMAGE ANALITYCS => is resposnive");
              // Ajuste para diseño en columna (responsive)
              restanteW2 = ((modalContainer2 - offsetW) / 2);
              restanteH2 = (((modalContainer2) - offsetH - 3) / 2);
            } else {
              // Ajuste para diseño en fila (no responsive)
              restanteW2 = ((modalContainer2.clientWidth - offsetW2) / 2) + panelinfo.clientWidth - 250;
              restanteH2 = (((modalContainer2.clientHeight) - offsetH2 - 3) / 2);
            }

            // const left2 = (boundingBox2[0] * diferenciaoriginalW2) + restanteW2 - ((modalContainer2.clientWidth - modalPrueba2.clientWidth));
            // const top2 = (boundingBox2[1] * diferenciaoriginalH2) + restanteH2 - ((modalContainer2.clientHeight - modalPrueba2.clientHeight));
            console.log("IMAGE ANALITYCS: => top3 boundingbox", top2);
            var left2, top2;

            if (isResponsive) {
              left2 = (boundingBox2[0] * diferenciaoriginalW2) + 35;
              top2 = (boundingBox2[1] * diferenciaoriginalH2);
            } else {
              left2 = (boundingBox2[0] * diferenciaoriginalW2) + restanteW2 - ((modalContainer2.clientWidth - modalPrueba2.clientWidth));
              top2 = (boundingBox2[1] * diferenciaoriginalH2) + restanteH2 - ((modalContainer2.clientHeight - modalPrueba2.clientHeight));
            }
            objectBox.style.position = "absolute";
            objectBox.style.left = left2 + "px";
            objectBox.style.top = top2 + "px";
            // Agrega el elemento .object-box al contenedor
            photo.parentNode.appendChild(objectBox);
            //document.querySelector("body").parentNode.appendChild(objectBox);


            this.mostrar();
          });

          if (cadena.includes("<li>")) {
            cadena += "</ul></div>";
          } else {
            cadena.replace("<ul>", "");
            cadena += "</div>";
          }
          infojson.innerHTML += cadena;

        });

        //Manejo de cambio de colores cuando se selscciona un item de la lista del acorrdion

        /* Esta funcion cambia de colores los cuadros que se elige en la lista del analisiss de la imagen
         para poder intercatuar con los boundinboxes del lado de la lista desplegada  
        */

        document.querySelectorAll('.word_content, .attribute_content, .content_content').forEach(wordContentActive => {
          wordContentActive.addEventListener('click', function () {
            const clickedWord = this.dataset.boxId;
            console.warn("IMAGE ANALITYCS => item clickeado: " + clickedWord);

            // Restablecer el color de todos los recuadros
            document.querySelectorAll('.object-box2').forEach(box => {
              box.style.borderColor = ""; // Restablecer el color del borde
              box.style.animation = ""; // Restablecer la animación

            });

            // Cambiar el color del borde del recuadro correspondiente
            const box = document.getElementById(clickedWord);
            if (box) {
              box.style.borderColor = "green"; // Cambiar el color del borde aquí

              // Obtener el ángulo de rotación del cuadro
              const angle2 = window.getComputedStyle(box).getPropertyValue('transform');
              // console.log("IMAGE ANALITYCS => Grados de tranfpormacion cuadro: ", angle2);

              // Aplicar la animación con el ángulo de rotación
              const pulseAnimation = `
                @keyframes pulseAnimation {
                    0% { transform: ${angle2} scale(1); }
                    50% { transform: ${angle2} scale(1.1); }
                    100% { transform: ${angle2} scale(1); }
                }
            `;

              // Crear un estilo para la animación
              const style = document.createElement('style');
              style.type = 'text/css';
              style.innerHTML = pulseAnimation;

              // Agregar el estilo al head del documento
              document.head.appendChild(style);

              // Aplicar la animación al cuadro
              box.style.animation = "pulseAnimation 1s ease-in-out infinite";
            }
          });
        });




        // Manejo del estado de colapso del acordeón
        document.querySelectorAll('.accordion-button').forEach((button, index) => {
          button.classList.add('active'); // Añadir la clase active inicialmente
          const targetId = `box-${index}`;
          console.log("IMAGE ANALITYCS => acorrdion taqgandme :", targetId);
          button.addEventListener('click', function () {
            const collapseElement = document.getElementById(button.getAttribute('data-bs-target').substring(1));
            const boxId = `box-${index}`;

            collapseElement.addEventListener('hidden.bs.collapse', function () {
              button.classList.add('collapsed');
              button.classList.remove('active'); // Add active class when expanded
              resetBoxStyles();
              highlightBox(boxId);

              setTimeout(() => {
                hideBackdrop(boxId);
              }, 500);
            });

            collapseElement.addEventListener('shown.bs.collapse', function () {
              button.classList.remove('collapsed');
              button.classList.add('active'); // Remove active class when collapsed
              resetBoxStyles();
              highlightBox(boxId);

              setTimeout(() => {
                hideBackdrop(boxId);
              }, 500);
            });
          });

          // Restablecer el color del borde de todos los cuadros
        });

        // Restablecer el color y el grosor del borde de todos los cuadros cuando se hace clic en cualquier otro elemento
        document.addEventListener('click', function (event) {
          if (!event.target.classList.contains('accordion-button')) {
            resetBoxStyles();
            hideBackdrop();
          }
        });

        function resetBoxStyles() {
          document.querySelectorAll('.object-box, object-box2').forEach(box => {
            box.style.borderColor = ""; // Restablecer el color del borde
            box.style.borderWidth = "2px"; // Restablecer el grosor del borde
          });
        }

        function highlightBox(boxId) {
          const box = document.getElementById(boxId);
          if (box) {
            box.style.borderColor = "blue"; // Cambiar el color del borde aquí
            box.style.borderWidth = "4px"; // Cambiar el grosor del borde aquí
            box.style.backgroundColor = 'rgba(0, 0, 255, 0.5)'
          }
        }




        function hideBackdrop(boxId) {

          const masks = document.querySelectorAll('.mask');
          // console.log("IMAGE ANALITYCS => boxId: ", boxId);
          const box = document.getElementById(boxId);
          if (box) {
            box.style.borderColor = "blue"; // Cambiar el color del borde aquí
            box.style.borderWidth = "2px"; // Cambiar el grosor del borde aquí
            box.style.backgroundColor = ''
          }
          // Remover todos los backdrops y máscaras


          masks.forEach(mask => {
            mask.remove();
          });
        }





      } catch (error) {
        console.error("Error al obtener datos JSON:", error);
      }
    };
  }

  handleClick = async (event) => {
    event.preventDefault();
    this.eliminar();
    const infojson = document.getElementById('infojson');
    const infoAddJson = document.getElementById('infofoto2')
    infojson.innerHTML = "";
    infoAddJson.innerHTML = `<p>No hay información adicional asociada</p>`;
    // console.log(`El índice de la 1foto actual es: ${this.state.currentIndex}`);
    var fotos = this.state.fotos;
    var f = fotos[this.state.currentIndex];
    console.log("foto Info: ", f);
    // console.log("info++", this.state.infoContainerNum);
    // console.log("info++", this.state.containerCode);
    // console.log("info++", this.state.containerOwner);

    document.getElementById("infofoto").innerHTML = f.Evento + "<br/>" + this.state.infoContainerNum + " - " + f.Descripcion + "<br/>" + f.Latitud + " , " + f.Longitud + "<br/>" + f.Nombreempresa + "<br/>" + f.Hora;

    var photo = document.getElementById(f.IDPhoto);
    // console.log("Photo onclick ID: ", photo);

    const img = new Image();
    // Función para sanitizar id
    function sanitizeId(id) {
      return id.replace(/[^a-zA-Z0-9-_]/g, '_');
    }

    var jsonData = (await this.getFotosjSON(f.photo));
    // console.log("datos de la prediccion: ", jsonData);

    img.src = f.Source;
    img.onload = () => {
      console.log("Ancho:", img.width);
      try {
        //Lista para manejar la vista de la lista bruta en acordeon
        var predictions = jsonData.predictions;
        console.log(predictions);

        console.log("IMAGE ANALITYCS => predictions", predictions);
        predictions.forEach((prediction, index) => {
          const sanitizedTagName = sanitizeId(prediction.tag_name);
          var cadena = `
          <div class="accordion-item">
            <div class="accordion-header">
              <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#collapse-${index}" aria-expanded="true">
                ${prediction.tag_name}
              </button>
            </div>
            <div id="collapse-${index}" class="accordion-collapse collapse show">
              <div class="accordion-body">
                <ul>
        `;

          const tagname = prediction.tag_name;
          const boundingBox = prediction.bounding_box;
          var width = boundingBox.right - boundingBox.left;
          var height = boundingBox.bottom - boundingBox.top;
          console.log(width + "   -   " + height);
          const modalPrueba = document.getElementById('prueba');
          const modalContainer = document.getElementById('ModalGaleriaW');
          const panelinfo = document.getElementById('panelinfo');
          const panelinfo2 = document.getElementById('panelinfo2');


          //const modalRect = modalContainer.getBoundingClientRect();
          const imageRect = photo.getBoundingClientRect();
          const offsetX = imageRect.left; // Offset en el eje X
          const offsetY = imageRect.top; // Offset en el eje Y
          const offsetW = imageRect.width;
          const offsetH = imageRect.height;
          var diferenciaoriginalW = imageRect.width / img.width;
          var diferenciaoriginalH = imageRect.height / img.height;


          const objectBox = document.createElement("div");
          objectBox.className = "object-box";
          objectBox.title = prediction.tag_name;
          objectBox.id = `box-${index}`; // Asignar un ID único
          objectBox.style.border = "2px solid blue";
          // Establece las dimensiones del cuadro después de la rotación
          objectBox.style.width = width * diferenciaoriginalW + "px";
          objectBox.style.height = height * diferenciaoriginalH + "px";

          var body = document.body;
          console.log("w. imagen: " + offsetW + " | " + "w. body: " + body.clientWidth + " | " + "w. modal: " + modalContainer.clientWidth + "| w. panel: " + panelinfo.clientWidth);
          console.log("h. imagen: " + offsetH + " | " + "h. body: " + body.clientHeight + " | " + "h. modal: " + modalContainer.clientHeight);
          //var restanteW = ((body.clientWidth-modalContainer.clientWidth)/2)+((modalContainer.clientWidth-offsetW)/2);

          // Verificar el diseño responsive
          var isResponsive = window.matchMedia("(max-width: 768px)").matches;

          var restanteW, restanteH;
          if (isResponsive) {
            // Ajuste para diseño en columna (responsive)
            restanteW = ((modalContainer - offsetW) / 2);
            restanteH = (((modalContainer) - offsetH - 3) / 2);
            console.log("IMAGE ANALITYCS => is resposnive ", restanteW, " ", restanteH);
          } else {
            // Ajuste para diseño en fila (no responsive)
            restanteW = ((modalContainer.clientWidth - offsetW) / 2) + panelinfo.clientWidth - 250;
            restanteH = (((modalContainer.clientHeight) - offsetH - 3) / 2);
          }


          console.log(restanteW + "   restante+   " + offsetX + "   -    " + boundingBox[0]);
          console.log(restanteH + "   restante+   " + offsetY + "   -    " + boundingBox[1]);
          //const left = (boundingBox[0]*diferenciaoriginalW) + offsetX;
          let left, top;

          if (isResponsive) {
            left = (boundingBox.left * diferenciaoriginalW) + 35;
            top = (boundingBox.top * diferenciaoriginalH);
          } else {
            left = (boundingBox.left * diferenciaoriginalW) + restanteW - ((modalContainer.clientWidth - modalPrueba.clientWidth));
            top = (boundingBox.top * diferenciaoriginalH) + restanteH - ((modalContainer.clientHeight - modalPrueba.clientHeight));
          }
          //(boundingBox[1]*diferenciaoriginalH)

          console.log(left + "   resultado+   " + top);
          console.log(boundingBox[0] + " {left} " + offsetX);
          console.log(boundingBox[1] + " {top} " + offsetY);
          objectBox.style.position = "absolute";
          objectBox.style.left = left + "px";
          objectBox.style.top = top + "px";
          // Agrega el elemento .object-box al contenedor
          photo.parentNode.appendChild(objectBox);

          console.log("*?* ", prediction);
          const attributes = prediction.attributes;
          let hasAditionalInfo = false;
          console.log("IMAGE ANALITYCS => attributes", attributes);
          console.log("IMAGE ANALITYCS => tipo de datos attributes", typeof attributes);
          let containerCode = '';
          let containerOwner = '';
          attributes.forEach((attribute, attrIndex) => {
            const name = attribute.name;
            console.log("*?* ", name);
            console.log("*?* length ", attributes.length);
            const value = attribute.value;
            console.log(value);
            // cadena += "<div class='attribute_class'>" + name + "<ul>";


            const content = value; // Cambio aquí: asigno value a content
            console.log("IMAGE ANALITYCS => content tipo de variable: ", typeof content);
            console.log("IMAGE ANALITYCS => content", content);
            if (Array.isArray(content)) {
              content.forEach((contenido, contentIndex) => {
                cadena += "<div class='attribute_class'>" + name + "<ul>";
                cadena += `<li class='content_content' data-box-id='box-${sanitizeId(contenido.content)}'>${contenido.content}</li>`;
                const boundingBox2 = contenido.boundingBox;
                console.log(boundingBox);
                const width2 = Math.sqrt((boundingBox2[2] - boundingBox2[0]) ** 2 + (boundingBox2[3] - boundingBox2[1]) ** 2);
                const height2 = Math.sqrt((boundingBox2[6] - boundingBox2[0]) ** 2 + (boundingBox2[7] - boundingBox2[1]) ** 2);
                const angle2 = Math.atan2(boundingBox2[3] - boundingBox2[1], boundingBox2[2] - boundingBox2[0]);

                if (name.includes('Container_Code')) {
                  console.log(name);
                  containerCode = contenido.content.replace(' ', '');
                }

                if (name.includes('Container_Owner')) {
                  console.log(name);
                  containerOwner = contenido.content.replace(' ', '');
                }

                if (containerCode !== '' && containerOwner !== '') {
                  const formattedFusion = containerOwner + containerCode;
                  console.log("formatted fussion :", formattedFusion);
                  if (formattedFusion === this.state.infoContainerNum) {
                    this.setState({
                      containerCode: containerCode,
                      containerOwner: containerOwner,
                      visibleContain: true,
                    });
                  } else {
                    this.setState({
                      containerCode: containerCode,
                      containerOwner: containerOwner,
                      visibleContain: false,
                    });
                  }
                }

                const modalPrueba2 = document.getElementById('prueba');
                const modalContainer2 = document.getElementById('ModalGaleriaW');
                const panelinfo2 = document.getElementById('panelinfo');
                //const modalRect = modalContainer.getBoundingClientRect();
                const imageRect2 = photo.getBoundingClientRect();
                const offsetX2 = imageRect.left; // Offset en el eje X
                const offsetY2 = imageRect.top; // Offset en el eje Y
                const offsetW2 = imageRect.width;
                const offsetH2 = imageRect.height;
                var diferenciaoriginalW2 = imageRect2.width / img.width;
                var diferenciaoriginalH2 = imageRect2.height / img.height;

                const objectBox = document.createElement("div");
                objectBox.className = "object-box2";
                objectBox.title = contenido.content;
                objectBox.id = `box-${sanitizeId(contenido.content)}`; // Asignar un ID único
                objectBox.style.transformOrigin = "top left";
                objectBox.style.border = "2px solid #6ABFF4";
                objectBox.style.transform = `rotate(${angle2}rad)`;

                // Establece las dimensiones del cuadro después de la rotación
                objectBox.style.width = width2 * diferenciaoriginalW2 + "px";
                objectBox.style.height = height2 * diferenciaoriginalH2 + "px";

                var body = document.body;

                // Verificar el diseño responsive
                var isResponsive = window.matchMedia("(max-width: 768px)").matches;

                var restanteW2, restanteH2;
                if (isResponsive) {
                  console.log("IMAGE ANALITYCS => is resposnive");
                  // Ajuste para diseño en columna (responsive)
                  restanteW2 = ((modalContainer2 - offsetW) / 2);
                  restanteH2 = (((modalContainer2) - offsetH - 3) / 2);
                } else {
                  // Ajuste para diseño en fila (no responsive)
                  restanteW2 = ((modalContainer2.clientWidth - offsetW) / 2) + panelinfo.clientWidth - 250;
                  restanteH2 = (((modalContainer2.clientHeight) - offsetH - 3) / 2);
                }

                var left2, top2;

                if (isResponsive) {
                  left2 = (boundingBox2[0] * diferenciaoriginalW2) + 35;
                  top2 = (boundingBox2[1] * diferenciaoriginalH2);
                } else {
                  left2 = (boundingBox2[0] * diferenciaoriginalW2) + restanteW2 - ((modalContainer2.clientWidth - modalPrueba2.clientWidth));
                  top2 = (boundingBox2[1] * diferenciaoriginalH2) + restanteH2 - ((modalContainer2.clientHeight - modalPrueba2.clientHeight));
                }

                objectBox.style.position = "absolute";
                objectBox.style.left = left2 + "px";
                objectBox.style.top = top2 + "px";
                // Agrega el elemento .object-box al contenedor
                photo.parentNode.appendChild(objectBox);
                //document.querySelector("body").parentNode.appendChild(objectBox);

                this.mostrar();
                attribute.value.forEach(contenido => {
                  if (infoAddJson.innerHTML.includes("No hay información adicional asociada")) {
                    // infoAddJson.innerHTML = "";
                  }
                  hasAditionalInfo = true;
                  attributeAddHtml += `<li class='attribute_content' >${contenido.content}</li>`;
                });
                // infoAddJson.innerHTML = `<p>No hay información adicional asociada</p>`;
              });
            } else if (typeof content === 'string') {
              // Si es una cadena, crea un nuevo elemento HTML para mostrar su valor
              let iconHTML = '';
              if (name === 'ID') {
                iconHTML = `<i class='bx bx-id-card bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (name === 'color') {
                iconHTML = `<i class='bx bxs-color-fill bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (name === 'status') {
                iconHTML = `<i class='bx bx-stats bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (name === 'condition') {
                iconHTML = `<i class='bx bx-box bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }

              if (name === 'door_status') {
                iconHTML = `<i class='bx bx-door-open bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }

              var attributeValueHtml = `<div class='attribute_class'><span class='container_span_iconName'>${iconHTML} <span class='color_name_attribute'>${name.toUpperCase()}</span></span>${content}</div>`;
              if (infoAddJson.innerHTML.includes("No hay información adicional asociada")) {
                infoAddJson.innerHTML = "";
              }
              // Agrega el nuevo elemento al contenedor infoAddJson
              hasAditionalInfo = true;
              infoAddJson.innerHTML += attributeValueHtml;
            } else {
              console.error("attribute.value no es un array:", value); // Cambio aquí: uso value en lugar de attribute.value

              cadena += "</ul></div>";
              // Añadir al infoAddJson
              let iconHTML = '';
              if (attribute.name === 'ID') {
                iconHTML = `<i class='bx bx-id-card bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (attribute.name === 'color') {
                iconHTML = `<i class='bx bxs-color-fill bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (attribute.name === 'status') {
                iconHTML = `<i class='bx bx-stats bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              if (attribute.name === 'condition') {
                iconHTML = `<i class='bx bx-box bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }

              if (attribute.name === 'door_status') {
                iconHTML = `<i class='bx bx-door-open bx-tada' style="position: relative; top: 2px; margin-right: 1px; font-size: 20px;"></i>`;
              }
              var attributeAddHtml = `
                  <div class='attribute_class'>
                      <span>${iconHTML} ${attribute.name}: ${attribute.value}</span>
                    <ul>
                `;
              // // attribute.value.forEach(contenido => {
              // //   attributeAddHtml += `<li class='attribute_content' >${contenido.content}</li>`;
              // // });
              attributeAddHtml += `</ul></div>`;
            }

          });

          const words = prediction.lines;
          console.log(words);
          words.forEach((word, wordIndex) => {
            cadena += `<li class='word_content' data-box-id='box-${sanitizeId(word.content)}-${wordIndex}'>${word.content}</li>`;
            const boundingBox2 = word.boundingBox;
            console.log(boundingBox);
            const width2 = Math.sqrt((boundingBox2[2] - boundingBox2[0]) ** 2 + (boundingBox2[3] - boundingBox2[1]) ** 2);
            const height2 = Math.sqrt((boundingBox2[6] - boundingBox2[0]) ** 2 + (boundingBox2[7] - boundingBox2[1]) ** 2);
            const angle2 = Math.atan2(boundingBox2[3] - boundingBox2[1], boundingBox2[2] - boundingBox2[0]);

            const modalPrueba2 = document.getElementById('prueba');
            const modalContainer2 = document.getElementById('ModalGaleriaW');
            const panelinfo2 = document.getElementById('panelinfo');
            //const modalRect = modalContainer.getBoundingClientRect();
            const imageRect2 = photo.getBoundingClientRect();
            const offsetX2 = imageRect.left; // Offset en el eje X
            const offsetY2 = imageRect.top; // Offset en el eje Y
            const offsetW2 = imageRect.width;
            const offsetH2 = imageRect.height;
            var diferenciaoriginalW2 = imageRect2.width / img.width;
            var diferenciaoriginalH2 = imageRect2.height / img.height;

            const objectBox = document.createElement("div");
            objectBox.className = "object-box2";
            objectBox.title = word.content;
            objectBox.id = `box-${sanitizeId(word.content)}-${wordIndex}`; // Asignar un ID único
            objectBox.style.transformOrigin = "top left";
            objectBox.style.transform = `rotate(${angle2}rad) scale(1)`;
            objectBox.style.border = "2px solid red";


            // Establece las dimensiones del cuadro después de la rotación
            objectBox.style.width = width2 * diferenciaoriginalW2 + "px";
            objectBox.style.height = height2 * diferenciaoriginalH2 + "px";

            var body = document.body;

            // Verificar el diseño responsive
            var isResponsive = window.matchMedia("(max-width: 768px)").matches;

            var restanteW2, restanteH2;


            if (isResponsive) {
              console.log("IMAGE ANALITYCS => is resposnive");
              // Ajuste para diseño en columna (responsive)
              restanteW2 = ((modalContainer2 - offsetW) / 2);
              restanteH2 = (((modalContainer2) - offsetH - 3) / 2);
            } else {
              // Ajuste para diseño en fila (no responsive)
              restanteW2 = ((modalContainer2.clientWidth - offsetW2) / 2) + panelinfo.clientWidth - 250;
              restanteH2 = (((modalContainer2.clientHeight) - offsetH2 - 3) / 2);
            }

            var left2, top2;

            if (isResponsive) {
              left2 = (boundingBox2[0] * diferenciaoriginalW2) + 35;
              top2 = (boundingBox2[1] * diferenciaoriginalH2);
            } else {
              left2 = (boundingBox2[0] * diferenciaoriginalW2) + restanteW2 - ((modalContainer2.clientWidth - modalPrueba2.clientWidth));
              top2 = (boundingBox2[1] * diferenciaoriginalH2) + restanteH2 - ((modalContainer2.clientHeight - modalPrueba2.clientHeight));
            }

            objectBox.style.position = "absolute";
            objectBox.style.left = left2 + "px";
            objectBox.style.top = top2 + "px";
            // Agrega el elemento .object-box al contenedor
            photo.parentNode.appendChild(objectBox);
            //document.querySelector("body").parentNode.appendChild(objectBox);

            this.mostrar();
          });

          if (cadena.includes("<li>")) {
            cadena += "</ul></div>";
          } else {
            cadena.replace("<ul>", "");
            cadena += "</div>";
          }
          infojson.innerHTML += cadena;

        });

        //Manejo de cambio de colores cuando se selscciona un item de la lista del acorrdion

        /* Esta funcion cambia de colores los cuadros que se elige en la lista del analisiss de la imagen
         para poder intercatuar con los boundinboxes del lado de la lista desplegada  
        */

        document.querySelectorAll('.word_content, .attribute_content, .content_content').forEach(wordContentActive => {
          wordContentActive.addEventListener('click', function () {
            const clickedWord = this.dataset.boxId;
            console.warn("IMAGE ANALITYCS => item clickeado: " + clickedWord);

            // Restablecer el color de todos los recuadros
            document.querySelectorAll('.object-box2').forEach(box => {
              box.style.borderColor = ""; // Restablecer el color del borde
              box.style.animation = ""; // Restablecer la animación

            });

            // Cambiar el color del borde del recuadro correspondiente
            const box = document.getElementById(clickedWord);
            if (box) {
              box.style.borderColor = "green"; // Cambiar el color del borde aquí

              // Obtener el ángulo de rotación del cuadro
              const angle2 = window.getComputedStyle(box).getPropertyValue('transform');
              // console.log("IMAGE ANALITYCS => Grados de tranfpormacion cuadro: ", angle2);

              // Aplicar la animación con el ángulo de rotación
              const pulseAnimation = `
                @keyframes pulseAnimation {
                    0% { transform: ${angle2} scale(1); }
                    50% { transform: ${angle2} scale(1.1); }
                    100% { transform: ${angle2} scale(1); }
                }
            `;

              // Crear un estilo para la animación
              const style = document.createElement('style');
              style.type = 'text/css';
              style.innerHTML = pulseAnimation;

              // Agregar el estilo al head del documento
              document.head.appendChild(style);

              // Aplicar la animación al cuadro
              box.style.animation = "pulseAnimation 1s ease-in-out infinite";
            }
          });
        });




        // Manejo del estado de colapso del acordeón
        document.querySelectorAll('.accordion-button').forEach((button, index) => {
          button.classList.add('active'); // Añadir la clase active inicialmente
          const targetId = `box-${index}`;
          console.log("IMAGE ANALITYCS => acorrdion taqgandme :", targetId);
          button.addEventListener('click', function () {
            const collapseElement = document.getElementById(button.getAttribute('data-bs-target').substring(1));
            const boxId = `box-${index}`;

            collapseElement.addEventListener('hidden.bs.collapse', function () {
              button.classList.add('collapsed');
              button.classList.remove('active'); // Add active class when expanded
              resetBoxStyles();
              highlightBox(boxId);

              setTimeout(() => {
                hideBackdrop(boxId);
              }, 500);
            });

            collapseElement.addEventListener('shown.bs.collapse', function () {
              button.classList.remove('collapsed');
              button.classList.add('active'); // Remove active class when collapsed
              resetBoxStyles();
              highlightBox(boxId);

              setTimeout(() => {
                hideBackdrop(boxId);
              }, 500);
            });
          });

          // Restablecer el color del borde de todos los cuadros
        });

        // Restablecer el color y el grosor del borde de todos los cuadros cuando se hace clic en cualquier otro elemento
        document.addEventListener('click', function (event) {
          if (!event.target.classList.contains('accordion-button')) {
            resetBoxStyles();
            hideBackdrop();
          }
        });

        function resetBoxStyles() {
          document.querySelectorAll('.object-box, object-box2').forEach(box => {
            box.style.borderColor = ""; // Restablecer el color del borde
            box.style.borderWidth = "2px"; // Restablecer el grosor del borde
          });
        }

        function highlightBox(boxId) {
          const box = document.getElementById(boxId);
          if (box) {
            box.style.borderColor = "blue"; // Cambiar el color del borde aquí
            box.style.borderWidth = "4px"; // Cambiar el grosor del borde aquí
            box.style.backgroundColor = 'rgba(0, 0, 255, 0.5)'
          }
        }




        function hideBackdrop(boxId) {

          const masks = document.querySelectorAll('.mask');
          // console.log("IMAGE ANALITYCS => boxId: ", boxId);
          const box = document.getElementById(boxId);
          if (box) {
            box.style.borderColor = "blue"; // Cambiar el color del borde aquí
            box.style.borderWidth = "2px"; // Cambiar el grosor del borde aquí
            box.style.backgroundColor = ''
          }
          // Remover todos los backdrops y máscaras


          masks.forEach(mask => {
            mask.remove();
          });
        }





      } catch (error) {
        console.error("Error al obtener datos JSON:", error);
      }
    };
  };




  loadFillData(array) {
    const { isZoomed, mouseX, mouseY } = this.state;
    if (array.length > 0) {
      return array.map((data, index) => {
        const f = data.Source;
        const id = "f" + data.IDPhoto;
        const props = { width: 400, zoomWidth: 500, zoomPosition: 'original', img: f };
        var zoomedAreaStyle = {
          background: 'white',
          opacity: 0.4,
          position: 'absolute',
          width: 'auto',
          height: '400px',
          top: `${mouseY}px`,
          left: `${mouseX}px`,
          display: isZoomed ? 'block' : 'none',
        };

        var zoomedImageStyle = {
          backgroundImage: `url("${f}")`,
          backgroundRepeat: 'no-repeat',
          display: isZoomed ? 'block' : 'none',
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
          backgroundSize: '700px 800px',
          backgroundPosition: `${-1 * (mouseX - 50)}px ${-1 * (mouseY)}px`,
        };



        const cursorStyle = isZoomed ? { cursor: 'zoom-in' } : {};
        return (
          <div key={index} className="image-container" onMouseDown={this.handleMouseEnter}
            onMouseUp={this.handleMouseLeave} onMouseMove={this.handleMouseMove} style={cursorStyle}>
            <img

              id={data.IDPhoto}
              src={f}
              ref={this.imageRef}
              style={{ maxHeight: "400px", width: "auto" }}
            />
            <div className="js-image-zoom__zoomed-area" style={zoomedAreaStyle}></div>
            <div className="js-image-zoom__zoomed-image" style={zoomedImageStyle}></div>
          </div>
        )
      });
    } else {
      if (this.state.nfotos !== -1) {
        return (
          <div key={-1}>
            <img src="assets/img/Nofoto.jpg" style={{ maxHeight: "400px", width: "auto" }} />

          </div>
        )
      }
    }
  }


  /* Traer fotos  */
  async getFotos(contrato) {
    console.log("# ENTRO A CONTRATO*************");
    try {
      this.setState({ nfotos: -1, currentIndex: 0, selectedItem: 0 });
      const infojson = document.getElementById('infojson');
      infojson.innerHTML = "";
      let img = 'img'
      let data = { "contrato": contrato, "tipo": img } //Para video es vid;
      const json = JSON.stringify(data);
      if (contrato != -1) {
        console.log("si hay contrato = " + contrato);
        const response = await axios.post(process.env.REACT_APP_SERVER + '/operaciones/getfotoscontrato', json, {
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
          withCredentials: true
        });
        if (response.data.success) {
          console.log("si hay fotos", response.data.data);
          this.setState({ fotos: response.data.data });
          this.setState({ nfotos: 1 });
          if (response.data.data.length > 0) {
            this.mostrarInfo(response.data.data[0]);
          }

        } else {
          window.history.go(0);
        }
      } else {
        console.log("no hay contrato aun");
      }

    } catch (error) {
      console.error(error);
    }
  }

  async trackingModal(e) {
    e.preventDefault();
    console.log("entre a tracking");
    this.props.tracking();
  }

  async monitoreoModal(e) {
    e.preventDefault();
    console.log("entre a monitoreo");
    this.props.monitoreo();
  }

  async consolidadoMonitoreo(e) {
    e.preventDefault();
    console.log("entre a consolidado");
    this.props.consolidado();
  }


  /* Analizar fotos */
  async getFotosjSON(nombreimagen) {
    console.log("***********" + nombreimagen);
    let token = localStorage.getItem('tkn');
    console.log(token);
    try {
      var data = {
        "nombreimagen": nombreimagen
      };
      console.log(data);
      const response = await axios.post(process.env.REACT_APP_SERVER + '/operaciones/getjsonvisuallogistic', JSON.stringify(data), {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        withCredentials: true
      });

      return response.data.info;
    } catch (error) {
      console.error(error);
    }
  }


  // Función para renderizar la flecha previa del carrusel con un color personalizado
  renderCustomPrevArrow = (onClickHandler, hasPrev, label) =>
    hasPrev && (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        style={{ backgroundColor: 'red', color: 'white' }} // Color personalizado para la flecha previa
      >
        Prev
      </button>
    );

  // Función para renderizar la flecha siguiente del carrusel con un color personalizado
  renderCustomNextArrow = (onClickHandler, hasNext, label) =>
    hasNext && (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        style={{ backgroundColor: 'blue', color: 'white' }} // Color personalizado para la flecha siguiente
      >
        Next
      </button>
    );


  renderThumbsArrows = ({ direction, onClick }) => (
    <button className={`thumbs-arrow thumbs-${direction}`} onClick={onClick} style={{ color: 'blue' }}>
      {direction === 'prev' ? '<' : '>'}
    </button>
  );

  render() {
    return (
      <>
        <MDBRow style={{ marginTop: '-15px', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <a href="#" onClick={(e) => { this.trackingModal(e) }}>Tracking</a>&nbsp;/&nbsp;<a href="#" onClick={(e) => { this.monitoreoModal(e) }}>Monitoreo</a>&nbsp;/&nbsp;<a href="#" onClick={(e) => { this.consolidadoMonitoreo(e) }}>Consolidado</a>
          </div>
        </MDBRow>
        <MDBRow>
          <MDBCol md='6' style={{ margin: '0', padding: '0' }}>
            <div id="prueba">
              <Carousel selectedItem={this.state.selectedItem} showArrows={true} showThumbs={true} showStatus={true} showIndicators={false} renderThumbsArrows={this.renderThumbsArrows} className="custom-carousel" onChange={this.handleChange}

              >
                {this.loadFillData(this.state.fotos)}

              </Carousel>

            </div>
          </MDBCol>
          <MDBCol id="panelinfo" md='6' style={{ margin: '0', padding: '0' }}>
            <div style={{ backgroundColor: '#006699', color: '#fff', width: '100%', textAlign: 'center', margin: '0' }}>Información</div>
            <div id="infofoto" style={{ padding: '5px', border: '5px outset #ccc', color: '#000', fontWeight: 700, textAlign: 'center' }}>
            </div>
            <div className="d-flex flex-column flex-md-row w-100">

              <MDBRow className="w-100 m-0">
                <MDBCol md="6" className="p-0">
                  <div style={{
                    backgroundColor: '#006699',
                    color: '#fff',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly'
                  }}>
                    Image Analytics
                    <img
                      src="/images/reload.png"
                      alt="Icono"
                      style={{ marginLeft: '10px', width: '18px', cursor: 'pointer' }}
                      onClick={this.handleClick}
                    />
                    <i className='bx bxs-trash bx-sm bx-tada-hover' onClick={this.eliminar}></i>
                  </div>
                  <div
                    id="infojson"
                    style={{
                      padding: '5px',
                      border: '5px outset #ccc',
                      color: '#000',
                      height: '300px',
                      overflow: 'auto'
                    }}
                  >
                    {/* Contenido JSON */}
                  </div>
                </MDBCol>

                <MDBCol md="6" className="p-0">
                  <div style={{ backgroundColor: '#006699', color: '#fff', textAlign: 'center' }}>
                    In-depth Analysis
                  </div>
                  <div
                    id="infofoto2"
                    style={{
                      padding: '5px',
                      border: '5px outset #ccc',
                      color: '#000',
                      height: '300px',
                      overflowY: 'scroll'
                    }}
                  >
                    {/* Análisis detallado */}
                  </div>
                </MDBCol>
              </MDBRow>


            </div>
            {this.state.containerOwner !== '' && this.state.containerCode !== '' ? (
              this.state.visibleContain ? (
                <div div style={{ backgroundColor: 'green', color: '#fff', width: '100%', textAlign: 'center', margin: '0' }}>El contenedor de la imagen coincide con el asignado</div>
              ) : (
                <div style={{ backgroundColor: 'red', color: '#fff', width: '100%', textAlign: 'center', margin: '0' }}>El contenedor de la imagen no coincide con el asignado</div>
              )
            ) : null}
          </MDBCol>

        </MDBRow >

      </>

    );
  }
}



export default Gallery;
