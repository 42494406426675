import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";

const ModalVideo = (props) => {
    const [contrato, setContrato] = useState(props.selectedContrato || "");
    const [latitud, setLatitud] = useState("0.00");
    const [longitud, setLongitud] = useState("0.00");
    const [fechaVideo, setFechaVideo] = useState("");
    const [evento, setEvento] = useState(props.eventoSeleccionado || props.listEventos[0]?.ID_Evento);
    const [videos, setVideos] = useState([]);
    const username = localStorage.getItem("username") || "unknown_user";

    const fileInputRef = useRef(null);

    useEffect(() => {
        setContrato(props.selectedContrato || "");
        setEvento(props.eventoSeleccionado || props.listEventos[0]?.ID_Evento);
    }, [props.selectedContrato, props.eventoSeleccionado]);

    const handleFileChange = (event) => {
        setVideos(event.target.files);
    };

    const uploadVideo = async () => {
        console.log("Subiendo video...");

        // Validación de latitud y longitud
        const latLonPattern = /\d+\.\d{2,}$/;
        if (!latLonPattern.test(latitud) || !latLonPattern.test(longitud)) {
            Swal.fire("Error", "Latitud o Longitud inválida", "error");
            return;
        }

        // Validación de fecha
        const fecha = new Date(fechaVideo);
        if (isNaN(fecha.getTime())) {
            Swal.fire("Error", "Fecha inválida", "error");
            return;
        }

        const fechaFormateada = `${fecha.getFullYear()}_${String(fecha.getMonth() + 1).padStart(2, "0")}_${String(fecha.getDate()).padStart(2, "0")}_${String(fecha.getHours()).padStart(2, "0")}_${String(fecha.getMinutes()).padStart(2, "0")}_25`;

        if (videos.length === 0) {
            Swal.fire("Error", "No se han seleccionado archivos", "error");
            return;
        }

        const formData = new FormData();

        for (let i = 0; i < videos.length; i++) {
            const file = videos[i];

            const newName = `${fechaFormateada}_${i}_${props.device || "nodata"}_${props.placa || "nodata"}_${evento || "nodata"}_${Math.floor(Math.random() * 1000)}_${username}_${latitud}_${longitud}`;

            console.log(`Archivo ${i}:`, newName);

            if (file.type.startsWith("image/")) {
                formData.append("files", file, `${newName}.jpg`);
            } else if (file.type.startsWith("video/")) {
                formData.append("files", file, `${newName}.mp4`);
            }
        }

        for (let pair of formData.entries()) {
            console.log("FormData contenido:", pair[0], pair[1]);
        }

        Swal.fire({
            title: "Subiendo Video...",
            timerProgressBar: true,
            didOpen: () => Swal.showLoading(),
        });

        try {
            console.log("Subiendo video...");
            console.log("FormData enviado:", formData);

            // Descomenta para hacer la petición real
            const response = await fetch(process.env.REACT_APP_SERVER + "/uploadvideo", {
                method: "POST",
                body: formData,
            });


            if (response.data.hasOwnProperty("success")) {
                Swal.close();
                props.onClose();
                Swal.fire({
                    icon: "success",
                    title: "Éxito",
                    text: "Video subido correctamente",
                    timer: 1000, // Cierra la alerta automáticamente después de 2 segundos
                    showConfirmButton: false, // Oculta el botón de confirmación
                });
                setVideos([]);
                fileInputRef.current.value = ""; // Limpiar input de archivos
                props.onClose(); // Cierra el modal después de subir
            } else {
                Swal.fire("Error", "Hubo un problema al subir el video", "error");
            }
        } catch (error) {
            Swal.close();
            console.error(error);
            Swal.fire("Error", "Error al subir video", "error");
        }
    };

    useEffect(() => {
        console.log("props", props);
        console.log("Data", props.data);
    }, [props]);

    useEffect(() => {
        if (props.data) {
            setFechaVideo(props.data.fechainicio);
        }
    }, [props]);

    return (
        <>
            <div className="modal-body">
                <div className="row">
                    <div className="mb-3">
                        <label className="form-label" htmlFor="contrato">Contrato</label>
                        <div className="input-group input-group-merge">
                            <span className="input-group-text">
                                <i className="bx bx-file-blank"></i>
                            </span>
                            <input type="text" className="form-control" value={props.selectedContrato} readOnly />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="latitud">Latitud</label>
                        <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-map-pin"></i></span>
                            <input type="text" className="form-control" value={latitud} onChange={(e) => setLatitud(e.target.value)} />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="longitud">Longitud</label>
                        <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-map-pin"></i></span>
                            <input type="text" className="form-control" value={longitud} onChange={(e) => setLongitud(e.target.value)} />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="fechaVideo">Hora/Fecha</label>
                        <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-calendar"></i></span>
                            <input type="datetime-local" className="form-control" value={fechaVideo} onChange={(e) => setFechaVideo(e.target.value)} min={props.data.fechainicio} max={props.data.fechafin ? props.data.fechafin : undefined} />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="eventos">Evento</label>
                        <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-file-blank"></i></span>
                            <select className="form-select" value={evento} onChange={(e) => setEvento(e.target.value)}>
                                {props.listEventos?.map((loc) => (
                                    <option key={loc.ID_Evento} value={loc.ID_Evento}>{loc.Descripcion}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="videos">Videos</label>
                        <div className="input-group input-group-merge">
                            <span className="input-group-text"><i className="bx bx-file"></i></span>
                            <input type="file" className="form-control" multiple ref={fileInputRef} onChange={handleFileChange} />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-outline-secondary" onClick={props.onClose}>
                        Cerrar
                    </button>
                    <button type="button" className="btn btn-primary" onClick={uploadVideo}>
                        Guardar
                    </button>
                </div>
            </div>
        </>
    );
};

export default ModalVideo;
