import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TokenServiceRefresh from "./tokenServiceRefresh";

const AuthenticatedApp = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(true);
    const [lastActionTime, setLastActionTime] = useState(new Date().getTime());

    // Intervalo de inactividad en minutos (configurable desde .env o manualmente)
    const inactivityInterval = useMemo(() => (
        process.env.REACT_APP_INACTIVITY_INTERVAL ? parseInt(process.env.REACT_APP_INACTIVITY_INTERVAL) : 1
    ), []);

    // Convertir minutos a milisegundos
    const intervalTime = 55 * 60 * 1000;

    // Determinar si estamos en la página de login
    const isLoginPage = useMemo(() => location.pathname === "/", [location.pathname]);

    // Función para manejar la actividad del usuario
    const handleUserActivity = useCallback(() => {
        if (!isLoginPage) {
            setIsActive(true);
            setLastActionTime(new Date().getTime());
        }
    }, [isLoginPage]);

    useEffect(() => {
        if (isLoginPage) return; // No registrar eventos en la página de login

        const events = ["mousedown", "mousemove", "keypress", "scroll", "touchstart"];
        events.forEach(event => window.addEventListener(event, handleUserActivity));

        return () => {
            events.forEach(event => window.removeEventListener(event, handleUserActivity));
        };
    }, [handleUserActivity, isLoginPage]);

    useEffect(() => {
        if (isLoginPage) return; // No validar inactividad en login

        const checkInactivity = setInterval(() => {
            const currentTime = new Date().getTime();
            const elapsedTime = currentTime - lastActionTime;

            if (elapsedTime >= intervalTime) {
                console.log(`Sesión cerrada por inactividad de ${inactivityInterval} minuto(s).`);
                localStorage.clear();

                // Evitar redirección forzada si ya estamos en "/"
                if (location.pathname !== "/") {
                    window.location.href = "/";
                }
            }
        }, 1000);

        return () => clearInterval(checkInactivity);
    }, [lastActionTime, navigate, intervalTime, inactivityInterval, isLoginPage]);

    useEffect(() => {
        if (isLoginPage) return; // No actualizar token en login
        if (isActive) {
            TokenServiceRefresh.refreshTokenIfNeeded();
        }
    }, [isActive, isLoginPage]);

    return <>{children}</>;
};

export default AuthenticatedApp;
