import React from 'react';

const ModalComprobanteValitronics = ({ listComprobantes }) => {
    // Función para renderizar las filas de la tabla
    const loadFillData2 = (comprobantes) => {
        return comprobantes?.map((data, index) => (
            <tr key={index}>
                <td className="prueba" style={{ color: "#000" }}>
                    <strong>{data.Comprobante}</strong>
                </td>
                <td className="prueba" style={{ color: "#000" }}>
                    {data.Fecha}
                </td>
                <td className="prueba" style={{ color: "#000" }}>
                    {data.Latitud}
                </td>
                <td className="prueba" style={{ color: "#000" }}>
                    {data.Longitud}
                </td>
                <td className="prueba" style={{ color: "#000" }}>
                    {data.Usuario}
                </td>
            </tr>
        ));
    };

    return (
        <div className="modal-body">
            <div className="table-container">
                <table className="table table-hover">
                    <thead className="FondoHeaderTabla2">
                        <tr>
                            <th style={{ color: "#ffffff" }}>Comprobante</th>
                            <th style={{ color: "#ffffff" }}>Fecha</th>
                            <th style={{ color: "#ffffff" }}>Latitud</th>
                            <th style={{ color: "#ffffff" }}>Longitud</th>
                            <th style={{ color: "#ffffff" }}>Usuario</th>
                        </tr>
                    </thead>
                    <tbody id="tablacomprobante">
                        {loadFillData2(listComprobantes)}
                    </tbody>
                </table>
            </div>

            <style jsx>{`
        .table-container {
          overflow-x: auto;
          width: 100%;
        }
        table {
          width: 100%;
          /* Establece un ancho mínimo para que, en pantallas pequeñas, se active el scroll horizontal */
          min-width: 600px;
        }
        .modal-body {
          padding: 15px;
        }
      `}</style>
        </div>
    );
};

export default ModalComprobanteValitronics;
