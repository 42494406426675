import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';
import '../Pages/Css/reporteservicio.css';
import StaticMap from './Staticmap'
import StaticMapRecorrido from './Staticmaprecorrido'
import Swal from 'sweetalert2';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import axios from '../utils/axiosConfig';
import { formattedGlobalDate } from '../utils/formattedDates';
import StaticsMapsRecorrido2 from './StaticsMapsRecorrido2';
import StaticMaps2 from './StaticMaps2';
var miStorage = window.localStorage;

class Reporte extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.containerRef = React.createRef();
        this.imgRef = [];


        this.state = {
            imgData: {},
            imgWidth: null,
            imgHeight: null,
            infocontrato: {},
            infocontratoFake: [],
            fotos: [],
            recorrido: [],
            reportes: [],
            comparision: {} || [],
            idFPhoto: '',
            idSPhoto: '',
            fPhoto: '',
            sPhoto: '',
            scoreColor: 'transparent',
            loading: false,
            modal: false,
            modalPhoto: '',
            isClicked: false,
            clickedImage: null,
            isOpen: false,
            ultPoint: null,
            ultRecorrido: null,
            isSmallScreen: false,
            // recorridoV: [],
        };
        this.containerRef = React.createRef();

    }


    handleImageClick = (photo) => {
        this.setState({
            clickedImage: photo,
            isOpen: true,
        });
    };

    handleMouseDown = (photo) => {
        this.handleImageClick(photo);
    };

    handleMouseUp = () => {
        this.setState({
            clickedImage: null,
        });
    };

    handleResize = () => {
        const isSmallScreen = window.innerWidth <= 768; // Define a threshold for small screens (e.g., 768px)
        this.setState({ isSmallScreen });
    };


    showImageNewModal = (imageSrc, event) => {
        const clickPosition = { x: event.clientX, y: event.clientY };
        console.log("click en la posicion: ", clickPosition);
        console.log("click en la fullScreenImage: ", imageSrc);
        this.setState({ fullscreenImage: imageSrc, clickPosition: clickPosition });
    };

    handleOutsideClick = () => {
        this.setState({ isOpen: false, clickedImage: null });
    };

    handleModalImageLoad = (event) => {
        const { naturalWidth, naturalHeight } = event.target;
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        console.log(naturalHeight);
        console.log(naturalWidth);

        // Porcentaje de la pantalla que queremos ocupar
        const maxWidth = screenWidth * 0.95;  // 95% del ancho
        const maxHeight = screenHeight * 0.95; // 95% del alto

        let finalWidth, finalHeight;

        if (naturalWidth >= naturalHeight) {
            // IMAGEN HORIZONTAL O CUADRADA

            // 1) Escalar a un ancho cercano a la pantalla, aunque sea más grande que su tamaño natural.
            //    Esto hace que la imagen horizontal se vea "más grande" (forzando el ancho a 95%).
            finalWidth = maxWidth;
            finalHeight = (maxWidth / naturalWidth) * naturalHeight;

            // 2) Si después de escalar, la altura sobrepasa el alto de la pantalla, ajustamos por altura.
            if (finalHeight > maxHeight) {
                finalHeight = maxHeight;
                finalWidth = (maxHeight / naturalHeight) * naturalWidth;
            }

        } else {
            // IMAGEN VERTICAL

            // 1) Primero escalamos por altura para que quepa en la pantalla
            if (naturalHeight > maxHeight) {
                finalHeight = maxHeight;
                finalWidth = (maxHeight / naturalHeight) * naturalWidth;
            } else {
                // Si cabe en altura, se queda en tamaño natural
                finalHeight = naturalHeight;
                finalWidth = naturalWidth;
            }

            // 2) Si el ancho tras el escalado supera el maxWidth, lo reducimos
            if (finalWidth > maxWidth) {
                finalWidth = maxWidth;
                finalHeight = (maxWidth / naturalWidth) * naturalHeight;
            }
        }

        this.setState({
            modalImageWidth: finalWidth,
            modalImageHeight: finalHeight,
        });
    };







    componentDidMount() {
        console.log("pruebaajustado", "entro a didMount");
        // Solo ejecutamos si hay un contrato disponible al montar el componente.
        if (this.props.contrato && this.props.contrato.ContractID) {
            this.updateContractState(this.props);
        } else {
            console.log("No hay contrato disponible al montar el componente.");
        }

        window.addEventListener('resize', this.handleResize);
        // Call the function once to set the initial screen size
        this.handleResize();
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("pruebaajustado", "entro a didUpdate");
        if (this.props.contrato.ContractID !== prevProps.contrato.ContractID) {
            console.log("pruebaajustado", "entro a 1");
            this.updateContractState(this.props);
        }


        // Asegúrate de que cada dato se está actualizando correctamente
        if (this.state.recorrido !== prevState.recorrido) {
            console.log("pruebaajustado", "entro a 2");
            console.log("Recorrido actualizado:", this.state.recorrido);
        }

        if (this.state.infocontrato !== prevState.infocontrato) {
            console.log("pruebaajustado", "entro a 3");
            console.log("Contrato actualizado:", this.state.infocontrato);
        }

        if (this.state.reportes !== prevState.reportes) {
            console.log("Reportes actualizados:", this.state.reportes);
        }

        if (this.state.fotos !== prevState.fotos) {
            console.log("Fotos actualizadas:", this.state.fotos);
        }

        if (this.state.comparision !== prevState.comparision) {
            console.log("pruebaajustado", "entro a 6");
            console.log("Comparación actualizada:", this.state.comparision);
        }
        if (this.state.ultPoint !== prevState.ultPoint) {
            console.log("pruebaajustado", "entro a 7");
            console.log("Comparación actualizada:", this.state.ultPoint);
        }
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }


    // Función que maneja la actualización de estado y las llamadas a funciones comunes
    updateContractState = (props) => {
        console.table("PRODPS JEJE:", props.contrato);
        console.log("PROPs contrato ultimo Recorrido :", props.contractR);
        this.setState({
            infocontrato: props.contrato,
            ultPoint: props.ultPunto,
            ultRecorrido: props.contractR,
            fotos: [],
            recorrido: [],
            reportes: []
        }, () => {
            if (props.contractR !== -1 && props.contractR !== undefined) {
                this.getInfoContratoFake();
            }
            this.getFotos(props.contrato);
            this.getReportesdevice(props.contrato);
            this.getReportestrafico(props.contrato);
        });
    };


    toggle = () => {
        this.setState({ modal: !this.state.modal });
    }

    showImageNewModalOverlay = () => {
        const imageUrl = `https://fotos.sfo2.digitaloceanspaces.com/${this.state.modalPhoto.substring(0, 10)}/${this.state.modalPhoto}`;
        const newWindow = window.open("", "_blank", "width=600,height=400");
        newWindow.document.write(`
            <html>
                <head>
                    <title>Imagen</title>
                    <style>
                        body {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 0;
                            height: 100vh;
                            background-color: #f0f0f0;
                            padding:12px;
                        }
                        img {
                            max-width: 100%;
                            height: auto;
                            padding:12px;
                        }
                    </style>
                </head>
                <body>
                    <img src="${imageUrl}" alt="Custom image"/>
                </body>
            </html>
        `);
    }

    showImageNewModal2 = (photo) => {
        console.log("Carga 2");
        const imageUrl = `https://fotos.sfo2.digitaloceanspaces.com/${photo.substring(0, 10)}/${photo}`;
        const newWindow = window.open("", "_blank", "width=600,height=400");
        newWindow.document.write(`
            <html>
                <head>
                    <title>Imagen</title>
                    <style>
                       
                    </style>
                </head>
                <body>
                    <img src="${imageUrl}" alt="Custom image"/>
                </body>
            </html>
        `);
    };

    loadFillData(array) {
        return array.map((data) => {
            const f = data?.Source;
            const ImageOverlay = this.ImageOverlay;
            //Mostrar fotos mas grandes y completas 
            return (

                <div
                    key={data.IDPhoto} className="card cardphoto"
                    onMouseDown={() => this.handleMouseDown(f)}
                    onMouseUp={this.handleMouseUp}

                >
                    <img src={f} className='card-img-top' />
                    {this.state.isOpen && this.state.clickedImage && ReactDOM.createPortal(
                        <ImageOverlay photo={this.state.clickedImage} onClose={this.handleOutsideClick} />,
                        document.body
                    )}
                    <div className="card-body">
                        <p className="card-text">Evento: {data.Evento}<br />Latitud: {data.Latitud}<br />Longitud: {data.Longitud}<br />Geocerca: {data.Geo}<br />{data.Hora}</p>
                    </div>
                </div>
            )
        });
    }

    ImageOverlay = ({ photo, onClose }) => {
        console.log("Foto", photo);
        const [orientation, setOrientation] = useState('horizontal');

        const handleImageLoad = (e) => {
            const { naturalWidth, naturalHeight } = e.target;
            // Si el ancho es mayor o igual que el alto => horizontal (o cuadrada)
            if (naturalWidth >= naturalHeight) {
                setOrientation('horizontal');
            } else {
                setOrientation('vertical');
            }
        };

        const imageUrl = `${photo.Source}`;

        return (
            <div className="overlay" onClick={onClose}>
                <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
                    <img
                        src={photo}
                        alt="Overlay"
                        onLoad={handleImageLoad}
                        className={orientation}
                    />
                </div>
            </div>
        );
    };

    loadFillData2(array) {
        return array.map((data) => {
            return (
                <tr key={data.IdReport} className='trData'>
                    <td style={{ border: '1px solid #000' }}>{formattedGlobalDate(data.XTime)}</td>
                    <td style={{ border: '1px solid #000' }}>{data.TipoAccion}</td>
                    <td style={{ border: '1px solid #000' }}>{data.TipoReporte}</td>
                    <td style={{ border: '1px solid #000' }}>{data.Ubicacion}</td>
                    <td style={{ border: '1px solid #000' }}>{data.Nota}</td>
                    <td style={{ border: '1px solid #000' }}>{data.XUser}</td>
                </tr>
            )
        });
    }

    async getFotos(contrato) {
        try {
            let data = { "contrato": contrato.ContractID };
            //TODO:chnague to production
            // let data = { "contrato": 'serv-00375230' };
            const json = JSON.stringify(data);
            if (contrato != -1) {
                console.log("si hay contrato = " + contrato);
                const response = await axios.post(process.env.REACT_APP_SERVER + '/operaciones/getfotoscontrato', json, {
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
                    withCredentials: true
                });
                if (response.data.success) {
                    this.setState({ fotos: response.data.data }, () => {
                        console.log("Fotos: ", this.state.fotos);
                        this.getInfoAditionalContrato(this.props.contrato);
                    });

                } else {
                    window.history.go(0);
                }
            } else {
                console.log("no hay contrato aun");
            }

        } catch (error) {
            console.error(error);
        }
    }

    //BUSQUEDA DE INFO FOTO
    async getInfoFotosReporte(events) {
        console.log("Processing events:", events);
        console.log("State photos:", this.state.fotos);

        let infoPhoto = [];

        // Case 1: If `events` contains strings (image URLs), loop over the values directly
        if (typeof events === 'object' && !Array.isArray(events)) {
            const isStringEvent = Object.values(events).every(value => typeof value === 'string');

            if (isStringEvent) {
                // If all event values are strings, match those strings with `this.state.fotos`
                infoPhoto = await Promise.all(
                    Object.values(events).map(async (event) => {
                        return this.state.fotos.find(foto => foto && foto.photo === event);
                    })
                );
            } else {
                // Case 2: If `events` contains objects with `image` property, match the `image` field
                infoPhoto = await Promise.all(
                    Object.keys(events).map(async (eventKey) => {
                        const event = events[eventKey];
                        return this.state.fotos.find(f => f && f.photo === event.image);
                    })
                );
            }
        }

        return infoPhoto.filter(Boolean); // Return only valid photos
    }

    // Función para procesar una comparación de tipo Container
    async processContainerComparison(comp, assetID) {
        console.log("Processing container comparison:", comp);
        const events = comp.events || {};
        const results = comp.results || {};

        // Inicializar un array para almacenar todos los related_objects
        const allRelatedObjects = [];
        // Iterar sobre cada evento y extraer los related_objects
        for (const eventKey in events) {
            const event = events[eventKey];
            const relatedObjects = event.related_objects || [];

            // Añadir los related_objects de este evento a la lista completa
            allRelatedObjects.push(...relatedObjects);
        }

        const fotosInfo = await this.getInfoFotosReporte(events);
        const maxScore = results.scores?.status ? parseInt(results.scores.status) : 0;
        const scoresColor = this.getScoreColor(maxScore);

        return {
            id: assetID,
            events: events,
            results: results,
            scores: results.scores,
            scoreColor: scoresColor,
            fotosInfo: fotosInfo,
            type: 'Container',
            relatedObjects: allRelatedObjects
        };
    }

    // Función para procesar una comparación de tipo Seal
    async processSealComparison(comp, assetID) {

        const events = comp.events || {};
        const results = comp.results || {};

        const fotosInfo = await this.getInfoFotosReporte(events);
        const maxScore = results.scores?.status ? parseInt(results.scores.status) : 0;
        const scoresColor = this.getScoreColor(maxScore);

        return {
            id: assetID,
            events: events,
            results: results,
            scores: results.scores,
            scoreColor: scoresColor,
            fotosInfo: fotosInfo,
            type: 'Seal'
        };
    }
    // Función para procesar una comparación de tipo Security Label
    async processSLabelComparison(comp, assetID) {

        const events = comp.events || {};
        const results = comp.results || {};

        const fotosInfo = await this.getInfoFotosReporte(events);
        const maxScore = results.scores?.status ? parseInt(results.scores.status) : 0;
        const scoresColor = this.getScoreColor(maxScore);

        return {
            id: assetID,
            events: events,
            results: results,
            scores: results.scores,
            scoreColor: scoresColor,
            fotosInfo: fotosInfo,
            type: 'Security Label'
        };
    }

    getScoreColor(score) {
        switch (score) {
            case 1:
                return 'green';
            case 2:
                return 'yellow';

            case 3:
                return 'red';

            case 4:
                return 'red';

            case 5:
                return 'red';
            default:
                return 'transparent';
        }
    }



    async getInfoAditionalContrato(contrato) {
        let token = localStorage.getItem('tkn');
        var data = {
            "contrato": contrato.ContractID
            // "contrato": 'serv-00375230'
        };
        console.log("Contrato a utilizar: ", contrato.ContractID);
        this.setState({ loading: true });

        try {
            const response = await axios.post(
                process.env.REACT_APP_SERVER + '/operaciones/getcontractvisuallogistic',
                JSON.stringify(data),
                {
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                    withCredentials: true
                }
            );

            const assetList = response.data.info.asset_list || [];
            //TODO: Remove this line when the API is ready
            // const assetList =
            //     [
            //         {
            //             "ID": "2506564",
            //             "type": "Container",
            //             "comparison": [
            //                 {
            //                     "events": {
            //                         "14": {
            //                             "image": "2024_05_27_22_03_36_266_8041501165_UFV131_14__esenttia1_10.343978333333332_-75.49233_SERV-00375230_18_360.jpg",
            //                             "bounding_box": {
            //                                 "top": 12,
            //                                 "left": 0,
            //                                 "right": 459,
            //                                 "bottom": 612
            //                             },
            //                             "related_objects": [
            //                                 {
            //                                     "type": "Electronic device",
            //                                     "tagName": "Electronic device 1",
            //                                     "boundingBox": {
            //                                         "top": 429.47,
            //                                         "left": 224.95,
            //                                         "right": 262.05,
            //                                         "bottom": 483.53
            //                                     }
            //                                 },
            //                                 {
            //                                     "type": "Security label",
            //                                     "tagName": "Security label 1",
            //                                     "object_id": null,
            //                                     "boundingBox": {
            //                                         "top": 383.31,
            //                                         "left": 208.65,
            //                                         "right": 256.35,
            //                                         "bottom": 407.69
            //                                     }
            //                                 },
            //                                 {
            //                                     "type": "Security label",
            //                                     "tagName": "Security label 2",
            //                                     "object_id": null,
            //                                     "boundingBox": {
            //                                         "top": 545.65,
            //                                         "left": 159.28,
            //                                         "right": 184.72,
            //                                         "bottom": 593.35
            //                                     }
            //                                 },
            //                                 {
            //                                     "type": "Security label",
            //                                     "tagName": "Security label 3",
            //                                     "object_id": null,
            //                                     "boundingBox": {
            //                                         "top": 336.22,
            //                                         "left": 200.2,
            //                                         "right": 263.8,
            //                                         "bottom": 363.78
            //                                     }
            //                                 }
            //                             ]
            //                         },
            //                         "18": {
            //                             "image": "2024_05_28_07_14_49_328_8041501165_UFV131_18__esenttia1_10.38024427369237_-75.5043347273022_SERV-00375230_18_360.jpg",
            //                             "bounding_box": {
            //                                 "top": 0,
            //                                 "left": 0,
            //                                 "right": 436,
            //                                 "bottom": 531
            //                             },
            //                             "related_objects": [
            //                                 {
            //                                     "type": "Electronic device",
            //                                     "tagName": "Electronic device 1",
            //                                     "boundingBox": {
            //                                         "top": 312.44,
            //                                         "left": 173.83,
            //                                         "right": 215.17,
            //                                         "bottom": 367.56
            //                                     }
            //                                 },
            //                                 {
            //                                     "type": "Security label",
            //                                     "tagName": "Security label 1",
            //                                     "object_id": null,
            //                                     "boundingBox": {
            //                                         "top": 281.37,
            //                                         "left": 173.89,
            //                                         "right": 213.11,
            //                                         "bottom": 303.63
            //                                     }
            //                                 },
            //                                 {
            //                                     "type": "Security label",
            //                                     "tagName": "Security label 2",
            //                                     "object_id": null,
            //                                     "boundingBox": {
            //                                         "top": 421.44,
            //                                         "left": 113.37,
            //                                         "right": 135.63,
            //                                         "bottom": 476.56
            //                                     }
            //                                 }
            //                             ]
            //                         }
            //                     },
            //                     "results": {
            //                         "alerts": {
            //                             "number_objects": true,
            //                             "objects_position": true,
            //                             "container_closure": false,
            //                             "physical_condition": false
            //                         },
            //                         "number_objects": "El número de objetos de seguridad ha disminuido de 4 a 3.",
            //                         "objects_position": "La posición de los objetos de seguridad ha cambiado dado que el número de objetos también ha cambiado.",
            //                         "container_closure": "La imagen del contenedor no muestra cambios en cuanto a estar abierto o cerrado.",
            //                         "physical_condition": "No se observan cambios en la condición física del contenedor entre las imágenes."
            //                     }
            //                 }
            //             ],
            //             "similar_objects": []
            //         }
            //     ];

            console.log("ASSETLIST***: ", assetList);

            // Verificar si `assetList` es un array y procesar
            const assets = Array.isArray(assetList) ? assetList : [assetList];

            // Procesar cada asset y todas sus comparaciones según el tipo
            const comparisionData = [];
            for (const asset of assets) {
                if (asset.comparison && Array.isArray(asset.comparison) && asset.comparison.length > 0) {
                    if (asset.type === 'Container') {
                        for (const comp of asset.comparison) {
                            const processedData = await this.processContainerComparison(comp, asset.ID);
                            comparisionData.push(processedData);
                        }
                    } else if (asset.type === 'Seal') {
                        for (const comp of asset.comparison) {
                            const processedData = await this.processSealComparison(comp, asset.ID);
                            comparisionData.push(processedData);
                        }
                    } else if (asset.type === 'Security label') {
                        for (const comp of asset.comparison) {
                            const processedData = await this.processSLabelComparison(comp, asset.ID);
                            comparisionData.push(processedData);
                        }
                    }
                }
            }

            // Extraer los primeros eventos y sus claves si existen
            const firstComparison = comparisionData[0] || {};
            const events = firstComparison.events || {};
            const firstEventKey = Object.keys(events)[0];
            const secondEventKey = Object.keys(events)[1];
            const firstEventValue = events[firstEventKey];
            const secondEventValue = events[secondEventKey];

            console.log("Datos de comparision asset_List: ", comparisionData);

            // Actualizar el estado con toda la información de comparación
            this.setState({
                comparision: comparisionData,
                fPhoto: firstEventValue,
                sPhoto: secondEventValue,
                idFPhoto: firstEventKey,
                idSPhoto: secondEventKey,
                loading: false,
            });
        } catch (err) {
            console.log("Error al obtener la información adicional del contrato:", err);
            this.setState({ loading: false });
        }
    }

    // Actualiza las dimensiones de la imagen cuando la imagen se carga
    handleImageLoad = (event, index) => {
        const img = event.target;
        if (img) {
            const imgWidth = img.clientWidth;
            const imgHeight = img.clientHeight;
            this.setState((prevState) => ({
                imgData: {
                    ...prevState.imgData,
                    [index]: {
                        imgWidth,      // ancho renderizado de la imagen
                        imgHeight,     // alto renderizado de la imagen
                        naturalWidth: img.naturalWidth,
                        naturalHeight: img.naturalHeight,
                    },
                },
            }));
        }
    };





    getInfoContratoFake = async () => {
        let token = localStorage.getItem('tkn');
        let data = {
            "contrato": this.props.contractR
        }
        try {
            const response = await axios.post(
                process.env.REACT_APP_SERVER + '/operaciones/getinfocontrato',
                JSON.stringify(data),
                {
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                    withCredentials: true
                });
            console.log("Info de contrato fake:", response.data.data);
            this.setState({ infocontratoFake: response.data.data }, () => {
                console.log("Info de contrato fake actualizado en el estado:", this.state.infocontratoFake);
                this.getReportesdevice(this.props.contrato);
            });

        } catch (error) {
            console.log(error);
        }
    }


    async getReportesdevice(contrato) {
        let data = {};
        if (this.props.contractR === -1 || this.props.contractR === undefined) {
            console.log("entre normal")

            data = {
                "contrato": contrato.ContractID,
            };
        } else {
            console.log("entro por input")
            data = {
                "contrato": this.props.contractR,
            };
        }
        try {
            if (contrato.FKLokTipoEquipo === 8) {

                console.log(data);
                const json = JSON.stringify(data);
                const response = await axios.post(process.env.REACT_APP_SERVER + '/operaciones/getreportesdevicevalitronics', json, {
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
                    withCredentials: true
                });
                if (response.data.success) {
                    console.log(response.data.data);
                    this.setState({ recorrido: response.data.data });
                } else {
                    console.log("no entrooooooooo");
                }
            } else {
                let data = {};
                if (this.props.contractR === -1 || this.props.contractR === undefined) {
                    console.log("entre normal")

                    data = {
                        "fechainicio": contrato.fecha,
                        "fechafin": contrato.fechafin,
                        "device": contrato.FKLokDeviceID,
                        "utcMinutos": 5,
                        "allreport": 1,
                        "tipo": contrato.Active,
                        "tipoequipo": contrato.FKLokTipoEquipo,
                    };
                } else {
                    // this.getInfoContratoFake();
                    console.log("entro por input");
                    console.log("entro por input valor fecha inicio: ", this.state.infocontratoFake[0].ComienzoServicio);
                    console
                    data = {
                        "fechainicio": this.state.infocontratoFake[0].ComienzoServicio,
                        "fechafin": this.state.infocontratoFake[0].FinalServicio,
                        "device": this.state.infocontratoFake[0].Dispositivo,
                        "utcMinutos": 5,
                        "allreport": 1,
                        "tipo": this.state.infocontratoFake[0].isActive,
                        "tipoequipo": this.state.infocontratoFake[0].FKLokTipoEquipo,
                    };
                }

                console.log(data);
                const json = JSON.stringify(data);
                // const response = await axios.post(process.env.REACT_APP_SERVER + '/operaciones/getreportesdevice', json, {
                //     headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
                //     withCredentials: true
                // });
                const response = await axios.post(process.env.REACT_APP_SERVER + '/operaciones/getreportesdevice2', json, {
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
                    withCredentials: true
                });
                if (response.data.success) {
                    console.log(response.data.data);
                    this.setState({ recorrido: response.data.data });
                } else {
                    console.log("no entrooooooooo");
                }
            }

        } catch (error) {
            console.error(error);
        }
    }

    async getReportestrafico(contrato) {
        try {
            let data = {
                "contrato": contrato.ContractID
            };
            const json = JSON.stringify(data);
            const response = await axios.post(process.env.REACT_APP_SERVER + '/operaciones/getreportestrafico', json, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tkn')}` },
                withCredentials: true
            });
            if (response.data.success) {
                this.setState({ reportes: response.data.data });
            } else {
                console.log("no entrooooooooo");
            }

        } catch (error) {
            console.error(error);
        }
    }


    handlePrint = () => {
        console.log("Imprimiendo el contenido...");
        console.log("estos osn los datos a imprimir: ", this.state.infocontrato);
        const printContents = this.containerRef.current.innerHTML;
        console.log("Impresion Contenedor", printContents);
        const printTitle = `REPORTE DE SERVICIO EN TRANSITO ${this.state.infocontrato.ContractID}`;

        const newWindow = window.open('', '_blank');
        const customStyles = `
        .titleLabel{        
            font-size: 16px;
        }
        .informacionContratoReporte{
            font-size:12px;
        }
            .informacionContratoReporte .maps{
                margin-top: 50px;
                
            }
          .informacionContratoReporte .row {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 10px; /* Espacio entre las filas */
                }

                .informacionContratoReporte .col {
                flex: 1;
                min-width: 150px; /* Asegura que las columnas no se vuelvan demasiado estrechas */
                padding: 5px; /* Espacio interno de las columnas */
                }

                .informacionContratoReporte .col img {
                width: 100%; /* Asegura que las imágenes ocupen todo el ancho de la columna */
                height: auto; /* Mantiene la proporción de la imagen */
                }

                .cuadrophotos {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 10px; /* Ajusta el espacio entre las imágenes si es necesario */
                margin-left: 5px;
                margin-top:20px;
                }

                .cardphoto {
                background-color: #a0b5bf;
                padding: 0;
                margin: 0;
                width: 100%;
                box-sizing: border-box;
                }

                .cardphoto img {
                width: 100%;
                height: 200px;
                object-fit: cover; /* Asegura que la imagen se ajuste bien dentro del contenedor */
                }

                .cardphoto .card-text {
                font-size: 10px;
                color: #000;
                }

                .cardphoto .card-body {
                padding: 0.5rem;
                }                
                

                .comparision {
                display: flex;
                flex-direction: column;
                page-break-before: always;
                
                }

                .comparision h3 {
                margin-bottom: 40px;
                }

                .infoAditionalBody {
                height: auto;
                }

                .infoAditionalBody p {
                margin-bottom: 0;
                font-size: 10px;
                }

                .comparision .comparisionImgs {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                }

                .comparision-item {
                margin-bottom: 20px;
                }

                .cardphotoComparative {
                position: relative;
                display: inline-block;
                width: calc(46% - 10px);
                margin-bottom: 20px;
                }

                .cardphotoComparative img {
                display: block;
                width: 100%;
                height: 200px;
                border-radius: 8px;
                }

                .cardphotoComparative::before,
                .cardphotoComparative::after {
                content: '';
                position: absolute;
                top: 1px;
                left: 1px;
                right: 1px;
                bottom: 1px;
                border: 2px solid;
                border-radius: 8px;
                opacity: 0;
                animation: pulse 2s infinite;
                }

                .cardphotoComparative.border-green::before,
                .cardphotoComparative.border-green::after {
                border-color: green;
                }

                .cardphotoComparative.border-yellow::before,
                .cardphotoComparative.border-yellow::after {
                border-color: yellow;
                }

                .cardphotoComparative.border-red::before,
                .cardphotoComparative.border-red::after {
                border-color: red;
                }

                .cardphotoComparative::before {
                animation-delay: 0s;
                }

                .cardphotoComparative::after {
                animation-delay: 1s;
                }

                .comparision .comparisionImgs {
                margin-top: 12px;
                }

                .comparision .comparisionResults {
                margin-top: 40px;
                margin-bottom: 30px;
                font-size:12px;
                }
             

                .tablePadre {
                    border-collapse: collapse;
                    page-break-before: always;
                }

                .tablePadre th, .tablePadre td {
                    border: 1px solid #000;
                    padding: 2px;
                }

        `;

        if (newWindow) {
            newWindow.document.write(`
                <html>
                    <head>
                        <title>${printTitle}</title>
                        <style>${customStyles}</style>
                    </head>
                    <body>
                        ${printContents}
                    </body>
                </html>
            `);

            newWindow.document.close();
            newWindow.onload = () => {
                newWindow.print();
                newWindow.close();
            };
        } else {
            console.error('No se pudo abrir la ventana de impresión.');
        }
    };










    render() {
        const { fullscreenImage, clickPosition } = this.state;
        const cardClass = `card cardphotoComparative border-${this.state.scoreColor}`;
        const ImageOverlay = this.ImageOverlay;
        return (
            <>

                <div id='containerFather'>
                    {/* <ReactToPrint
                        trigger={() => <button type="button" className="btn btn-outline-secondary float-end" onClick={this.handlePrint}><i className="bx bx-printer me-1"></i> Imprimir</button>}
                        content={() => this.containerRef.current}
                        
                    /> */}
                    <button type="button" className="btn btn-outline-secondary float-end" onClick={this.handlePrint}><i className="bx bx-printer me-1"></i> Imprimir</button>
                    <div className="container" ref={this.containerRef} id="contenedor" style={{ fontSize: '12px' }}>
                        <div className="row py-1">
                            <img id="logo" alt="" src="https://static.wixstatic.com/media/9a4347_a8dbd9ccfecd4eb2b4239eadc7369c73~mv2.png/v1/fill/w_383,h_93,al_c,lg_1,q_85,enc_auto/logo-logiseguridad2-PNG.png" style={{ width: '300px' }} />
                        </div>
                        <div className="row py-3 text-center" style={{ fontSize: '16px', textAlign: 'center', marginBottom: '24px', marginTop: '24px' }}>
                            <b>REPORTE DE SERVICIO EN TRANSITO</b>
                        </div>
                        {this.state.isSmallScreen ? (
                            <div>
                                <div className="row py-1">
                                    <div className="col"><b>CLIENTE:</b> {this.state.infocontrato.NombreEmpresa}</div>
                                    <div className="col"><b>COD. SERVICIO:</b> {this.state.infocontrato.ContractID}</div>
                                </div>
                                <div className="row py-1">
                                    <div className="col"><b>RUTA:</b> {this.state.infocontrato.DescripcionRuta}</div>
                                    <div className="col"><b>DISPOSITIVO:</b> {this.state.infocontrato.FKLokDeviceID}</div>
                                </div>
                                <div className="row py-1">
                                    <div className="col"><b>CONDUCTOR:</b> {this.state.infocontrato.NombreConductor}</div>
                                    <div className="col"><b>CELULAR:</b> {this.state.infocontrato.MovilConductor}</div>
                                </div>
                                <div className="row py-1">
                                    <div className="col"><b>PLACA CABEZOTE:</b> {this.state.infocontrato.PlacaTruck}</div>
                                    <div className="col"><b>PLACA REMOLQUE:</b> {this.state.infocontrato.PlacaTrailer}</div>
                                </div>
                                <div className="row py-1">
                                    <div className="col"><b>TRANSPORTADOR:</b> {this.state.infocontrato.NombreTranspo}</div>
                                    <div className="col"><b>INICIO SERVICIO:</b> {this.state.infocontrato.fecha}</div>
                                </div>
                                <div className="row py-1">
                                    <div className="col"><b>CONTENEDOR N°:</b> {this.state.infocontrato.ContainerNum}</div>
                                    <div className="col"><b>FINAL SERVICIO:</b> {this.state.infocontrato.fechafin}</div>
                                </div>
                                <div className="row py-1">
                                    <div className="col"><b>REFERENCIA:</b> {this.state.infocontrato.Ref}</div>
                                </div>
                                <div className="row py-3 maps">
                                    <div className="col">
                                        Ultimo Punto<br />
                                        {process.env.REACT_APP_SELECTED_MAP === 'google' ? (
                                            <StaticMap contrato={this.state.infocontrato} ultPoint={this.props.ultPunto} />

                                        ) : (
                                            <StaticMaps2 contrato={this.state.infocontrato} ultPoint={this.props.ultPunto} />
                                        )}
                                    </div>
                                    <div className="col">
                                        Recorrido<br />
                                        {process.env.REACT_APP_SELECTED_MAP === 'google' ? (
                                            <StaticMapRecorrido recorrido={this.state.recorrido} ultRecorrido={this.props.contractR} />
                                        ) : (
                                            <StaticsMapsRecorrido2 recorrido={this.state.recorrido} ultRecorrido={this.props.contractR} />
                                        )}

                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='informacionContratoReporte'>
                                <div className="row py-1">
                                    <div className="col">
                                        <b>CLIENTE:</b>
                                    </div>
                                    <div className="col">
                                        {this.state.infocontrato.NombreEmpresa}
                                    </div>
                                    <div className="col">
                                        <b>COD. SERVICIO:</b>
                                    </div>
                                    <div className="col">
                                        {this.state.infocontrato.ContractID}
                                    </div>
                                </div>
                                <div className="row py-1">
                                    <div className="col">
                                        <b>RUTA:</b>
                                    </div>
                                    <div className="col">
                                        {this.state.infocontrato.DescripcionRuta}
                                    </div>
                                    <div className="col">
                                        <b>DISPOSITIVO:</b>
                                    </div>
                                    <div className="col">
                                        {this.state.infocontrato.FKLokDeviceID}
                                    </div>
                                </div>
                                <div className="row py-1">
                                    <div className="col">
                                        <b>CONDUCTOR:</b>
                                    </div>
                                    <div className="col">
                                        {this.state.infocontrato.NombreConductor}
                                    </div>
                                    <div className="col">
                                        <b>CELULAR:</b>
                                    </div>
                                    <div className="col">
                                        {this.state.infocontrato.MovilConductor}
                                    </div>
                                </div>
                                <div className="row py-1">
                                    <div className="col">
                                        <b>PLACA CABEZOTE:</b>
                                    </div>
                                    <div className="col">
                                        {this.state.infocontrato.PlacaTruck}
                                    </div>
                                    <div className="col">
                                        <b>PLACA REMOLQUE:</b>
                                    </div>
                                    <div className="col">
                                        {this.state.infocontrato.PlacaTrailer}
                                    </div>
                                </div>
                                <div className="row py-1">
                                    <div className="col">
                                        <b>TRANSPORTADOR:</b>
                                    </div>
                                    <div className="col">
                                        {this.state.infocontrato.NombreTranspo}
                                    </div>
                                    <div className="col">
                                        <b>INICIO SERVICIO:</b>
                                    </div>
                                    <div className="col">
                                        {this.state.infocontrato.fecha}
                                    </div>
                                </div>
                                <div className="row py-1">
                                    <div className="col">
                                        <b>CONTENEDOR N°:</b>
                                    </div>
                                    <div className="col">
                                        {this.state.infocontrato.ContainerNum}
                                    </div>
                                    <div className="col">
                                        <b>FINAL SERVICIO:</b>
                                    </div>
                                    <div className="col">
                                        {this.state.infocontrato.fechafin}
                                    </div>
                                </div>
                                <div className="row py-1">
                                    <div className="col">
                                        <b>REFERENCIA:</b>
                                    </div>
                                    <div className="col">
                                        {this.state.infocontrato.Ref}
                                    </div>
                                    <div className="col">
                                    </div>
                                    <div className="col">
                                    </div>
                                </div>
                                <div className="row py-3 maps">
                                    <div className="col">
                                        Ultimo Punto<br />
                                        {process.env.REACT_APP_SELECTED_MAP === 'google' ? (
                                            <StaticMap contrato={this.state.infocontrato} ultPoint={this.props.ultPunto} />

                                        ) : (
                                            <StaticMaps2 contrato={this.state.infocontrato} ultPoint={this.props.ultPunto} />
                                        )}
                                    </div>
                                    <div className="col">
                                        Recorrido<br />
                                        {process.env.REACT_APP_SELECTED_MAP === 'google' ? (
                                            <StaticMapRecorrido recorrido={this.state.recorrido} ultRecorrido={this.props.contractR} />
                                        ) : (
                                            <StaticsMapsRecorrido2 recorrido={this.state.recorrido} ultRecorrido={this.props.contractR} />
                                        )}

                                    </div>
                                </div>
                            </div>
                        )}




                        {this.state.reportes && this.state.reportes.length > 0 && (

                            <div className="row py-3 tablaaaa">
                                <table style={{ border: '1px solid #000' }} className={this.state.reportes.length > 0 ? 'tablePadre' : ''}>
                                    <thead>
                                        <tr>
                                            <th style={{ border: '1px solid #000' }}>Fecha/Hora</th>
                                            <th style={{ border: '1px solid #000' }}>Acción</th>
                                            <th style={{ border: '1px solid #000' }}>Estado</th>
                                            <th style={{ border: '1px solid #000' }}>Ubicación</th>
                                            <th style={{ border: '1px solid #000' }}>Observaciones</th>
                                            <th style={{ border: '1px solid #000' }}>Usuario</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.loadFillData2(this.state.reportes)}
                                    </tbody>
                                </table>
                            </div>

                        )}
                        <>
                            {this.state.loading ? (
                                <div>Loading...</div>  // Muestra un indicador de carga
                            ) : (
                                this.state.comparision.length > 0 && (
                                    <div className="row py-3 comparision">
                                        <h3>Análisis de seguridad</h3>
                                        {this.state.comparision.slice().reverse().map((item, index) => (
                                            <div key={index} className="comparision-item">
                                                <div className="comparisionImgs">
                                                    {Object.values(item.events).map((photo, idx) => {
                                                        let infoPhoto = null;
                                                        console.log("photo", photo);

                                                        // Si hay fotosInfo y es un array, se busca la foto correspondiente
                                                        if (Array.isArray(item.fotosInfo)) {
                                                            infoPhoto = item.fotosInfo.find(f => f && f.photo === (photo.image || photo));
                                                            console.log("infoPhoto: ", infoPhoto);
                                                        }

                                                        // Lógica para obtener la URL de la imagen
                                                        const imageUrl = typeof photo === 'string'
                                                            ? `${infoPhoto?.Source}`
                                                            : typeof photo === 'object' && photo.image
                                                                ? `${infoPhoto?.Source}`
                                                                : null;

                                                        const cardClass = `card cardphotoComparative border-${item.scoreColor} ${this.state.clickedImage === imageUrl ? 'clicked' : ''}`;

                                                        // Si hay una URL de imagen válida, renderizamos el contenido
                                                        return imageUrl ? (
                                                            <div
                                                                key={idx}
                                                                className={cardClass}
                                                                onMouseDown={() => this.handleMouseDown(imageUrl)}
                                                                onMouseUp={this.handleMouseUp}
                                                                style={{ position: 'relative', width: 'calc(46% - 10px)' }} // Asegúrate de que este div tenga el mismo tamaño que la imagen
                                                            >
                                                                <div style={{ position: 'relative', width: '100%' }}>
                                                                    <img
                                                                        src={imageUrl}
                                                                        className="card-img-top"
                                                                        alt={`Event ${idx}`}
                                                                        ref={(el) => (this.imgRef[idx] = el)} // Guardar referencia de la imagen
                                                                        onLoad={(e) => this.handleImageLoad(e, idx)} // Manejar carga de imagen
                                                                        style={{ display: 'block', width: '100%', height: '250px' }}
                                                                    />

                                                                    {/* Dibujar bounding box principal */}
                                                                    {photo.bounding_box && this.state.imgData[idx] && (() => {
                                                                        const { imgWidth, imgHeight } = this.state.imgData[idx];
                                                                        if (!imgWidth || !imgHeight) return null;

                                                                        // Los valores ya vienen en porcentajes (valores entre 0 y 1)
                                                                        const boxLeft = photo.bounding_box.left * imgWidth;
                                                                        const boxTop = photo.bounding_box.top * imgHeight;
                                                                        const boxWidth = photo.bounding_box.width * imgWidth;
                                                                        const boxHeight = photo.bounding_box.height * imgHeight;

                                                                        return (
                                                                            <div
                                                                                className="bounding-box"
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    top: `${boxTop}px`,
                                                                                    left: `${boxLeft}px`,
                                                                                    width: `${boxWidth}px`,
                                                                                    height: `${boxHeight}px`,
                                                                                    border: '2px solid blue',
                                                                                    boxSizing: 'border-box',
                                                                                    zIndex: 1,
                                                                                }}
                                                                            />
                                                                        );
                                                                    })()}

                                                                    {/* Dibujar bounding boxes de objetos relacionados */}
                                                                    {photo.related_objects && this.state.imgData[idx] && photo.related_objects.map((relatedObj, relIdx) => {
                                                                        const { imgWidth, imgHeight } = this.state.imgData[idx];
                                                                        if (!imgWidth || !imgHeight) return null;

                                                                        const boxLeft = relatedObj.boundingBox.left * imgWidth;
                                                                        const boxTop = relatedObj.boundingBox.top * imgHeight;
                                                                        const boxWidth = relatedObj.boundingBox.width * imgWidth;
                                                                        const boxHeight = relatedObj.boundingBox.height * imgHeight;

                                                                        return (
                                                                            <div
                                                                                key={relIdx}
                                                                                className="related-bounding-box"
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    top: `${boxTop}px`,
                                                                                    left: `${boxLeft}px`,
                                                                                    width: `${boxWidth}px`,
                                                                                    height: `${boxHeight}px`,
                                                                                    border: '2px solid red',
                                                                                    boxSizing: 'border-box',
                                                                                    zIndex: 2,
                                                                                }}
                                                                            />
                                                                        );
                                                                    })}

                                                                    {this.state.isOpen && this.state.clickedImage && ReactDOM.createPortal(
                                                                        <ImageOverlay photo={this.state.clickedImage} onClose={this.handleOutsideClick} />,
                                                                        document.body
                                                                    )}
                                                                </div>

                                                                {/* Mostrar información adicional fuera de la imagen */}
                                                                {infoPhoto && (
                                                                    <div className="card-body infoAditionalBody">
                                                                        <p className="card-text">ID: {item.id}</p>
                                                                        <p className="card-text">Evento: {infoPhoto.Evento}</p>
                                                                        <p className="card-text">Fecha/Hora: {infoPhoto.Hora}</p>
                                                                    </div>
                                                                )}
                                                            </div>

                                                        ) : null;
                                                    })}
                                                </div>

                                                {/* Mostrar resultados de la comparación */}
                                                <div className="comparisionResults">
                                                    <h5 className="titleLabel">{item.type} - {item.id}</h5>
                                                    {item.results.status && item.results.status.length > 0 && (
                                                        <div>
                                                            <strong>STATUS: </strong>
                                                            {item.scores.status === 1 ? '✅' : null}
                                                            {item.scores.status === 2 ? '⚠️' : null}
                                                            {item.scores.status >= 3 ? '❌' : null}
                                                            <span>{item.results.status}</span>
                                                        </div>
                                                    )}
                                                    {item.results.alerts && (
                                                        <div>
                                                            <strong>ALERTAS:</strong>

                                                            <div>
                                                                {item.results.alerts.container_closure ? '⚠️' : '✅'}
                                                                <span>{item.results.container_closure}</span>
                                                            </div>

                                                            <div>
                                                                {item.results.alerts.physical_condition ? '⚠️' : '✅'}
                                                                <span>{item.results.physical_condition}</span>
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )


                            )}

                        </>

                        <div className="row py-3 cuadrophotos">
                            {this.loadFillData(this.state.fotos)}
                        </div>
                    </div>

                    {/* <Modal isOpen={this.state.modal} toggle={this.toggle} centered>
                        <ModalHeader toggle={this.toggle}>Foto</ModalHeader>
                        <ModalBody style={{ overflow: 'auto', textAlign: 'center' }}>
                            <img
                                src={`https://fotos.sfo2.digitaloceanspaces.com/${this.state.modalPhoto.substring(0, 10)}/${this.state.modalPhoto}`}
                                alt="Imagen en Modal"
                                onLoad={this.handleModalImageLoad}
                                style={{
                                    width: this.state.modalImageWidth ? `${this.state.modalImageWidth}px` : 'auto',
                                    height: this.state.modalImageHeight ? `${this.state.modalImageHeight}px` : 'auto',
                                    display: 'inline-block',
                                }}
                            />
                        </ModalBody>
                    </Modal> */}




                </div>
            </>
        );

    }
}

export default Reporte;
