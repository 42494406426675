import React from 'react'
import Reporte from '../../../Modulos/Reporteservicio'

const ModalReporteServicio2 = ({ infocontrato, ultPunto, contractR }) => {
    return (
        <div className="modal-dialog modal-lg">
            <form className="modal-content">
                <div className="modal-header">
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <Reporte
                            contrato={infocontrato}
                            ultPunto={ultPunto}
                            contractR={contractR} />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ModalReporteServicio2
