import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import React, { useEffect } from 'react';

const ModalInfromacionExtra = ({ contrato }) => {
    useEffect(() => {
        console.log('Información adicional');
        console.log('Contrato:', contrato);
    }, [contrato]);

    // Estilos para cada fila, etiqueta y valor
    const rowStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
    };

    const labelStyle = {
        fontWeight: 'bold',
        width: '40%', // ancho fijo para la etiqueta
        textAlign: 'left',
    };

    const valueStyle = {
        width: '60%', // ancho para el valor
        textAlign: 'left',
        wordWrap: 'break-word', // permite romper palabras largas
    };

    return (
        <div style={{ textAlign: 'center', padding: '20px', maxWidth: '500px', margin: '0 auto', marginTop: '-20px' }}>
            <FaInfoCircle size={40} color="#007bff" style={{ marginBottom: '10px' }} />
            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Información adicional</div>
            <div style={{ fontSize: '14px', marginTop: '20px', textAlign: 'left' }}>
                <div style={rowStyle}>
                    <div style={labelStyle}>PLACA:</div>
                    <div style={valueStyle}>{contrato?.PlacaTruck || "N/A"}</div>
                </div>
                <div style={rowStyle}>
                    <div style={labelStyle}>NRO CONTENEDOR:</div>
                    <div style={valueStyle}>{contrato?.ContainerNum || "N/A"}</div>
                </div>
                <div style={rowStyle}>
                    <div style={labelStyle}>TRANSPORTADORA:</div>
                    <div style={valueStyle}>{contrato?.NombreTranspo || "N/A"}</div>
                </div>
                <div style={rowStyle}>
                    <div style={labelStyle}>NOMBRE CONDUCTOR:</div>
                    <div style={valueStyle}>{contrato?.NombreConductor || "N/A"}</div>
                </div>
                <div style={rowStyle}>
                    <div style={labelStyle}>MOVIL CONDUCTOR:</div>
                    <div style={valueStyle}>{contrato?.MovilConductor || "N/A"}</div>
                </div>
            </div>
        </div>
    );
};

export default ModalInfromacionExtra;
