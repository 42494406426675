import React, { createRef } from "react";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import "./Css/themes/base/all.css";
import "../App.css";
import "./Css/trafico.css";
import Barra from "../Modulos/Barra";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { FaSearch } from "@react-icons/all-files/fa/FaSearch";
import { FaFilter } from "@react-icons/all-files/fa/FaFilter";
import { FaTools } from "@react-icons/all-files/fa/FaTools";
import { FaEye } from "@react-icons/all-files/fa/FaEye";
import { FaEyeSlash } from "@react-icons/all-files/fa/FaEyeSlash";
import { FaExpand } from "@react-icons/all-files/fa/FaExpand";
import { FaCompress } from "@react-icons/all-files/fa/FaCompress";
import Gallery from "../Modulos/Gallery";
import ReporteServicio from "../Modulos/Reporteservicio";
import ReporteDian from "../Modulos/ReporteDian";
import ReporteContrato from "../Modulos/ReporteContrato";
import VideoGallery from "../componentes/videoGallery";
import Swal from "sweetalert2";
import TraficoResponsive from "../components/responsive/TraficoResponsive";
import PrintComponent from "../components/printComponent/PrintComponent";
import { createRoot } from "react-dom/client";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import FormUpdateContrato from "../components/formsSolicitudes/formUpdateContrato";
import BaseModalCreateSolcitud from "../components/modals/baseModal";
import axios from './../utils/axiosConfig';
import RSeguimientoServicio from "../components/modals/modalRSeguimientoServicio";
import FormReporteServicio from "./../components/formsSolicitudes/formReporteServicio";
import FormUpdateContratoProyecto from "../components/formsSolicitudes/formUpdateContratoProyectos";
import { formatMinutesToHoursAndMinutes, formattedGlobalDate } from "../utils/formattedDates";
import ModalReporteConsolidadoAjustado from "../components/modals/modalReporteConsolidadoAjustado";
import FormStaticsMapsRecorrido from "../components/formsSolicitudes/formStaticsMapsRecorrido";
import ModalReporteServicio from "../components/modals/modalReporteServicio";
import BarraTrafico from "../Modulos/BarraTrafico";
import FilterBodyOffcanvas from "../components/filtersTrafico/filterBodyOffcanvas";

var table;
var miStorage = window.localStorage;
const showEmpresa = localStorage.getItem("sw-empresa") === "true";
const showUbicacionGps = localStorage.getItem("sw-ubicaciongps") === "true";
const showDispositivo = localStorage.getItem("sw-dispositivo") === "true";
const showBateria = localStorage.getItem("sw-bateria") === "true";
const showRuta = localStorage.getItem("sw-ruta") === "true";
const showEstado = localStorage.getItem("sw-estado") === "true";
const showUbicacionTrafico =
  localStorage.getItem("sw-ubicaciontrafico") === "true";
const showTiempo = localStorage.getItem("sw-tiempo") === "true";
const showMoving = localStorage.getItem("sw-moving") === "true";
const showLocked = localStorage.getItem("sw-locked") === "true";
const showBack = localStorage.getItem("sw-back") === "true";
const showDesvio = localStorage.getItem("sw-desvio") === "true";
const showSeguro = localStorage.getItem("sw-seguro") === "true";
const showPlaca = localStorage.getItem("sw-placa") === "true";
const showValitronics = localStorage.getItem("sw-valitronics") === "true";


const trueValues = [
  showEmpresa,
  showUbicacionGps,
  showDispositivo,
  showBateria,
  showRuta,
  showEstado,
  showUbicacionTrafico,
  showTiempo,
  showMoving,
  showLocked,
  showBack,
  showDesvio,
  showSeguro,
  showPlaca,
  showValitronics
];

const columnasfijadas = trueValues.filter((value) => value === true).length + 1;

const videos2 = [
  {
    src: "https://www.youtube.com/watch?v=LXb3EKWsInQ",
    subHtml: `<h4>'Peck Pocketed' by Kevin Herron | Disney Favorite</h4>`,
  },
  {
    src: "https://www.youtube.com/watch?v=ttLu7ygaN6I",
    subHtml: `<h4>Forest Path - Unreal Engine 5 Cinematic by Sharkyy</h4>`,
  },
  {
    src: "https://www.youtube.com/watch?v=C3vyugaBhSs",
    subHtml: `<h4>UE5 | In The Heart Of The Forest by Anastasia Gorban</h4>`,
  },
];

class Trafico extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listContratos: [],
      listRutas: [],
      listEventos: [],
      listComprobantes: [],
      contratoseleccionado: -1,
      contratoDian: -1,
      rutaseleccionada: -1,
      eventoseleccionado: -1,
      isShow: false,
      infocontrato: { ContractID: "ND" },
      infocontrato2: { ContractID: "ND" },
      infocontratoAjustado: { ContractID: "ND" },
      modalAbierto: false,
      modalAbierto2: false,
      sortBy: null,
      sortDirection: "asc",
      filteredContratos: [],
      isFullscreen: false,
      fondoseleccionado: "#e6eef7",
      dropdownOpenMultimedia: false,
      dropdownOpenReportes: false,
      dropdownOpenOpciones: false,
      dropdownOpenValitronics: false,
      playing: false,
      modalOpen: false,
      onHide: false,
      collapsableResponsive: false,
      listControlesColores: [],
      isDialogOpenEditSolicitud: false,
      isDialogOpenEditContratoProject: false,
      isDialogOpenRSeguimientoServicio: false,
      isDialogOpenRServicioAjustado: false,
      isDialogOpenModalReporteServicio: false,
      infoUpdateContrato: [],
      project: localStorage.getItem('project'),
      critico: false,
      countContrats: -1,
      ultPunto: -1,
      contractR: -1,
      infocontratoContainer: '',
      bitOpen: false,
      bitInsert: false,
      bitEdit: false,
      bitDelete: false,
      bitOpenTracking: false,
      bitInsertTracking: false,
      bitEditTracking: false,
      bitDeleteTracking: false,
      bitOpenMonitoreo: false,
      bitInsertMonitoreo: false,
      bitEditMonitoreo: false,
      bitDeleteMonitoreo: false,
      bitOpenUpdateContrato: false,
      bitInsertUpdateContrato: false,
      bitEditUpdateContrato: false,
      bitDeleteUpdateContrato: false,
      bitOpenUpdateContract: false,
      bitInsertUpdateContract: false,
      bitEditUpdateContract: false,
      bitDeleteUpdateContract: false,
      bitOpenActualizarContrato: false,
      bitInsertUActualizarContrato: false,
      bitEditUActualizarContrato: false,
      bitDeleteUActualizarContrato: false,

      isMovingActive: false,
      isStopActive: false,
      isStopAlertActive: false,
      isDesvioAlertActive: false,
      isLowBatteryActive: false,
      isOpenActive: false,
      isTimeExchangeActive: false,

      notificationCircle: false,
      offcanvasActive: false,

      lowBatteryCount: 0,
      openCount: 0,
      timeExchange: 0,
      movingCount: 0,
      stopCount: 0,
      stopAlertCount: 0,
      desvioCount: 0,
      differTime: 0,

      newValueFilterButton: '',
      deleteValueFilterButton: '',

      savedFilters: false,

      dataGlobal: {},

      arrayFilters: JSON.parse(localStorage.getItem('arrayFilters')) || { filters: [] },
      // speedMoving:null,
      // timeDetention:'',
      // toltipDetention:'',
    };

    this.HandlePageByRole();
    this.ListarContratos();
    this.ListarRutas();
    this.ListarEventos();
    this.listarcontrolesColores();

    this.abrirModal = this.abrirModal.bind(this);
    this.cerrarModal = this.cerrarModal.bind(this);
    this.abrirModal2 = this.abrirModal2.bind(this);
    this.cerrarModal2 = this.cerrarModal2.bind(this);


    /* manejo de this componentes hijos*/
    this.OpenGalleryPhoto = this.OpenGalleryPhoto.bind(this);
    this.OpenGalleryVideo = this.OpenGalleryVideo.bind(this);
    this.OpenUploadPhoto = this.OpenUploadPhoto.bind(this);
    this.OpenUploadVideo = this.OpenUploadVideo.bind(this);
    this.OpenReporteServicio = this.OpenReporteServicio.bind(this);
    this.OpenComprobante = this.OpenComprobante.bind(this);
    this.EditContrato = this.EditContrato.bind(this);
    this.ActualizarContratoDevice = this.ActualizarContratoDevice.bind(this);
    this.onCloseEditContract = this.onCloseEditContract.bind(this);
    this.onCloseReporteServicio = this.onCloseReporteServicio.bind(this);
    this.onCloseReporteServicioAjustado = this.onCloseReporteServicioAjustado.bind(this);
    this.onCloseModalReporteServicio = this.onCloseModalReporteServicio.bind(this);
  }

  abrirModal(data) {
    console.log(data.ContractID);
    this.setState({ modalAbierto: true, contratoDian: data.ContractID });
  }

  cerrarModal() {
    this.setState({ modalAbierto: false });
  }

  abrirModal2 = async (data) => {
    this.setState({
      dataGlobal: data,
    })
    let dataPermisos = {
      pagina: "13",
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
        JSON.stringify(dataPermisos),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );

      // Handle the response here after the request completes
      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("BIt permisos tracking: ", res.data.data);
        if (res.data.data.length > 0) {
          this.setState(
            {
              bitOpenTracking: res.data.data[0].bitOpen,
              bitInsertTracking: res.data.data[0].bitInsert,
              bitEditTracking: res.data.data[0].bitEdit,
              bitDeleteTracking: res.data.data[0].bitDelete,
            },
            () => {
              if (this.state.bitOpenTracking) {
                console.warn("Tienes todos los permisos");
                console.log("$$", data);
                console.log(data.ContractID);
                this.setState({
                  modalAbierto2: true,
                  infocontrato2: data,
                });
              } else {
                this.cerrarModal2();
                Swal.fire({
                  title: "Información",
                  text: "No tiene los permisos necesarios para acceder a esta página",
                  icon: "info",
                  timer: 3000,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                }).then(() => {

                  console.error("No tienes permisos");
                }).catch((error) => { console.log(error) });
              }
            }
          );
        }
      }
    } catch (error) {
      console.error("Error al listar bit permisos:", error);
    }
  };


  cerrarModal2() {
    const modalElement = document.getElementById("ModalReporteSegimiento");
    if (modalElement) {
      const modalInstance = bootstrap.Modal.getInstance(modalElement); // Get the Bootstrap modal instance
      if (modalInstance) {
        modalInstance.hide(); // Close the Bootstrap modal
      }
    }
    this.setState({ modalAbierto2: false }); // Also update your React state
  }

  componentDidUpdate(prevProps, prevState) {
    $("#search").keyup();

    //quiero validar si hay filtros en arrayFilters.length para activar un span palpitnate en el boton de filtros

    // Verificar si los contratos han cambiado
    if (prevState.filteredContratos !== this.state.filteredContratos) {
      // Solo actualizar el estado si ha habido un cambio
      const updatedContracts = this.state.filteredContratos.map((contrato) => {
        const storedState = JSON.parse(localStorage.getItem(`contrato_${contrato.ContractID}_expanded`)) || false;
        return {
          ...contrato,
          expanded: storedState, // Restaurar el estado de expansión desde localStorage
        };
      });

      // Solo actualizar el estado si los contratos han cambiado
      if (JSON.stringify(prevState.filteredContratos) !== JSON.stringify(updatedContracts)) {
        this.setState({ filteredContratos: updatedContracts });
      }
    }
  }


  componentDidMount() {
    // Borrar todos los estados de expansión de los contratos en localStorage al montar el componente
    Object.keys(localStorage).forEach((key) => {
      if (key.includes('_expanded')) {
        localStorage.setItem(key, JSON.stringify(false)); // Cambiar el estado de cada contrato a false (colapsado)
      }

      localStorage.setItem('search', ''); // Limpiar la búsqueda guardada



      // Restaurar la búsqueda guardada si existe
      // const busquedaGuardada = localStorage.getItem("search");
      // if (busquedaGuardada) {
      //   const event = { target: { value: busquedaGuardada } };
      //   this.handleSearchChange(event); // Aplicar la búsqueda guardada
      //   document.getElementById("search").value = busquedaGuardada; // Actualizar el input con el valor restaurado
      // }

    });

    // Leer el estado global de expansión desde localStorage
    const globalExpansionState = JSON.parse(localStorage.getItem('global_expansion_state'));

    if (globalExpansionState !== null) {
      // Si existe un estado global de expansión, aplicarlo a todos los contratos
      this.collapsable(globalExpansionState);
    }

    var busquedaGuardada = localStorage.getItem("search");
    $("#search").val(busquedaGuardada);
    $("#success-alert").hide();
    $("#danger-alert").hide();

    $("#dialog").dialog({
      autoOpen: false,
      show: {
        effect: "blind",
        duration: 1000,
      },
      hide: {
        effect: "explode",
        duration: 500,
      },
    });

    const swEmpresa = localStorage.getItem("sw-empresa");
    document.getElementById("Sw-01").checked = swEmpresa === "true";
    const swUbicacionGps = localStorage.getItem("sw-ubicaciongps");
    document.getElementById("Sw-02").checked = swUbicacionGps === "true";
    const swDispositivo = localStorage.getItem("sw-dispositivo");
    document.getElementById("Sw-03").checked = swDispositivo === "true";
    const swRuta = localStorage.getItem("sw-ruta");
    document.getElementById("Sw-04").checked = swRuta === "true";
    const swUbicacionTrafico = localStorage.getItem("sw-ubicaciontrafico");
    document.getElementById("Sw-05").checked = swUbicacionTrafico === "true";
    const swTiempo = localStorage.getItem("sw-tiempo");
    document.getElementById("Sw-06").checked = swTiempo === "true";
    const swPlaca = localStorage.getItem("sw-placa");
    document.getElementById("Sw-07").checked = swPlaca === "true";
    const swBateria = localStorage.getItem("sw-bateria");
    document.getElementById("Sw-08").checked = swBateria === "true";
    const swEstado = localStorage.getItem("sw-estado");
    document.getElementById("Sw-09").checked = swEstado === "true";
    const swMoving = localStorage.getItem("sw-moving");
    document.getElementById("Sw-11").checked = swMoving === "true";
    const swLocked = localStorage.getItem("sw-locked");
    document.getElementById("Sw-12").checked = swLocked === "true";
    const swBack = localStorage.getItem("sw-back");
    document.getElementById("Sw-13").checked = swBack === "true";
    const swDesvio = localStorage.getItem("sw-desvio");
    document.getElementById("Sw-14").checked = swDesvio === "true";
    const swSeguro = localStorage.getItem("sw-seguro");
    document.getElementById("Sw-15").checked = swSeguro === "true";
    const swValitronics = localStorage.getItem("sw-valitronics");
    document.getElementById("Sw-16").checked = swValitronics === "true";
    var self = this;
    // this.setupDataReload();



  }

  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      offcanvasActive: !prevState.offcanvasActive,
    }));
  };

  // Función para aplicar filtros
  applyFilters = (arrayFilter) => {
    console.log("Aplicando filtros:", arrayFilter);
    const searchValue = localStorage.getItem("search") || "";
    const arrayFilters = JSON.parse(localStorage.getItem('arrayFilters')) || { filters: [] };
    console.log("Aplicando filtros desde localStorage:", arrayFilters);

    // Filtrar los nuevos contratos
    const filteredContratos = this.filterContratos(this.state.filteredContratos, searchValue);
    const updatedContratos = filteredContratos;

    // Actualizar el estado de notificacion si el arreglo de filtros es es mayor a 0, es decir que exista
    const checkNotificationCircle = (arrayFilter) => {
      console.log("Verificando si hay filtros para notificación:", arrayFilter);
      if (arrayFilter) {
        this.setState({ notificationCircle: true });
      } else {
        this.setState({
          notificationCircle: false,
          isMovingActive: false,
          isStopActive: false,
          isStopAlertActive: false,
          isDesvioAlertActive: false,
          isLowBatteryActive: false,
          isOpenActive: false,
          isTimeExchangeActive: false,
        });

      }
    }


    this.setState({ arrayFilter: arrayFilter }, () => {
      console.log("Guardando filtros en localStorage:", this.state.arrayFilters);
      checkNotificationCircle(localStorage.getItem('arrayFilters'));
    });
    const storedFilters = arrayFilter;
    // const storedFilters = JSON.parse(localStorage.getItem('arrayFilters'));
    if (!storedFilters) {
      console.log('No hay filtros en localStorage');
      this.ListarContratosWithinFilters();
      //this.loadFillData(this.state.listContratos);
      // this.setState({
      //   countContrats: this.state.listContratos.length,
      // });
      return;
    }

    console.log("Aplicando filtros desde localStorage:", storedFilters);

    let contratosFiltrados = [...this.state.listContratos];

    storedFilters.filters.forEach((filter) => {
      const key = Object.keys(filter)[0];
      const value = filter[key];

      switch (key) {
        case 'filterEmpresa':
          contratosFiltrados = contratosFiltrados.filter((contrato) =>
            value.includes(contrato.NombreEmpresa)
          );
          break;
        case 'filterEstado':
          contratosFiltrados = contratosFiltrados.filter((contrato) =>
            value.includes(contrato.TipoReporte)
          );
          break;
        case 'filterRuta':
          contratosFiltrados = contratosFiltrados.filter((contrato) =>
            value.includes(contrato.DescripcionRuta)
          );
          break;
        case 'filterDispositivo':
          contratosFiltrados = contratosFiltrados.filter((contrato) =>
            value.includes(contrato.FKLokDeviceID)
          );
          break;
        case 'filterAlerta':
          contratosFiltrados = contratosFiltrados.filter((contrato) => {
            const alertIcons = [
              contrato.IconMoving,
              contrato.IconBack,
              contrato.icon_bat,
              contrato.IconDesvio,
              contrato.IconLocked,
              contrato.IconSeguro,
              contrato.IconValitronics,
            ];

            // Filtrar por íconos de alerta
            const iconResult = alertIcons.some(icon => {
              // Extraer el nombre del archivo sin la extensión
              const iconName = icon.split("/").pop().replace(/\.(png|gif)$/, "");  // RegExp para manejar .png o .gif
              return value?.alertas?.includes(iconName) && iconName !== "transparent";
            });

            // Filtrar por `tiempoUltReporte`
            let tiempoUltReporteResult = false;
            if (value.tiempoUltReporte) {

              const contratoFecha = new Date(contrato.LoksysServerTime); // LoksysServerTime debería ser una cadena de fecha válida, como "2024-10-10T15:20:52.200Z"
              const fechaActual = new Date();
              console.log("fecha actual", fechaActual);
              const diferenciaEnMinutos = Math.floor(((fechaActual - contratoFecha) / (1000 * 60)) - 300);
              const valorNumerico = parseInt(value.tiempoUltReporte, 10);
              console.log("diferecnia en minutos", diferenciaEnMinutos);
              console.log("valor de tiempo ultimo reporte", valorNumerico);
              tiempoUltReporteResult = diferenciaEnMinutos >= valorNumerico;
            }

            // Filtrar por `tiempoDetencion`
            let tiempoDetencionResult = false;
            if (value.tiempoDetencion) {
              tiempoDetencionResult =
                parseInt(contrato.tiempodetencion || 0, 10) >= parseInt(value.tiempoDetencion, 10);
            }

            return iconResult || tiempoUltReporteResult || tiempoDetencionResult;
          });
          break;
        case 'orderByCriteria':
          contratosFiltrados = this.sortByCriteria(contratosFiltrados, value);
          break;
        default:
          break;
      }
    });

    // Actualizar estadísticas
    const countOpenChain = contratosFiltrados.filter((contrato) =>
      contrato.IconLocked.includes('candado') || contrato.IconLocked.includes('openedpadlockalerta')
    ).length;
    const countLowBattery = contratosFiltrados.filter((contrato) =>
      contrato.icon_bat.includes('batt-low')
    ).length;
    const countMoving = contratosFiltrados.filter((contrato) =>
      contrato.IconMoving.includes('moving')
    ).length;
    const countStop = contratosFiltrados.filter((contrato) =>
      contrato.IconMoving.includes('stop')
    ).length;
    const countStopAlert = contratosFiltrados.filter((contrato) =>
      contrato.IconMoving.includes('stopAlert')
    ).length;
    const countDesvio = contratosFiltrados.filter((contrato) =>
      contrato.IconDesvio.includes('desvioalerta')
    ).length;

    const thirtyMinutesInMs = 30 * 60 * 1000;
    const timeExchangeContractCount = contratosFiltrados.filter((contrato) => {
      const loksysServerTime = new Date(contrato.LoksysServerTime);
      const currentTime = new Date();
      const diference = currentTime - loksysServerTime;

      return diference >= thirtyMinutesInMs;
    }).length;

    // Actualizar estado
    this.setState({
      filteredContratos: contratosFiltrados,
      countContrats: contratosFiltrados.length,
      openCount: countOpenChain,
      lowBatteryCount: countLowBattery,
      movingCount: countMoving,
      stopCount: countStop,
      stopAlertCount: countStopAlert,
      desvioCount: countDesvio,
      timeExchange: timeExchangeContractCount,
    });
  };


  filterContratos = (contratos, value) => {
    console.log("Filtrando contratos con el término de búsqueda:", value);
    console.log("Filtrando contratos con el término de búsqueda: contrato", contratos);
    return contratos.filter(contrato => {
      const lowerValue = value.toLowerCase();
      const lowerContractID = contrato.ContractID ? contrato.ContractID.toLowerCase() : "";
      const lowerNombreEmpresa = contrato.NombreEmpresa ? contrato.NombreEmpresa.toLowerCase() : "";
      const lowerCiudad = contrato.Ciudad ? contrato.Ciudad.toLowerCase() : "";
      const lowerFKLokDeviceID = contrato.FKLokDeviceID ? contrato.FKLokDeviceID.toLowerCase() : "";
      const lowerPlacaTruck = contrato.PlacaTruck ? contrato.PlacaTruck.toLowerCase() : "";
      const lowerDescripcionRuta = contrato.DescripcionRuta ? contrato.DescripcionRuta.toLowerCase() : "";
      const lowerLastReportUbica = contrato.LastReportUbica ? contrato.LastReportUbica.toLowerCase() : "";
      const lowerContainerNum = contrato.ContainerNum ? contrato.ContainerNum.toLowerCase() : "";
      const lowerRef = contrato.Ref ? contrato.Ref.toLowerCase() : "";

      return (
        lowerContractID.includes(lowerValue) ||
        lowerNombreEmpresa.includes(lowerValue) ||
        lowerCiudad.includes(lowerValue) ||
        lowerFKLokDeviceID.includes(lowerValue) ||
        lowerPlacaTruck.includes(lowerValue) ||
        lowerDescripcionRuta.includes(lowerValue) ||
        lowerLastReportUbica.includes(lowerValue) ||
        lowerContainerNum.includes(lowerValue) ||
        lowerRef.includes(lowerValue)
      );
    });
  }

  handleSearchChange = (event) => {
    const { listContratos } = this.state;
    const value = event.target.value.toLowerCase();
    // Guardar el valor de búsqueda en localStorage
    localStorage.setItem("search", value);

    // Filtra los contratos con el término de búsqueda
    const filteredContratos = this.filterContratos(listContratos, value);

    const countOpenChain = filteredContratos.filter((contrato) => contrato.IconLocked.includes('candado') || contrato.IconLocked.includes('openedpadlockalerta')).length;
    console.log(countOpenChain);

    const countLowBattery = filteredContratos.filter((contrato) => contrato.icon_bat.includes('batt-low')).length;
    console.log(countLowBattery);

    const thirtyMinutesInMs = 30 * 60 * 1000;

    const timeExchangeContractCount = filteredContratos.filter((contrato) => {
      const loksysServerTime = new Date(contrato.LoksysServerTime);
      const currentTime = new Date();
      currentTime.setHours(currentTime.getHours() - 5);
      const diference = currentTime - loksysServerTime;

      console.log("currentTime", currentTime);
      console.log("loksysServerTime", loksysServerTime);
      console.log("diference", diference);
      console.log("thirtyMinutesInMs", thirtyMinutesInMs);

      // Filtra solo aquellos contratos cuya diferencia es mayor o igual a 30 minutos
      return diference >= thirtyMinutesInMs;
    }).length;

    // Actualizar el estado con los contratos filtrados
    this.setState({
      openCount: countOpenChain,
      lowBatteryCount: countLowBattery,
      filteredContratos: filteredContratos,
      countContrats: filteredContratos.length,
      timeExchange: timeExchangeContractCount,
    });
  };

  HandlePageByRole = async () => {
    let data = {
      pagina: "1",
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true, // this should be inside the options object
        }
      );

      // Handle the response here after the request completes
      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("BIt permisos: ", res.data.data);
        if (res.data.data?.length > 0) {
          this.setState({
            bitOpen: res.data.data[0].bitOpen,
            bitInsert: res.data.data[0].bitInsert,
            bitEdit: res.data.data[0].bitEdit,
            bitDelete: res.data.data[0].bitDelete,
          }, () => {
            if (this.state.bitOpen) {
              console.warn("Tienes todos los permisos");

              return
            } else {
              Swal.fire({
                title: 'Información',
                text: 'No tiene los permisos necesarios para acceder a esta página',
                icon: 'info',
                timer: 3000, // The time in milliseconds before the alert closes (3 seconds in this case)
                showConfirmButton: false,
                allowOutsideClick: false, // Prevents clicking outside the modal to close it
                didOpen: () => {
                  Swal.showLoading(); // Shows the loading spinner
                },
                timer: 3000 // Time before redirecting (3 seconds)
              }).then(() => {
                // This will execute after the modal finishes showing
                window.location.href = "/principal"; // Redirect to another page
              });
            }

          })
        }

      }
    } catch (error) {
      console.error("Error al listar bit permisos:", error);
    }
  };




  toggleFullscreen = () => {
    if (!this.state.isFullscreen) {
      // Activar el modo de pantalla completa
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
    } else {
      // Desactivar el modo de pantalla completa
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    // Actualizar el estado
    this.setState({ isFullscreen: !this.state.isFullscreen });
  };

  // METODO PARA EDITAR TRAYECTO EN EL CONTRATO
  EditarRuta() {
    let data = {
      Trayecto: this.state.rutaseleccionada,
      Contrato: this.state.contratoseleccionado,
    };
    const json = JSON.stringify(data);
    if (this.state.rutaseleccionada != -1) {
      axios
        .post(
          process.env.REACT_APP_SERVER + "/operaciones/updatecontratotrayecto",
          json,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("tkn")}`,
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data.hasOwnProperty("success")) {
            $("#success-alert")
              .fadeTo(2000, 500)
              .slideUp(500, function () {
                $("#success-alert").slideUp(2000);
              });
            $("#ModalAsignacion .close").click();

            //history.go(0);
          } else {
            $("#danger-alert")
              .fadeTo(2000, 500)
              .slideUp(500, function () {
                $("#danger-alert").slideUp(2000);
              });
          }
        });
    } else {
      $("#danger-alert")
        .fadeTo(2000, 500)
        .slideUp(500, function () {
          $("#danger-alert").slideUp(2000);
        });
    }
  }

  async ListarContratosWithinFilters() {
    try {

      let contratosConEstadoExpandido = [];
      this.setState({ filteredContratos: this.state.listContratos }, () => {
        console.log(this.state.filteredContratos);
        contratosConEstadoExpandido = this.state.filteredContratos;
        console.log("estos son los filtros desde localStorage:", this.state.arrayFilters);

        const countOpenChain = contratosConEstadoExpandido.filter((contrato) => contrato.IconLocked.includes('candado') || contrato.IconLocked.includes('openedpadlockalerta')).length;
        console.log(countOpenChain);

        const countLowBattery = contratosConEstadoExpandido.filter((contrato) => contrato.icon_bat.includes('batt-low')).length;
        console.log(countLowBattery);

        const countMoving = contratosConEstadoExpandido.filter((contrato) => contrato.IconMoving.includes('moving')).length;
        console.log(countMoving);

        const countStop = contratosConEstadoExpandido.filter((contrato) => contrato.IconMoving.includes('stop')).length;
        console.log(countStop);

        const countStopAlert = contratosConEstadoExpandido.filter((contrato) => contrato.IconMoving.includes('stopAlert')).length;
        console.log(countStopAlert);

        const countDesvio = contratosConEstadoExpandido.filter((contrato) => contrato.IconDesvio.includes('desvioalerta')).length;
        console.log(countDesvio);

        const thirtyMinutesInMs = 30 * 60 * 1000;

        const timeExchangeContractCount = contratosConEstadoExpandido.filter((contrato) => {
          const loksysServerTime = new Date(contrato.LoksysServerTime);
          const currentTime = new Date();
          currentTime.setHours(currentTime.getHours() - 5);
          const diference = currentTime - loksysServerTime;

          console.log("currentTime", currentTime);
          console.log("loksysServerTime", loksysServerTime);
          console.log("diference", diference);
          console.log("thirtyMinutesInMs", thirtyMinutesInMs);

          // Filtra solo aquellos contratos cuya diferencia es mayor o igual a 30 minutos
          return diference >= thirtyMinutesInMs;
        }).length;
        const countContratos = contratosConEstadoExpandido.length;
        console.log("countContratos :", countContratos);
        this.setState({
          countContrats: countContratos,
          openCount: countOpenChain,
          lowBatteryCount: countLowBattery,
          timeExchange: timeExchangeContractCount,
          movingCount: countMoving,
          stopCount: countStop,
          stopAlertCount: countStopAlert,
          desvioCount: countDesvio
        });
      }

      );
      console.log(contratosConEstadoExpandido);






    } catch (error) {
      console.error("Error al listar contratos:", error);
    }
  }

  // METODO PARA LISTAR CONTRATOS ACTIVOS
  async ListarContratos() {
    const routeCritica = 'operaciones/getcontratostraficocritico';
    const routeNormal = 'operaciones/getcontratostrafico'
    const routeSelected = this.state.critico ? routeCritica : routeNormal;
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER}/${routeSelected}`, {
        withCredentials: true, // Si necesitas credenciales
      });

      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("entre a listar contratos");
        const contratosConEstadoExpandido = res.data.data?.map((contrato) => {
          // Convierte el tiempo Unix a una fecha formateada
          const formattedDate = contrato.tiempoUnix
            ? new Date(contrato.tiempoUnix * 1000).toLocaleString() // Formatea si existe tiempoUnix
            : null;

          return {
            ...contrato,
            expanded: JSON.parse(localStorage.getItem(`contrato_${contrato.ContractID}_expanded`)) || false,
            formattedDate // Agrega la fecha formateada al objeto
          };
        });
        console.log("LISTAR CONTRATOSSSSSSSS", res.data.data);
        console.log(this.state.filteredContratos);
        this.setState({ listContratos: contratosConEstadoExpandido, filteredContratos: contratosConEstadoExpandido });
        console.log(contratosConEstadoExpandido);




        console.log("estos son los filtros desde localStorage:", this.state.arrayFilters);
        const storedFilters = this.state.arrayFilters;
        if (storedFilters.filters.length > 0) {
          let contratosFiltrados = contratosConEstadoExpandido;
          storedFilters.filters.forEach(filter => {
            const key = Object.keys(filter)[0];
            const value = filter[key];

            switch (key) {
              case 'filterEmpresa':
                contratosFiltrados = contratosFiltrados.filter(contrato =>
                  value.includes(contrato.NombreEmpresa)
                );
                break;
              case 'filterEstado':
                contratosFiltrados = contratosFiltrados.filter(contrato =>
                  value.includes(contrato.TipoReporte)
                );
                break;
              case 'filterRuta':
                contratosFiltrados = contratosFiltrados.filter(contrato =>
                  value.includes(contrato.DescripcionRuta)
                );
                break;
              case 'filterDispositivo':
                contratosFiltrados = contratosFiltrados.filter(contrato =>
                  value.includes(contrato.FKLokDeviceID)
                );
                break;
              case 'filterAlerta':
                contratosFiltrados = contratosFiltrados.filter(contrato => {
                  const alertIcons = [
                    contrato.IconMoving,
                    contrato.IconBack,
                    contrato.icon_bat,
                    contrato.IconDesvio,
                    contrato.IconLocked,
                    contrato.IconSeguro,
                    contrato.IconValitronics
                  ];

                  // Filtrar por íconos de alerta
                  const iconResult = alertIcons.some(icon => {
                    // Extraer el nombre del archivo sin la extensión
                    const iconName = icon.split("/").pop().replace(/\.(png|gif)$/, "");  // RegExp para manejar .png o .gif
                    return value?.alertas?.includes(iconName) && iconName !== "transparent";
                  });

                  // Filtrar por `tiempoUltReporte`
                  let tiempoUltReporteResult = false;
                  if (value.tiempoUltReporte) {

                    const contratoFecha = new Date(contrato.LoksysServerTime); // LoksysServerTime debería ser una cadena de fecha válida, como "2024-10-10T15:20:52.200Z"
                    const fechaActual = new Date();
                    console.log("fecha actual", fechaActual);
                    const diferenciaEnMinutos = Math.floor(((fechaActual - contratoFecha) / (1000 * 60)) - 300);
                    const valorNumerico = parseInt(value.tiempoUltReporte, 10);
                    console.log("diferecnia en minutos", diferenciaEnMinutos);
                    console.log("valor de tiempo ultimo reporte", valorNumerico);
                    tiempoUltReporteResult = diferenciaEnMinutos >= valorNumerico;
                  }


                  // Filtrar por `tiempoDetencion`
                  let tiempoDetencionResult = false;
                  if (value.tiempoDetencion) {
                    const tiempoDetencionNumerico = parseInt(contrato.tiempodetencion || 0, 10);
                    const valorDetencionNumerico = parseInt(value.tiempoDetencion, 10);
                    tiempoDetencionResult = tiempoDetencionNumerico >= valorDetencionNumerico;
                  }

                  console.log("Resultado del filtro por íconos:", iconResult);
                  console.log("Resultado del filtro por tiempo último reporte:", tiempoUltReporteResult);
                  console.log("Resultado del filtro por tiempo de detención:", tiempoDetencionResult);

                  // Retorna `true` si alguno de los filtros coincide (operador OR)
                  return iconResult || tiempoUltReporteResult || tiempoDetencionResult;
                });
                break;
              case 'orderByCriteria': // Ordenar los contratos
                contratosFiltrados = this.sortByCriteria(contratosFiltrados, value);
                break;
              default:
                break;
            }
          });
          const countOpenChain = contratosFiltrados.filter((contrato) => contrato.IconLocked.includes('candado') || contrato.IconLocked.includes('openedpadlockalerta')).length;
          console.log(countOpenChain);

          const countLowBattery = contratosFiltrados.filter((contrato) => contrato.icon_bat.includes('batt-low')).length;
          console.log(countLowBattery);

          const countMoving = contratosFiltrados.filter((contrato) => contrato.IconMoving.includes('moving')).length;
          console.log(countMoving);

          const countStop = contratosFiltrados.filter((contrato) => contrato.IconMoving.includes('stop')).length;
          console.log(countStop);

          const countStopAlert = contratosFiltrados.filter((contrato) => contrato.IconMoving.includes('stopAlert')).length;
          console.log(countStopAlert);

          const countDesvio = contratosFiltrados.filter((contrato) => contrato.IconDesvio.includes('desvioalerta')).length;
          console.log(countDesvio);

          const thirtyMinutesInMs = 30 * 60 * 1000;

          const timeExchangeContractCount = contratosFiltrados.filter((contrato) => {
            const loksysServerTime = new Date(contrato.LoksysServerTime);
            const currentTime = new Date();
            currentTime.setHours(currentTime.getHours() - 5);
            const diference = currentTime - loksysServerTime;

            console.log("currentTime", currentTime);
            console.log("loksysServerTime", loksysServerTime);
            console.log("diference", diference);
            console.log("thirtyMinutesInMs", thirtyMinutesInMs);

            // Filtra solo aquellos contratos cuya diferencia es mayor o igual a 30 minutos
            return diference >= thirtyMinutesInMs;
          }).length;
          const countContratos = contratosFiltrados.length;
          console.log("countContratos :", countContratos);
          this.setState({
            // listContratos: this.state.listContratos,
            filteredContratos: contratosFiltrados,
            countContrats: countContratos,
            openCount: countOpenChain,
            lowBatteryCount: countLowBattery,
            timeExchange: timeExchangeContractCount,
            movingCount: countMoving,
            stopCount: countStop,
            stopAlertCount: countStopAlert,
            desvioCount: countDesvio
          })
        } else {
          const countOpenChain = contratosConEstadoExpandido.filter((contrato) => contrato.IconLocked.includes('candado') || contrato.IconLocked.includes('openedpadlockalerta')).length;
          console.log(countOpenChain);

          const countLowBattery = contratosConEstadoExpandido.filter((contrato) => contrato.icon_bat.includes('batt-low')).length;
          console.log(countLowBattery);

          const countMoving = contratosConEstadoExpandido.filter((contrato) => contrato.IconMoving.includes('moving')).length;
          console.log(countMoving);

          const countStop = contratosConEstadoExpandido.filter((contrato) => contrato.IconMoving.includes('stop')).length;
          console.log(countStop);

          const countStopAlert = contratosConEstadoExpandido.filter((contrato) => contrato.IconMoving.includes('stopAlert')).length;
          console.log(countStopAlert);

          const countDesvio = contratosConEstadoExpandido.filter((contrato) => contrato.IconDesvio.includes('desvioalerta')).length;
          console.log(countDesvio);

          const thirtyMinutesInMs = 30 * 60 * 1000;

          const timeExchangeContractCount = contratosConEstadoExpandido.filter((contrato) => {
            const loksysServerTime = new Date(contrato.LoksysServerTime);
            const currentTime = new Date();
            currentTime.setHours(currentTime.getHours() - 5);
            const diference = currentTime - loksysServerTime;

            console.log("currentTime", currentTime);
            console.log("loksysServerTime", loksysServerTime);
            console.log("diference", diference);
            console.log("thirtyMinutesInMs", thirtyMinutesInMs);

            // Filtra solo aquellos contratos cuya diferencia es mayor o igual a 30 minutos
            return diference >= thirtyMinutesInMs;
          }).length;
          const countContratos = contratosConEstadoExpandido.length;
          console.log("countContratos :", countContratos);
          this.setState({
            //listContratos: this.state.listContratos,
            filteredContratos: contratosConEstadoExpandido,
            countContrats: countContratos,
            openCount: countOpenChain,
            lowBatteryCount: countLowBattery,
            timeExchange: timeExchangeContractCount,
            movingCount: countMoving,
            stopCount: countStop,
            stopAlertCount: countStopAlert,
            desvioCount: countDesvio
          });
        }

      }
    } catch (error) {
      console.error("Error al listar contratos:", error);
    }
  }


  //MANEJO DE CONTRATO Y REPORTE AJUSTADO
  async handleUltPunto(newValue) {
    this.setState({
      ultPunto: newValue
    });
  }

  async handleUltRecorrido(newValue) {
    this.setState({
      contractR: newValue
    });
  }

  handleSubmitAndOpenReporteAjustado = () => {
    const { infocontrato } = this.state;

    // Mostrar en consola la información de los servicios
    // console.log('Contrato último punto:', ultPunto);
    // console.log('Contrato recorrido:', contractR);
    console.log('INFOCONTRATO:', infocontrato);

    // Hacer una copia del infocontrato para evitar mutaciones
    const infocontratoCopy = { ...infocontrato };

    // Después de actualizar el estado, abrir el modal
    this.setState({
      isDialogOpenRServicioAjustado: false,
    });
    this.OpenModalReporteServicio(infocontratoCopy);
  };


  ListarComprobantes(contrato) {
    let send = {
      contrato: contrato,
    };
    console.log(send);
    const json = JSON.stringify(send);
    axios
      .post(process.env.REACT_APP_SERVER + "/operaciones/getcomprobantevalitronics", json, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.success == false) {
          window.location = "/";
        } else {
          console.log(res.data.data);
          this.setState({ listComprobantes: res.data.data });
        }
      });
  }

  // METODO PARA LISTAR TRAYECTOS
  ListarRutas() {
    axios
      .get(process.env.REACT_APP_SERVER + "/operaciones/gettrayectos", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.success == false) {
          window.location = "/";
        } else {
          this.setState({ listRutas: res.data.data });
        }
      });
  }

  // ABRIR MODAL DE EDICION DE TRAYECTO-CONTRATO
  EditContrato = async (data) => {
    let dataPermisos = {
      pagina: "18",
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
        JSON.stringify(dataPermisos),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );

      // Handle the response here after the request completes
      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("BIt permisos tracking: ", res.data.data);
        if (res.data.data.length > 0) {
          this.setState(
            {
              bitOpenUpdateContract: res.data.data[0].bitOpen,
              bitInsertUpdateContract: res.data.data[0].bitInsert,
              bitEditUpdateContract: res.data.data[0].bitEdit,
              bitDeleteUpdateContract: res.data.data[0].bitDelete,
            },
            () => {
              if (this.state.bitOpenUpdateContract) {
                console.warn("Tienes todos los permisos");
                this.setState({ contratoseleccionado: data.ContractID });
                if (data.trayecto != 0) {
                  this.setState({ rutaseleccionada: data.trayecto });
                }
                const modalElement = document.getElementById("ModalAsignacion");
                const modalInstance3 = new bootstrap.Modal(modalElement, {
                  backdrop: 'static',
                  keyboard: false,
                });
                modalInstance3.show();
              } else {
                Swal.fire({
                  title: "Información",
                  text: "No tiene los permisos necesarios para acceder a esta opción",
                  icon: "info",
                  timer: 3000,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                }).then(() => {

                  console.error("No tienes permisos");
                });
              }
            }
          );
        }
      }
    } catch (error) {
      console.error("Error al listar bit permisos:", error);
    }

    //$( "#dialog" ).dialog( "open" );
  }

  showEditContract = async (data) => {
    let dataPermisos = {
      pagina: "19",
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
        JSON.stringify(dataPermisos),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );

      // Handle the response here after the request completes
      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("BIt permisos tracking: ", res.data.data);
        if (res.data.data.length > 0) {
          this.setState(
            {
              bitOpenActualizarContrato: res.data.data[0].bitOpen,
              bitInsertUActualizarContrato: res.data.data[0].bitInsert,
              bitEditUActualizarContrato: res.data.data[0].bitEdit,
              bitDeleteUActualizarContrato: res.data.data[0].bitDelete,
            },
            () => {
              if (this.state.bitOpenActualizarContrato) {
                console.warn("Tienes todos los permisos");
                if (this.state.project == 1) {
                  this.obtenerYEstablecerDatos(data.ContractID);
                } else {
                  this.obtenerYEstablecerDatosProyecto(data.ContractID);
                }
              } else {

                Swal.fire({
                  title: "Información",
                  text: "No tiene los permisos necesarios para acceder a esta opción",
                  icon: "info",
                  timer: 3000,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                }).then(() => {

                  console.error("No tienes permisos");
                });
              }
            }
          );
        }
      }
    } catch (error) {
      console.error("Error al listar bit permisos:", error);
    }

  }

  onCloseEditContract() {
    if (this.state.project == 1) {
      this.setState({
        isDialogOpenEditSolicitud: false
      });
    } else {
      this.setState({
        isDialogOpenEditContratoProject: false
      });
    }
  }

  onCloseReporteServicio() {
    this.setState({
      isDialogOpenRSeguimientoServicio: false,
    });
  }
  onCloseModalReporteServicio() {
    this.setState({
      isDialogOpenModalReporteServicio: false,
      ultPunto: -1,
      contractR: -1
    })
  }
  onCloseReporteServicioAjustado() {
    this.setState({
      isDialogOpenRServicioAjustado: false
    });
  }

  editarContrato() {
    this.EditarRuta();
  }

  handleChange(ruta) {
    this.setState({ rutaseleccionada: ruta });
  }

  ChangeFondo(color) {
    this.setState({ fondoseleccionado: color });
  }

  OpenReporteServicio(data) {
    console.log("Data para el modal Reporte :", data);
    this.setState({ infocontrato: data });
  }

  OpenModalReporteServicio(data) {
    console.log("DAta desde el reporteModal", data);
    this.setState(
      {
        infocontratoAjustado: data,
        isDialogOpenModalReporteServicio: true
      }
    );
  }

  OpenReporteServicioAjustado(data) {
    console.log("Data para el modal Reporte ajustado :", data);
    this.setState({ infocontrato: data, isDialogOpenRServicioAjustado: true });
  }

  OpenListCheck(data) {
    this.setState({ infocontrato: data });
  }


  //LISTAR INFO CONTRATO UPDATE
  obtenerYEstablecerDatos = async (contrato) => {
    try {
      const resContrato = await axios.post(
        process.env.REACT_APP_SERVER + "/contratos/getinfocontrato",
        JSON.stringify({ contrato }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );

      if (resContrato.success === false) {
        window.location = "/";
      } else {
        console.log("Respuesta a evaluar del contrato antes de actualizar ", resContrato);
        if (this.state.project == 1) {
          console.log("Proyecto status", this.state.project)
          this.setState({
            infoUpdateContrato: resContrato.data.data,
            isDialogOpenEditSolicitud: true,
            contratoseleccionado: contrato
          })
        } else {
          console.log("Proyecto status", this.state.project)
          this.setState({
            infoUpdateContrato: resContrato.data.data,
            isDialogOpenEditContratoProject: true,
            contratoseleccionado: contrato
          })
        }
      }
    } catch (err) {
      console.log(err);

    }
  };

  obtenerYEstablecerDatosProyecto = async (contrato) => {
    try {
      const resContrato = await axios.post(
        process.env.REACT_APP_SERVER + "/contratos/getinfocontratoproyecto",
        JSON.stringify({ contrato }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );

      if (resContrato.success === false) {
        window.location = "/";
      } else {
        console.log("Respuesta a evaluar del contrato antes de actualizar ", resContrato);
        if (this.state.project == 1) {
          console.log("Proyecto status", this.state.project)
          this.setState({
            infoUpdateContrato: resContrato.data.data,
            isDialogOpenEditSolicitud: true,
            contratoseleccionado: contrato
          })
        } else {
          console.log("Proyecto status", this.state.project)
          this.setState({
            infoUpdateContrato: resContrato.data.data,
            isDialogOpenEditContratoProject: true,
            contratoseleccionado: contrato
          })
        }
      }
    } catch (err) {
      console.log(err);

    }
  };



  // METODO PARA OBTENER LISTADO DE EVENTOS POR PROYECTO
  ListarEventos() {
    axios
      .get(process.env.REACT_APP_SERVER + "/operaciones/geteventos", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.success === false) {
          window.location = "/";
        } else {
          this.setState({ listEventos: res.data.data });
          this.setState({ eventoseleccionado: res.data.data[0].ID_Evento });
        }
      }).catch((error) => console.log(error));
  }

  // METODO PARA ACTUALIZAR EVENTO SELECCIONADO
  EditarEvento(evento) {
    this.setState({ eventoseleccionado: evento });
  }

  // ABRIR NODAL PARA SUBIR FOTOS
  OpenUploadPhoto = async (data) => {
    let dataPermisos = {
      pagina: "15",
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
        JSON.stringify(dataPermisos),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );

      // Handle the response here after the request completes
      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("BIt permisos tracking: ", res.data.data);
        if (res.data.data.length > 0) {
          this.setState(
            {
              bitOpenUploadPhoto: res.data.data[0].bitOpen,
              bitInsertUploadPhoto: res.data.data[0].bitInsert,
              bitEditUploadPhoto: res.data.data[0].bitEdit,
              bitDeleteUploadPhoto: res.data.data[0].bitDelete,
            },
            () => {
              if (this.state.bitOpenUploadPhoto) {
                console.warn("Tienes todos los permisos");
                console.warn("DATA uplodad foto", data);
                $("#fotos").val("");
                this.setState({
                  contratoseleccionado: data.ContractID,
                  username: data.username,
                  placa: data.PlacaTruck,
                  device: data.FKLokDeviceID,
                  FKLokProyecto: data.FKLokProyecto,
                  FKICEmpresa: data.FKICEmpresa,
                });
                $("#latitudF").val("0.00");
                $("#longitudF").val("0.00");
                $("#fechafoto").val(data.fecha);
                const modalElement = document.getElementById("ModalFoto");
                const modalInstance = new bootstrap.Modal(modalElement, {
                  backdrop: 'static',
                  keyboard: false,
                });
                modalInstance.show();
              } else {
                Swal.fire({
                  title: "Información",
                  text: "No tiene los permisos necesarios para acceder a esta opción",
                  icon: "info",
                  timer: 3000,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                }).then(() => {

                  console.error("No tienes permisos");
                });
              }
            }
          );
        }
      }
    } catch (error) {
      console.error("Error al listar bit permisos:", error);
    }

  }

  //ABRIR MODAL PARA SUBIR VIDEOS
  OpenUploadVideo = async (data) => {
    let dataPermisos = {
      pagina: "16",
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
        JSON.stringify(dataPermisos),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );

      // Handle the response here after the request completes
      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("BIt permisos tracking: ", res.data.data);
        if (res.data.data.length > 0) {
          this.setState(
            {
              bitOpenUploadVideo: res.data.data[0].bitOpen,
              bitInsertUploadVideo: res.data.data[0].bitInsert,
              bitEditUploadVideo: res.data.data[0].bitEdit,
              bitDeleteUploadVideo: res.data.data[0].bitDelete,
            },
            () => {
              if (this.state.bitOpenUploadVideo) {
                console.warn("Tienes todos los permisos");
                $("#videos").val("");
                this.setState({
                  contratoseleccionado: data.ContractID,
                  username: data.username,
                  placa: data.PlacaTruck,
                  device: data.FKLokDeviceID,

                });
                $("#latitudV").val("0.00");
                $("#longitudV").val("0.00");
                $("#fechaVideo").val(data.fecha);
                const modalElement = document.getElementById("ModalVideo");
                const modalInstance2 = new bootstrap.Modal(modalElement, {
                  backdrop: 'static',
                  keyboard: false,
                });
                modalInstance2.show();
              } else {
                Swal.fire({
                  title: "Información",
                  text: "No tiene los permisos necesarios para acceder a esta opción",
                  icon: "info",
                  timer: 3000,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                }).then(() => {

                  console.error("No tienes permisos");
                });
              }
            }
          );
        }
      }
    } catch (error) {
      console.error("Error al listar bit permisos:", error);
    }

  }

  // METODO PARA SUBIR FOTOS
  UploadPhoto() {
    console.log("Subiendo Foto...");
    console.log(this.state.contratoseleccionado);

    var pattern = /\d+\.\d{2,}$/;
    if (
      !pattern.test($("#latitudF").val()) ||
      !pattern.test($("#longitudF").val())
    ) {
      $("#danger-alert")
        .fadeTo(2000, 500)
        .slideUp(500, function () {
          $("#danger-alert").slideUp(2000);
        });
    } else {
      try {
        const fecha = new Date($("#fechafoto").val());
        if (isNaN(fecha.getTime())) {
          $("#danger-alert")
            .fadeTo(2000, 500)
            .slideUp(500, function () {
              $("#danger-alert").slideUp(2000);
            });
        } else {
          const year = fecha.getFullYear();
          const mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
          const dia = ("0" + fecha.getDate()).slice(-2);
          const hora = ("0" + fecha.getHours()).slice(-2);
          const minuto = ("0" + fecha.getMinutes()).slice(-2);
          const segundos = "25";
          var fechacompleta =
            year +
            "_" +
            mes +
            "_" +
            dia +
            "_" +
            hora +
            "_" +
            minuto +
            "_" +
            segundos;

          const files = $("#fotos").prop("files");
          const formData = new FormData();

          for (let i = 0; i < files.length; i++) {
            const newName =
              fechacompleta +
              "_" +
              i +
              "_" +
              this.state.device +
              "_" +
              this.state.placa +
              "_" +
              this.state.eventoseleccionado +
              "_" +
              Math.floor(Math.random() * 1000) +
              "_" +
              this.state.username +
              "_" +
              $("#latitudF").val() +
              "_" +
              $("#longitudF").val() +
              "_" +
              this.state.contratoseleccionado +
              "_" +
              this.state.FKLokProyecto +
              "_" +
              this.state.FKICEmpresa;

            if (files[i].type.startsWith("image/")) {
              formData.append("files", files[i], newName + ".jpg");
            } else if (files[i].type.startsWith("video/")) {
              formData.append("files", files[i], newName + ".mp4");
            }
            console.log("Nombre de la foto ajustado", newName)
          }
          let timerInterval;
          Swal.fire({
            title: "Subiendo Foto...",
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          });

          fetch(process.env.REACT_APP_SERVER + "/upload", {
            method: "POST",
            headers: {
              "Authorization": `Bearer ${localStorage.getItem("tkn")}`,
              // No se incluye 'Content-Type': 'multipart/form-data' porque fetch lo maneja automáticamente
            },
            body: formData
          })
            .then(response => response.json())
            .then(data => {
              Swal.close();
              if (data.hasOwnProperty("success")) {
                $("#success-alert")
                  .fadeTo(2000, 500)
                  .slideUp(500, function () {
                    $("#success-alert").slideUp(2000);
                  });
                $("#ModalFoto .close").click();
              } else {
                Swal.close();
                $("#danger-alert")
                  .fadeTo(2000, 500)
                  .slideUp(500, function () {
                    $("#danger-alert").slideUp(2000);
                  });
              }
            })
            .catch(error => {
              Swal.close();
              console.error("Error subiendo foto:", error);
            });
        }
      } catch (err) {
        Swal.close();
        $("#danger-alert")
          .fadeTo(2000, 500)
          .slideUp(500, function () {
            $("#danger-alert").slideUp(2000);
          });
      }
    }
  }


  // METODO PARA SUBIR VIDEOS
  UploadVideo() {
    console.log("subiendo video...");
    var pattern = /\d+\.\d{2,}$/;
    if (
      !pattern.test($("#latitudV").val()) ||
      !pattern.test($("#longitudV").val())
    ) {
      $("#danger-alert")
        .fadeTo(2000, 500)
        .slideUp(500, function () {
          $("#danger-alert").slideUp(2000);
        });
    } else {
      try {
        const fecha = new Date($("#fechaVideo").val());
        if (isNaN(fecha.getTime())) {
          $("#danger-alert")
            .fadeTo(2000, 500)
            .slideUp(500, function () {
              $("#danger-alert").slideUp(2000);
            });
        } else {
          const year = fecha.getFullYear();
          const mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
          const dia = ("0" + fecha.getDate()).slice(-2);
          const hora = ("0" + fecha.getHours()).slice(-2);
          const minuto = ("0" + fecha.getMinutes()).slice(-2);
          const segundos = "25";
          var fechacompleta =
            year +
            "_" +
            mes +
            "_" +
            dia +
            "_" +
            hora +
            "_" +
            minuto +
            "_" +
            segundos;

          const files = $("#videos").prop("files");
          const formData = new FormData();

          for (let i = 0; i < files.length; i++) {
            const newName =
              fechacompleta +
              "_" +
              i +
              "_" +
              this.state.device +
              "_" +
              this.state.placa +
              "_" +
              this.state.eventoseleccionado +
              "_" +
              Math.floor(Math.random() * 1000) +
              "_" +
              this.state.username +
              "_" +
              $("#latitudV").val() +
              "_" +
              $("#longitudV").val();
            if (files[i].type.startsWith("image/")) {
              formData.append("files", files[i], newName + ".jpg");
            } else if (files[i].type.startsWith("video/")) {
              formData.append("files", files[i], newName + ".mp4");
            }

            //formData.append('files', files[i]);
          }
          let timerInterval;
          Swal.fire({
            title: "Subiendo Video...",
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          });

          fetch(process.env.REACT_APP_SERVER + "/uploadvideo", formData, {
            method: 'POST',
            headers: {
              "Content-Type": "multipart/form-data",
              // Authorization: `Bearer ${localStorage.getItem("tkn")}`,
            },
          })
            .then((response) => {
              Swal.close();
              if (response.data.hasOwnProperty("success")) {
                $("#success-alert")
                  .fadeTo(2000, 500)
                  .slideUp(500, function () {
                    $("#success-alert").slideUp(2000);
                  });
                $("#ModalVideo .close").click();

                //history.go(0);
              } else {
                Swal.close();
                $("#danger-alert")
                  .fadeTo(2000, 500)
                  .slideUp(500, function () {
                    $("#danger-alert").slideUp(2000);
                  });
              }
            })
            .catch((error) => {
              Swal.close();
              console.error(error);
            });
        }
      } catch (err) {
        Swal.close();
        $("#danger-alert")
          .fadeTo(2000, 500)
          .slideUp(500, function () {
            $("#danger-alert").slideUp(2000);
          });
      }
    }
  }

  showModalOpenResporteServicio = async (data) => {
    let dataPermisos = {
      pagina: "14",
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
        JSON.stringify(dataPermisos),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );

      // Handle the response here after the request completes
      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("BIt permisos tracking: ", res.data.data);
        if (res.data?.data?.length > 0) {
          this.setState(
            {
              bitOpenMonitoreo: res.data.data[0].bitOpen,
              bitInsertMonitoreo: res.data.data[0].bitInsert,
              bitEditMonitoreo: res.data.data[0].bitEdit,
              bitDeleteMonitoreo: res.data.data[0].bitDelete,
            },
            () => {
              if (this.state.bitOpenMonitoreo) {
                console.warn("Tienes todos los permisos");
                console.log("$$", data);
                console.log(data.ContractID);
                this.setState({
                  dataGlobal: data,
                  contratoseleccionado: data.ContractID,
                  isDialogOpenRSeguimientoServicio: true,
                });
              } else {
                this.onCloseReporteServicio();
                Swal.fire({
                  title: "Información",
                  text: "No tiene los permisos necesarios para acceder a esta página",
                  icon: "info",
                  timer: 3000,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                }).then(() => {

                  console.error("No tienes permisos");
                });
              }
            }
          );
        }
      }
    } catch (error) {
      console.error("Error al listar bit permisos:", error);
    }


  }

  /* Metodos para reporte consolidado ajustado 🎩*/



  /* Metodo para mostrar el contenido del dropdown */

  handleAllStatesDropdowns = () => {
    this.setState({
      dropdownOpenMultimedia: false,
      dropdownOpenReportes: false,
      dropdownOpenOpciones: false,
      dropdownOpenValitronics: false
    })

  }

  toggleDropdownMultimedia = () => {
    this.setState(prevState => ({
      dropdownOpenMultimedia: !prevState.dropdownOpenMultimedia
    }));
  };

  toggleDropdownReportes = () => {
    this.setState(prevState => ({
      dropdownOpenReportes: !prevState.dropdownOpenReportes
    }));
  }
  toggleDropdownOpciones = () => {
    this.setState(prevState => ({
      dropdownOpenOpciones: !prevState.dropdownOpenOpciones
    }));
  }
  toggleDropdownValitronics = () => {
    this.setState(prevState => ({
      dropdownOpenValitronics: !prevState.dropdownOpenValitronics
    }));
  }

  OpenGalleryPhoto(data) {
    this.setState({
      dataGlobal: data,
      contratoseleccionado: data.ContractID,
      infocontratoContainer: data.ContainerNum
    });
  }

  OpenComprobante(data) {
    this.setState({ contratoseleccionado: data.ContractID });
    this.ListarComprobantes(data.ContractID);
  }

  OpenGalleryVideo(data) {
    this.setState({ contratoseleccionado: data.ContractID });
  }

  ActualizarContratoDevice = async (data) => {
    let dataPermisos = {
      pagina: "17",
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER + "/usuarios/getconfiguracionpagina",
        JSON.stringify(dataPermisos),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tkn")}`,
          },
          withCredentials: true,
        }
      );

      // Handle the response here after the request completes
      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("BIt permisos actualizar contrato: ", res.data.data);
        if (res.data.data.length > 0) {
          this.setState(
            {
              bitOpenUpdateContrato: res.data.data[0].bitOpen,
              bitInsertUpdateContrato: res.data.data[0].bitInsert,
              bitEditUpdateContrato: res.data.data[0].bitEdit,
              bitDeleteUpdateContrato: res.data.data[0].bitDelete,
            },
            () => {
              if (this.state.bitOpenUpdateContrato) {
                console.warn("Tienes todos los permisos");
                let send = {
                  Device: data.FKLokDeviceID,
                  Contrato: data.ContractID,
                };
                const json = JSON.stringify(send);
                axios
                  .post(
                    process.env.REACT_APP_SERVER + "/operaciones/setlastcontractdevice",
                    json,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("tkn")}`,
                      },
                      withCredentials: true,
                    }
                  )
                  .then((res) => {
                    if (res.data.success) {
                      $("#success-alert")
                        .fadeTo(2000, 500)
                        .slideUp(500, function () {
                          $("#success-alert").slideUp(2000);
                        });
                    } else {
                      $("#danger-alert")
                        .fadeTo(2000, 500)
                        .slideUp(500, function () {
                          $("#danger-alert").slideUp(2000);
                        });
                    }
                  });
              } else {
                Swal.fire({
                  title: "Información",
                  text: "No tiene los permisos necesarios para acceder a esta opción",
                  icon: "info",
                  timer: 3000,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                }).then(() => {

                  console.error("No tienes permisos");
                });
              }
            }
          );
        }
      }
    } catch (error) {
      console.error("Error al listar bit permisos:", error);
    }

  }

  handleCheckboxChange(sw, valor) {
    console.log(sw + " - " + valor);
    localStorage.setItem(sw, valor);
  }

  sortData = (field) => {
    const { sortDirection } = this.state;
    const sortedData = [...this.state.filteredContratos].sort((a, b) => {
      if (
        field === "NombreEmpresa" ||
        field === "Ciudad" ||
        field === "FKLokDeviceID" ||
        field === "DescripcionRuta" ||
        field === "LastReportUbica" ||
        field === "PlacaTruck" ||
        field === "IconMoving" ||
        field === "IconLocked" ||
        field === "IconBack" ||
        field === "IconDesvio" ||
        field === "IconSeguro" ||
        field === "IconValitronics"
      ) {
        // Ordenar cadenas alfabéticamente (campo 'empresa')
        return (a[field] || "").localeCompare(b[field] || "");
      } else {
        return (a[field] || 0) - (b[field] || 0);
      }
    });

    // Cambia la dirección de orden si es necesario
    if (sortDirection === "desc") {
      sortedData.reverse();
    }

    this.setState({
      filteredContratos: sortedData,
      sortBy: field,
      sortDirection: sortDirection === "asc" ? "desc" : "asc",
    });
  };

  renderSortIcon(column) {
    const { sortBy, sortDirection } = this.state;

    if (sortBy === column) {
      if (sortDirection === "asc") {
        return <span>&uarr;</span>; // Icono de flecha hacia arriba
      } else {
        return <span>&darr;</span>; // Icono de flecha hacia abajo
      }
    }

    return null; // No mostrar ningún ícono si no está ordenado por esta columna
  }

  handleChangueCritico = async (newValue) => {
    console.log("Cambio de estados contratos criticos", newValue)
    this.setState({
      critico: newValue,
    }, async () => {
      await this.ListarContratos();
    });
  }

  eliminar() {

    var elementos = document.getElementsByClassName("object-box");
    var elementosArray = Array.from(elementos);
    elementosArray.forEach(function (elemento) {
      elemento.parentNode.removeChild(elemento);
    });
  };

  // Función para abrir el modal
  openModal = () => {
    this.setState({ modalOpen: true });
    console.log("Modal abierto");

  };

  // Función para cerrar el modal
  closeModal = () => {
    this.setState({ modalOpen: false });
    this.eliminar();
    this.setState(prevState => ({ ...prevState }));
  };

  OpenImages = async () => {
    this.cerrarModal2();
    this.OpenGalleryPhoto(this.state.dataGlobal);
    $("#ModalGaleria").modal("show");
  }

  OpenImages2 = async () => {
    this.setState({
      isDialogOpenRSeguimientoServicio: false,
    });
    this.OpenGalleryPhoto(this.state.dataGlobal);
    $("#ModalGaleria").modal("show");
  }

  OpenTracking2 = async () => {
    this.setState({
      isDialogOpenRSeguimientoServicio: false,
    });
    this.abrirModal2(this.state.dataGlobal);
    $("#ModalReporteSegimiento").modal("show");
  }

  OpenTracking = async () => {

    console.log("Entre");
    console.log("Data global", this.state.dataGlobal);
    const modalElement = document.getElementById("ModalGaleria");
    if (modalElement) {
      const modalInstance = bootstrap.Modal.getInstance(modalElement); // Get the Bootstrap modal instance
      if (modalInstance) {
        modalInstance.hide(); // Close the Bootstrap modal
      }
    }
    this.abrirModal2(this.state.dataGlobal);
    $("#ModalReporteSegimiento").modal("show");
  }

  OpenMonitoreo = async () => {
    this.cerrarModal2();
    this.showModalOpenResporteServicio(this.state.dataGlobal);
  }

  OpenMonitoreo2 = async () => {
    const modalElement = document.getElementById("ModalGaleria");
    if (modalElement) {
      const modalInstance = bootstrap.Modal.getInstance(modalElement); // Get the Bootstrap modal instance
      if (modalInstance) {
        modalInstance.hide(); // Close the Bootstrap modal
      }
    }
    this.showModalOpenResporteServicio(this.state.dataGlobal);
  }
  //METODO HANDLE PRINT 



  // Función para pausar los videos cuando se cierra el modal
  handleModalClose = () => {
    // Aquí puedes pausar los videos
    console.log('Modal cerrado, pausar los videos aquí');
    this.setState({ onHide: true });

  };

  //LISTAR CONTRATOS CONTROLES DE TRAFICO 
  listarcontrolesColores = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER}/contratos/getcontrolestrafico`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tkn")}`,
        },
        withCredentials: true,
      });

      if (res.data.success === false) {
        window.location = "/";
      } else {
        console.log("listControlesColores: ", res.data.data);
        this.setState({ listControlesColores: res.data.data });
      }
    } catch (error) {
      console.error("Error al listar los controles de colores:", error);
    }
  };



  // Nueva función para ordenar por criterios
  sortByCriteria = (contratos, criteria) => {
    console.log("Criteria", criteria);
    if (!criteria || criteria.length === 0) return contratos;

    return [...contratos].sort((a, b) => {
      for (const { field, direction } of criteria) {

        // Si el campo es un campo numérico (tiempodetencion o LoksysServerTime), realizamos la comparación numérica
        const isNumericField = field === 'tiempodetencion' || field === 'LoksysServerTime';

        if (isNumericField) {
          const valA = field === 'LoksysServerTime' ? new Date(a[field]).getTime() : Number(a[field]);
          const valB = field === 'LoksysServerTime' ? new Date(b[field]).getTime() : Number(b[field]);

          if (valA !== valB) {
            return (valA - valB) * (direction === 'asc' ? 1 : -1);
          }
        } else if (field === 'LoksysServerTime') {
          // Si el campo es una fecha, comparamos como objetos Date
          const dateA = new Date(a[field]);
          const dateB = new Date(b[field]);

          if (dateA.getTime() !== dateB.getTime()) {
            return (dateA - dateB) * (direction === 'asc' ? 1 : -1);
          }
        } else {
          // Lógica para ordenar los íconos de batería
          let imageIdMap = {
            'images/batt-full.png': 5,
            'images/batt-med1.png': 4,
            'images/batt-med2.png': 3,
            'images/batt-low.png': 2,
            'images/batt-charge.png': 1,
          };

          // Lógica para ordenar los íconos de candado
          let imageClosedPad = {
            '/images/closedpadlock.png': 3,
            '/images/candado.png': 2,
            '/images/openedpadlockalerta.gif': 1,
          };

          if (field === 'IconLocked') {
            console.log("IconLocked", a.IconLocked);
            const indexA = imageClosedPad[a.IconLocked] || Number.MAX_SAFE_INTEGER;
            const indexB = imageClosedPad[b.IconLocked] || Number.MAX_SAFE_INTEGER;

            console.log(`a.IconLocked: ${a.IconLocked}, b.IconLocked: ${b.IconLocked}`);

            console.log(`Comparando IconLocked: ${indexA} con ${indexB}`);

            if (indexA !== indexB) {
              return (indexA - indexB) * (direction === 'asc' ? 1 : -1);
            }
          } else if (field === 'icon_bat') {
            const indexA = imageIdMap[a.icon_bat] || Number.MAX_SAFE_INTEGER;
            const indexB = imageIdMap[b.icon_bat] || Number.MAX_SAFE_INTEGER;

            if (indexA !== indexB) {
              return (indexA - indexB) * (direction === 'asc' ? 1 : -1);
            }
          } else {
            // Para otros campos (alfabéticos o valores), mantenemos la comparación alfabética
            const dirMultiplier = direction === 'asc' ? 1 : -1;
            if (a[field] < b[field]) return -1 * dirMultiplier;
            if (a[field] > b[field]) return 1 * dirMultiplier;
          }
        }
      }
      return 0; // Si todos los criterios son iguales, no cambia el orden
    });
  };





  updateContratos = (newContratos) => {
    console.log("Contratos actualizados", newContratos);
    // Recuperar el valor de búsqueda del localStorage
    const searchValue = localStorage.getItem("search") || "";

    // Filtrar los nuevos contratos
    const filteredContratos = this.filterContratos(newContratos, searchValue);
    const updatedContratos = filteredContratos;


    console.log("estos son los filtros desde localStorage:", this.state.arrayFilters);
    const storedFilters = localStorage.getItem("arrayFilters") ? JSON.parse(localStorage.getItem("arrayFilters")) : { filters: [] };
    console.log(storedFilters);
    if (storedFilters.filters.length > 0) {
      let contratosFiltrados = filteredContratos;
      storedFilters.filters.forEach(filter => {
        const key = Object.keys(filter)[0];
        console.log(key);
        const value = filter[key];

        switch (key) {
          case 'filterEmpresa':
            contratosFiltrados = contratosFiltrados.filter(contrato =>
              value.includes(contrato.NombreEmpresa)
            );
            console.log(contratosFiltrados);
            break;
          case 'filterEstado':
            contratosFiltrados = contratosFiltrados.filter(contrato =>
              value.includes(contrato.TipoReporte)
            );
            break;
          case 'filterRuta':
            contratosFiltrados = contratosFiltrados.filter(contrato =>
              value.includes(contrato.DescripcionRuta)
            );
            break;
          case 'filterDispositivo':
            contratosFiltrados = contratosFiltrados.filter(contrato =>
              value.includes(contrato.FKLokDeviceID)
            );
            break;
          case 'filterAlerta':
            contratosFiltrados = contratosFiltrados.filter(contrato => {
              const alertIcons = [
                contrato.IconMoving,
                contrato.IconBack,
                contrato.icon_bat,
                contrato.IconDesvio,
                contrato.IconLocked,
                contrato.IconSeguro,
                contrato.IconValitronics
              ];

              // Filtrar por íconos de alerta
              const iconResult = alertIcons.some(icon => {
                // Extraer el nombre del archivo sin la extensión
                const iconName = icon.split("/").pop().replace(/\.(png|gif)$/, "");  // RegExp para manejar .png o .gif
                return value?.alertas?.includes(iconName) && iconName !== "transparent";
              });

              // Filtrar por `tiempoUltReporte`
              let tiempoUltReporteResult = false;
              if (value.tiempoUltReporte) {

                const contratoFecha = new Date(contrato.LoksysServerTime); // LoksysServerTime debería ser una cadena de fecha válida, como "2024-10-10T15:20:52.200Z"
                const fechaActual = new Date();
                console.log("fecha actual", fechaActual);
                const diferenciaEnMinutos = Math.floor(((fechaActual - contratoFecha) / (1000 * 60)) - 300);
                const valorNumerico = parseInt(value.tiempoUltReporte, 10);
                console.log("diferecnia en minutos", diferenciaEnMinutos);
                console.log("valor de tiempo ultimo reporte", valorNumerico);
                tiempoUltReporteResult = diferenciaEnMinutos >= valorNumerico;
              }

              // Filtrar por `tiempoDetencion`
              let tiempoDetencionResult = false;
              if (value.tiempoDetencion) {
                const tiempoDetencionNumerico = parseInt(contrato.tiempodetencion || 0, 10);
                const valorDetencionNumerico = parseInt(value.tiempoDetencion, 10);
                tiempoDetencionResult = tiempoDetencionNumerico >= valorDetencionNumerico;
              }

              console.log("Resultado del filtro por íconos:", iconResult);
              console.log("Resultado del filtro por tiempo último reporte:", tiempoUltReporteResult);
              console.log("Resultado del filtro por tiempo de detención:", tiempoDetencionResult);

              // Retorna `true` si alguno de los filtros coincide (operador OR)
              return iconResult || tiempoUltReporteResult || tiempoDetencionResult;
            });
            break;
          case 'orderByCriteria': // Ordenar los contratos
            contratosFiltrados = this.sortByCriteria(contratosFiltrados, value);
            break;
          default:
            break;
        }
      });
      const countOpenChain = contratosFiltrados.filter((contrato) => contrato.IconLocked.includes('candado') || contrato.IconLocked.includes('openedpadlockalerta')).length;
      console.log(countOpenChain);

      const countLowBattery = contratosFiltrados.filter((contrato) => contrato.icon_bat.includes('batt-low')).length;
      console.log(countLowBattery);

      const countMoving = contratosFiltrados.filter((contrato) => contrato.IconMoving.includes('moving')).length;
      console.log(countMoving);

      const countStop = contratosFiltrados.filter((contrato) => contrato.IconMoving.includes('stop')).length;
      console.log(countStop);

      const countStopAlert = contratosFiltrados.filter((contrato) => contrato.IconMoving.includes('stopAlert')).length;
      console.log(countStopAlert);

      const countDesvio = contratosFiltrados.filter((contrato) => contrato.IconDesvio.includes('desvioalerta')).length;
      console.log(countDesvio);

      const thirtyMinutesInMs = 30 * 60 * 1000;

      const timeExchangeContractCount = contratosFiltrados.filter((contrato) => {
        const loksysServerTime = new Date(contrato.LoksysServerTime);
        const currentTime = new Date();
        currentTime.setHours(currentTime.getHours() - 5);
        const diference = currentTime - loksysServerTime;

        console.log("currentTime", currentTime);
        console.log("loksysServerTime", loksysServerTime);
        console.log("diference", diference);
        console.log("thirtyMinutesInMs", thirtyMinutesInMs);

        // Filtra solo aquellos contratos cuya diferencia es mayor o igual a 30 minutos
        return diference >= thirtyMinutesInMs;
      }).length;
      console.log(contratosFiltrados.length);
      this.setState({
        openCount: countOpenChain,
        lowBatteryCount: countLowBattery,
        // listContratos: updatedContratos,
        filteredContratos: contratosFiltrados,
        countContrats: contratosFiltrados.length,
        timeExchange: timeExchangeContractCount,
        movingCount: countMoving,
        stopCount: countStop,
        stopAlertCount: countStopAlert,
        desvioCount: countDesvio
      });
    } else {
      // Actualizar el estado con los contratos filtrados
      const countOpenChain = updatedContratos.filter((contrato) => contrato.IconLocked.includes('candado') || contrato.IconLocked.includes('openedpadlockalerta')).length;
      console.log(countOpenChain);

      const countLowBattery = updatedContratos.filter((contrato) => contrato.icon_bat.includes('batt-low')).length;
      console.log(countLowBattery);

      const countMoving = updatedContratos.filter((contrato) => contrato.IconMoving.includes('moving')).length;
      console.log(countMoving);

      const countStop = updatedContratos.filter((contrato) => contrato.IconMoving.includes('stop')).length;
      console.log(countStop);

      const countStopAlert = updatedContratos.filter((contrato) => contrato.IconMoving.includes('stopAlert')).length;
      console.log(countStopAlert);

      const countDesvio = updatedContratos.filter((contrato) => contrato.IconDesvio.includes('desvioalerta')).length;
      console.log(countDesvio);

      const thirtyMinutesInMs = 30 * 60 * 1000;

      const timeExchangeContractCount = updatedContratos.filter((contrato) => {
        const loksysServerTime = new Date(contrato.LoksysServerTime);
        const currentTime = new Date();
        currentTime.setHours(currentTime.getHours() - 5);
        const diference = currentTime - loksysServerTime;

        console.log("currentTime", currentTime);
        console.log("loksysServerTime", loksysServerTime);
        console.log("diference", diference);
        console.log("thirtyMinutesInMs", thirtyMinutesInMs);

        // Filtra solo aquellos contratos cuya diferencia es mayor o igual a 30 minutos
        return diference >= thirtyMinutesInMs;
      }).length;
      console.log(filteredContratos.length);
      this.setState({
        openCount: countOpenChain,
        lowBatteryCount: countLowBattery,
        filteredContratos: filteredContratos,
        countContrats: filteredContratos.length,
        timeExchange: timeExchangeContractCount,
        movingCount: countMoving,
        stopCount: countStop,
        stopAlertCount: countStopAlert,
        desvioCount: countDesvio
      });
      console.log(this.state.filteredContratos);

    }

  };

  handleNewValueSavedFilter = (newFilters) => {
    this.setState({
      savedFilters: newFilters,
    });
  }

  updateDifferTime = (newDifferTime) => {
    console.log("Differ Time actualizado", newDifferTime);
    this.setState({
      differTime: newDifferTime,
    });
  }

  // handleFilterButtons = (value) => {
  //   const localStorageFilters = localStorage.getItem("arrayFilters")
  //     ? JSON.parse(localStorage.getItem("arrayFilters"))
  //     : { filters: [] };

  //   const { listContratos } = this.state;
  //   let contratosFiltrados = [...listContratos];
  //   let newFilters = [...localStorageFilters.filters];
  //   let countContratosActualizado = this.state.countContrats; // Mantener el contador de contratos total

  //   // Función para filtrar los contratos por un ítem específico
  //   const filterByCondition = (filterName, conditionFn) => {
  //     const filtered = contratosFiltrados.filter(conditionFn);
  //     return filtered;
  //   };

  //   // Función para actualizar el estado según el filtro
  //   const updateFilterState = (filterName, filteredData, activeStateKey) => {
  //     // Sumamos el valor actual de contratos con el valor filtrado
  //     countContratosActualizado += filteredData.length;

  //     // Actualizamos el estado con el nuevo valor acumulado
  //     this.setState({
  //       newValueFilterButton: filterName,
  //       // countContrats: countContratosActualizado,  // Actualiza el total de contratos filtrados
  //       // filteredContratos: filteredData,  // Establece los contratos filtrados
  //       [activeStateKey]: true,  // Marca el filtro como activo
  //     }, () => {
  //       this.updateContratos(filteredData);
  //     });
  //   };

  //   // Función para eliminar el filtro y restaurar el contador previo
  //   const removeFilter = (filterName, activeStateKey) => {
  //     // Filtramos los filtros existentes eliminando el filtro que se desea quitar
  //     newFilters = newFilters.filter((filter) => filter.filterButton !== filterName);

  //     // Calculamos cuántos contratos fueron eliminados por este filtro
  //     const filteredOutContracts = this.state.filteredContratos.filter(contrato => contrato.filterCondition === filterName);

  //     // Restauramos el contador, restando los contratos eliminados
  //     const countContratosRestaurado = countContratosActualizado - filteredOutContracts.length;

  //     this.setState(
  //       {
  //         deleteValueFilterButton: filterName,
  //         [activeStateKey]: false,  // Marca el filtro como no activo
  //         countContrats: countContratosRestaurado,  // Restauramos el contador
  //       },
  //       () => {
  //         // Aplicamos los filtros restantes
  //         this.applyFilters({ filters: newFilters });
  //       }
  //     );
  //   };

  //   // Mapeo de los filtros
  //   const filtersMapping = {
  //     moving: () => {
  //       if (!this.state.isMovingActive) {
  //         const filtered = filterByCondition(value, (contrato) =>
  //           contrato.IconMoving.includes('moving')
  //         );
  //         newFilters.push({ filterButton: value });
  //         updateFilterState(value, filtered, "isMovingActive");
  //       } else {
  //         removeFilter(value, "isMovingActive");
  //       }
  //     },
  //     stop: () => {
  //       if (!this.state.isStopActive) {
  //         const filtered = filterByCondition(value, (contrato) =>
  //           contrato.IconMoving.includes('stop')
  //         );
  //         newFilters.push({ filterButton: value });
  //         updateFilterState(value, filtered, "isStopActive");
  //       } else {
  //         removeFilter(value, "isStopActive");
  //       }
  //     },
  //     stopAlert: () => {
  //       if (!this.state.isStopAlertActive) {
  //         const filtered = filterByCondition(value, (contrato) =>
  //           contrato.IconMoving.includes('stopAlert')
  //         );
  //         newFilters.push({ filterButton: value });
  //         updateFilterState(value, filtered, "isStopAlertActive");
  //       } else {
  //         removeFilter(value, "isStopAlertActive");
  //       }
  //     },
  //     desvioalerta: () => {
  //       if (!this.state.isDesvioAlertActive) {
  //         const filtered = filterByCondition(value, (contrato) =>
  //           contrato.IconDesvio.includes('desvioalerta')
  //         );
  //         newFilters.push({ filterButton: value });
  //         updateFilterState(value, filtered, "isDesvioAlertActive");
  //       } else {
  //         removeFilter(value, "isDesvioAlertActive");
  //       }
  //     },
  //     'batt-low': () => {
  //       if (!this.state.isLowBatteryActive) {
  //         const filtered = filterByCondition(value, (contrato) =>
  //           contrato.icon_bat.includes('batt-low')
  //         );
  //         newFilters.push({ filterButton: value });
  //         updateFilterState(value, filtered, "isLowBatteryActive");
  //       } else {
  //         removeFilter(value, "isLowBatteryActive");
  //       }
  //     },
  //     candado: () => {
  //       if (!this.state.isOpenActive) {
  //         const filtered = filterByCondition(value, (contrato) =>
  //           contrato.IconLocked.includes('candado')
  //         );
  //         newFilters.push({ filterButton: value });
  //         updateFilterState(value, filtered, "isOpenActive");
  //       } else {
  //         removeFilter(value, "isOpenActive");
  //       }
  //     },
  //     tiempo: () => {
  //       if (!this.state.isTimeExchangeActive) {
  //         const thirtyMinutesInMs = 30 * 60 * 1000;
  //         const filtered = contratosFiltrados.filter((contrato) => {
  //           const loksysServerTime = new Date(contrato.LoksysServerTime);
  //           const currentTime = new Date();
  //           currentTime.setHours(currentTime.getHours() - 5);
  //           return currentTime - loksysServerTime >= thirtyMinutesInMs;
  //         });
  //         newFilters.push({ filterButton: value });
  //         updateFilterState(value, filtered, "isTimeExchangeActive");
  //       } else {
  //         removeFilter(value, "isTimeExchangeActive");
  //       }
  //     },
  //   };

  //   // Llamada al filtro correspondiente
  //   if (filtersMapping[value]) {
  //     filtersMapping[value]();
  //   }

  //   // Guardar los filtros en el localStorage
  //   localStorage.setItem("arrayFilters", JSON.stringify({ filters: newFilters }));
  // };



  handleFilterButtons2 = (value) => {
    console.log("Valor de filtro", value);
    if (value) {
      if (value === 'moving') {
        this.setState({
          isMovingActive: !this.state.isMovingActive,
          isStopActive: false,
          isStopAlertActive: false,
          isDesvioAlertActive: false,
          isLowBatteryActive: false,
          isOpenActive: false,
          isTimeExchangeActive: false
        });
      } else if (value === 'stop') {
        this.setState({
          isStopActive: !this.state.isStopActive,
          isMovingActive: false,
          isStopAlertActive: false,
          isDesvioAlertActive: false,
          isLowBatteryActive: false,
          isOpenActive: false,
          isTimeExchangeActive: false
        });
      } else if (value === 'stopAlert') {
        this.setState({
          isStopAlertActive: !this.state.isStopAlertActive,
          isMovingActive: false,
          isStopActive: false,
          isDesvioAlertActive: false,
          isLowBatteryActive: false,
          isOpenActive: false,
          isTimeExchangeActive: false
        });
      } else if (value === 'desvioalerta') {
        this.setState({
          isDesvioAlertActive: !this.state.isDesvioAlertActive,
          isMovingActive: false,
          isStopActive: false,
          isStopAlertActive: false,
          isLowBatteryActive: false,
          isOpenActive: false,
          isTimeExchangeActive: false
        });
      } else if (value === 'batt-low') {
        this.setState({
          isLowBatteryActive: !this.state.isLowBatteryActive,
          isMovingActive: false,
          isStopActive: false,
          isStopAlertActive: false,
          isDesvioAlertActive: false,
          isOpenActive: false,
          isTimeExchangeActive: false
        });
      } else if (value === 'candado') {
        this.setState({
          isOpenActive: !this.state.isOpenActive,
          isMovingActive: false,
          isStopActive: false,
          isStopAlertActive: false,
          isDesvioAlertActive: false,
          isLowBatteryActive: false,
          isTimeExchangeActive: false
        });
      } else if (value === 'tiempo') {
        this.setState({
          isTimeExchangeActive: !this.state.isTimeExchangeActive,
          isMovingActive: false,
          isStopActive: false,
          isStopAlertActive: false,
          isDesvioAlertActive: false,
          isLowBatteryActive: false,
          isOpenActive: false,
        });
      }
    }

  }


  render() {
    return (

      <>
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            <div className="layout-page">
              <BarraTrafico count={this.state.filteredContratos.length} filteredSolicitudes={this.state.filteredContratos} onUpdateSolicitudes={this.updateContratos} ListarContratos={this.ListarContratos} differTime={this.updateDifferTime} />

              {this.state.bitOpen && (
                <div className="content-wrapper">
                  <div className="container-fluid flex-grow-1 container-p-y">
                    <MDBRow>
                      <MDBCol md="4">
                        <label
                          className="fw-bold mb-4 label_trafico"
                          style={{ fontSize: "22px", marginRight: "10px" }}
                        >
                          {" "}
                          Control de tráfico {this.state.critico ? 'crítico' : ''} ({this.state.countContrats})
                        </label>
                        <FaEye
                          onClick={() => this.collapsable(true)}
                          style={{ marginRight: "5px" }}
                        />
                        <FaEyeSlash onClick={() => this.collapsable(false)} />
                      </MDBCol>
                      <MDBCol md={4} className='countersColorsTrafico'>
                        <div className={`counterItem ${this.state.isMovingActive ? 'active' : 'inactive'}`} onClick={() => this.handleFilterButtons2('moving')}>
                          <div className='notification'>
                            <img src="/images/moving.png" alt="Moving" title="Dispositivos en movimiento" />
                            <div className='notificationCount'>{this.state.movingCount}</div>
                          </div>
                        </div>

                        <div className={`counterItem ${this.state.isStopActive ? 'active' : 'inactive'}`} onClick={() => this.handleFilterButtons2('stop')}>
                          <div className='notification'>
                            <img src="/images/stop.png" alt="Stop" title="Dispositivos detenidos" />
                            <div className='notificationCount'>{this.state.stopCount}</div>
                          </div>
                        </div>

                        <div className={`counterItem ${this.state.isStopAlertActive ? 'active' : 'inactive'}`} onClick={() => this.handleFilterButtons2('stopAlert')}>
                          <div className='notification'>
                            <img src="/images/stopAlert.png" alt="Stop Alerta" title="Dispositivos con detención prolongada" />
                            <div className='notificationCount'>{this.state.stopAlertCount}</div>
                          </div>
                        </div>

                        <div className={`counterItem ${this.state.isDesvioAlertActive ? 'active' : 'inactive'}`} onClick={() => this.handleFilterButtons2('desvioalerta')}>
                          <div className='notification'>
                            <img src="/images/desvioalerta.png" alt="Desvio" title="Alerta desvio" />
                            <div className='notificationCount'>{this.state.desvioCount}</div>
                          </div>
                        </div>

                        <div className={`counterItem ${this.state.isLowBatteryActive ? 'active' : 'inactive'}`} onClick={() => this.handleFilterButtons2('batt-low')}>
                          <div className='notification'>
                            <img src="/images/batt-low.png" alt="Batería baja" width={'30px'} height={'30px'} title="Dispositivos con batería baja" style={{ paddingLeft: 5, paddingRight: 5 }} />
                            <div className='notificationCount'>{this.state.lowBatteryCount}</div>
                          </div>
                        </div>

                        <div className={`counterItem ${this.state.isOpenActive ? 'active' : 'inactive'}`} onClick={() => this.handleFilterButtons2('candado')}>
                          <div className='notification'>
                            <img src="/images/openedpadlockalerta.gif" alt="Candado abierto" title="Dispositivos abiertos" width={30} />
                            <div className='notificationCount'>{this.state.openCount}</div>
                          </div>
                        </div>

                        <div className={`counterItem ${this.state.isTimeExchangeActive ? 'active' : 'inactive'}`} onClick={() => this.handleFilterButtons2('tiempo')}>
                          <div className='notification'>
                            <img src="/images/timer-regular-24.png" alt="Temporizador" width={'30px'} height={'30px'} title="Contratos con tiempos excedidos a 30 minutos" />
                            <div className='notificationCount'>{this.state.timeExchange}</div>
                          </div>
                        </div>
                      </MDBCol>


                      <MDBCol md="2" className="FilterSearchTraficc">
                        <div className="input-group col-md-12">
                          <span className="input-group-text" id="b1">
                            <FaSearch />
                          </span>
                          <input
                            id="search"
                            type="text"
                            className="form-control"
                            placeholder="Filtrar"
                            aria-describedby="b1"
                            onKeyUp={this.handleSearchChange}
                          />
                        </div>
                      </MDBCol>
                      <MDBCol md="2" className="d-flex flex-row align-items-center filterTraficCss" style={{ height: '38.94px' }}>
                        <button
                          style={{
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 0,
                            position: "relative",

                          }}
                          className={`btn btn-outline-secondary ${this.state.notificationCircle ? "active" : ""
                            }`}
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasFilter"
                          aria-controls="offcanvasFilter"
                          onClick={this.toggleOffcanvas}
                        >
                          <FaFilter />
                          {this.state.notificationCircle && <div className="notification-badge"></div>}
                        </button>

                        <button
                          style={{
                            marginLeft: '12px',
                            width: '40px', // Ajusta el ancho según sea necesario
                            height: '40px', // Ajusta el alto según sea necesario
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 0
                          }}
                          className="btn btn-outline-secondary"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasEnd"
                          aria-controls="offcanvasEnd"
                        >
                          <FaTools style={{ fontSize: '1rem' }} />
                        </button>
                        {/* <div className="ms-2" style={{ width: '160px' }}>
                        <p className="mb-0">Tráfico crítico</p>
                        <SwitchButton checked={this.state.critico} type={''} onChange={this.handleChangueCritico} />
                      </div> */}
                        <button
                          style={{
                            marginLeft: '12px',
                            width: '40px', // Ajusta el ancho según sea necesario
                            height: '40px', // Ajusta el alto según sea necesario
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 0
                          }}
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={this.toggleFullscreen}
                        >
                          {this.state.isFullscreen ? (
                            <FaCompress />
                          ) : (
                            <FaExpand />
                          )}
                        </button>
                      </MDBCol>

                    </MDBRow>
                    <MDBRow>

                    </MDBRow>

                    <div className="card cardContainerTrafico">
                      <div
                        className="table-responsive text-nowrap"
                        style={{
                          height: `calc(100vh - 180px)`,
                          overflowY: "auto",
                          overflowX: "auto",
                          // display: "flex",
                          // flexDirection: "column",
                        }}
                      >

                        <table className="table table-hover" id="tabla">
                          <thead className="FondoHeaderTabla2">
                            <tr>
                              {showEmpresa && (
                                <th
                                  style={{ color: "#ffffff" }}
                                // onClick={() => this.sortData("NombreEmpresa")}
                                >
                                  EMPRESA {this.renderSortIcon("NombreEmpresa")}
                                </th>
                              )}
                              {showDispositivo && (
                                <th
                                  style={{ color: "#ffffff" }}
                                // onClick={() => this.sortData("FKLokDeviceID")}
                                >
                                  DISPOSITIVO{" "}
                                  {this.renderSortIcon("FKLokDeviceID")}
                                </th>
                              )}
                              {showBateria && (
                                <th
                                  style={{ color: "#ffffff", width: "70px" }}
                                // onClick={() => this.sortData("bateria")}
                                >
                                  BATT {this.renderSortIcon("bateria")}
                                </th>
                              )}
                              {showPlaca && (
                                <th
                                  style={{ color: "#ffffff", width: "100px" }}
                                // onClick={() => this.sortData("PlacaTruck")}
                                >
                                  PLACA {this.renderSortIcon("PlacaTruck")}
                                </th>
                              )}
                              {showRuta && (
                                <th
                                  style={{ color: "#ffffff" }}
                                // onClick={() => this.sortData("DescripcionRuta")}
                                >
                                  RUTA {this.renderSortIcon("DescripcionRuta")}
                                </th>
                              )}
                              {showEstado && (
                                <th style={{ color: "#ffffff", width: '110px' }}>ESTADO</th>
                              )}
                              {showUbicacionGps && (
                                <th
                                  style={{ color: "#ffffff", width: "214px" }}
                                // onClick={() => this.sortData("Ciudad")}
                                >
                                  UBIC. GPS {this.renderSortIcon("Ciudad")}
                                </th>
                              )}
                              {showUbicacionTrafico && (
                                <th
                                  style={{ color: "#ffffff" }}
                                // onClick={() => this.sortData("LastReportUbica")}
                                >
                                  UBIC. TRAFICO{" "}
                                  {this.renderSortIcon("LastReportUbica")}
                                </th>
                              )}
                              {showMoving && (
                                <th
                                  style={{ color: "#ffffff", width: "18px" }}
                                // onClick={() => this.sortData("IconMoving")}
                                >
                                  {this.renderSortIcon("IconMoving")}
                                </th>
                              )}
                              {showLocked && (
                                <th
                                  style={{ color: "#ffffff", width: "18px" }}
                                // onClick={() => this.sortData("IconLocked")}
                                >
                                  {this.renderSortIcon("IconLocked")}
                                </th>
                              )}
                              {showBack && (
                                <th
                                  style={{ color: "#ffffff", width: "18px" }}
                                // onClick={() => this.sortData("IconBack")}
                                >
                                  {this.renderSortIcon("IconBack")}
                                </th>
                              )}
                              {showDesvio && (
                                <th
                                  style={{ color: "#ffffff", width: "18px" }}
                                // onClick={() => this.sortData("IconDesvio")}
                                >
                                  {this.renderSortIcon("IconDesvio")}
                                </th>
                              )}
                              {showSeguro && (
                                <th
                                  style={{ color: "#ffffff", width: "18px" }}
                                // onClick={() => this.sortData("IconSeguro")}
                                >
                                  {this.renderSortIcon("IconSeguro")}
                                </th>
                              )}
                              {showValitronics && (
                                <th
                                  style={{ color: "#ffffff", width: "18px" }}
                                // onClick={() => this.sortData("IconValitronics")}
                                >
                                  {this.renderSortIcon("IconValitronics")}
                                </th>
                              )}
                              {showTiempo && (
                                <th
                                  style={{ color: "#ffffff", width: "100px" }}
                                // onClick={() => this.sortData("tiempoUnix")}
                                >
                                  TIEMPO {this.renderSortIcon("tiempoUnix")}
                                </th>
                              )}
                              <th
                                style={{ color: "#ffffff", width: "38px" }}
                              ></th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0" id="tabladata">
                            {this.loadFillData(this.state.filteredContratos)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="card cardContainerTrafico2">
                      <TraficoResponsive
                        showEmpresa={showEmpresa}
                        showDispositivo={showDispositivo}
                        showBateria={showBateria}
                        showPlaca={showPlaca}
                        showRuta={showRuta}
                        showEstado={showEstado}
                        showUbicacionGps={showUbicacionGps}
                        showUbicacionTrafico={showUbicacionTrafico}
                        showMoving={showMoving}
                        showLocked={showLocked}
                        showBack={showBack}
                        showDesvio={showDesvio}
                        showSeguro={showSeguro}
                        showValitronics={showValitronics}
                        showTiempo={showTiempo}
                        contratosList={this.state.listContratos}
                        renderempresa={this.renderSortIcon("NombreEmpresa")}
                        renderTiempo={this.renderSortIcon("tiempoUnix")}
                        renderdispositivo={this.renderSortIcon("FKLokDeviceID")}
                        renderbatt={this.renderSortIcon("bateria")}
                        renderplaca={this.renderSortIcon("PlacaTruck")}
                        renderruta={this.renderSortIcon("DescripcionRuta")}
                        rendergps={this.renderSortIcon("Ciudad")}
                        renderubitrafico={this.renderSortIcon("LastReportUbica")}
                        rendericonLocked={this.renderSortIcon("IconLocked")}
                        rendericonback={this.renderSortIcon("IconBack")}
                        rendericondesvio={this.renderSortIcon("IconBack")}
                        rendericonseguro={this.renderSortIcon("IconSeguro")}
                        rendericonvalitronics={this.renderSortIcon("IconValitronics")}
                        renderDropdown={() => this.handleAllStatesDropdowns()}
                        OpenReporteServicio={this.OpenReporteServicio}
                        abrirModal2={this.abrirModal2}
                        abrirModal={this.abrirModal}
                        abrirModalMonitoreo={this.showModalOpenResporteServicio}
                        toggleDropdownReportes={() => this.toggleDropdownReportes()}
                        toggleDropdownMultimedia={() => this.toggleDropdownMultimedia()}
                        toggleDropdownValitronics={() => this.toggleDropdownValitronics()}
                        toggleDropdownOpciones={() => this.toggleDropdownOpciones()}
                        dropdownOpenReportes={this.state.dropdownOpenReportes}
                        dropdownOpenMultimedia={this.state.dropdownOpenMultimedia}
                        dropdownOpenValitronics={this.state.dropdownOpenValitronics}
                        dropdownOpenOpciones={this.state.dropdownOpenOpciones}
                        EditTrayecto={this.EditContrato}
                        EditContrato={this.showEditContract}
                        ActualizarContratoDevice={this.ActualizarContratoDevice}
                        OpenComprobante={this.OpenComprobante}
                        OpenUploadPhoto={this.OpenUploadPhoto}
                        OpenUploadVideo={this.OpenUploadVideo}
                        OpenGalleryPhoto={this.OpenGalleryPhoto}
                        OpenGalleryVideo={this.OpenGalleryVideo}
                        collapsableResponsive={this.state.collapsableResponsive}
                        filteredContratos={this.state.filteredContratos}
                        project={this.state.project}
                      />
                    </div>
                  </div>
                  <div className="content-backdrop fade"></div>
                </div>
              )}
            </div>
          </div>

          <div className="layout-overlay layout-menu-toggle"></div>
        </div >

        <div
          className="offcanvas offcanvas-end"
          data-bs-scroll="true"
          data-bs-backdrop="false"
          tabIndex="-1"
          id="offcanvasEnd"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
              Control de tráfico
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <div className="row">

              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-16"
                    data-bs-dismiss="offcanvas"
                    onChange={(e) =>
                      this.handleChangueCritico(!this.state.critico)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Tráfico {this.state.critico ? 'crítico' : 'normal'}
                  </label>
                </div>
              </div>
              <h5 className="offcanvas-title" id="offcanvasScrollingLabel" style={{ marginBottom: 12 }}>
                Columnas grilla
              </h5>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-01"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-empresa", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Empresa
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-02"
                    onChange={(e) =>
                      this.handleCheckboxChange(
                        "sw-ubicaciongps",
                        e.target.checked
                      )
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Ubicación GPS
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-03"
                    onChange={(e) =>
                      this.handleCheckboxChange(
                        "sw-dispositivo",
                        e.target.checked
                      )
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Dispositivo
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-04"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-ruta", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Ruta
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-05"
                    onChange={(e) =>
                      this.handleCheckboxChange(
                        "sw-ubicaciontrafico",
                        e.target.checked
                      )
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Ubicación Trafico
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-06"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-tiempo", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Tiempo
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-07"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-placa", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Placa
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-08"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-bateria", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Bateria
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-09"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-estado", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Estado
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-11"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-moving", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Icono Movimiento
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-12"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-locked", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Icono Apertura
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-13"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-back", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Icono Back
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-14"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-desvio", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Icono Desvio
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-15"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-seguro", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Icono Asegurado
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="Sw-16"
                    onChange={(e) =>
                      this.handleCheckboxChange("sw-valitronics", e.target.checked)
                    }
                  />
                  <label className="form-check-label" for="mySwitch">
                    Icono Valitronics
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-switch custom-switch">
                  <label>Fondo </label>
                  <select
                    className="form-select"
                    defaultValue={this.state.fondoseleccionado}
                    onChange={(e) => this.ChangeFondo(e.target.value)}
                  >
                    <option value="#e6eef7">Azul</option>
                    <option value="#F5F5D1">Amarillo</option>
                  </select>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div
          className="offcanvas offcanvas-end"
          data-bs-scroll="true"
          data-bs-backdrop="false"
          tabIndex="-1"
          id="offcanvasFilter"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasScrollingLabel" style={{ display: 'flex', flexDirection: "column" }}>
              Filtros avanzados
              {localStorage.getItem("nameFilters") ? (
                <span style={{ color: '#006699' }}>
                  ({localStorage.getItem("nameFilters")})&nbsp;
                  {this.state.savedFilters ? (
                    <i
                      className="bx bx-flashing bx-pencil"
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <i
                      className="bx bx-pencil"
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </span>
              ) : (
                <span style={{ color: '#006699' }}>
                  (Sin guardar)
                </span>
              )}

            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <FilterBodyOffcanvas contratos={this.state.filteredContratos} listContratos={this.state.listContratos} onFiltersChange={this.applyFilters} savedFilter={this.handleNewValueSavedFilter} statusFilter={this.state.savedFilters} newValueFilter={this.state.newValueFilterButton} deleteNewFilter={this.state.deleteValueFilterButton} />
          </div>
        </div >

        {/* Modal edicion de contrato 🎩*/}
        < BaseModalCreateSolcitud
          isOpen={this.state.isDialogOpenEditSolicitud}
          title={'Actualizar contrato'}
          onClose={this.onCloseEditContract}
          childComponent={< FormUpdateContrato contrato={this.state.contratoseleccionado} info={this.state.infoUpdateContrato} onClose={this.onCloseEditContract} />}
        />
        {/* Modal edicion de contrato 🎩*/}

        {/* Modal ediciotn contrato proyecto */}
        <BaseModalCreateSolcitud
          isOpen={this.state.isDialogOpenEditContratoProject}
          title={'Actualizar contrato'}
          onClose={this.onCloseEditContract}
          childComponent={<FormUpdateContratoProyecto contrato={this.state.contratoseleccionado} info={this.state.infoUpdateContrato} onClose={this.onCloseEditContract} />}
        />
        {/* Modal ediciotn contrato proyecto */}

        {/* Modal seguimiento contrato */}
        <RSeguimientoServicio
          isOpen={this.state.isDialogOpenRSeguimientoServicio}
          title={'Monitoreo de tráfico'}
          onClose={this.onCloseReporteServicio}
          childComponent={<FormReporteServicio contrato={this.state.contratoseleccionado} images={this.OpenImages2} tracking={this.OpenTracking2} />}
        />
        {/* Modal seguimiento contrato */}

        {/* Modal Reporte consolidado ajustado */}
        <ModalReporteConsolidadoAjustado
          isOpen={this.state.isDialogOpenRServicioAjustado}
          title={'Reporte consolidado ajustado'}
          onClose={this.onCloseReporteServicioAjustado}
          childComponent={<FormStaticsMapsRecorrido
            infoContrato={this.state.infocontrato}
            handleUltPunto={(value) => this.handleUltPunto(value)}
            handleUltRecorrido={(value) => this.handleUltRecorrido(value)}
            onSubmit={this.handleSubmitAndOpenReporteAjustado}
          />}
        />
        {/* Modal Reporte consolidado ajustado */}
        {/* Modal Reporte servicio */}
        <ModalReporteServicio
          isOpen={this.state.isDialogOpenModalReporteServicio}
          onClose={this.onCloseModalReporteServicio}
          childComponent={<ReporteServicio
            contrato={this.state.infocontratoAjustado}
            ultPunto={this.state.ultPunto}
            contractR={this.state.contractR}
          />}
        />
        {/* Modal Reporte servicio */}


        <div
          className="modal fade"
          id="ModalAsignacion"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog">
            <form className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">
                  Asignación de trayecto
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="contrato">
                      Contrato
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-file-blank"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="contrato"
                        value={this.state.contratoseleccionado}
                        onChange={(e) => ""}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="georuta">
                      Trayecto
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname"
                        className="input-group-text"
                      >
                        <i className="bx bx-trip"></i>
                      </span>
                      <select
                        className="form-select"
                        id="georuta"
                        defaultValue={this.state.rutaseleccionada}
                        onChange={(e) => this.handleChange(e.target.value)}
                      >
                        <option key={-1} value="-1">
                          Selecciona un trayecto.
                        </option>
                        {this.state.listRutas?.map((loc) => (
                          <option key={loc.IDTrayecto} value={loc.IDTrayecto}>
                            {loc.DescripcionTrayecto}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary close"
                  data-bs-dismiss="modal"
                >
                  Cerrar
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.editarContrato()}
                >
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>


        <div
          className="modal fade"
          id="ModalFoto"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog">
            <form className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">
                  Subir fotos
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="contrato">
                      Contrato
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-file-blank"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="contrato"
                        value={this.state.contratoseleccionado}
                        onChange={(e) => ""}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="latitud">
                      Latitud
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-map-pin"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="latitudF"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="longitud">
                      Longitud
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-map-pin"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="longitudF"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="fechafoto">
                      Hora/Fecha
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-calendar"></i>
                      </span>
                      <input
                        type="datetime-local"
                        className="form-control"
                        id="fechafoto"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="eventos">
                      Evento
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-file-blank"></i>
                      </span>
                      <select
                        className="form-select"
                        id="eventos"
                        name="location-input"
                        value={this.state.eventoseleccionado}
                        onChange={(e) => this.EditarEvento(e.target.value)}
                      >
                        {this.state.listEventos?.map((loc) => (
                          <option key={loc.ID_Evento} value={loc.ID_Evento}>
                            {loc.Descripcion}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="fotos">
                      Fotos
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname"
                        className="input-group-text"
                      >
                        <i className="bx bx-file"></i>
                      </span>
                      <input
                        type="file"
                        className="form-control"
                        id="fotos"
                        multiple
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary close"
                  data-bs-dismiss="modal"
                >
                  Cerrar
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.UploadPhoto()}
                >
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>


        <div
          className="modal fade"
          id="ModalVideo"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog">
            <form className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">
                  Subir Videos
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="contrato">
                      Contrato
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-file-blank"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="contrato"
                        value={this.state.contratoseleccionado}
                        onChange={(e) => ""}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="latitud">
                      Latitud
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-map-pin"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="latitudV"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="longitud">
                      Longitud
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-map-pin"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="longitudV"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="fechaVideo">
                      Hora/Fecha
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-calendar"></i>
                      </span>
                      <input
                        type="datetime-local"
                        className="form-control"
                        id="fechaVideo"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="eventos">
                      Evento
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname2"
                        className="input-group-text"
                      >
                        <i className="bx bx-file-blank"></i>
                      </span>
                      <select
                        className="form-select"
                        id="eventos"
                        name="location-input"
                        value={this.state.eventoseleccionado}
                        onChange={(e) => this.EditarEvento(e.target.value)}
                      >
                        {this.state.listEventos?.map((loc) => (
                          <option key={loc.ID_Evento} value={loc.ID_Evento}>
                            {loc.Descripcion}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="videos">
                      Videos
                    </label>
                    <div className="input-group input-group-merge">
                      <span
                        id="basic-icon-default-fullname"
                        className="input-group-text"
                      >
                        <i className="bx bx-file"></i>
                      </span>
                      <input
                        type="file"
                        className="form-control"
                        id="videos"
                        multiple
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary close"
                  data-bs-dismiss="modal"
                >
                  Cerrar
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.UploadVideo()}
                >
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>

        <div
          className="modal fade"
          id="ModalGaleria"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-xl">
            <form className="modal-content" id="ModalGaleriaW">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">
                  FOTOS CONTRATO
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={this.eliminar}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <Gallery contrato={this.state.contratoseleccionado} infoContrato={this.state.infocontratoContainer} tracking={this.OpenTracking} monitoreo={this.OpenMonitoreo2} />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          className="modal fade"
          id="ModalVideoGaleria"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg">
            <form className="modal-content" id="ModalGaleriaW">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">
                  VIDEOS
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => { this.handleModalClose() }}
                ></button>
              </div>
              <div className="modal-body">
                <div className="container text-center">
                  <div className="row">
                    <VideoGallery contrato={this.state.contratoseleccionado} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          className="modal fade"
          id="ModalReporteServicio"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg">
            <form className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <ReporteServicio contrato={this.state.infocontrato} ultPunto={this.state.ultPunto} contractR={this.state.contractR} />
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* Modal para lista de chequeo  🎩*/}
        <div
          className="modal fade"
          id="ModalListaCheck"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg">
            <form className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <PrintComponent data={this.state.infocontrato} />
                </div>
              </div>
            </form>
          </div>
        </div>


        <div
          className="modal fade"
          id="ModalComprobanteValitronics"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg">
            <form className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <table className="table table-hover">
                    <thead className="FondoHeaderTabla2">
                      <th style={{ color: "#ffffff" }}>Comprobante</th>
                      <th style={{ color: "#ffffff" }}>fecha</th>
                      <th style={{ color: "#ffffff" }}>Latitud</th>
                      <th style={{ color: "#ffffff" }}>Longitud</th>
                      <th style={{ color: "#ffffff" }}>Usuario</th>
                    </thead>
                    <tbody id="tablacomprobante">
                      {this.loadFillData2(this.state.listComprobantes)}
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          className="modal fade"
          id="ModalReporteDian"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg">
            <form className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">
                  Contrato Dian
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <ReporteDian
                    isOpen={this.state.modalAbierto}
                    onClose={this.cerrarModal}
                    contratoVar={this.state.contratoDian}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* {this.state.bitOpenTracking && ( */}
        <div
          className="modal fade"
          id="ModalReporteSegimiento"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-hidden={!this.state.modalAbierto2}
          tabIndex="-1"
        >
          <div className="modal-dialog modal-xxl">
            <form className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="backDropModalTitle">
                  Contrato Seguimiento
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={this.cerrarModal2}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <ReporteContrato
                    isOpen={this.state.modalAbierto2}
                    onClose={this.cerrarModal2}
                    contratoVar={this.state.infocontrato2}
                    aleatorio={Math.floor(Math.random() * 1000) + 1}
                    images={this.OpenImages}
                    monitoreo={this.OpenMonitoreo}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* )} */}

        <div className="alert alert-success" id="success-alert">
          <strong>Exitoso! </strong> Los cambios han sido realizados con exito.
        </div>

        <div className="alert alert-danger" id="danger-alert">
          <strong>Error! </strong> Se ha presentado un error, valide la
          información.
        </div>

        <div id="dialog" title="Basic dialog"></div>
      </>
    );
  }

  toggleRow(contrato) {
    const updatedListContratos = this.state.filteredContratos?.map((item) => {
      if (item.ContractID === contrato) {
        const newExpandedState = !item.expanded;
        // Guardar el nuevo estado expandido en localStorage
        localStorage.setItem(`contrato_${item.ContractID}_expanded`, JSON.stringify(newExpandedState));
        return {
          ...item,
          expanded: newExpandedState,
        };
      }
      return item;
    });

    this.setState({ filteredContratos: updatedListContratos });
  }

  collapsable(estado) {
    // Guardar el estado global de expansión en localStorage
    localStorage.setItem('global_expansion_state', JSON.stringify(estado));

    const updatedListContratos = this.state.filteredContratos?.map((item) => {
      // Actualizar cada contrato con el estado global de expansión
      localStorage.setItem(`contrato_${item.ContractID}_expanded`, JSON.stringify(estado));
      return {
        ...item,
        expanded: estado,
      };
    });

    this.setState({ filteredContratos: updatedListContratos, collapsableResponsive: estado });
  }

  loadFillData2(array) {
    return this.state.listComprobantes?.map((data) => {
      return (
        <tr>
          <td className="prueba" style={{ color: "#000" }}>
            <strong>{data.Comprobante}</strong>
          </td>
          <td className="prueba" style={{ color: "#000" }}>
            {data.Fecha}
          </td>
          <td className="prueba" style={{ color: "#000" }}>
            {data.Latitud}
          </td>
          <td className="prueba" style={{ color: "#000" }}>
            {data.Longitud}
          </td>
          <td className="prueba" style={{ color: "#000" }}>
            {data.Usuario}
          </td>
        </tr>
      )
    });
  }
  loadFillData(array) {
    console.log("Array loadFill: ", this.state.filteredContratos);
    console.log("Array loadFill: ", array);

    const {
      isMovingActive,
      isStopActive,
      isStopAlertActive,
      isDesvioAlertActive,
      isLowBatteryActive,
      isOpenActive,
      isTimeExchangeActive
    } = this.state;

    let arrayFilter = [...array];
    let countContratos = 0;
    if (isMovingActive) {
      const countMoving = arrayFilter.filter((contrato) => contrato.IconMoving.includes('moving'));
      arrayFilter = countMoving;
    } else if (isStopActive) {
      const countStop = arrayFilter.filter((contrato) => contrato.IconMoving.includes('stop'));
      arrayFilter = countStop;
    } else if (isStopAlertActive) {
      const countStopAlert = arrayFilter.filter((contrato) => contrato.IconMoving.includes('stopAlert'));
      arrayFilter = countStopAlert;
    } else if (isDesvioAlertActive) {
      const countDesvioAlert = arrayFilter.filter((contrato) => contrato.IconDesvio.includes('desvioalerta'));
      arrayFilter = countDesvioAlert;
    } else if (isLowBatteryActive) {
      const countLowBattery = arrayFilter.filter((contrato) => contrato.icon_bat.includes('batt-low'));
      arrayFilter = countLowBattery;
    } else if (isOpenActive) {
      const countOpenChain = arrayFilter.filter((contrato) => contrato.IconLocked.includes('candado') || contrato.IconLocked.includes('openedpadlockalerta'));
      arrayFilter = countOpenChain;
    } else if (isTimeExchangeActive) {
      const thirtyMinutesInMs = 30 * 60 * 1000;

      const timeExchangeContractCount = arrayFilter.filter((contrato) => {
        const loksysServerTime = new Date(contrato.LoksysServerTime);
        const currentTime = new Date();
        currentTime.setHours(currentTime.getHours() - 5);
        const diference = currentTime - loksysServerTime;

        console.log("currentTime", currentTime);
        console.log("loksysServerTime", loksysServerTime);
        console.log("diference", diference);
        console.log("thirtyMinutesInMs", thirtyMinutesInMs);

        // Filtra solo aquellos contratos cuya diferencia es mayor o igual a 30 minutos
        return diference >= thirtyMinutesInMs;
      });
      arrayFilter = timeExchangeContractCount;
    } else {
      arrayFilter = array;
      console.log("No hay botones activos");
    }



    return arrayFilter?.map((data) => {
      let tooltipDetention = ``;
      if (data.IconMoving.includes('stop')) {
        const dataMin = formatMinutesToHoursAndMinutes(data.tiempodetencion);
        tooltipDetention = `EL DISPOSITIVO ESTA DETENIDO \n DESDE HACE ${dataMin}`
      } else {
        tooltipDetention = `EL DISPOSITIVO VA \n A ${data.Speed} KM/H`
      }


      return (
        <React.Fragment key={data.ContractID}>
          <tr onClick={() => this.toggleRow(data.ContractID)} id="traficoTR">
            {showEmpresa && (
              <td className="prueba" style={{ color: "#000", paddingLeft: "10px" }}>
                <strong>{data.NombreEmpresa}</strong>
              </td>
            )}
            {showDispositivo && (
              <td style={{ color: "#000" }}>{data.FKLokDeviceID}</td>
            )}
            {showBateria && (
              <td style={{ textAlign: "center" }}>
                <img src={`${data.icon_bat}`} width="20" height="25" title={data.bateria} />
              </td>
            )}
            {showPlaca && <td style={{ color: "#000" }}>{data.PlacaTruck}</td>}
            {showRuta && (
              <td style={{ color: "#000" }}>{data.DescripcionRuta}</td>
            )}
            {showEstado && (
              <td style={{ color: "#000" }}>
                {data.TipoReporte}
              </td>
            )}
            {showUbicacionGps && (
              <td style={{ color: "#000" }}>
                {data.Ciudad}
                {data.mostrargeocerca === 1 && (
                  <>
                    <br />
                    <strong>Geocerca: </strong>
                    {data.geocerca}{" "}{`(${data.LastReportgeocerca} min)`}
                  </>
                )}
              </td>
            )}
            {showUbicacionTrafico && (
              <td style={{ color: "#000" }}>{data.LastReportUbica}</td>
            )}
            {showMoving && (
              <td className="textCenterIcon">
                <img src={`${data.IconMoving}`} width="25" height="25" title={tooltipDetention} />
              </td>
            )}
            {showLocked && (
              <td>
                <img src={`${data.IconLocked}`} width="25" height="25" />
              </td>
            )}
            {showBack && (
              <td>
                <img src={`${data.IconBack}`} width="25" height="25" />
              </td>
            )}
            {showDesvio && (
              <td>
                <img src={`${data.IconDesvio}`} width="25" height="25" />
              </td>
            )}
            {showSeguro && (
              <td>
                <img src={`${data.IconSeguro}`} width="25" height="25" />
              </td>
            )}
            {showValitronics && (
              <td>
                <img src={`${data.IconValitronics}`} width="25" height="25" />
              </td>
            )}
            {showTiempo && <td>{data.Tiempo}</td>}
            <td>
              <div className="dropdown position-static">
                <button
                  type="button"
                  className="btn p-0 dropdown-toggle hide-arrow"
                  data-bs-toggle="dropdown"
                  onClick={() => this.handleAllStatesDropdowns()}
                >
                  <i className="bx bx-dots-vertical position-static"></i>
                </button>
                <div className="dropdown-menu">
                  {data.FKLokTipoEquipo !== 8 && (
                    <span
                      onClick={() => this.abrirModal2(data)}
                      data-bs-toggle="modal"
                      data-bs-target="#ModalReporteSegimiento"
                      className="dropdown-item"
                    >
                      <i className="bx bxs-map me-1"></i> Tracking Tiempo Real
                    </span>
                  )}
                  <span
                    onClick={() => this.showModalOpenResporteServicio(data)}
                    className="dropdown-item"
                  >
                    <i className="bx bx-file me-1"></i> Monitoreo de tráfico
                  </span>
                  <span className="dropdown">
                    <button onClick={(e) => { e.stopPropagation(); this.toggleDropdownReportes() }} className="dropdown-item"><i className="bx bxs-report"></i>Reportes <i className="bx bx-caret-down"></i></button>
                    {this.state.dropdownOpenReportes && (
                      <div className="containerSpaceMenu">

                        <span
                          onClick={() => this.OpenReporteServicio(data)}
                          data-bs-toggle="modal"
                          data-bs-target="#ModalReporteServicio"
                          className="dropdown-item"
                        >
                          <i className="bx bx-file me-1"></i> Reporte Consolidado
                        </span>

                        {data.FKLokTipoEquipo !== 8 && (
                          <span
                            onClick={() => this.abrirModal(data)}
                            data-bs-toggle="modal"
                            data-bs-target="#ModalReporteDian"
                            className="dropdown-item"
                          >
                            <i className="bx bx-file me-1"></i> Reporte Dian
                          </span>

                        )}
                      </div>
                    )}
                  </span>

                  <span className="dropdown">
                    <button onClick={(e) => { e.stopPropagation(); this.toggleDropdownMultimedia() }} className="dropbtn dropdown-item"><i className="bx bx-film"></i>Multimedia <i className="bx bx-caret-down"></i></button>
                    {this.state.dropdownOpenMultimedia && (
                      <div className="containerSpaceMenu">
                        <span onClick={() => this.OpenGalleryPhoto(data)} data-bs-toggle="modal" data-bs-target="#ModalGaleria" className="dropdown-item">
                          <i className="bx bx-camera me-1"></i> Imagenes / IA
                        </span>
                        <span onClick={() => this.OpenGalleryVideo(data)} data-bs-toggle="modal" data-bs-target="#ModalVideoGaleria" className="dropdown-item">
                          <i className="bx bx-video me-1"></i> Videos / IA
                        </span>
                        <span onClick={() => this.OpenUploadPhoto(data)} className="dropdown-item">
                          <i className="bx bx-upload me-1"></i> Subir Fotos
                        </span>
                        <span onClick={() => this.OpenUploadVideo(data)} className="dropdown-item">
                          <i className="bx bx-upload me-1"></i> Subir Videos
                        </span>
                      </div>
                    )}

                  </span>
                  <span className="dropdown">
                    <button onClick={(e) => { e.stopPropagation(); this.toggleDropdownValitronics() }} className="dropdown-item"><i className="bx bx-check-circle"></i>Valitronics<i className="bx bx-caret-down"></i></button>
                    {this.state.dropdownOpenValitronics && (
                      <div className="containerSpaceMenu">
                        <span
                          onClick={() => this.OpenComprobante(data)}
                          data-bs-toggle="modal" data-bs-target="#ModalComprobanteValitronics"
                          className="dropdown-item"
                        >
                          <i className="bx bx-file me-1"></i> Historial de validaciones
                        </span>
                        <span
                          onClick={() => this.OpenListCheck(data)}
                          data-bs-toggle="modal"
                          data-bs-target="#ModalListaCheck"
                          className="dropdown-item"
                        >
                          <i className="bx bx-file me-1"></i> Check List Contenedor
                        </span>
                      </div>
                    )}
                  </span>
                  <span className="dropdown">
                    <button onClick={(e) => { e.stopPropagation(); this.toggleDropdownOpciones() }} className="dropdown-item"><i className="bx bxs-cog"></i>Opciones<i className="bx bx-caret-down"></i></button>
                    {this.state.dropdownOpenOpciones && (
                      <div className="containerSpaceMenu">

                        <span
                          onClick={() => this.showEditContract(data)}
                          className="dropdown-item"
                        >
                          <i className="bx bx-edit-alt me-1"></i> Editar Contrato
                        </span>

                        <span
                          onClick={() => this.EditContrato(data)}
                          className="dropdown-item"
                        >
                          <i className="bx bx-edit-alt me-1"></i> Editar Trayecto
                        </span>
                        {data.FKLokTipoEquipo !== 8 && (
                          <span
                            onClick={() => this.ActualizarContratoDevice(data)}
                            className="dropdown-item"
                          >
                            <i className="bx bx-file me-1"></i> Actualizar contrato
                          </span>
                        )}

                      </div>
                    )}
                  </span>
                </div>
              </div>
            </td>
          </tr>
          {data.expanded && (
            <tr
              style={{
                backgroundColor: this.state.fondoseleccionado,
                borderBottom: "1px dashed #006699",
              }}
            >
              <td colSpan={`${columnasfijadas}`}>
                <div className="row">
                  <div className="col-md-2">
                    <b>Contrato:</b> {data.ContractID}
                    <br />
                    <b>Nivel riesgo:</b>
                  </div>
                  <div className="col-md-2">
                    <b>Ref:</b> {data.Ref}
                    <br />
                    <b>Container:</b> {data.ContainerNum}
                  </div>
                  <div className="col-md-3">
                    <b>Trayecto:</b> {data.DescripcionTrayecto}
                    <br />
                    <b>Ultimo Contacto:</b> {formattedGlobalDate(data.LoksysServerTime)}
                  </div>
                  <div className="col-md-5">
                    <b>Observaciones:</b>
                    <br />
                    {data.LastReportNota}
                  </div>
                </div>
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });
    $(".dropdown-toggle").dropdown();
  }
}

export default Trafico;
